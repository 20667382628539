// Global
import React from 'react';
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';

// Lib
import { ComponentProps } from 'lib/component-props';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { useCurrentScreenType } from 'lib/utils/get-screen-type';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

type InfographicProps = ComponentProps &
  Marketing.DataTemplates.Infographic & {
    fields: {
      children: Marketing.DataTemplates.InfographicCard[];
    };
  };
type DisplayOptions = { Value: { value: string } };

const Infographic = (props: InfographicProps): JSX.Element => {
  const { fields, params } = props;
  const { screenType } = useCurrentScreenType();
  const getBackgroundImage = (): string => {
    if (screenType === 'mobile') {
      return fields?.MobileImage?.value?.src as string;
    } else if (screenType === 'tablet') {
      return fields?.TabletImage?.value?.src as string;
    } else {
      return fields?.DesktopImage?.value?.src as string;
    }
  };
  const width = params?.EnableFluidWidth === '1' ? 'w-full max-w-[100vw]' : 'w-full container';
  const overlayStyle = {
    backgroundColor: `rgba(255,255,255,${(fields?.OverlayOpacity?.value as number) / 100 || 0})`,
  };

  if (!fields) {
    return <></>;
  }
  // to handle InfographicCards and fallback to first 3 children
  const InfographicCards = fields?.InfographicCards?.length
    ? fields?.InfographicCards
    : fields?.children?.slice(0, 3);
  return (
    <section
      className={classNames('py-5 text-center spacing-md', width)}
      aria-label="infographic"
      style={{
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="w-full py-4 lg:py-6" style={overlayStyle}>
        <div className="max-w-[1130px] mx-auto px-4">
          <div className="grid grid-cols-1 gap-y-4 md:gap-y-6 md:auto-cols-fr md:grid-flow-col-dense lg:gap-x-8">
            {fields?.Title?.value && (
              <RichTextA11yWrapper
                className="mx-auto text-left lg:mx-0 rtaw"
                field={fields?.Title}
                tag="h3"
              />
            )}

            {fields?.Link?.value && (
              <div className="order-3 mx-auto mb-0 text-center md:order-2 md:mx-0 md:text-left w-full col-span-full md:col-span-1 md:w-auto">
                <Button
                  type="default"
                  field={fields?.Link as LinkField}
                  title={(fields?.Link as LinkField).value?.text}
                />
              </div>
            )}

            <ul className="order-2 col-span-3 row-span-2 mb-0 grid h-full list-none items-center justify-center pl-0 md:order-3 grid-cols-2 gap-4 md:gap-6  md:grid-cols-3 lg:gap-8">
              {InfographicCards &&
                InfographicCards.length > 0 &&
                InfographicCards.map(
                  (card: Marketing.DataTemplates.InfographicCard, index: number) => {
                    const DataPointColorValue = (
                      card.fields?.DataPointColor?.fields as DisplayOptions
                    )?.Value?.value;
                    let DataPointColorClass;
                    switch (DataPointColorValue) {
                      case 'color-success':
                        DataPointColorClass = 'text-color-success';
                        break;
                      case 'color-progress-blue':
                        DataPointColorClass = 'text-color-progress-blue';
                        break;
                      default:
                        DataPointColorClass = '';
                    }
                    return (
                      <li
                        key={index}
                        className={classNames(
                          'mb-0 flex flex-1 flex-col items-center justify-center gap-2 pb-0 md:grow md:flex-row w-full',
                          index === 2
                            ? 'col-span-full flex justify-center md:col-span-1 md:flex md:justify-center'
                            : ''
                        )}
                      >
                        <ImageWrapper
                          field={card.fields?.Image}
                          className="w-[45px] h-[45px] md:w-[48px] md:h-[48px] lg:w-[76px] lg:h-[76px] "
                          width="80"
                          height="80"
                        />
                        <div className="mx-auto w-auto lg:w-[70%] text-center md:mx-0 md:text-left">
                          <Text
                            className={classNames('mb-0', DataPointColorClass)}
                            field={card?.fields?.DataPoint}
                            tag="h3"
                          />

                          <div className="mx-auto text-center md:mx-0 md:text-left">
                            <Text
                              className="block paragraph-2-medium"
                              field={card?.fields?.BoldText}
                              tag="p"
                            />

                            <RichTextA11yWrapper
                              className="block paragraph-2-regular rtaw"
                              field={card?.fields?.AfterText}
                              tag="p"
                            />
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Infographic;
