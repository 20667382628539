// Global
import { MouseEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { ArticleCard, Presence } from '@sitecore-search/ui';
import { WidgetComponentProps } from '@sitecore-search/react/dist/esm/types';
import type {
  ActionProp,
  ItemClickedAction,
  SearchResponseFacet,
  SearchResponseFacetItem,
  SearchResultsInitialState,
  SearchResultsStoreState,
} from '@sitecore-search/react';
import {
  FacetChangedPayload,
  WidgetDataType,
  useSearchResultsActions,
  widget,
} from '@sitecore-search/react';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';

// Lib
import { Profiles } from 'lib/templates/Feature.BOKF.model';
import { ComponentProps } from 'lib/component-props';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { FacetValue, urlToFacet, useEnsureFacetUrl } from 'lib/utils/use-ensure-facet-url';

// Local
import Button from 'helpers/Button/Button';
import SVG from 'helpers/SVG/SVG';
import classNames from 'classnames';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';

export type BankerSearchProps = Profiles.DataTypes.BankerSearch &
  ComponentProps &
  WidgetComponentProps;

type ArticleCardItemCardProps = {
  className?: string;
  displayText?: boolean;
  description?: string;
  article: ArticleModel;
  onItemClick: ActionProp<ItemClickedAction>;
  index: number;
};

type SearchFacetsProps = {
  facets: SearchResponseFacet[];
  props?: BankerSearchProps;
  state?: SetStateAction<string | undefined>;
  setShowSearchResults: (value: boolean) => void;
};

type SpinnerProps = {
  loading?: boolean;
};

const ArticleHorizontalItemCard = ({ article, onItemClick, index }: ArticleCardItemCardProps) => {
  const dict = useDictionary();
  const dictNMLS = dict?.['Feature.Profiles.BankerProfileNMLS'] || 'NMLS #';

  return (
    <ArticleCard.Root key={article.id}>
      <a
        className="group flex flex-row p-4 gap-6 flex-nowrap w-full relative border rounded hover:border-color-active hover:shadow-small text-color-default-1 hover:text-color-default-1 hover:no-underline bg-background-default-2"
        href={article.url}
        onClick={() => {
          onItemClick({
            id: article.id,
            index,
            sourceId: article.source_id,
          });
        }}
      >
        <div className="max-w-[104px] max-h-[176px] md:max-w-[200px] md:max-h-[248px] min-w-[104px] min-h-[176px] md:min-w-[200px] md:min-h-[248px]">
          <ArticleCard.Image
            src={article?.image_url}
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>
        <ArticleCard.ArticleCardContent className="flex flex-col justify-between w-full paragraph-2-regular">
          <div>
            <ArticleCard.Title className="subtitle-2-medium mb-2 break-words text-color-default-1">
              {article.name}
            </ArticleCard.Title>
            <ArticleCard.Subtitle className="paragraph-2-regular break-words text-color-default-2">
              {article.job_title}
            </ArticleCard.Subtitle>
            <div className="caption text-color-default-2 pb-2 mt-4">
              {dictNMLS}
              {article.nmls}
            </div>
            <div className="caption text-color-default-2 pb-2 break-all">{article.email}</div>
            <address className="caption text-color-default-2 not-italic break-words">
              {article.address}
              <br />
              {article.city}, {article.state}&nbsp;{article.zipcode}
            </address>
          </div>
          <div className="flex justify-end w-full mt-1">
            <div className="w-5 h-5">
              <SVG svg="icon-arrow-right" hidden={false} className="text-color-active block"></SVG>
            </div>
          </div>
        </ArticleCard.ArticleCardContent>
      </a>
    </ArticleCard.Root>
  );
};

export const SearchFacetsServiceLocations = ({
  facets,
  props,
  setShowSearchResults,
}: SearchFacetsProps) => {
  const { onFacetClick } = useSearchResultsActions();
  const { StateSelectText, StateLabel, CitySelectText, CityLabel, SearchLabel } =
    props?.fields || {};

  const [serviceState, setServiceState] = useState<string>(StateSelectText?.value || '');
  const [serviceCity, setServiceCity] = useState<string>(CitySelectText?.value || '');

  const [selectCityDisabled, setSelectCityDisabled] = useState<boolean>(true);
  const [cityFacet, setCityFacet] = useState<FacetChangedPayload>();

  const [cityFacetValidation, setCityFacetValidation] = useState<boolean>(false);
  const [stateFacetValidation, setStateFacetValidation] = useState<boolean>(false);

  const [cityKey, setCityKey] = useState(0);

  const getServiceCities = (array: SearchResponseFacet[], name: string, label: string) => {
    const facetServiceCities = array.filter((a) => a.name === name).map((a) => a.value);
    function removeCityItemsWithSlash(array: SearchResponseFacetItem[]) {
      return array?.filter((item) => {
        return Object.values(item).some(
          (value) => typeof value === 'string' && value.includes(serviceState + '/')
        );
      });
    }
    const serviceCities = removeCityItemsWithSlash(facetServiceCities[0]);
    return [{ name: name, label: label, value: serviceCities }];
  };

  const facetServiceCitiesArray: SearchResponseFacet[] = getServiceCities(
    facets,
    'state_city_breadcrumbs',
    'State/City Breadcrumbs'
  );

  const getServiceStates = (array: SearchResponseFacet[], name: string, label: string) => {
    const facetServiceStates = array.filter((a) => a.name === name).map((a) => a.value);
    function removeStateItemsWithSlash(array: SearchResponseFacetItem[]) {
      return array?.filter((item) => {
        return !Object.values(item).some(
          (value) => typeof value === 'string' && value.includes('/')
        );
      });
    }
    const serviceStates = removeStateItemsWithSlash(facetServiceStates[0]);
    return [{ name: name, label: label, value: serviceStates }];
  };

  const facetServiceStatesArray: SearchResponseFacet[] = getServiceStates(
    facets,
    'state_city_breadcrumbs',
    'State/City Breadcrumbs'
  );

  const handleServiceState = (state: string) => {
    setServiceState(state);
    setCityKey((prevKey: number) => prevKey + 1);
    // setServiceCity(CitySelectText?.value || 'Select One');
    if (serviceCity !== CitySelectText?.value) {
      setServiceCity(CitySelectText?.value as string);
    }
    if (state === StateSelectText?.value) {
      setSelectCityDisabled(true);
      setCityFacetValidation(false);
    } else if (state !== StateSelectText?.value) {
      setStateFacetValidation(false);
      setSelectCityDisabled(false);
    } else if (state === StateSelectText?.value && serviceCity === CitySelectText?.value) {
      setCityFacetValidation(false);
      setStateFacetValidation(false);
    } else {
      return;
    }
  };

  const handleServiceCity = (
    city: {
      label: string;
      value: string;
      key: number;
    },
    searchResponseFacet: SearchResponseFacetItem[],
    name: string
  ) => {
    setServiceCity(city.label);
    if (city.value !== CitySelectText?.value) {
      setCityFacetValidation(false);
    }
    const facetValuetId = serviceState + '/' + city.value;
    const facetValue = searchResponseFacet.find((x) => x.text === facetValuetId);
    setCityFacet({
      facetId: name,
      facetIndex: 0,
      facetValueId: facetValue?.id ?? '',
      facetValueIndex: 0,
      checked: true,
      type: 'valueId',
    });
    setServiceCity(city.value);
  };

  const { onClearFilters } = useSearchResultsActions();

  const handleSearchButton = (e: MouseEvent<Element, globalThis.MouseEvent>) => {
    e.preventDefault();
    if (serviceState === StateSelectText?.value) {
      setStateFacetValidation(true);
      setSelectCityDisabled(true);
      return;
    } else if (serviceCity === CitySelectText?.value) {
      setCityFacetValidation(true);
      return;
    }
    onClearFilters();
    if (cityFacet) {
      onFacetClick(cityFacet);
    }
    setShowSearchResults(true);
  };

  useEnsureFacetUrl(facets);

  useEffect(() => {
    const hasWindow = typeof window !== 'undefined';
    const hash = hasWindow ? window.location.hash.replace(/^#+/, '') : '';

    if (!hash) return;

    const facetsFromUrl = urlToFacet(hash);
    const stateBreadcrumb = facetsFromUrl[0]?.facetValueText?.split('/')[0] || '';
    const cityBreadcrumb = facetsFromUrl[0]?.facetValueText?.split('/')[1] || '';

    handleServiceState(stateBreadcrumb);
    setServiceCity(cityBreadcrumb);

    const facetValue = facets
      .find((a) => a.name === 'state_city_breadcrumbs')
      ?.value.find((x) => x.text === facetsFromUrl[0]?.facetValueText);

    const urlCityFacet = {
      facetId: 'state_city_breadcrumbs',
      facetIndex: 0,
      facetValueId: facetValue?.id || '',
      facetValueIndex: 0,
      checked: true,
      type: 'valueId' as 'valueId' | 'text',
    };

    if (cityFacet !== urlCityFacet) {
      setCityFacet(urlCityFacet);
      onFacetClick(urlCityFacet);
      setShowSearchResults(true);
    }
  }, []);

  const [inputState, setInputState] = useState('');

  const handleStateChange = (value: string) => {
    const modifiedValue = value.replace(/[^a-zA-Z\s]/g, '').slice(0, 2);
    setInputState(modifiedValue);
    return modifiedValue;
  };

  return (
    <div className="w-full flex flex-col md:flex-row gap-8 lg:w-3/4">
      <div className="flex flex-col md:flex-row w-full gap-6 md:gap-4">
        <div className="flex flex-col w-full md:w-1/3">
          <div className="caption text-color-default-2">{StateLabel?.value}</div>

          {facetServiceStatesArray.map((f) => (
            <Select
              options={f.value?.map((fv, index) => {
                return {
                  label: fv.text,
                  value: fv.text,
                  key: index,
                };
              })}
              placeholder={serviceState}
              key={f.label}
              onChange={(state) => handleServiceState(state?.value || '')}
              aria-labelledby="Select State"
              unstyled // Remove all non-essential styles
              classNames={{
                container: ({ isDisabled, isFocused }) =>
                  classNames(
                    'border-b-2 w-full py-0.5',
                    isDisabled
                      ? 'border-background-dark-1'
                      : isFocused
                      ? 'border-color-active'
                      : 'border-color-default-2'
                  ),
                dropdownIndicator: ({ isDisabled, isFocused }) =>
                  classNames(
                    isDisabled
                      ? 'text-background-dark-1'
                      : isFocused
                      ? 'text-color-active'
                      : 'text-color-default-2',
                    'p-2'
                  ),
                menu: () =>
                  classNames(
                    'bg-white',
                    'rounded',
                    'shadow-[0_2px_10px_0px_rgba(0,0,0,0.1)]',
                    'my-1'
                  ),
                menuList: () => classNames('py-1 bok-scrollbar'),
                option: ({ isSelected, isFocused }) =>
                  classNames(
                    isSelected
                      ? 'bg-background-default-1 font-medium text-color-default-1'
                      : 'bg-transparent text-color-default-2',
                    'py-2 px-3 hover:underline',
                    isFocused ? 'underline' : ''
                  ),

                placeholder: ({ isDisabled, isFocused }) =>
                  classNames(
                    'paragraph-2-regular',
                    'mx-0.5',
                    isDisabled ? 'text-color-disabled' : 'text-color-default-1',
                    isFocused ? '' : ''
                  ),
              }}
              inputValue={inputState}
              onInputChange={handleStateChange}
            />
          ))}

          {/* {facetServiceStatesArray.map((f) => (
            <Select
              options={f.value?.map((fv, index) => {
                return {
                  label: fv.text,
                  value: fv.text,
                  key: index,
                };
              })}
              placeholder={<div>{serviceState}</div>}
              key={f.label}
              onChange={(state) => handleServiceState(state?.value || '')}
              className={`w-full paragraph-2-medium text-color-default-1 py-2 border-b-2 border-color-default-2 ${
                stateFacetValidation ? 'border-color-negative' : ''
              } cursor-pointer bg-background-default-2`}
              aria-labelledby="Select State"
            />
          ))} */}
          {stateFacetValidation && (
            <div className="flex flex-row mt-2">
              <SVG
                svg="icon-error"
                className="max-w-[20px] max-h-[20px] text-icon-error mr-2"
              ></SVG>
              <p className="caption text-color-negative">Select state.</p>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full md:w-3/4">
          <div
            className={`caption text-color-default-2 ${
              selectCityDisabled ? 'text-color-disabled' : ''
            }`}
          >
            {CityLabel?.value}
          </div>
          {facetServiceCitiesArray.map((f) => {
            const options = f.value?.map((fv, index) => {
              return {
                label: fv.text.split('/')[1],
                value: fv.text.split('/')[1],
                key: index,
              };
            });

            return (
              <Select
                options={options as unknown as OptionsOrGroups<string, GroupBase<string>>}
                placeholder={CitySelectText?.value || 'Select One'}
                key={cityKey}
                //eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(city) => handleServiceCity(city as any, f.value, f.name)}
                aria-labelledby="Select City"
                unstyled // Remove all non-essential styles
                classNames={{
                  container: ({ isDisabled, isFocused }) =>
                    classNames(
                      'border-b-2 w-full py-0.5',
                      isDisabled
                        ? 'border-background-dark-1'
                        : isFocused
                        ? 'border-color-active'
                        : 'border-color-default-2'
                    ),
                  dropdownIndicator: ({ isDisabled, isFocused }) =>
                    classNames(
                      isDisabled
                        ? 'text-background-dark-1'
                        : isFocused
                        ? 'text-color-active'
                        : 'text-color-default-2',
                      'p-2'
                    ),
                  menu: () =>
                    classNames(
                      'bg-white',
                      'rounded',
                      'shadow-[0_2px_10px_0px_rgba(0,0,0,0.1)]',
                      'my-1'
                    ),
                  menuList: () => classNames('py-1'),
                  option: ({ isSelected, isFocused }) =>
                    classNames(
                      isSelected
                        ? 'bg-background-default-1 font-medium text-color-default-1'
                        : 'bg-transparent text-color-default-2',
                      'py-2 px-3 hover:underline',
                      isFocused ? 'underline' : ''
                    ),
                  placeholder: ({ isDisabled, isFocused }) =>
                    classNames(
                      'paragraph-2-regular',
                      'mx-0.5',
                      isDisabled
                        ? 'text-color-disabled'
                        : isFocused
                        ? 'text-color-default-2'
                        : 'text-color-default-1'
                    ),
                }}
                isDisabled={selectCityDisabled}
              />
            );
          })}
          {cityFacetValidation && serviceState && (
            <div className="flex flex-row mt-2">
              <SVG
                svg="icon-error"
                className="max-w-[20px] max-h-[20px] text-icon-error mr-2"
              ></SVG>
              <p className="caption text-color-negative">
                Select the city closest to the property.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="block mt-0 md:mt-4">
        <Button
          type="default"
          size="large"
          title="Search"
          label={SearchLabel?.value || 'Search'}
          onClick={(e) => handleSearchButton(e)}
          aria-labelledby="Search Button for Banker using City and State"
        />
      </div>
    </div>
  );
};

const Spinner = ({ loading = false }: SpinnerProps) => {
  return (
    <Presence present={loading}>
      <div className="text-center">
        <div role="status">
          <svg
            aria-busy={loading}
            aria-hidden={!loading}
            focusable="false"
            viewBox="0 0 20 20"
            className="inline animate-spin w-10 text-icon-action"
          >
            <path
              fill="currentColor"
              d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Presence>
  );
};

type ArticleModel = {
  id: string;
  type?: string;
  title?: string;
  name?: string;
  subtitle?: string;
  url?: string;
  description?: string;
  content_text?: string;
  image_url?: string;
  source_id?: string;
  first_name?: string;
  last_name?: string;
  job_title?: string;
  nmls?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
};

type ArticleSearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  props?: BankerSearchProps;
  facetsFromUrl?: FacetValue[];
};

type InitialState = SearchResultsInitialState<'itemsPerPage' | 'keyphrase' | 'page' | 'sortType'>;

const searchSource = process.env.BANKER_SEARCH_SOURCE_ID;
const searchSourceIds = searchSource?.split('|') || [];

export const BankerProfileSearchComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 24,
  props,
}: ArticleSearchResultsProps) => {
  const {
    sanitizedResults,
    widgetRef,
    actions: { onItemClick },
    queryResult: { isLoading, isFetching, data: { facet: facets = [] } = {} },
  } = useSanitizedSearchResults<ArticleModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFacetTypes([{ name: 'state_city_breadcrumbs' }])
        .setSearchFacetAll(false);
    },
    state: () => {
      const hasWindow = typeof window !== 'undefined';
      const hash = hasWindow ? window.location.hash.replace(/^#+/, '') : '';
      const facetsFromUrl = urlToFacet(hash);

      return {
        sortType: defaultSortType,
        page: defaultPage,
        itemsPerPage: defaultItemsPerPage,
        keyphrase: defaultKeyphrase,
        selectedFacets: facetsFromUrl,
      };
    },
  });

  const { Title, NamePlaceholder, ResultsNote } = props?.fields || {};

  const [showSearchResults, setShowSearchResults] = useState<boolean>();
  const [searchInput, setSearchInput] = useState<string>();

  const dict = useDictionary();
  const dictNoResults =
    dict?.['Feature.Profiles.BankerSearch.No.Results'] || 'Your search returned no results.';

  const refSearchInput = useRef<HTMLInputElement>(null);

  const filteredArticles = sanitizedResults?.content?.filter(
    (x) => !searchInput || x.name?.toUpperCase().indexOf(searchInput?.toUpperCase().trim()) !== -1
  );

  const keyphraseChangeFn = (e: { target: { value: SetStateAction<string | undefined> } }) => {
    setSearchInput(e.target.value);
  };

  const shuffleBankerProfiles = (array: ArticleModel[]) => {
    let currentIndex = array.length;
    while (currentIndex != 0) {
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  };

  sanitizedResults?.content && shuffleBankerProfiles(sanitizedResults?.content);

  const handleClearSearchInput = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    refSearchInput.current?.focus();
    setSearchInput('');
  };

  useEffect(() => {
    refSearchInput.current?.focus();
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-full bg-background-default-1">
        <Spinner loading />
      </div>
    );
  }

  return (
    <section className="container mt-6 lg:mt-8 " ref={widgetRef}>
      <div className="p-4 md:p-8 lg:p-10 bg-background-default-2 shadow-lg" ref={widgetRef}>
        {isFetching && (
          <div className="flex justify-center items-center h-screen w-full bg-background-default-2 relative">
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col justify-center items-center z-40">
              <Spinner loading />
            </div>
          </div>
        )}
        <section className="flex flex-col">
          <div className="flex flex-col gap-8">
            <h4 className="w-full subtitle-1-medium">{Title?.value}</h4>
            <SearchFacetsServiceLocations
              setShowSearchResults={setShowSearchResults}
              facets={facets}
              props={props}
            />
          </div>
          <div className="flex flex-row gap-0 md:gap-4 mt-8">
            <div className="w-full block relative my-2 md:my-4 lg:w-5/12 paragraph-2-medium">
              <input
                value={searchInput}
                ref={refSearchInput}
                onChange={(e) => {
                  keyphraseChangeFn(e);
                  setSearchInput(e.target.value);
                }}
                className={`peer border ${
                  showSearchResults
                    ? 'border-color-default-2 placeholder:text-color-default-2'
                    : 'stroke-strokes-disabled placeholder:text-color-disabled cursor-not-allowed'
                } rounded-full w-full p-2 pl-5 pr-12 bg-color-inverse focus:outline-strokes-action placeholder:paragraph-3-regular text-color-default-1 paragraph-3-medium`}
                placeholder={NamePlaceholder?.value}
                disabled={!showSearchResults}
              />
              <div
                className={`absolute top-0 right-[46px] bottom-0 ${
                  searchInput?.length && searchInput !== '' ? '' : 'hidden'
                }`}
              >
                <button
                  type="button"
                  onClick={(e) => handleClearSearchInput(e)}
                  className="flex items-center justify-center w-[40px] h-full stroke-color-default-2"
                >
                  <span className="sr-only">Clear Search Input</span>
                  <SVG
                    svg="icon-close"
                    hidden={false}
                    className="w-[12px] h-[12px] min-w-[12px] min-h-[12px]"
                  ></SVG>
                </button>
              </div>
              <div className="absolute top-0 right-0 bottom-0">
                <button
                  type="button"
                  onClick={(e) => e.preventDefault}
                  className={`flex items-center justify-center w-[46px] h-full rounded-r-full ${
                    showSearchResults
                      ? 'bg-button-primary-enabled hover:bg-button-primary-hover'
                      : 'bg-button-secondary-disabled hover:bg-button-secondary-disabled cursor-not-allowed'
                  } text-color-inverse peer-focus:text-color-active`}
                  disabled={!showSearchResults}
                >
                  <span className="sr-only">Search</span>
                  <SVG
                    svg="icon-search"
                    hidden={false}
                    className="w-[20px] h-[20px] min-w-[20px] min-h-[20px]"
                  ></SVG>
                </button>
              </div>
            </div>
            <div className="hidden lg:w-full md:flex items-center ">
              <div className="flex-grow border-t border-color-default-2"></div>
            </div>
          </div>
          <section className="flex flex-col mt-8">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-5 justify-center">
              {showSearchResults &&
                filteredArticles?.map((a, index) => (
                  <ArticleHorizontalItemCard
                    key={a.id}
                    article={a as ArticleModel}
                    index={index}
                    onItemClick={onItemClick}
                    displayText={true}
                  />
                ))}
            </div>
          </section>
        </section>
        {filteredArticles && filteredArticles.length <= 0 && !isFetching && (
          <p className="flex justify-center w-full subtitle-2-medium text-color-default-1">
            {dictNoResults}
          </p>
        )}
        {!showSearchResults && !isFetching && (
          <p className="flex justify-center w-full text-paragraph-2-regular text-color-default-2">
            {ResultsNote?.value}
          </p>
        )}
      </div>
    </section>
  );
};

const BankerProfileSearchWidget = widget(
  BankerProfileSearchComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

export default BankerProfileSearchWidget;
