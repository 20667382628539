//Global
import React, { useEffect } from 'react';

// Lib
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';

// Local
import SearchOverlayWidget from 'widgets/SearchOverlayWidget/SearchOverlayWidget';
import { useSearchOverlayContext } from 'helpers/Providers/SearchOverlayContextProvider';
import SVG from 'helpers/SVG/SVG';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';

export type SearchOverlayProps = BoKNavigation.SiteSearch;

const SearchOverlay = (props: SearchOverlayProps) => {
  const { overlayOpen, setOverlayOpen } = useSearchOverlayContext();
  const { SearchBarPlaceholderText, SearchFormTarget } = props?.fields || {};

  const closeSearchOverlay = () => {
    setOverlayOpen(!overlayOpen);
  };

  useEffect(() => {
    if (overlayOpen) {
      document.querySelector('body')?.classList.add('overflow-y-hidden');
    } else {
      document.querySelector('body')?.classList.remove('overflow-y-hidden');
    }
  }, [overlayOpen]);

  const { fields = undefined } = props;
  if (!fields) {
    return <></>;
  }

  return (
    <section
      className={`${
        overlayOpen ? '' : 'hidden'
      } fixed top-0 bottom-0 left-0 right-0 h-full w-full z-50 overflow-y-auto bg-scrim-clickable`}
    >
      <div
        className="fixed top-0 right-0 left-0 h-[152px] z-[55]"
        style={{ background: 'linear-gradient(1turn, rgba(53, 43, 43, 0), #352b2b)' }}
      ></div>
      <div className="fixed top-0 right-0 left-0 flex items-center justify-end md:max-w-[768px] mx-6 md:mx-auto z-[70]">
        <button
          className="w-6 h-6 min-w-6 min-h-6 my-5 md:my-[40px] text-icon-inverse"
          onClick={closeSearchOverlay}
          type="button"
        >
          <span className="sr-only">Close Search</span>
          <SVG svg="icon-close" hidden={false}></SVG>
        </button>
      </div>
      <WidgetWrapper>
        <SearchOverlayWidget
          rfkId="rfkid_7"
          defaultItemsPerPage={5}
          searchBarPlaceholderText={SearchBarPlaceholderText?.value}
          searchFormTarget={SearchFormTarget}
        />
      </WidgetWrapper>
    </section>
  );
};

export default SearchOverlay;
