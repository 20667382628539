import Script from 'next/script';

/*
  component no longer used.  This hardcoded JS is replaced with JS files received from MotionPoint. 
  We now have 5 different js files from Motionpoint for the 5 sites (4 banks and one statement)
  These files will be checked into to public folder and the script tag will be introduced via CMS (site settings object)

  We can permanently delete this file at a later point.
*/

export const MotionPointScripts = (): JSX.Element => {
  return (
    <>
      {/* MotionPoint init script */}
      <Script id="motionpoint-init">{String.raw`let MP = {
    Version: '3.2.2.0-notrailing',
    SrcLang: 'en',
    Protocols: { 'http:': 'http://', 'https:': 'https://' },
    UrlLang: 'mp_js_current_lang',
    SrcUrl: decodeURIComponent('mp_js_orgin_url'),
    oSite: decodeURIComponent('mp_js_origin_baseUrl'),
    tSite: decodeURIComponent('mp_js_translated_baseUrl'),
    init: function () {
      if (MP.oSite.indexOf('p_js_') == 1) {
        MP.SrcUrl = window.top.document.location.href;
        MP.oSite = MP.tSite = window.top.document.location.host;
        MP.UrlLang = MP.SrcLang;
      }
    },
    switchLanguage: function (url, pref, sync) {
      let _sync = sync;
      let oSite = MP.oSite.replace('http://', '').replace('https://', '').replace(/\/?$/, '');
      let tSite = MP.tSite.replace('http://', '').replace('https://', '').replace(/\/?$/, '');
      url = url.replace('http://', '').replace('https://', '').replace(/\/?$/, '');
      if (
        _sync &&
        typeof MpStorage !== 'undefined' &&
        typeof MpStorage.updatePref !== 'undefined'
      ) {
        MpStorage.updatePref(url, pref);
      }
      lang = pref.substring(0, 2);
      setTimeout(function () {
        let script = document.createElement('SCRIPT');
        let protocol = MP.Protocols[location.protocol];
        if (url == oSite) {
          tSite = tSite.split(/[/?#]/)[0];
          script.src =
            protocol +
            tSite +
            '/' +
            MP.SrcLang +
            MP.UrlLang +
            '/?1023749634;' +
            encodeURIComponent(location.href);
        } else {
          if (MP.SrcLang == lang && tSite == oSite) {
            return false;
          }
          url = url.split(/[/?#]/)[0];
          script.src =
            protocol +
            url +
            '/' +
            MP.SrcLang +
            lang +
            '/?1023749632;' +
            encodeURIComponent(MP.SrcUrl);
        }
        let target = document.getElementsByTagName('script')[0];
        target.parentNode.insertBefore(script, target);
      }, 500);
      return false;
    },
    switchToLang: function (url) {
      if (window.top.location.href == url) {
        if (typeof MpStorage !== 'undefined' && typeof MpStorage.updatePref !== 'undefined')
          MpStorage.updatePref(MP.oSite, MP.SrcLang);
      } else {
        window.top.location.href = url;
      }
    },
  };`}</Script>

      {/* MotionPoint Execution script */}
      <Script id="motionpoint-execute">
        {String.raw`let SC = {
    Brand: {},
    Chat: {},
  };

  (() => {
    MP.UrlLang = 'mp_js_current_lang';
    MP.SrcUrl = decodeURIComponent('mp_js_orgin_url');
    MP.oSite = decodeURIComponent('mp_js_origin_baseUrl');
    MP.tSite = decodeURIComponent('mp_js_translated_baseUrl');
    MP.init();
    let mp_langLink = function () {
      let langlinks = document.querySelectorAll('.langLink');
      for (let i = 0; i < langlinks.length; i++) {
        // langlinks.item(i).setAttribute('href', '');
        langlinks.item(i).onclick = function () {
          MP.init();
          let lang = this.getAttribute('data-lang');
          let url = this.getAttribute('data-href');
          let tSite = MP.tSite.replace(/(https?:\/\/|\/?$)/g, '');
          url = url.replace(/(https?:\/\/|\/?$)/g, '');
          MP.switchLanguage(tSite.search(url) != -1 ? MP.oSite : url, lang, true);
          return false;
        };
      }
    };
    if (window.addEventListener) {
      window.addEventListener('load', mp_langLink, false);
    } else if (window.attachEvent) {
      window.attachEvent('onload', mp_langLink);
    }
  })();`}
      </Script>
    </>
  );
};
