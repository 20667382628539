// Global
import React from 'react';
import { RichTextField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Profiles } from 'lib/templates/Feature.BOKF.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import { useDictionary } from 'lib/hooks/use-dictionary';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import useExperienceEditor from 'lib/use-experience-editor';

export type BankerProfileProps = Profiles.DataTypes.BankerProfile;
export type TableType = { [key: string]: string } | object;

const BankerProfile = (props: BankerProfileProps): JSX.Element => {
  const dict = useDictionary();

  let bankerProfileBlend;

  let bankerProfileConnect;

  let bankerProfileGetStarted;

  let bankerProfileGetStartedSection;

  let bankerProfileGetStartedTitle;

  let bankerProfileNMLS;

  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    bankerProfileBlend = 'Our online mortgage lending platform is hosted by Blend Labs, Inc.';

    bankerProfileConnect = 'Connect';

    bankerProfileGetStarted = 'Apply now or sign in';

    bankerProfileGetStartedSection =
      "After applying, we'll contact you to discuss loan details and answer any questions. You can connect with your mortgage banker using the contact information above for even more personalized service and advice.";

    bankerProfileGetStartedTitle = 'Are you ready to get started?';

    bankerProfileNMLS = 'NMLS #';
  } else {
    bankerProfileBlend =
      dict?.['Feature.Profiles.BankerProfileBlend'] ||
      'Our online mortgage lending platform is hosted by Blend Labs, Inc.';

    bankerProfileConnect = dict?.['Feature.Profiles.BankerProfileConnect'] || 'Connect';

    bankerProfileGetStarted =
      dict?.['Feature.Profiles.BankerProfileGetStarted'] || 'Apply now or sign in';

    bankerProfileGetStartedSection =
      dict?.['Feature.Profiles.BankerProfileGetStartedSection'] ||
      "After applying, we'll contact you to discuss loan details and answer any questions. You can connect with your mortgage banker using the contact information above for even more personalized service and advice.";
    bankerProfileGetStartedTitle =
      dict?.['Feature.Profiles.BankerProfileGetStartedTitle'] || 'Are you ready to get started?';

    bankerProfileNMLS = dict?.['Feature.Profiles.BankerProfileNMLS'] || 'NMLS #';
  }

  const {
    Image,
    FirstName,
    LastName,
    JobTitle,
    Logo,
    Phone,
    Mobile,
    Fax,
    Email,
    Address1,
    Address2,
    City,
    State,
    Zip,
    Link,
    ApplicationLink,
    ProfileBio,
    NMLS,
  } = props?.fields || {};

  const phone: LinkField = {
    value: {
      href: `tel:+${Phone?.value.replace(/-/g, '')}`,
      text: Phone?.value,
    },
  };

  const mobile: LinkField = {
    value: {
      href: `tel:+${Mobile?.value.replace(/-/g, '')}`,
      text: Mobile?.value,
    },
  };

  const fax: LinkField = {
    value: {
      href: `fax:+${Fax?.value.replace(/-/g, '')}`,
      text: Fax?.value,
    },
  };

  const email: LinkField = {
    value: {
      href: `mailto:${Email?.value}`,
      text: Email?.value,
    },
  };

  const isEE = useExperienceEditor();

  return (
    <section className="component container ">
      <div className="flex flex-col lg:flex-row gap-y-4 md:gap-y-8 gap-x-6 bg-background-default-2 border border-strokes-default-3 rounded p-4 md:p-6 lg:p-8">
        {/* left side longform */}
        <div className="flex mobile:flex-col flex-row lg:flex-col gap-x-6 gap-y-8 lg:gap-y-6">
          <ImageWrapper
            field={Image}
            height="258"
            width="208"
            className="mobile:w-full w-1/3 lg:w-full object-cover"
          />
          <div className="flex flex-col mobile:w-full w-2/3 lg:w-full gap-2 justify-center lg:justify-normal">
            <h4 className="flex flex-row text-color-brand">
              <RichTextA11yWrapper field={FirstName} /> &nbsp;
              <RichTextA11yWrapper field={LastName} />
            </h4>
            <div className="paragraph-2-medium">
              <RichTextA11yWrapper field={JobTitle?.fields.Value as RichTextField} />
            </div>
            {(NMLS?.value !== '' || isEE) && (
              <div className="flex flex-row paragraph-3-regular text-color-default-2">
                <RichTextA11yWrapper field={{ value: bankerProfileNMLS }} />
                <RichTextA11yWrapper field={NMLS} />
              </div>
            )}

            <ImageWrapper field={Logo} width="208" height="54" className="mt-4" />
          </div>
        </div>
        {/* main content */}
        <div className="flex flex-col w-full gap-y-4">
          <div className="paragraph-1-medium text-color-default-1">
            <RichTextA11yWrapper field={{ value: bankerProfileConnect }} />
          </div>
          <div className="flex flex-col sm:flex-row gap-y-3 gap-x-6 w-full">
            {/* contact info */}
            <div className="flex flex-col gap-y-3 flex-1">
              {(Phone?.value !== '' || isEE) && (
                <div className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                  T
                  {isEE ? (
                    <Text field={Phone} />
                  ) : (
                    <LinkWrapper
                      className="paragraph-2-regular text-color-active lg:text-color-default-1"
                      field={phone}
                    />
                  )}
                </div>
              )}

              {(Mobile?.value !== '' || isEE) && (
                <div className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                  M
                  {isEE ? (
                    <Text field={Mobile} />
                  ) : (
                    <LinkWrapper
                      className="paragraph-2-regular text-color-active lg:text-color-default-1"
                      field={mobile}
                    />
                  )}
                </div>
              )}
              {(Fax?.value !== '' || isEE) && (
                <div className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                  F
                  {isEE ? (
                    <Text field={Fax} />
                  ) : (
                    <LinkWrapper className="paragraph-2-regular text-color-default-1" field={fax} />
                  )}
                </div>
              )}

              {(Email?.value !== '' || isEE) && (
                <>
                  {isEE ? (
                    <Text field={Email} />
                  ) : (
                    <LinkWrapper
                      className="text-color-active paragraph-2-medium hover:underline"
                      field={email}
                    />
                  )}
                </>
              )}
            </div>
            {/* address, linkedin, CTA */}
            <div className="flex flex-col flex-1 gap-y-4">
              <div>
                {Address1 && (
                  <RichTextA11yWrapper
                    className="text-color-default-1 paragraph-2-regular  "
                    tag="div"
                    field={Address1}
                  />
                )}
                {Address2 && (
                  <RichTextA11yWrapper
                    className="text-color-default-1 paragraph-2-regular  "
                    tag="div"
                    field={Address2}
                  />
                )}

                <div className="flex">
                  <RichTextA11yWrapper
                    className="text-color-default-1 paragraph-2-regular  "
                    tag="div"
                    field={City}
                  />
                  , &nbsp;
                  <RichTextA11yWrapper
                    className="text-color-default-1 paragraph-2-regular  "
                    tag="div"
                    field={State?.fields.StateName as RichTextField}
                  />
                  &nbsp;
                  <RichTextA11yWrapper
                    className="text-color-default-1 paragraph-2-regular  "
                    tag="div"
                    field={Zip}
                  />
                </div>
              </div>
              <LinkWrapper
                className="text-color-active hover:underline hover:cursor-pointer paragraph-2-medium"
                field={Link || {}}
              />
              <Button
                type="default"
                size="full"
                tag="a"
                href={ApplicationLink?.value.href}
                label={bankerProfileGetStarted}
                fieldClass={ApplicationLink?.value?.class || ''}
              />
              <RichTextA11yWrapper
                tag="div"
                field={{ value: bankerProfileBlend }}
                className="caption text-color-default-1"
              />
            </div>
          </div>
          <hr className="solid divide-strokes-default-3"></hr>
          <RichTextA11yWrapper
            className="paragraph-2-regular rtaw text-color-default-2"
            field={ProfileBio}
          />
          <RichTextA11yWrapper
            field={{ value: bankerProfileGetStartedTitle }}
            className="headline-3"
          />
          <RichTextA11yWrapper
            field={{ value: bankerProfileGetStartedSection }}
            className="paragraph-2-regular text-color-default-2"
          />
          <div className="">
            <Button
              type="auxiliary"
              size="large"
              tag="a"
              href={ApplicationLink?.value.href}
              label={bankerProfileGetStarted}
              fieldClass={ApplicationLink?.value?.class || ''}
            />
          </div>
          <RichTextA11yWrapper
            tag="div"
            field={{ value: bankerProfileBlend }}
            className="caption text-color-default-1"
          />
        </div>
      </div>
    </section>
  );
};

export default BankerProfile;
