import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { ItemType } from 'components/authorable/Header/Header';

export const useCookieRedirection = (disableChannel?: boolean, primaryNavData?: ItemType[]) => {
  const cookieName = '__bok_channel_redirect';
  const isUserRedirectionSessionOut = 'isUserRedirectionSessionOut';

  const LobChannels: string[] = [];
  primaryNavData &&
    Array.isArray(primaryNavData) &&
    primaryNavData.length > 0 &&
    primaryNavData.map((item) => {
      const navigationLinkPath =
        item?.link?.jsonValue?.value?.href?.startsWith('http') ||
        item?.link?.jsonValue?.value?.href?.startsWith('https')
          ? new URL(item?.link?.jsonValue?.value?.href)?.pathname
          : item?.link?.jsonValue?.value?.href;

      navigationLinkPath && LobChannels?.push(navigationLinkPath as string);
    });

  useEffect(() => {
    if (document && window && !disableChannel) {
      const windowLocationObj = window && window.location;
      if (!sessionStorage.getItem(isUserRedirectionSessionOut)) {
        sessionStorage.setItem(isUserRedirectionSessionOut, 'false');
      }
      if (!windowLocationObj.pathname || windowLocationObj?.pathname == '/') {
        if (
          Cookies.get(cookieName) &&
          sessionStorage.getItem(isUserRedirectionSessionOut) == 'false'
        ) {
          const pathName = Cookies.get(cookieName) as string;
          sessionStorage.setItem(isUserRedirectionSessionOut, 'true');
          window.location.href = windowLocationObj?.origin + pathName;
        }
      } else {
        LobChannels.map((item) => {
          windowLocationObj?.pathname?.toLowerCase()?.startsWith(item?.toLowerCase()) &&
            Cookies.set(cookieName, item, { expires: 30 });
        });
      }
    } else {
      document && window && Cookies.get(cookieName) && Cookies.remove(cookieName);
    }
  }, []);
};
