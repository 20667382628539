// Global
import React from 'react';
import { Field, ImageField, Text, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Local
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type FeaturedProfileProp = {
  fields: Fields;
  params: {
    LabelText: string;
  };
};

export type Fields = {
  ExpertFirstName?: Field<string>;
  ExpertLastName?: Field<string>;
  ExpertSubtitle?: Field<string>;
  ExpertImage?: ImageField;
  ExpertLink?: LinkField;

  AuthorImage?: ImageField;
  AuthorName?: Field<string>;
  url?: LinkField;
  AuthorLink?: LinkField;
  AuthorSubtitle?: Field<string>;
};

const FeaturedProfile = (props: FeaturedProfileProp) => {
  const { fields = undefined } = props;
  const { LabelText } = props?.params;

  const fullName = fields?.ExpertFirstName?.value + ' ' + fields?.ExpertLastName?.value;

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      {fields?.AuthorName?.value ? (
        <>
          <ImageWrapper className="max-w-[124px] lg:max-w-[138px]" field={fields?.AuthorImage} />
          <div className="flex flex-col gap-4 justify-center">
            <Text
              tag="span"
              className="text-color-default-1 caption"
              field={{ value: LabelText }}
            />
            <div className="flex flex-col">
              <LinkWrapper
                className="headline-4"
                field={{
                  value: { href: fields?.url?.value, text: fields?.AuthorName?.value },
                }}
              />
              <Text
                tag="span"
                className="text-color-default-1 paragraph-2-regular"
                field={fields?.AuthorSubtitle}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <ImageWrapper className="max-w-[124px] lg:max-w-[138px]" field={fields?.ExpertImage} />
          <div className="flex flex-col gap-4 justify-center">
            <Text
              tag="span"
              className="text-color-default-1 caption"
              field={{ value: LabelText }}
            />
            <div className="flex flex-col">
              <LinkWrapper
                className="headline-4"
                field={{
                  value: { href: fields?.url?.value, text: fullName },
                }}
              />
              <Text
                tag="span"
                className="text-color-default-1 paragraph-2-regular"
                field={fields?.ExpertSubtitle}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FeaturedProfile;
