import React, { useEffect, useRef, useState } from 'react';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import useExperienceEditor from 'lib/use-experience-editor';
import SVG from 'helpers/SVG/SVG';
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Button from 'helpers/Button/Button';

export type InterstitialCallToActionProps = Marketing.DataTemplates.InterstitialCallToAction;

const InterstitialCallToActionModal = (props: InterstitialCallToActionProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const interstitialDialog = useRef<HTMLDivElement>(null);
  const isEE = useExperienceEditor();

  // Setup link Clicks
  useEffect(() => {
    const cssTrigger = props.fields?.ClassTriggerValue?.value;
    if (cssTrigger) {
      const ctaLinks = Array.from(document.getElementsByClassName(cssTrigger));
      ctaLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          setDialogOpen(true);
        });
      });
    }
  }, [props.fields]);

  // Handle keyboard controls
  useEffect(() => {
    const linkElements = interstitialDialog?.current?.querySelectorAll(
      'a[href], button, input, [tabindex]:not([tabindex="-1"])'
    );

    const handleKeyboard = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setDialogOpen(false);
      }

      if (e.key === 'Tab' && linkElements && linkElements.length > 0) {
        const firstElement = linkElements[0] as HTMLElement;
        const lastElement = linkElements[linkElements.length - 1] as HTMLElement;

        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    if (dialogOpen) {
      document.addEventListener('keydown', handleKeyboard);
      document.documentElement.style.overflow = 'hidden';
      return () => {
        document.removeEventListener('keydown', handleKeyboard);
        document.documentElement.style.overflow = '';
      };
    }
    return;
  }, [dialogOpen, interstitialDialog]);

  return (
    <>
      {dialogOpen && (
        <dialog
          className="fixed w-full h-full inset-0 overflow-y-auto z-50 bg-scrim-clickable"
          open
        >
          <div
            className="fixed w-full h-full"
            onClick={() => {
              setDialogOpen(false);
            }}
          ></div>
          <div
            className="absolute	top-1/3 left-0 right-0 px-4 pb-4 pt-10 md:px-6 md:pb-6 md:pt-13 lg:px-8 lg:pb-8 lg:pt-16 mx-8 lg:mx-auto lg:w-1/2 lg:min-w-[728px] bg-background-default-2"
            ref={interstitialDialog}
          >
            <div className="absolute right-1 top-1 md:right-3.5 md:top-3.5 lg:right-6 lg:top-6 text-color-default-1">
              <button
                type="button"
                aria-label="Close"
                onClick={() => {
                  setDialogOpen(false);
                }}
                tabIndex={0}
                className="p-3 md:p-2.5 lg:p-2"
              >
                <div className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6">
                  <SVG svg="icon-close"></SVG>
                </div>
              </button>
            </div>
            <Text
              field={props.fields?.Title}
              tag="div"
              className="headline-3 text-color-default-1"
            ></Text>
            <RichTextA11yWrapper
              field={props.fields?.CopyText}
              className="mt-2 paragraph-2-regular text-color-default-2"
            ></RichTextA11yWrapper>
            <hr className="mt-4 md:mt-6 mb-0" />
            <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6 mt-4 md:mt-6">
              <Button
                field={props.fields?.PrimaryCallToAction as LinkField}
                type="auxiliary"
              ></Button>
              <Button
                field={props.fields?.SecondaryCallToAction as LinkField}
                type="auxiliary"
              ></Button>
            </div>
            <RichTextA11yWrapper
              field={props.fields?.DisclaimerText}
              className="mt-4 md:mt-6 caption text-color-default-1"
            ></RichTextA11yWrapper>
          </div>
        </dialog>
      )}

      {isEE && (
        <>
          <div
            style={{
              backgroundColor: '#e7f3fe',
              borderLeft: '6px solid #2196F3',
              color: 'red',
              padding: '4px 12px',
            }}
          >
            <p>
              Interstitial Call to Action Modal component to edit its content in the Sitecore XM
              Cloud Pages. It will appear as a lightbox modal with an overlay on site page.
            </p>
          </div>
          <dialog className=" relative flex flex-col w-full h-auto" open>
            <div
              className=" flex w-full h-full"
              onClick={() => {
                setDialogOpen(false);
              }}
            ></div>
            <div
              className=" relative px-4 pb-4 pt-10 md:px-6 md:pb-6 md:pt-13 lg:px-8 lg:pb-8 lg:pt-16 mx-8 lg:mx-auto lg:w-1/2 lg:min-w-[728px] bg-background-default-2"
              ref={interstitialDialog}
            >
              <div className="absolute right-1 top-1 md:right-3.5 md:top-3.5 lg:right-6 lg:top-6 text-color-default-1">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                  tabIndex={0}
                  className="p-3 md:p-2.5 lg:p-2"
                >
                  <div className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6">
                    <SVG svg="icon-close"></SVG>
                  </div>
                </button>
              </div>
              <Text
                field={props.fields?.Title}
                tag="div"
                className="headline-3 text-color-default-1"
              ></Text>
              <RichTextA11yWrapper
                field={props.fields?.CopyText}
                className="mt-2 paragraph-2-regular text-color-default-2"
              ></RichTextA11yWrapper>
              <hr className="mt-4 md:mt-6 mb-0" />
              <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6 mt-4 md:mt-6">
                <Button
                  field={props.fields?.PrimaryCallToAction as LinkField}
                  type="auxiliary"
                ></Button>
                <Button
                  field={props.fields?.SecondaryCallToAction as LinkField}
                  type="auxiliary"
                ></Button>
              </div>
              <RichTextA11yWrapper
                field={props.fields?.DisclaimerText}
                className="mt-4 md:mt-6 caption text-color-default-1"
              ></RichTextA11yWrapper>
            </div>
          </dialog>
        </>
      )}
    </>
  );
};

export default InterstitialCallToActionModal;
