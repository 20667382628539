export interface LocationSearchProps {
  setSearchValue: (searchValue: string) => void;
  searchValue: string;
  onSearch: (location: string) => void;
  onGetCurrentLocation: () => void;
  searchPlaceholderText: string;
}

export interface DistanceOption {
  label: string;
  value: number;
}

export interface MapProps {
  locationItemIndex: number | null;
  defaultLocationName: string;
  locationData: LocationData[];
  distance?: number;
  currentLat: number;
  currentLng: number;
  setScrollToLocationIndex: (index: number | null) => void;
}

export interface LocationData {
  id: number;
  branch: boolean;
  inStore: boolean;
  instantIssue: boolean;
  mortgage: boolean;
  business: boolean;
  atm: boolean;
  distanceFromCenter: number;
  city: string;
  state: string;
  zip: string;
  address: string;
  locationName: string;
  surchargeFree: boolean;
  latitude: number;
  longitude: number;
}
