import { useEffect, useState } from 'react';

/**
 * Injects an external script into the document if it doesn't already exist.
 *
 * @param url - The external script's source URL.
 * @returns A promise that resolves with the injected script element.
 */
export const injectScript = (url: string): Promise<HTMLScriptElement> => {
  return new Promise((resolve, reject) => {
    // Check if the script is already loaded
    let scriptElement = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement;

    if (scriptElement) {
      resolve(scriptElement); // Return the existing script element
      return;
    }

    // Create a new script element if it doesn't exist
    scriptElement = document.createElement('script');
    scriptElement.src = url;
    scriptElement.async = true;

    // Set up load and error handlers
    scriptElement.onload = () => resolve(scriptElement);
    scriptElement.onerror = () => reject(`Failed to load script: ${url}`);

    // Append the script to the body
    document.body.appendChild(scriptElement);
  });
};

/**
 * Custom hook to load external scripts asynchronously.
 *
 * @param urls - An array of external script URLs to load.
 * @returns A boolean indicating if all scripts have been loaded.
 */
export const useExternalScripts = (urls: Array<string>): boolean => {
  const [isScriptsLoaded, setIsScriptsLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!urls || urls.length === 0) {
        setIsScriptsLoaded(true);
        return;
      }

      try {
        // Inject scripts sequentially
        for (const url of urls) {
          await injectScript(url);
        }
        setIsScriptsLoaded(true);
      } catch (error) {
        console.error('Error loading scripts:', error);
        setIsScriptsLoaded(false);
      }
    })();
  }, [urls]);

  return isScriptsLoaded;
};
