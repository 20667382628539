// "B942B3DF-8894-4BA8-B97D-12E12AC88E60"

const HeaderQuery = `query($datasource: String!, $language: String!) {
  header: item(path: $datasource, language: $language) {
    id
    name
    __typename
    ... on NavigationConfiguration {
      logo {
        jsonValue
      } 
      logoLink {
        jsonValue
      }
    }
    children {
      total
      
      results {
        displayName
        template{
          name
        }
        id
        displayName
        name
        __typename
        ... on NavigationLink {
          hideLabelSmallDesktop {
            jsonValue
          }
          icon:field(name:"icon") {
            jsonValue
          }
          link {
            jsonValue
          }
          text {
            jsonValue
          }
        }
        ... on Item {
          id
          __typename
        }
        children(first: 20) {
          total
          results {
          ... on Item {
              id
              __typename
            }
            ... on NavigationLabel {
                labelText {
                  jsonValue
              }
            }
            ... on NavigationLink {
              
              hideLabelSmallDesktop {
                jsonValue
              }
              icon:field(name:"icon") { # we are doing this because sitecore is giving us an error when we tried using "icon"
                jsonValue
              }
              link {
                jsonValue
              }
              text {
                jsonValue
              }
              children {
                total
                results {
                  ... on NavigationLink {
                    hideLabelSmallDesktop {
                      jsonValue
                    }
                    icon:field(name:"icon") {
                      jsonValue
                    }
                    link {
                      jsonValue
                    }
                    text {
                      jsonValue
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default HeaderQuery;
