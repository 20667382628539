import React from 'react';
import { ComponentParams } from '@sitecore-jss/sitecore-jss-nextjs';
import { Field, Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { FilterAnyOf, FilterAnd, FilterEqual, FilterNot } from '@sitecore-search/react';

import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import { ResourceListWidget } from 'widgets/ResourceListWidget/ResourceListWidget';

interface RelatedResourcesProps {
  params: ComponentParams & { Title?: string; NumberToShow?: string };
}

type Topic = Item & {
  fields: {
    Name: Field<string>;
  };
};

const RelatedResources = (props: RelatedResourcesProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();

  const {
    params: { Title = 'Related Resources', NumberToShow = '3' },
  } = props;
  const filterValues = (sitecoreContext.route?.fields?.Topics as Array<Topic>)?.map(
    (topic) => topic.name
  );

  const SearchIDEnvPostFix = process.env.NEXT_PUBLIC_SEARCH_ID_ENV_POSTFIX || '';

  if (!filterValues) return <></>;

  return (
    <WidgetWrapper>
      <ResourceListWidget
        title={{ tag: 'h4', text: Title, classes: '' }}
        initialCount={NumberToShow}
        SkipItemFromSearch={0}
        filters={
          new FilterAnd([
            new FilterAnyOf('topics', filterValues),
            new FilterNot(new FilterEqual('id', sitecoreContext.itemId + SearchIDEnvPostFix)),
          ])
        }
        rfkId="related_resources"
      />
    </WidgetWrapper>
  );
};

export default RelatedResources;
