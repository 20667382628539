// Global
import React, { useEffect, useState } from 'react';
import { Field, ImageField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import classNames from 'classnames';
import {
  FilterAnyOf,
  WidgetRequest,
  Request,
  DataProvider,
  SearchResponse,
} from '@sitecore-search/react';
import { usePathname } from 'next/navigation';

// Lib
import { formatTimestamp } from 'lib/utils/date-utils';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { ComponentProps } from 'lib/component-props';
import { SitecoreIds } from 'lib/constants/sitecore-ids';
import { getThumnailUrl } from 'lib/utils/vimeo-thumbnail';
import { LOCALE_CONFIG, SupportedLanguage } from 'lib/constants/locale-constant';
import { useNavContext } from 'lib/react-context/NavContext';

//helpers
import Button from 'helpers/Button/Button';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { replaceTokens, SourceObject, TokenObject } from 'lib/utils/token-replacer';

type CategoryType = 'category-1' | 'category-2' | 'category-3' | 'category-4' | undefined;
export type DisplayOptions = { Value: Field<string> };

type PageTypes = 'Category';

export type RelatedResourcesProps = Omit<ComponentProps, 'params'> & {
  uid: string;
  componentName: string;
  dataSource: string;
  params: Params;
  fields: Fields;
};

export type Params = {
  DisplayOptions: DisplayOptions;
  ImageBleed: string;
  dataSource: string;
};

export type Fields = {
  Headline: Field<string>;
  Hero: ImageField;
  HeroTablet: ImageField;
  ReadTime: Field<string>;
  PublishDate: Field<string>;
  Teaser: Field<string>;
  ContentType: ContentTypePropItem;
  Category: CategoryItemProps[];
  VideoUrl: Field<string>;
  url: Field<string>;
};

export type ContentTypePropItem = {
  name: string;
  url: string;
  displayName: string;
  fields: {
    Name: Field<string>;
  };
};

export type ColorItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Value: Field<string>;
  };
};

export type CategoryItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Color: ColorItemProps;
  };
};

export type InitialStateProp = {
  HeadLine: Field<string>;
  Teaser: Field<string>;
  PublishDate: Field<string>;
  ReadTime: Field<string>;
  Category: unknown;
  Type: Field<string>;
  MediaUrl: ImageField;
  MediaUrlTablet: ImageField;
  url: Field<string>;
};

export const card = tv({
  slots: {
    categoryStripe: [],
  },
  variants: {
    categoryType: {
      'category-1': {
        categoryStripe: 'bg-background-category-1',
      },
      'category-2': {
        categoryStripe: 'bg-background-category-2',
      },
      'category-3': {
        categoryStripe: 'bg-background-category-3',
      },
      'category-4': {
        categoryStripe: 'bg-background-category-4',
      },
      'bokf-red': {
        categoryStripe: 'bg-bokf-red',
      },
    },
  },
});

const FeaturedResource = (props: RelatedResourcesProps): JSX.Element => {
  const initialState: InitialStateProp = {
    HeadLine: { value: '' },
    Teaser: { value: '' },
    PublishDate: { value: '' },
    ReadTime: { value: '' },
    Category: {},
    Type: { value: '' },
    MediaUrl: { value: { src: '' } },
    MediaUrlTablet: { value: { src: '' } },
    url: { value: '' },
  };

  const { sitecoreContext } = useSitecoreContext();
  const navContext = useNavContext();
  const [LHNExpanded, setLHNExpanded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [leftNavExists, setLeftNavExists] = useState(false);

  // Check if LHN is present on the page
  useEffect(() => {
    const exists = document.getElementById('sidebar-navigation')?.hasChildNodes() || false;
    setLeftNavExists(exists);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      const shouldExpand = navContext.leftNavState === 'expanded';
      setLHNExpanded(shouldExpand);
    };

    if (typeof window !== 'undefined') {
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [navContext.leftNavState]);

  const { site } = sitecoreContext;
  const matchSiteName = 'Journalism';

  const { categoryStripe } = card();

  const [renderObj, setRenderObj] = useState(initialState);

  const dict = useDictionary();

  let watchMoreText = undefined;
  let readMoreText = undefined;
  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    watchMoreText = 'Watch More';
    readMoreText = 'Read More';
  } else {
    watchMoreText = dict?.['Feature.Resource.WatchMore'] || 'Watch More';
    readMoreText = dict?.['Feature.Resource.ReadMore'] || 'Read More';
  }

  const pageType: PageTypes | undefined = (() => {
    for (const [, { templateId, templateName }] of Object.entries(
      SitecoreIds?.FeaturedResource?.Route
    )) {
      if (templateId === sitecoreContext?.route?.templateId) {
        return templateName;
      }
    }
    return undefined;
  })() as PageTypes;
  const pathName = usePathname();
  useEffect(() => {
    if (process.env.IS_STORYBOOK || props?.rendering?.dataSource) {
      const stripColor: CategoryType =
        site?.name != matchSiteName
          ? ('bokf-red' as CategoryType)
          : (props?.fields?.Category?.[0]?.fields?.Color?.fields?.Value?.value as CategoryType) ||
            ('bokf-red' as CategoryType);

      const updatedState = {
        ...initialState, // Spread the existing state to keep unchanged values
        HeadLine: props?.fields?.Headline,
        Teaser: props?.fields?.Teaser,
        Category: categoryStripe({
          categoryType: stripColor,
        }),
        ReadTime: props?.fields?.ReadTime,
        Type: props?.fields?.ContentType?.fields?.Name,
        MediaUrlTablet: {
          value: {
            src:
              props?.fields?.ContentType?.fields?.Name?.value === 'Article'
                ? props?.fields?.HeroTablet?.value?.src
                : getThumnailUrl(props?.fields?.VideoUrl?.value),
          },
        },
        MediaUrl: {
          value: {
            src:
              props?.fields?.ContentType?.fields?.Name?.value === 'Article'
                ? props?.fields?.Hero?.value?.src
                : getThumnailUrl(props?.fields?.VideoUrl?.value),
          },
        },
        url: props?.fields?.url,
        PublishDate: props?.fields?.PublishDate,
      };
      setRenderObj(updatedState);
    } else {
      (async () => {
        //#region Prepare related resources request
        const relatedResourcesRequest = new Request();

        let language = 'en';

        if (typeof document !== 'undefined') {
          language = document.getElementsByTagName('html')[0].getAttribute('lang') || language;
        }
        const resolvedLanguage = (language in LOCALE_CONFIG ? language : 'en') as SupportedLanguage;

        const country = LOCALE_CONFIG[resolvedLanguage];

        relatedResourcesRequest.setContext({
          locale: {
            language,
            country,
          },
        });

        const sources = process.env.RESOURCES_SEARCH_SOURCE_ID;

        const relatedResourcesWidget = new WidgetRequest('related_resources');
        relatedResourcesWidget.setEntity('content');
        relatedResourcesWidget.setSearchLimit(parseInt('1'));
        relatedResourcesWidget.setSearchContent({});
        relatedResourcesWidget.setSources(sources?.split('|') as string[]);

        if (pageType) {
          relatedResourcesWidget.setSearchFilter(
            new FilterAnyOf('category', [sitecoreContext?.route?.name])
          );
        }

        relatedResourcesRequest.addWidgetItem(relatedResourcesWidget.toJson());
        try {
          let _relatedResources = await DataProvider.get(relatedResourcesRequest.toJson());

          // Sanitize results if any tokenized results
          if (sitecoreContext.tokenMappings) {
            _relatedResources = replaceTokens(
              _relatedResources as unknown as SourceObject,
              sitecoreContext.tokenMappings as TokenObject
            ) as unknown as SearchResponse;
          }

          const category = _relatedResources?.widgets?.[0]?.content?.[0]['category_color'];

          const stripColor: CategoryType =
            site?.name != matchSiteName
              ? 'bokf-red'
              : (category && Array.isArray(category) && category.length > 0 && category[0]) ||
                ('bokf-red' as CategoryType);

          // Create a new state object based on initialState
          const updatedState = {
            ...initialState, // Spread the previous state to avoid mutation
            HeadLine: { value: _relatedResources?.widgets?.[0]?.content?.[0]['name'] },
            Teaser: { value: _relatedResources?.widgets?.[0]?.content?.[0]['description'] },
            Category: categoryStripe({ categoryType: stripColor }),
            ReadTime: {
              value: _relatedResources?.widgets?.[0]?.content?.[0]['article_time'],
            },
            Type: { value: _relatedResources?.widgets?.[0]?.content?.[0]['type'] },
            MediaUrl: {
              value: {
                src:
                  _relatedResources?.widgets?.[0]?.content?.[0]['type'] == 'Article'
                    ? _relatedResources?.widgets?.[0]?.content?.[0]['image_url']
                    : getThumnailUrl(_relatedResources?.widgets?.[0]?.content?.[0]['media_url']),
              },
            },
            PublishDate: {
              value: new Date(
                _relatedResources?.widgets?.[0]?.content?.[0]['publish_date']
              ).toString(),
            },
            url: { value: _relatedResources?.widgets?.[0]?.content?.[0]['url'] },
          };

          // Use setRenderObj to update the state immutably
          setRenderObj(updatedState);
        } catch (error) {
          console.log('Error in fetching related resources', error);
        }
      })();
    }
  }, [pathName, sitecoreContext?.route?.name]);

  const displayOptions = (props?.params['DisplayOptions'] as unknown as DisplayOptions)?.Value
    ?.value;

  const imageBleed = props?.params?.ImageBleed || '';
  const hasBackground = !displayOptions?.includes('hero--noBkgd');
  const imageLeft = displayOptions?.includes('hero--imgLeft');

  if (!renderObj?.url?.value) return <></>;

  return (
    <section
      className={classNames(
        'container-wide',
        { 'spacing-md': site?.name !== matchSiteName },
        { 'mt-4 md:mt-12 lg:mt-8': pageType }
      )}
      style={{
        marginLeft:
          leftNavExists && !imageLeft
            ? LHNExpanded
              ? windowWidth >= 2200
                ? 'auto'
                : windowWidth >= 768
                ? '-2.5rem'
                : ''
              : windowWidth >= 1930
              ? 'auto'
              : windowWidth >= 768
              ? '-2.5rem'
              : ''
            : '',
      }}
    >
      <WidgetWrapper>
        <div
          className={`relative shadow-small md:shadow-none ${
            hasBackground
              ? 'md:border md:border-strokes-default-3 bg-background-default-2'
              : 'md:border md:border-strokes-default-3'
          }`}
        >
          <div
            className={classNames(
              'flex flex-col md:flex-row md:gap-8',
              imageLeft ? 'justify-start' : 'container'
            )}
            style={{
              paddingLeft:
                leftNavExists && !imageLeft
                  ? LHNExpanded
                    ? windowWidth >= 1940 && windowWidth <= 2200
                      ? '2.25rem'
                      : windowWidth >= 768 && windowWidth <= 1939
                      ? '3.5rem'
                      : windowWidth <= 767
                      ? ''
                      : ''
                    : windowWidth >= 1670 && windowWidth <= 1930
                    ? '2.25rem'
                    : windowWidth >= 1536 && windowWidth <= 1669
                    ? '3.25rem'
                    : windowWidth >= 1412 && windowWidth <= 1535
                    ? '2.25rem'
                    : windowWidth >= 1280 && windowWidth <= 1411
                    ? '3.25rem'
                    : windowWidth >= 1157 && windowWidth <= 1279
                    ? '2.25rem'
                    : windowWidth >= 768 && windowWidth <= 1156
                    ? '3.25rem'
                    : windowWidth <= 767
                    ? ''
                    : ''
                  : '',
              marginLeft:
                leftNavExists && !imageLeft
                  ? LHNExpanded
                    ? windowWidth >= 1900 && windowWidth <= 1939
                      ? '0'
                      : ''
                    : windowWidth >= 1633 && windowWidth <= 1670
                    ? '0'
                    : windowWidth >= 1280 && windowWidth <= 1412
                    ? '0'
                    : windowWidth >= 768 && windowWidth <= 1157
                    ? '0'
                    : windowWidth <= 767
                    ? ''
                    : ''
                  : '',
            }}
          >
            <div
              className={`flex flex-col ${
                imageLeft
                  ? 'order-last basis-1/2 px-4 md:pr-0 py-6 md:py-8 gap-y-4 md:gap-y-8'
                  : 'basis-1/2 md:order-first md:pr-0 py-6 md:py-8 gap-y-4 md:gap-y-8'
              }`}
            >
              <div className="w-full relative">
                <RichTextA11yWrapper
                  className="text-color-default-1 rtaw text-h2-title-sm lg:text-h2-title-lg md:text-[1.5rem] md:leading-[1.75rem]"
                  tag="h2"
                  field={renderObj?.HeadLine}
                />
                <div
                  className={`mt-3 md:mt-4 w-[48px] md:w-[54px] h-1 bottom-0 ${renderObj.Category}`}
                />
              </div>
              <ImageWrapper
                height="400"
                width="600"
                className={classNames(
                  imageBleed == '1' && '-my-8 -mb-2',
                  'w-full h-full object-cover object-center block md:hidden'
                )}
                field={{
                  value: {
                    src: renderObj?.MediaUrlTablet?.value?.src || renderObj?.MediaUrl?.value?.src,
                  },
                }}
              />
              <div className="flex flex-col gap-y-4 md:gap-y-3">
                <RichTextA11yWrapper
                  className="text-color-default-2 paragraph-1-regular rtaw"
                  field={renderObj?.Teaser}
                />
                <div className="flex justify-between">
                  <div>
                    <span className="relative caption text-color-default-1 after:absolute after:-right-2 after:h-[15px] after:top-1/2 after:-translate-y-1/2 after:w-[1px] after:bg-strokes">
                      {formatTimestamp(renderObj?.PublishDate?.value, 'Month DD, YYYY')}
                    </span>
                    <span className="caption text-color-default-1 ml-4">
                      {renderObj?.ReadTime?.value}
                    </span>
                  </div>
                  <div className="flex items-center justify-center text-icon-action md:hidden">
                    <SVG svg="icon-arrow-right" className="[&_svg]:h-[18px] [&_svg]:w-4" />
                  </div>
                </div>
              </div>
              <div className="relative hidden md:block">
                <Button
                  type="auxiliary"
                  size="large"
                  tag="a"
                  href={renderObj?.url?.value}
                  label={renderObj?.Type?.value == 'Article' ? readMoreText : watchMoreText}
                />
              </div>
            </div>

            <div
              className={classNames(
                imageLeft
                  ? 'basis-1/2 relative'
                  : 'absolute w-[calc(50%-3rem)] h-full right-0 top-0'
              )}
            >
              <ImageWrapper
                height="400"
                width="600"
                className={classNames(
                  imageBleed == '1' && '-my-8 -mb-2',
                  'absolute w-full h-full object-cover object-center hidden md:block lg:hidden'
                )}
                field={{
                  value: {
                    src: renderObj?.MediaUrlTablet?.value?.src || renderObj?.MediaUrl?.value?.src,
                  },
                }}
              />
              <ImageWrapper
                height="400"
                width="600"
                className={classNames(
                  imageBleed == '1' && '-my-8 -mb-2',
                  'absolute w-full h-full object-cover object-center hidden lg:block'
                )}
                field={{ value: { src: renderObj?.MediaUrl?.value?.src } }}
              />
            </div>
          </div>

          {/* Absolute div for mobile redirection */}
          <LinkWrapper
            className="absolute inset-0 z-10 md:hidden cursor-pointer"
            field={{ value: { href: renderObj.url?.value } }}
          ></LinkWrapper>
        </div>
      </WidgetWrapper>
    </section>
  );
};

export default FeaturedResource;
