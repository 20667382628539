exports.atmlocationsPlugin = require('../lib/next-config/plugins/atmlocations');
exports.componentPropsPlugin = require('../lib/next-config/plugins/component-props');
exports.corsHeaderPlugin = require('../lib/next-config/plugins/cors-header');
exports.feaasPlugin = require('../lib/next-config/plugins/feaas');
exports.graphqlPlugin = require('../lib/next-config/plugins/graphql');
exports.locationPlugin = require('../lib/next-config/plugins/location');
exports.robotsPlugin = require('../lib/next-config/plugins/robots');
exports.rssPlugin = require('../lib/next-config/plugins/rss');
exports.sassPlugin = require('../lib/next-config/plugins/sass');
exports.sitemapPlugin = require('../lib/next-config/plugins/sitemap');
