import React from 'react';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import SVG from 'helpers/SVG/SVG';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

type LayoutProps = {
  params: { AnchorID?: string; HeadingType?: { Styles?: Field<string>; Tag?: Field<string> } };
  fields: {
    Title?: Field<string>;
    CopyText?: Field<string>;
    children?: Marketing.DataTemplates.BulletItem[];
  };
};

export type BulletedListProps = LayoutProps;

type ItemType = { Value: { value: string } };

const BulletedList = (props: BulletedListProps): JSX.Element => {
  const hasTitleUnderline = props.params?.HeadingType?.Styles?.value.includes('title-border');
  const hasThickUnderline = props.params?.HeadingType?.Styles?.value.includes('thick');
  return (
    <div className={`component`} id={props.params?.AnchorID || ''}>
      <div className="flex flex-col items-start text-color-default-1 flex-center">
        <>
          <RichTextA11yWrapper
            field={props.fields?.Title}
            tag={props.params?.HeadingType?.Tag?.value || 'h2'}
            className={props.params?.HeadingType?.Styles?.value}
          />
          {hasTitleUnderline && (
            <div
              className={`${
                hasThickUnderline ? 'border-b-[5px]' : 'border-b-[3px]'
              } mt-2 border-strokes-brand w-[3rem]`}
            ></div>
          )}
          <div className="mt-2 md:mt-4 lg:mt-5">
            <RichTextA11yWrapper field={props.fields?.CopyText} className="paragraph-3-regular" />
          </div>
        </>
        {props.fields.children?.map((item, i) => {
          return (
            <div className="flex mt-2 md:mt-4 lg:mt-5 gap-x-2" key={i}>
              {(item.fields?.Style?.fields as ItemType)?.Value?.value && (
                <SVG
                  svg={(item.fields?.Style?.fields as ItemType)?.Value?.value}
                  className="w-5 h-5 min-w-5 md:min-w-6 md:w-6 md:h-6 text-icon-action"
                />
              )}
              <RichTextA11yWrapper field={item.fields?.Text} className="paragraph-2-regular" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BulletedList;
