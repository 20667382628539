export const SitecoreIds = {
  FeaturedResource: {
    Route: {
      Category: {
        templateName: 'Category',
        templateId: 'b268b95a-6a23-4db8-8ac5-a13e202e96f3',
      },
    },
  },
  Route: {
    Article: {
      templateName: 'Article',
      templateId: '902d49bd-8633-4b83-a0aa-5f63949dbd0a',
    },
    AuthorDetailsPage: {
      templateName: 'AuthorDetailsPage',
      templateId: 'a9dbafd5-547a-4ab5-91bf-81579023a2ed',
    },
    ExpertDetailsPage: {
      templateName: 'ExpertDetailsPage',
      templateId: '5b5ab339-bead-4f15-a6af-5354779c7224',
    },
    VideoResource: {
      templateName: 'VideoResource',
      templateId: '651335a1-b9f9-487f-9a7f-45c7611d9548',
    },
  },
};
