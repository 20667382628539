import React, { useEffect, useState, useRef } from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ProductComparisonSlider from './ProductComparisonSlider';
import style from './ComparisonChart.module.css';

interface DataFieldType {
  rowHeader: string;
  rowHeaderKey: string;
  value?: string | Field;
  productName?: string;
}

type SlideDataForCustom = {
  productName: string;
  fields: {
    rowHeader: string;
    rowHeaderKey: string;
    field: {
      value:
        | string
        | {
            href: string;
            text: string;
            linktype: string;
            url: string;
            anchor: string;
            target: string;
            id?: string;
            querystring?: string;
            title?: string;
            class?: string;
          };
    };
  }[];
};

interface CustomProductProps {
  dropDownOption: string[];
  addProductText: string;
  slidesData: SlideDataForCustom[];
  responsiveCount: number;
}
type GenericFieldValue = {
  includes(arg0: string): unknown;
  href?: string;
  text?: string;
};

const CustomProduct: React.FC<CustomProductProps> = ({
  dropDownOption,
  addProductText,
  slidesData,
  responsiveCount,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    new Array(responsiveCount).fill('')
  );
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [dropdownvalue, setDropdownvalue] = useState<string[] | null>(null);
  const [focusedOption, setFocusedOption] = useState<number | null>(null);
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [modifiedSlidesData, setModifiedSlidesData] = useState<
    { rowHeader: string; data: DataFieldType[] | '' }[]
  >([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const handleDropdownToggle = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
    setFocusedOption(0);
  };

  const handleProductSelect = (product: string, index: number) => {
    const updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts[index] = product;
    setSelectedProducts(updatedSelectedProducts);
    setDropdownOpen(null);
    setFocusedOption(null);

    slidesData.forEach((item) => {
      if (product === item.productName) {
        item.fields.forEach((itemData) => {
          const matchingIndex = modifiedSlidesData.findIndex(
            (data) => data.rowHeader === itemData.rowHeader
          );
          if (!Array.isArray(modifiedSlidesData[matchingIndex].data)) {
            modifiedSlidesData[matchingIndex].data = [];
          }
          if (matchingIndex !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modifiedSlidesData[matchingIndex].data[index] = {
              value: itemData.field,
              rowHeader: itemData.rowHeader,
              rowHeaderKey: itemData.rowHeaderKey,
              productName: item.productName,
            };
          }
        });
      }
    });
    setModifiedSlidesData([...modifiedSlidesData]);
  };

  const handleCloseDropdown = (index: number) => {
    const updatedSelectedProducts = [...selectedProducts];
    const product = updatedSelectedProducts[index];
    updatedSelectedProducts[index] = '';
    setSelectedProducts(updatedSelectedProducts);
    setDropdownOpen(null);
    setFocusedOption(null);

    slidesData.forEach((item) => {
      if (product === item.productName) {
        item.fields.forEach((itemData) => {
          const matchingIndex = modifiedSlidesData.findIndex(
            (data) => data.rowHeader === itemData.rowHeader
          );
          if (!Array.isArray(modifiedSlidesData[matchingIndex].data)) {
            modifiedSlidesData[matchingIndex].data = [];
          }
          if (matchingIndex !== -1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modifiedSlidesData[matchingIndex].data[index] = '';
          }
        });
      }
    });

    setModifiedSlidesData([...modifiedSlidesData]);
  };

  const isAnyProductSelected = selectedProducts.some((product) => product !== '');

  const similarFields: string[] = [];

  let dataFields: { rowHeader: string; data: DataFieldType[] }[] = Array.from({ length: 1 }, () => {
    return { rowHeader: '', data: Array(responsiveCount).fill('') };
  });

  const empthyFields = dataFields;

  if (modifiedSlidesData.length === 0) {
    slidesData.forEach((item) => {
      if (similarFields.length === 0) {
        item.fields.forEach((itemData) => {
          similarFields.push(itemData.rowHeader);
        });

        dataFields = Array.from({ length: similarFields.length }, () => {
          return { rowHeader: '', data: Array(responsiveCount).fill('') };
        });
      }

      item.fields.forEach((itemData, index) => {
        dataFields[index].rowHeader = itemData.rowHeader;
      });
    });

    setModifiedSlidesData(dataFields);
  }

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownOpen !== null) {
      const clickedInsideDropdown = dropdownRefs.current[dropdownOpen]?.contains(
        event.target as Node
      );
      if (!clickedInsideDropdown) {
        setDropdownOpen(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    setDropdownvalue(dropDownOption);
  }, [dropDownOption]);

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (dropdownOpen !== index) return;

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedOption((prev) =>
        prev === null || prev >= dropDownOption.length - 1 ? 0 : prev + 1
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedOption((prev) =>
        prev === null || prev <= 0 ? dropDownOption.length - 1 : prev - 1
      );
    } else if (event.key === 'Enter' && focusedOption !== null) {
      handleProductSelect(dropDownOption[focusedOption], index);
    } else if (event.key === 'Escape') {
      setDropdownOpen(null);
      setFocusedOption(null);
    }
  };

  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          'w-full flex sticky top-[153px] z-20 shadow-small bg-background-default-2'
        )}
      >
        {Array.from({ length: responsiveCount }).map((_, index) => (
          <div
            key={index}
            className={classNames(
              responsiveCount === 2
                ? 'w-[calc(100%/2)]'
                : responsiveCount === 3
                ? 'w-[calc(100%/3)]'
                : 'w-[calc(100%/4)]',
              `relative p-8 border-[1px] border-solid border-strokes-default-3 text-center`
            )}
            ref={(el) => {
              dropdownRefs.current[index] = el;
            }}
          >
            <div className="inline-flex items-center relative">
              {selectedProducts[index] ? (
                <div className="flex p-2">
                  <Text
                    field={{ value: selectedProducts[index] || addProductText }}
                    tag="span"
                    className={classNames(
                      'paragraph-2-medium',
                      selectedProducts[index] ? 'text-color-default-1' : 'text-color-active'
                    )}
                  />
                  <div
                    className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 flex items-center justify-center cursor-pointer"
                    onClick={() => handleCloseDropdown(index)}
                  >
                    <SVG svg="icon-close" className="text-icon-default-1 ml-2" />
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => handleDropdownToggle(index)}
                  className="flex items-center p-2 hover:bg-background-dark-1 hover:rounded focus:bg-background-dark-1 focus:rounded focus:border-b-2 focus:border-solid focus:border-strokes-action relative"
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  <Text
                    field={{ value: selectedProducts[index] || addProductText }}
                    tag="span"
                    className={classNames(
                      'paragraph-2-medium',
                      selectedProducts[index] ? 'text-color-default-1' : 'text-color-active'
                    )}
                  />
                  <div className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 inline-flex ml-2 items-center justify-center cursor-pointer">
                    <SVG svg="icon-add" className="text-color-active" />
                  </div>
                </button>
              )}

              {dropdownOpen === index && !selectedProducts[index] && (
                <ul className="absolute top-[41px] bg-background-default-2 border shadow-lg mt-1 w-full  md:max-w-[216px] lg:max-w-[236px] max-h-60 overflow-auto z-[1000] left-0 text-start">
                  {dropdownvalue && dropdownvalue.length > 0 ? (
                    dropdownvalue.map((product, optionIndex) => (
                      <li
                        key={optionIndex}
                        onClick={() => handleProductSelect(product, index)}
                        className={classNames(
                          'px-4 py-2 hover:bg-background-dark-1 cursor-pointer',
                          focusedOption === optionIndex ? 'bg-background-dark-1' : '',
                          selectedProducts.every((productIndexName) => productIndexName !== product)
                            ? 'block'
                            : 'hidden'
                        )}
                      >
                        <Text
                          field={{ value: product }}
                          tag="span"
                          className="text-color-active paragraph-2-regular"
                        />
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2">No products available</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="flex w-full flex-col">
        {isAnyProductSelected
          ? modifiedSlidesData.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                className={modifiedSlidesData.length === 1 ? style.empthy_block : ''}
              >
                <RichTextA11yWrapper
                  field={{ value: slide.rowHeader }}
                  tag="span"
                  className="text-center py-1 block bg-background-dark-1 rtaw text-color-default-1 paragraph-3-regular"
                />
                <ProductComparisonSlider
                  onSlideChange={(index) => setCurrentSlide(index)}
                  currentSlide={currentSlide}
                  slide={
                    slide as unknown as {
                      data: Array<{
                        rowHeaderKey: string;
                        value?: Field<GenericFieldValue> | string;
                        productName?: string;
                      }>;
                    }
                  }
                />
              </div>
            ))
          : empthyFields.map((slide, slideIndex) => (
              <div key={slideIndex} className={empthyFields.length === 1 ? style.empthy_block : ''}>
                <ProductComparisonSlider
                  onSlideChange={(index) => setCurrentSlide(index)}
                  currentSlide={currentSlide}
                  slide={
                    slide as unknown as {
                      data: Array<{
                        rowHeaderKey: string;
                        value?: Field<GenericFieldValue> | string;
                        productName?: string;
                      }>;
                    }
                  }
                />
              </div>
            ))}
      </div>

      <div
        className={classNames(
          style.comparison_chart_embla__buttons,
          'bg-background-default-2 shadow-small py-3 sticky bottom-0 flex justify-center border gap-[0.6rem] items-center border-solid border-strokes-default-3'
        )}
      ></div>
    </div>
  );
};

export default CustomProduct;
