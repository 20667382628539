// GLobal
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Field,
  GetStaticComponentProps,
  SitecoreContextValue,
} from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import clientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';
import { useNavContext } from 'lib/react-context/NavContext';
import { useRealPathName } from 'lib/utils/useRealPathname';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { hostToReplace } from 'lib/host-to-replace';

// Local
import { SubItemType, LeprechaunHeaderProps, ItemType } from '../Header/Header';
import SVG from 'helpers/SVG/SVG';
import LeftNavigationQuery from './LeftNavigation.graphql';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

type LeftNavigationProps = ComponentProps &
  BoKNavigation.NavigationConfiguration & {
    staticProps?: {
      header?: LeprechaunHeaderProps; // Left Navigation component uses the same data as header
    };
  };

type ExtendedSitecoreContextProps = SitecoreContextValue & {
  IncludeFDICLogo?: Field<boolean>;
  DiscludeFDIC?: Field<boolean>;
};

const LeftNavigation = (serverProps: LeftNavigationProps): JSX.Element => {
  const props = { fields: serverProps?.staticProps?.header };

  const extendedSitecoreContext: ExtendedSitecoreContextProps =
    useSitecoreContext().sitecoreContext;

  const includeFDICLogo = extendedSitecoreContext.IncludeFDICLogo?.value;
  const discludeFDIC = extendedSitecoreContext.route?.fields?.['DiscludeFDIC'] as Field<boolean>;
  const isFDICExists = includeFDICLogo && !discludeFDIC?.value;

  function filterFunc(value: ItemType) {
    return value?.template?.name === 'NavigationLink';
  }

  function filterFunc2(value: ItemType) {
    return value?.template?.name === 'Promo Button';
  }

  // look up Promo Button for subscribe button
  const primaryNavData = serverProps?.staticProps?.header?.children?.results.filter(filterFunc);
  const subscribeButton = serverProps?.staticProps?.header?.children?.results.filter(filterFunc2);

  const pathname = useRealPathName();
  let strippedPath = pathname?.split('/', 2) || ''; // get the first item in path

  if (process.env.IS_STORYBOOK) {
    strippedPath = ['', 'Personal']; // just use personal data for storybook
  }

  const isItem = (item: SubItemType) => {
    const displayName = item?.displayName
      ?.toLowerCase()
      .replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      .replaceAll(' ', '');
    const stripped = strippedPath[1]
      .toLowerCase()
      .replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
      .replaceAll(' ', '');
    return displayName === stripped;
  };

  const selectedItem =
    primaryNavData?.find(isItem) ||
    (primaryNavData && primaryNavData.length === 1 && primaryNavData[0]);

  const [navOpen, setNavOpen] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [scrollPositionZero, setScrollPositionZero] = useState(false);
  const [alertNoticeHeight, setAlertNoticeHeight] = useState(0);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [hasAlerts, setHasAlerts] = useState(false);

  const sitecoreContext = useSitecoreContext();
  const navContext = useNavContext();

  function toggleDrawer() {
    setNavOpen(!navOpen);
    navContext.setLeftNavState(navOpen ? 'collapsed' : 'expanded');
  }

  const updateAlertBannerHeight = () => {
    // Get all alert containers that aren't hidden
    const alertContainers = document.querySelectorAll('.alert-container:not(.hidden)');
    if (alertContainers.length === 0) {
      setHasAlerts(false);
      document.documentElement.style.removeProperty('--alert-notice-height');
    } else {
      const headerElement = document.querySelector('header');
      let totalHeight = 0;

      // Only process alerts that are above the header
      alertContainers.forEach((container) => {
        if (headerElement) {
          const alertRect = container.getBoundingClientRect();
          const headerRect = headerElement.getBoundingClientRect();

          // Check if alert is above header
          if (alertRect.top < headerRect.top) {
            totalHeight += container.clientHeight;
          }
        }
      });

      // Update the CSS variable with total height
      setHasAlerts(true);
      document.documentElement.style.setProperty('--alert-notice-height', `${totalHeight}px`);
    }
  };

  let leftNavExists;
  useEffect(() => {
    leftNavExists = document.getElementById('sidebar-navigation')?.hasChildNodes();
    navContext.setNavItem((selectedItem as ItemType)?.displayName);
    updateAlertBannerHeight();
    window.addEventListener('resize', updateAlertBannerHeight);
    return () => {
      window.removeEventListener('resize', updateAlertBannerHeight);
    };
  }, []);

  const router = useRouter();
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleRouteChange = () => {
      const { CollapseSidebar } = sitecoreContext?.sitecoreContext?.route?.fields || {};
      const isSideBarCollapsed = (CollapseSidebar as Field<boolean>)?.value || false;
      setNavOpen(!isSideBarCollapsed);
      navContext.setLeftNavState(isSideBarCollapsed ? 'collapsed' : 'expanded');
    };

    // Initial call
    handleRouteChange();

    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup subscription on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPositionZero(position === 0);
    setIsScrollingUp(position < prevScrollPosition);
    setPrevScrollPosition(position);
    setScrollPosition(position);
    const isHidden = document.querySelector('.header-hidden') !== null;
    setIsHeaderHidden(isHidden);
  };

  useEffect(() => {
    if (hasAlerts) {
      updateAlertBannerHeight();
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const alertHeight = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue('--alert-notice-height')
      );
      setAlertNoticeHeight(alertHeight);
    }
  }, []);

  const isScrolledPastAlert = scrollPosition > alertNoticeHeight;
  // 44 comes from fixed height of fdic banner
  const alertNoticeTopValue =
    scrollPosition > alertNoticeHeight ? 0 : alertNoticeHeight - scrollPosition + 44;

  if (
    selectedItem &&
    selectedItem?.children?.results &&
    selectedItem?.children?.results?.length > 0
  ) {
    return (
      <aside
        id="sidebar-navigation"
        style={
          isFDICExists && hasAlerts && !scrollPositionZero && !isScrolledPastAlert
            ? {
                top: `${alertNoticeTopValue}px`,
                height: `calc(100vh - ${alertNoticeTopValue}px)`,
              }
            : {}
        }
        className={`left-nav hidden md:flex flex-col pb-8 sticky z-10 -mt-nav-bar bg-background-default-2 shadow-small ${
          isFDICExists
            ? hasAlerts
              ? scrollPositionZero
                ? 'top-[calc(var(--alert-notice-height)+44px)] h-[calc(100vh-var(--alert-notice-height)-44px)]'
                : !isScrolledPastAlert
                ? 'transition-top duration-0 ease-in-out'
                : !isHeaderHidden
                ? 'top-11 h-[calc(100vh-44px)]'
                : !isScrollingUp && !isScrolledPastAlert
                ? 'top-11 h-[calc(100vh-44px)]'
                : !isScrollingUp && isScrolledPastAlert && scrollPosition < alertNoticeHeight + 56
                ? 'top-11 h-[calc(100vh-44px)]'
                : !isScrollingUp && isScrolledPastAlert && scrollPosition > alertNoticeHeight + 56
                ? 'top-0 h-screen'
                : ''
              : !isHeaderHidden
              ? 'top-11 h-[calc(100vh-44px)]'
              : 'top-0 h-screen'
            : 'top-0 h-screen'
        } ${navContext.leftNavState === 'expanded' ? '' : ''} ${
          !navContext.navItem
            ? '' // if we are not on a primary nav page, break out of this nested ternary early and leave no space for left nav
            : navContext.leftNavState === 'expanded'
            ? 'w-full min-w-80 max-w-80'
            : `${leftNavExists ? '' : 'max-w-12'}`
        }`}
      >
        <LinkWrapper
          id="underlogo"
          field={props.fields?.logoLink?.jsonValue || {}}
          className={`h-nav-bar flex px-8 py-2 ml-2 ${
            navOpen ? 'translate-x-0' : 'hidden -translate-x-64'
          }`}
        >
          <div className={`h-full ${navOpen ? '' : 'hidden'}`}>
            <ImageWrapper
              field={props.fields?.logo?.jsonValue}
              editable={true}
              className={`w-full h-full ${!isScrollingUp && scrollPosition > 100 ? 'show' : ''}`}
              height="56"
              width="256"
            />
          </div>
        </LinkWrapper>
        <button
          tabIndex={0}
          className="flex items-center py-1.5 pl-1 pr-1.5 absolute top-[calc(var(--nav-height)+2.5rem)] -right-10 overflow-visible z-20  hover:cursor-pointer focus:outline-strokes-action focus-visible:outline-strokes-action"
          onClick={toggleDrawer}
          aria-label="Left Side Navigation Toggle Button"
        >
          <div className="border py-1 pl-1 pr-[6px] flex items-center focus:border-color-active  focus:bg-background-dark-1 border-strokes-default-3 bg-background-default-2 hover:bg-background-dark-1 hover:border-color-active rounded text-color-active">
            {navOpen ? (
              <SVG className="flex items-center rotate-180 h-6 w-6" svg="icon-chevron-right"></SVG>
            ) : (
              <SVG className="flex items-center h-6 w-6" svg="icon-chevron-right"></SVG>
            )}
            <SVG className="ml-1" svg="icon-menu"></SVG>
          </div>
        </button>

        <nav
          className={`flex flex-col h-[calc(100vh-var(--nav-height))] pl-8 pr-2 mt-6 mr-8 ${
            navOpen ? 'overflow-y-auto bok-scrollbar bok-scrollbar--no-border' : 'overflow-y-hidden'
          }`}
        >
          <ul className={`${navOpen ? '' : 'hidden'}`}>
            {selectedItem.children?.results && selectedItem.children.results.length > 0
              ? selectedItem.children.results.map((item, key) =>
                  item && Object.keys(item).length > 0 ? (
                    <React.Fragment key={key}>
                      {/* Static label */}
                      {item.labelText?.jsonValue?.value && (
                        <li
                          key={key}
                          className={`caption text-color-default-2 pl-2 ${
                            key > 0 ? 'mt-6 pt-6 border-t stroke-strokes-default-3' : ''
                          }`}
                        >
                          {item.labelText?.jsonValue?.value}
                        </li>
                      )}
                      {/* END Static Label */}
                      {/* Secondary Links */}
                      {item.link?.jsonValue?.value && (
                        <li>
                          <LinkWrapper
                            className={`${
                              pathname &&
                              pathname === hostToReplace(item.link?.jsonValue)?.value?.href
                                ? 'no-underline text-color-default-1 bg-background-dark-1 '
                                : ''
                            } flex p-2 paragraph-2-medium text-color-default-1 rounded hover:no-underline hover:text-color-default-1 hover:bg-background-dark-1 focus:no-underline focus:outline-strokes-action focus:bg-background-dark-1 focus:outline focus:outline-2 focus-visible:outline-strokes-action focus-visible:outline focus-visible:outline-2 active:bg-background-dark-1 active:outline-strokes-action active:outline-2 active:outline`}
                            field={item.link?.jsonValue || {}}
                          >
                            {item.text?.jsonValue?.value}
                          </LinkWrapper>
                          {/* Tertiary Links */}
                          {item.children?.results && item.children.results.length > 0 && (
                            <ul>
                              {item.children?.results.map((subItem, key: number) => {
                                return (
                                  <li key={key}>
                                    <LinkWrapper
                                      className={`${
                                        pathname &&
                                        pathname ===
                                          hostToReplace(subItem.link?.jsonValue)?.value?.href
                                          ? 'no-underline text-color-default-1 bg-background-dark-1 paragraph-2-medium'
                                          : 'paragraph-2-regular'
                                      } flex py-2 pr-2 pl-4 text-color-default-1 rounded hover:no-underline hover:text-color-default-1 hover:bg-background-dark-1 focus:no-underline focus:outline-strokes-action focus:bg-background-dark-1 focus:outline focus:outline-2 focus-visible:outline-strokes-action focus-visible:outline focus-visible:outline-2 active:bg-background-dark-1 active:outline-strokes-action active:outline-2 active:outline`}
                                      field={subItem.link?.jsonValue || {}}
                                    >
                                      {subItem.text?.jsonValue?.value}
                                    </LinkWrapper>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                          {/* END Tertiary Links */}
                        </li>
                      )}
                      {/* END Secondary Label */}
                    </React.Fragment>
                  ) : null
                )
              : null}
          </ul>
          <ul className={`mt-4 space-y-2.5 ${navOpen ? '' : 'hidden'}`}>
            {subscribeButton?.[0]?.children?.results?.map((item, key) => {
              if (!item?.link?.jsonValue) return null;
              return (
                <li className="" key={key}>
                  <LinkWrapper
                    className="btn btn-auxiliary w-full hover:no-underline focus:no-underline"
                    field={{
                      value: {
                        href: item.link?.jsonValue?.value?.href,
                        text: item.text?.jsonValue?.value,
                        class: item.link?.jsonValue?.value?.class,
                      },
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </nav>
      </aside>
    );
  } else {
    return <></>;
  }
};

export default LeftNavigation;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<unknown>(LeftNavigationQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
