import { ComponentProps } from 'lib/component-props';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import ModalWrapper from 'helpers/ModalWrapper/ModalWrapper';

export type ModelProps = Partial<ComponentProps> & {
  params: {
    ContentCssClass?: string;
    DynamicPlaceholderId?: string;
    TargetCssClass?: string;
    TriggerClassName?: string;
    LaunchOnPageLoad?: string;
  };
};

const Modal = ({ params, rendering }: ModelProps) => {
  const phKey = `modal-${params?.DynamicPlaceholderId}`;

  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const modalTrigger = params?.TriggerClassName;
    if (modalTrigger) {
      const ctaLinks = Array.from(document.getElementsByClassName(modalTrigger));
      ctaLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          setShowModal(true);
        });
      });
    }
  }, [params]);

  React.useEffect(() => {
    if (params?.LaunchOnPageLoad == '1') {
      setShowModal(true);
    }
  }, []);

  return (
    <>
      {showModal && (
        <ModalWrapper onClose={() => setShowModal(false)} position={'center'}>
          <div className="content">
            {rendering && <Placeholder name={phKey} rendering={rendering} />}
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default Modal;
