import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { ComponentProps } from 'lib/component-props';

interface ActionTileFields {
  Title: {
    value: string;
  };
  'AspectRatio-Mobile'?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  'AspectRatio-Tablet'?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  AspectRatio?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  ImageDisplayDesktop?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  ImageDisplayMobile?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  ImageDisplayTablet?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  Position?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  'Position-Tablet'?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  'Position-Mobile'?: {
    fields: {
      Value: {
        value: string;
      };
    };
  } | null;
  Link: {
    value: {
      id: string;
      querystring: string;
      target: string;
      text: string;
      anchor: string;
      url: string;
      title: string;
      class: string;
      linktype: string;
      href: string;
    };
  };
  Image: {
    value: {
      src: string;
      alt: string;
      width: string;
      height: string;
    };
  };
}

interface ItemType {
  fields: ActionTileFields;
}

interface LayoutProps {
  fields: {
    ActionTiles?: ActionTileFields[] | unknown[];
    ContainedCollection?: { value: boolean | undefined };
    children: ActionTileFields[] | unknown[];
    Title: { value: string };
    CopyText: { value: string };
  };
  params?: { SectionOption?: { Value?: { value?: string } } };
}

export type ActionTileCollectionProps = ComponentProps & LayoutProps;

const ActionTileItem = ({ fields }: ItemType): JSX.Element => {
  const tileImageClass = classNames(
    ' max-w-full h-auto object-cover',
    fields?.['AspectRatio-Mobile']?.fields?.Value?.value,
    fields?.['AspectRatio-Tablet']?.fields?.Value?.value,
    fields?.AspectRatio?.fields?.Value?.value,
    fields?.ImageDisplayDesktop?.fields?.Value?.value,
    fields?.ImageDisplayMobile?.fields?.Value?.value,
    fields?.ImageDisplayTablet?.fields?.Value?.value
  );

  return (
    <div className="h-full mx-auto relative rounded flex flex-col justify-start items-center text-center bg-background-default-2 p-4 hover:shadow-hover shadow-small gap-y-4 focus:ring-2 focus:ring-strokes-action focus:border-2 focus:border-solid focus:border-strokes-action focus:outline-none">
      {fields?.Image?.value?.width && fields?.Image?.value?.height ? (
        <ImageWrapper className={tileImageClass} field={fields?.Image} />
      ) : (
        <ImageWrapper className={tileImageClass} field={fields?.Image} height="150" width="150" />
      )}

      <RichTextA11yWrapper
        className=" text-color-default-1 paragraph-1-medium relative rtaw"
        field={fields?.Title}
      />
    </div>
  );
};

const ActionTileCollection = (props: ActionTileCollectionProps): JSX.Element => {
  const { fields, params } = props;
  const ActionTiles = fields?.ContainedCollection?.value ? fields?.children : fields?.ActionTiles;
  if (!fields) {
    return <></>;
  }
  const sectionClassName =
    params?.SectionOption?.Value?.value === 'bg-white border-default padding-bkgd'
      ? 'container border border-strokes-default-3 bg-background-default-2 spacing-md padding-bkgd'
      : 'container spacing-md';
  return (
    <section className={sectionClassName}>
      <div className="flex items-center">
        <div className="flex flex-col gap-8 w-full text-center">
          <div>
            <Text tag="h2" field={fields?.Title} />
            <RichTextA11yWrapper className="paragraph-2-regular rtaw" field={fields?.CopyText} />
          </div>
          <div className="flex flex-wrap gap-6 justify-center">
            {ActionTiles &&
              ActionTiles.length > 0 &&
              ActionTiles?.map((item: ItemType, i: number) => (
                <div key={i} className="lg:w-[200px] md:w-[158px] w-[152px]">
                  {item?.fields?.Link?.value?.href ? (
                    <LinkWrapper field={item.fields.Link} className="hover:no-underline">
                      <ActionTileItem fields={item.fields} />
                    </LinkWrapper>
                  ) : (
                    <ActionTileItem fields={item.fields} />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActionTileCollection;
