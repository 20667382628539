// Global
import React, { MouseEventHandler, Ref, useState } from 'react';
import { tv } from 'tailwind-variants';
import { Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { hostToReplace } from 'lib/host-to-replace';

// Local
import SVG from 'helpers/SVG/SVG';
import classNames from 'classnames';
import useExperienceEditor from 'lib/use-experience-editor';

export const BUTTON_TYPES = ['default', 'secondary', 'auxiliary', 'action', undefined];
export const BUTTON_SIZES = ['large', 'medium', 'small', undefined];

interface Props {
  auto?: boolean;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  id?: string;
  label?: string;
  loading?: boolean;
  onClick?: MouseEventHandler;
  ref?: Ref<HTMLButtonElement | null>;
  tag?: string;
  title?: string;
  type?: string;
  size?: string;
  field?: LinkField;
  href?: string;
  target?: string;
  fieldClass?: string;
}

type NativeAttrs = Omit<React.ButtonHTMLAttributes<undefined>, keyof Props>;

type Variants = 'default' | 'secondary' | 'auxiliary' | 'action' | undefined;
type Sizes = 'full' | 'large' | 'medium' | 'small' | undefined;

export type ButtonProps = Props & NativeAttrs;

const button = tv({
  base: [
    'inline-flex',
    'justify-center',
    'items-center',
    'paragraph-2-medium',
    'whitespace-nowrap',
    'py-2',
    'px-10',
    'hover:duration-250',
    'hover:transition-all',
    'hover:ease-in',
    'w-full',
    'md:w-fit',
    'min-h-11',
    'lg:min-h-12',
    'focus:outline-none',
  ],
  variants: {
    type: {
      default: [
        'rounded-3xl',
        'text-color-inverse',
        'bg-button-primary-enabled',
        'active:bg-button-primary-active',
        'active:text-color-inverse',
        'focus:bg-button-primary-focus',
        'focus:text-color-inverse',
        'hover:bg-button-primary-hover',
        'hover:text-color-inverse',
        '!no-underline',
        'disabled:text-color-disabled',
        'disabled:bg-button-primary-disabled',
      ],
      secondary: [
        'rounded-3xl',
        '!text-color-default-1',
        'bg-button-secondary-background',
        'border-2',
        'border-strokes-default-3',
        'active:border-button-secondary-active',
        'focus:border-button-secondary-focus',
        'hover:border-button-secondary-hover',
        '!no-underline',
        'disabled:text-color-disabled',
        'disabled:border-button-secondary-disabled',
        '!outline-none',
      ],
      auxiliary: [
        'text-color-active',
        'rounded',
        'bg-background-default-2',
        'border-2',
        'border-auxiliary-default',
        'border-button-auxiliary-default',
        'active:border-button-auxiliary-active',
        'active:text-button-auxiliary-active',
        'focus:text-button-auxiliary-focus',
        'focus-visible:text-button-auxiliary-focus',
        'focus:ring-button-auxiliary-focus',
        'focus:no-underline',
        'hover:no-underline',
        'hover:border-button-auxiliary-hover',
        'disabled:text-color-disabled',
        'disabled:border-button-auxiliary-disabled',
      ],
      action: [
        'flex',
        'items-center',
        'disabled:text-color-disabled',
        'px-0',
        'mr-1',
        'hover:underline',
      ],
    },
    disabled: {
      true: ['cursor-not-allowed'],
    },
    size: {
      full: 'min-w-full',
      large: 'min-w-[4rem]',
      medium: 'min-w-[4rem]',
      small: 'min-w-[4rem]',
    },
  },
  compoundVariants: [
    {
      type: 'default',
      size: 'medium',
      className: 'text-paragraph-2-medium-md',
    },
    {
      type: 'default',
      size: 'small',
      className: 'text-paragraph-2-medium-sm',
    },
    {
      type: 'secondary',
      size: 'medium',
      className: 'text-paragraph-2-medium-md',
    },
    {
      type: 'secondary',
      size: 'small',
      className: 'text-paragraph-2-medium-sm',
    },
    {
      type: 'auxiliary',
      size: 'medium',
      className: 'text-paragraph-2-medium-md',
    },
    {
      type: 'auxiliary',
      size: 'small',
      className: 'text-paragraph-2-medium-sm',
    },
    {
      type: 'auxiliary',
      size: 'full',
      className: ['w-full'],
    },
    {
      type: 'action',
      size: 'large',
      className: ['paragraph-2-medium', 'min-w-fit'],
    },
    {
      type: 'action',
      size: 'medium',
      className: ['paragraph-2-medium', 'min-w-fit'],
    },
    {
      type: 'action',
      size: 'small',
      className: ['paragraph-2-medium', 'min-w-fit'],
    },
  ],
});

const Button = ({
  disabled = false,
  iconLeft,
  iconRight,
  id,
  label,
  loading = false,
  onClick = (): void => undefined,
  ref,
  tag = 'button',
  title,
  type = 'default',
  size = 'large',
  field,
  href,
  target,
  fieldClass,
}: ButtonProps): JSX.Element => {
  const [showIcons /*, setShowIcons */] = useState<boolean | undefined>(true);
  const asLinkField = !field?.value ? { value: { ...field } } : (field as LinkField);

  const className = classNames(
    fieldClass,
    asLinkField.value.class,
    button({ disabled: disabled, type: type as Variants, size: size as Sizes })
  );

  const children = (
    <>
      {showIcons && iconLeft && <SVG svg={iconLeft} />}
      {showIcons && loading && <SVG svg={'spinner'} />}
      {label && !loading && label}
      {showIcons && iconRight && <SVG svg={iconRight} className="ml-2" />}
    </>
  );

  const isEE = useExperienceEditor();

  const processedField = asLinkField ? hostToReplace(asLinkField) : undefined;

  return (
    <>
      {field?.value.href === undefined &&
        React.createElement(
          tag,
          {
            className,
            disabled,
            id,
            onClick: (evt: React.MouseEvent<Element, MouseEvent>) => onClick(evt),
            ref,
            title,
            href,
            target,
          },
          children
        )}
      {field?.value?.href || isEE ? (
        <Link
          field={processedField as LinkField}
          href={processedField?.value?.href}
          className={className}
          type={type}
          title={title}
          aria-label={field?.value?.title ? field?.value?.title : field?.value?.text}
          aria-describedby={field?.value?.title ? field?.value?.title : field?.value?.text}
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : undefined}
        ></Link>
      ) : null}
    </>
  );
};

export default Button;
