//Global
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState, useEffect } from 'react';
import { tv } from 'tailwind-variants';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { formatTimestamp } from 'lib/utils/date-utils';

//helper
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

export type ColorItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Value: Field<string>;
  };
};

export type CategoryItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Color: ColorItemProps;
  };
};

export type ArticleProps = {
  VideoCaption: Field<string>;
  Headline: Field<string>;
  PublishDate: Field<string>;
  ReadTime: Field<string>;
  ResourceBody: Field<string>;
  Teaser: Field<string>;
  VideoUrl: Field<string>;
  Category: CategoryItemProps[];
};

export type VideoProps = ComponentProps;

type CategoryKey = 'category-1' | 'category-2' | 'category-3' | 'category-4';

export const card = tv({
  slots: {
    categoryStripe: [],
  },
  variants: {
    categoryType: {
      'category-1': {
        categoryStripe: 'bg-background-category-1',
      },
      'category-2': {
        categoryStripe: 'bg-background-category-2',
      },
      'category-3': {
        categoryStripe: 'bg-background-category-3',
      },
      'category-4': {
        categoryStripe: 'bg-background-category-4',
      },
    },
  },
});

const Video = (props: VideoProps) => {
  const articlefooter = `articlefooter`;
  const articlesharebar = `articlesharebar`;

  const dict = useDictionary();
  const { sitecoreContext } = useSitecoreContext();
  const { categoryStripe } = card();

  const [scroll, setScroll] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 300);
    });
  }, []);

  let scrollToTopText = undefined;
  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    scrollToTopText = 'Scroll To Top';
  } else {
    scrollToTopText = dict?.['ArticleScrollToTop'] || 'Scroll To Top';
  }

  const {
    VideoUrl,
    VideoCaption,
    PublishDate,
    ReadTime,
    ResourceBody,
    Teaser,
    Category,
    Headline,
  } = sitecoreContext?.route?.fields as ArticleProps;

  const categoryValForBgColor = Category?.[0]?.fields?.Color?.fields?.Value?.value || '';
  const categoryType = (categoryValForBgColor as CategoryKey) || undefined;
  const stripClassName = categoryStripe({ categoryType });

  const onScrollTop = () => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  };

  return (
    <>
      <section className="container-wide mb-10 mt-[22px] md:mt-11 lg:mt-10">
        <div className="container-inner">
          <div className="relative w-full flex flex-col lg:flex-row justify-between">
            <div className="w-full max-w-[804px] lg:pr-4">
              <div className="relative mb-4 lg:mb-6">
                <RichTextA11yWrapper
                  tag="h1"
                  className="text-color-default-1 mb-2 lg:mb-4 rtaw"
                  field={Headline}
                />
                <div className={`h-1 bottom-0 w-[50px] ${stripClassName}`} />
              </div>
              <RichTextA11yWrapper
                className="headline-4 text-color-default-2 mb-4 lg:mb-6 rtaw"
                field={Teaser}
              />
              <div className="flex flex-col md:flex-row justify-between mb-8 lg:mb-10 items-center">
                <div className="flex flex-row gap-x-6 w-full md:w-5/6 items-center">
                  <span className="hidden md:block caption text-color-default-1 items-center">
                    {formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                  </span>
                </div>
                <div className="flex flex-row mt-6 md:mt-0 justify-between md:justify-end w-full md:w-1/6">
                  <span className="block md:hidden caption text-color-default-1">
                    {formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                  </span>
                  <Text tag="span" className="text-color-default-1 caption" field={ReadTime} />
                </div>
              </div>
              <div
                className={`${VideoUrl.value ? 'aspect-video' : ''} mb-8 lg:mb-10 w-full relative`}
              >
                {VideoUrl.value && (
                  <iframe
                    frameBorder="0"
                    src={VideoUrl.value}
                    className="w-full h-full"
                    loading="lazy"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen={true}
                    id="vimeo_id_0"
                  ></iframe>
                )}
                <RichTextA11yWrapper
                  field={VideoCaption}
                  className="block mt-4 text-color-default-1 caption text-center rtaw"
                />
              </div>
              <RichTextA11yWrapper
                className="paragraph-2-regular text-color-default-1 rtaw"
                field={ResourceBody}
              />
              {props.rendering && <Placeholder name={articlefooter} rendering={props?.rendering} />}
            </div>
            <div
              className={`max-w-[190px] w-[190px] relative ${
                scroll ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
            >
              <div className="lg:block hidden pointer-events-none h-[438px] xl:h-[504px] max-h-[504px]"></div>
              <div className="right-section lg:pb-3 right-0 lg:top-[144px] lg:sticky">
                <div className="lg:flex hidden flex-col lg:max-w-[190px] lg:w-[190px]">
                  <button
                    onClick={onScrollTop}
                    type="button"
                    className="cursor-pointer group cursor-pointer flex mb-4 gap-x-1.5 focus:p-2 focus:border focus:border-color-active focus:rounded"
                  >
                    <div className="inline-flex justify-center items-center h-6 w-6 rounded-xl bg-btn-scroll-gradient group-hover:bg-btn-gradient-hover group-focus:bg-btn-scroll-gradient">
                      <SVG className="text-white [&_svg]:p-1" svg="icon-carat-up" />
                    </div>
                    <span className="paragraph-3-medium group-hover:paragraph-3-medium-underline text-color-active group-hover:text-color-link-hover">
                      {scrollToTopText}
                    </span>
                  </button>

                  <div className="flex mb-2">
                    <RichTextA11yWrapper
                      className="paragraph-3-regular text-wrap rtaw"
                      field={Headline}
                    />
                  </div>
                  <div className="flex">
                    <span className="caption">
                      {formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                    </span>
                  </div>
                  <hr />
                </div>
                {props.rendering && (
                  <Placeholder name={articlesharebar} rendering={props?.rendering} />
                )}
              </div>
            </div>
          </div>
          <hr className="my-0" />
        </div>
      </section>
    </>
  );
};

export default Video;
