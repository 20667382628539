import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { LocationData, MapProps } from './LocationTypes';
import LocationSearch from './LocationSearch';
import DistanceFilter from './DistanceFilter';
import LocationList from './LocationList';
import { GoogleMap, InfoWindowF, Marker, useLoadScript } from '@react-google-maps/api';
import {
  createMyLocationIcon,
  createSvgGradientIcon,
  createSvgMarkerIcon,
} from './LocationMapSVGs';

const LocationMap: React.FC<MapProps> = ({
  locationItemIndex,
  defaultLocationName,
  locationData,
  distance,
  currentLat,
  currentLng,
  setScrollToLocationIndex,
}) => {
  const [iconHoverIndex, setIconHoverIndex] = useState<number | null>(null);
  const [iconClickIndex, setIconClickIndex] = useState<number | null>(null);
  const [markerClickedLocation, setMarkerClickedLocation] = useState<LocationData>();
  const [defaultCenter, setDefaultCenter] = useState<{ lat: number; lng: number }>({
    lat: 36.1550178, // default location
    lng: -95.9928783, // default location
  });

  const { currentScreenWidth } = useCurrentScreenType();

  // Update default center when locationItemIndex is set
  useEffect(() => {
    if (locationItemIndex && locationItemIndex > 0) {
      const clickedLocationItemData = locationData[locationItemIndex - 1];
      setIconClickIndex(locationItemIndex);
      setMarkerClickedLocation(clickedLocationItemData);
      setDefaultCenter({
        lat: clickedLocationItemData?.latitude,
        lng: clickedLocationItemData?.longitude,
      });
    }
  }, [locationItemIndex]);

  // Set default center to currentLat and currentLng if available
  useEffect(() => {
    const position = { lat: 36.1550178, lng: -95.9928783 }; // default location (if geolocation or input is unavailable)
    const newDefaultCenter = locationData.length
      ? { lat: locationData[0].latitude, lng: locationData[0].longitude }
      : currentLat && currentLng
      ? { lat: currentLat, lng: currentLng }
      : position;
    setDefaultCenter(newDefaultCenter);
  }, [currentLat, currentLng, locationData]);

  let computeZoom = 14;
  switch (distance) {
    case 15:
      computeZoom = 14;
      break;
    case 50:
      computeZoom = 13;
      break;
    case 100:
      computeZoom = 12;
      break;
    case 200:
      computeZoom = 11;
      break;
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY as string,
  });

  if (!isLoaded) return <div>Loading...{defaultLocationName}</div>;

  return (
    <GoogleMap
      onClick={() => {
        setIconClickIndex(null);
        setScrollToLocationIndex(null);
        setMarkerClickedLocation(undefined);
      }}
      mapContainerStyle={{
        width: '100%',
        height: currentScreenWidth < getBreakpoint('desktop') ? '340px' : '100%',
      }}
      zoom={computeZoom}
      center={defaultCenter} // Centering the map on the current location
    >
      {locationData.map((location, index) => {
        const markerIndex = index + 1;
        const contentString =
          '<a class="text-color-active hover:text-color-active !no-underline hover:!no-underline" href="https://www.google.com/maps/dir/Current+Location/' +
          location.latitude +
          ',' +
          location.longitude +
          '" id="bodyContent">' +
          '<p class="paragraph-3-regular">' +
          location.address +
          '</br>' +
          location.city +
          ', ' +
          location.state +
          ' ' +
          location.zip +
          '</p>' +
          '<p class="caption-medium mt-2">' +
          location.distanceFromCenter?.toFixed(2) +
          ' mi | Get directions</p>' +
          '</a>';

        const svgIcon = createSvgGradientIcon();

        return (
          <Marker
            label={{
              className: 'caption-medium',
              text: markerIndex.toString(),
              color: 'white',
            }}
            onMouseOver={() => setIconHoverIndex(markerIndex)}
            onMouseOut={() => setIconHoverIndex(null)}
            key={index}
            position={{ lat: location.latitude, lng: location.longitude }}
            title={location.locationName}
            onClick={() => {
              setIconClickIndex(markerIndex);
              setScrollToLocationIndex(markerIndex);
              setMarkerClickedLocation(location);
              setDefaultCenter({ lat: location.latitude, lng: location.longitude });
            }}
            icon={
              iconHoverIndex == markerIndex || iconClickIndex == markerIndex
                ? svgIcon
                : createSvgMarkerIcon()
            }
          >
            {markerClickedLocation &&
            markerIndex == iconClickIndex &&
            location?.id == markerClickedLocation?.id ? (
              <InfoWindowF
                zIndex={9999}
                position={{ lat: location.latitude, lng: location.longitude }}
              >
                <div dangerouslySetInnerHTML={{ __html: contentString }}></div>
              </InfoWindowF>
            ) : (
              <></>
            )}
          </Marker>
        );
      })}
      {locationData?.length <= 0 && (
        <Marker title="Default Location" position={defaultCenter} icon={createMyLocationIcon()} />
      )}
    </GoogleMap>
  );
};

const Locations: React.FC = () => {
  const [locationData, setLocationData] = useState<LocationData[]>([]);
  const [selectedDistance, setSelectedDistance] = useState<number>(15);
  const [searchPlaceholderText, setSearchPlaceholderText] = useState<string>(
    'Search by ZIP code or city and state'
  );
  const [searchValue, setSearchValue] = useState('');
  const [currentLat, setCurrentLat] = useState<number>(0);
  const [currentLng, setCurrentLng] = useState<number>(0);
  const [scrollToLocationIndex, setScrollToLocationIndex] = useState<number | null>(null);
  const [locationItemIndex, setLocationItemIndex] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDistanceFilterShown, setIsDistanceFilterShown] = useState<boolean>(true);
  const { currentScreenWidth } = useCurrentScreenType();
  useEffect(() => {
    handleGetCurrentLocation && handleGetCurrentLocation();
  }, []);

  const handleDistanceChange = (distance: number) => {
    setSelectedDistance(distance);
    getFindLocationsCallBackFn(currentLat, currentLng, distance);
  };

  const getFindLocationsCallBackFn = async (
    latitude: number,
    longitude: number,
    distance?: number
  ) => {
    if (!distance) {
      distance = selectedDistance;
    }

    if (!latitude || !longitude) return;

    try {
      const response = await axios.get(
        `/find?lat=${latitude}&lng=${longitude}&maxDistance=${distance}`
      );
      const locations = response?.data?.data;
      if (Array.isArray(locations) && locations.length > 0) {
        setLocationData(locations);
      } else {
        setLocationData([]);
        setErrorMessage('Nearby ATM not found. Please try again or adjust distance filter.');
      }
    } catch (error) {
      setLocationData([]);
      setErrorMessage('Nearby ATM not found. Please try again or adjust distance filter.');
    }
  };

  const handleSearch = async (location: string) => {
    if (!location) {
      location = currentLat + ',' + currentLng;
    }

    //Get lat and long from map api
    const geocoder = new window.google.maps.Geocoder();
    type geoResult = google.maps.GeocoderResult[];
    // Perform geocode
    try {
      geocoder.geocode({ address: location }, async (results: geoResult, status: string) => {
        if (status === 'OK') {
          setCurrentLat(results[0]?.geometry?.location?.lat());
          setCurrentLng(results[0]?.geometry?.location?.lng());
          setIsDistanceFilterShown(true);
          await getFindLocationsCallBackFn(
            results[0]?.geometry?.location?.lat(),
            results[0]?.geometry?.location?.lng()
          );
        } else {
          setLocationData([]);
          setIsDistanceFilterShown(false);
          setErrorMessage('Location not found. Please try again.');
        }
      });
    } catch (err) {}
  };

  const handleGetCurrentLocation = async () => {
    if (window && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setSearchPlaceholderText('My Location');
          setSearchValue('');
          const { latitude, longitude } = position.coords || {};
          setCurrentLat(latitude);
          setCurrentLng(longitude);
          await getFindLocationsCallBackFn(latitude, longitude);
        },
        () => {
          //Set textbox placeholder when user block current location popup
          setSearchPlaceholderText('Error retrieving location');
        }
      );
    } else {
      //set textbox placeholder when browser not supported map
      setSearchPlaceholderText('Geolocation is not supported.');
    }
  };

  return (
    <section className="container mt-6 lg:mt-8">
      <div className="locations-component flex flex-col md:flex-row md:gap-4 md:max-h-[704px] md:h-[704px]">
        <div className="flex flex-col w-full md:max-w-[344px] md:w-[344px] lg:max-w-[427px] lg:w-[427px]">
          <LocationSearch
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            searchPlaceholderText={searchPlaceholderText}
            onSearch={handleSearch}
            onGetCurrentLocation={handleGetCurrentLocation}
          />
          {isDistanceFilterShown && (
            <DistanceFilter
              distanceProp={selectedDistance}
              onDistanceChange={handleDistanceChange}
            />
          )}
          {currentScreenWidth >= getBreakpoint('desktop') && (
            <LocationList
              errorMessage={errorMessage}
              setScrollToLocationIndex={setScrollToLocationIndex}
              setLocationItemIndex={setLocationItemIndex}
              scrollToLocationIndex={scrollToLocationIndex}
              locations={locationData}
            />
          )}
        </div>
        <div className="flex flex-col md:flex grow">
          <LocationMap
            locationItemIndex={locationItemIndex}
            setScrollToLocationIndex={setScrollToLocationIndex}
            currentLat={currentLat}
            currentLng={currentLng}
            distance={selectedDistance}
            defaultLocationName="Default Location"
            locationData={locationData}
          />
          {currentScreenWidth < getBreakpoint('desktop') && (
            <LocationList
              errorMessage={errorMessage}
              setScrollToLocationIndex={setScrollToLocationIndex}
              setLocationItemIndex={setLocationItemIndex}
              scrollToLocationIndex={scrollToLocationIndex}
              locations={locationData}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Locations;
