import React from 'react';
import { Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { tv } from 'tailwind-variants';
import SVG from 'helpers/SVG/SVG';

// Define category keys and corresponding text color styles
type CategoryKey = 'category-1' | 'category-2' | 'category-3' | 'category-4';
interface Route {
  fields: Fields;
}

interface Fields {
  Color: Color;
  CategoryIcon: Color;
  PageTitle: Value;
}

interface Color {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: Fields1;
}
interface Fields1 {
  Value: Value;
}
interface Value {
  value: string;
}

const categoryText = tv({
  variants: {
    categoryType: {
      'category-1': 'text-background-category-1',
      'category-2': 'text-background-category-2',
      'category-3': 'text-background-category-3',
      'category-4': 'text-background-category-4',
    },
  },
});

const CategoryTitle = (): JSX.Element => {
  const sitecoreContext = useSitecoreContext();
  const rootData = sitecoreContext?.sitecoreContext?.route as Route | undefined;

  const title = rootData && rootData.fields?.PageTitle;
  const CategoryIcon = rootData && rootData.fields?.CategoryIcon?.fields?.Value?.value;
  const CategoryColorValue =
    rootData && (rootData.fields?.Color?.fields?.Value?.value as CategoryKey);

  const CategoryColorSVG = categoryText({ categoryType: CategoryColorValue || 'category-1' });

  return (
    <section className="container spacing-sm" aria-label="page-title">
      <div className="flex flex-row items-center justify-start">
        {CategoryIcon && (
          <SVG
            svg={CategoryIcon}
            className={classNames('w-8 h-8 md:w-12 md:h-12 lg:w-14 lg:h-14 mr-4', CategoryColorSVG)}
          />
        )}
        <Text tag="h1" field={title as TextField} className="text-color-default-1" />
      </div>
    </section>
  );
};

export default CategoryTitle;
