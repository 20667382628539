const NavigationMenuQuery = `
query NavigationMenu($datasource: String!, $language: String!) {
  navigationMenu: item(
    path: $datasource,
    language: $language
  ) {
    ... on NavigationMenu {
      title {
        jsonValue
      }
      children(first: 100) {
        results {
          ... on SingleLink {
            link {
              jsonValue
            }
          }
        }
      }
    }
  }
}
`;

export default NavigationMenuQuery;
