import {
  Environment,
  FilterAnd,
  FilterEqual,
  FilterNot,
  SearchResponseFacet,
  setup,
  WidgetRequest,
  Request,
  DataProvider,
  SearchResponseFacetItem,
} from '@sitecore-search/react';
import { Field, Item } from '@sitecore-jss/sitecore-jss-nextjs';
import { LOCALE_CONFIG, SupportedLanguage } from 'lib/constants/locale-constant';

export type ProcessedFacetItem = SearchResponseFacetItem & { isEnabled: boolean };

export type ProcessedFacets = Array<SearchResponseFacet & { value: ProcessedFacetItem }>;

const searchSource = process.env.SEARCH_SOURCE_ID;
export const searchSourceIds = searchSource?.split('|') || [];

export const getAllFacets = async (TopicsToExclude: Array<Item>, TypesToExclude: Array<Item>) => {
  setup({
    env: process.env.SEARCH_ENV as Environment,
    customerKey: process.env.SEARCH_CUSTOMER_KEY,
    apiKey: process.env.SEARCH_API_KEY,
    discoverDomainId: process.env.NEXT_PUBLIC_DISCOVER_DOMAIN_ID || '',
  });

  const searchResultsWidgetRequest = new WidgetRequest('resource_search');

  searchResultsWidgetRequest
    .setEntity('content')
    .setSearchFacetAll(false)
    .setSearchFacetTypes([{ name: 'category' }, { name: 'topics' }, { name: 'type' }])
    .setSearchFilter(
      new FilterAnd([
        ...TopicsToExclude.map(
          (topic) =>
            new FilterNot(new FilterEqual('topics', (topic.fields.Name as Field<string>).value))
        ),
        ...TypesToExclude.map(
          (type) =>
            new FilterNot(
              new FilterEqual(
                'type',
                (type.fields.Name as Field<string>).value?.toLowerCase() == 'video'
                  ? 'VideoResource'
                  : (type.fields.Name as Field<string>).value?.toLowerCase() == 'calculator'
                  ? 'CalculatorResource'
                  : (type.fields.Name as Field<string>).value
              )
            )
        ),
      ])
    )
    .setSources(searchSourceIds)
    .setSearchLimit(1);

  const request = new Request();

  let language = 'en';

  if (typeof document !== 'undefined') {
    language = document.getElementsByTagName('html')[0].getAttribute('lang') || language;
  }
  const resolvedLanguage = (language in LOCALE_CONFIG ? language : 'en') as SupportedLanguage;

  const country = LOCALE_CONFIG[resolvedLanguage];
  request.setContext({
    locale: {
      language,
      country,
    },
  });

  request.addWidgetItem(searchResultsWidgetRequest.toJson());

  const response = await DataProvider.get(request.toJson());

  return response.widgets?.[0].facet || [];
};

export const getProcessedFacets = (
  allFacets: Array<SearchResponseFacet>,
  enabledFacets: Array<SearchResponseFacet>
): ProcessedFacets => {
  allFacets.forEach((facetGroup) => {
    facetGroup.value.forEach((facet: ProcessedFacetItem) => {
      // Check if the facet is enabled based on enabledFacets
      const isEnabled = enabledFacets.some((enabledGroup) =>
        enabledGroup.value.some((enabledFacet) => enabledFacet.id === facet.id)
      );

      facet.isEnabled = isEnabled;
    });
  });

  return allFacets as ProcessedFacets;
};

export const getFacetGroupByName = (
  facets: Array<SearchResponseFacet>,
  name: 'category' | 'topics' | 'type'
) => {
  return facets.filter((facet) => facet.name === name)?.[0];
};

export const getIconDetailsFromFacetId: Record<string, { name: string; color?: string }> = {
  'facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoiY2F0ZWdvcnkiLCJ2YWx1ZSI6IllvdXIgTW9uZXkifQ==': {
    name: 'money',
    color: 'text-background-category-4',
  },
  facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoiY2F0ZWdvcnkiLCJ2YWx1ZSI6IlBlcnNwZWN0aXZlcyJ9: {
    name: 'perspectives',
    color: 'text-background-category-2',
  },
  'facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoiY2F0ZWdvcnkiLCJ2YWx1ZSI6IllvdXIgQnVzaW5lc3MifQ==': {
    name: 'business',
    color: 'text-background-category-1',
  },
  facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoiY2F0ZWdvcnkiLCJ2YWx1ZSI6IkNvbW11bml0eSJ9: {
    name: 'community',
    color: 'text-background-category-3',
  },
  facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoidHlwZSIsInZhbHVlIjoiQXJ0aWNsZSJ9: {
    name: 'article',
  },
  facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoidHlwZSIsInZhbHVlIjoiVmlkZW9SZXNvdXJjZSJ9: {
    name: 'video',
  },
  'facetid_eyJ0eXBlIjoiZXEiLCJuYW1lIjoidHlwZSIsInZhbHVlIjoiQ2FsY3VsYXRvclJlc291cmNlIn0=': {
    name: 'calc',
  },
};
