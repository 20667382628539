// Global
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

// Lib
import { getStaticAssetUrl } from 'lib/public-url-utils';
import { addSvgLoading, removeDartSvgLoading } from 'lib/svg-loading';

// Local
import { normalizeImageUrl } from 'helpers/ImageWrapper/ImageWrapper';

export type SVGProps = {
  className?: string;
  hidden?: boolean;
  svg?: string;
  url?: string;
  title?: string;
  children?: never[];
};

const SVG = ({ className, hidden = false, svg, url, title, children }: SVGProps): ReactElement => {
  const [fetchedSVG, setFetchedSVG] = useState('');
  const svgUriRef = useRef<string>('');

  useEffect(() => {
    if (!svg && !url) return;

    async function fetchSVG() {
      if (!url) {
        svgUriRef.current = `${getStaticAssetUrl()}/${svg}.svg`;
      }
      if (url) {
        svgUriRef.current = normalizeImageUrl(url) ?? '';
      }

      addSvgLoading(svgUriRef.current);
      if (svgUriRef.current) {
        fetch(svgUriRef.current, { cache: 'force-cache' })
          .then((res) => (res.ok ? res.text() : null))
          .then((text) =>
            text
              ? setFetchedSVG(text)
              : console.error('There was a problem with the Fetch operation:')
          )
          .catch((error) => {
            console.error('There was a problem with the Fetch operation:', error);
          })
          .finally(() => {
            removeDartSvgLoading(svgUriRef.current);
          });
      }
    }
    fetchSVG();
  }, [svg, url, children]);

  return (
    <>
      <span
        aria-hidden={hidden}
        data-svg-url={svgUriRef.current}
        className={className}
        title={title}
      >
        {convertHtmlToReact(fetchedSVG)}
      </span>
    </>
  );
};

export default SVG;
