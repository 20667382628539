import React, { useEffect, useRef, useState } from 'react';
import { Field, RichTextField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { BoK } from 'lib/templates/Feature.BOKF.model';
import { ComponentProps } from 'lib/component-props';
import { useCurrentScreenType } from 'lib/utils/get-screen-type';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ProductSliderWrapper from './ProductSliderWrapper';
import CustomProduct from './CustomProduct';
import { useNavContext } from 'lib/react-context/NavContext';

type Product = {
  fields: {
    Title: Field;
    [key: string]: Field | undefined;
  };
};

interface SlideData {
  rowHeader: string;
  productName?: string;
  data?: Array<{
    rowHeaderKey?: string;
    value?: Field | string;
    productName?: string;
  }>;
  value?: Array<{
    rowHeaderKey?: string;
    value?: Field | string;
    productName?: string;
  }>;
}

interface DataFieldType {
  value?: string;
  productName: string;
  rowHeader: string;
  rowHeaderKey: string;
}

type ProductComparisonChartTypes = ComponentProps &
  BoK.ProductComparison.ProductComparisonChart & {
    fields: {
      ProductFilters: {
        fields: {
          Products: {
            fields: {
              Title: {
                value: string;
              };
              [key: string]:
                | {
                    value: string;
                  }
                | undefined;
            };
          }[];
          DisplayTitle: {
            value: string;
          };
          IsCustomFilter: {
            value: string;
          };
        };
      }[];
      children: {
        fields: {
          Title: {
            value: string;
          };
          Field: {
            value: string;
          };
          CalloutText?: {
            value: string;
          };
          CalloutItems?: {
            displayName: string;
          }[];
        };
      }[];
    };
  };

const ProductComparisonChart = (props: ProductComparisonChartTypes) => {
  const { fields = undefined } = props;
  const navContext = useNavContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [responsiveCount, setResponsiveCount] = useState<number>(4);
  const tabs = fields?.ProductFilters || [];

  const [LHNExpanded, setLHNExpanded] = useState(false);
  useEffect(() => {
    if (navContext.leftNavState === 'expanded') {
      setLHNExpanded(true);
    } else {
      setLHNExpanded(false);
    }
  }, [navContext.leftNavState]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const rowHeaders = fields?.children
    ?.filter((item) => item?.fields?.Title && item?.fields?.Field)
    .map((item) => ({
      title: item?.fields?.Title?.value,
      rowHeaderKey: item?.fields?.Field?.value,
    }));

  const calloutData = fields?.children
    ?.map((item) => {
      const calloutText = item?.fields?.CalloutText?.value;
      const calloutProductName = item?.fields?.CalloutItems?.map(
        (calloutItem) => calloutItem?.displayName
      );

      return {
        calloutText: calloutText,
        calloutProductName: calloutProductName,
      };
    })
    .filter((item) => item?.calloutText && item?.calloutProductName);

  const products = tabs[selectedTab]?.fields?.Products || [];

  const getUniqueProductTitles = (products: Product[]) => {
    return [
      ...new Set(
        products
          .map((product: Product) => product.fields?.Title?.value)
          .filter((title: string) => title)
      ),
    ];
  };

  const getFieldsByProductName = (products: Product[], productName: string) => {
    const product = products.find((p) => p.fields?.Title?.value === productName);
    if (!product || !product.fields) {
      return [];
    }
    return Object.entries(product.fields)
      .filter(([key]) => key.startsWith('Field') || key === 'ApplyLink' || key === 'DetailLink')
      .map(([key, value]) => ({ key, value }));
  };

  const getUniqueFields = (fields: { key: string; value: Field }[]) => {
    const uniqueFields = [...new Map(fields.map((field) => [field.key, field])).values()];
    uniqueFields.sort(
      (a, b) => parseInt(a.key.replace('Field', ''), 10) - parseInt(b.key.replace('Field', ''), 10)
    );
    return uniqueFields;
  };

  const getAllFieldsForUniqueTitles = (products: Product[]) => {
    const uniqueTitles = getUniqueProductTitles(products);
    return uniqueTitles.map((title) => {
      const fieldsForTitle = getFieldsByProductName(products, title as string);
      return {
        productName: title,
        fields: getUniqueFields(fieldsForTitle as { key: string; value: Field }[]),
      };
    });
  };

  const getSlidesData = (
    allFields: ReturnType<typeof getAllFieldsForUniqueTitles>
  ): SlideData[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return allFields.map((product) => {
      return {
        productName: product.productName,
        fields: rowHeaders?.map((header) => ({
          rowHeader: header?.title,
          rowHeaderKey: header?.rowHeaderKey,
          field: product?.fields?.find((f) => f.key === header?.rowHeaderKey)?.value || '',
        })),
      };
    });
  };

  const allFieldsForUniqueTitles = getAllFieldsForUniqueTitles(products);
  const slidesData = getSlidesData(allFieldsForUniqueTitles);
  const slideDataCustom = slidesData;

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    setDropdownOpen(false);
  };
  const productName = slidesData.map((item: SlideData) => item?.productName);

  const similarFields: string[] = [];

  let dataFields: {
    rowHeader: string;
    data: ProductComparisonChartTypes[];
    rowHeaderKey: string;
  }[] = Array.from({ length: 0 }, () => {
    return { rowHeader: '', data: [], rowHeaderKey: '' };
  });

  const { screenType } = useCurrentScreenType();

  const changeSlideWidth = (length: number) => {
    if (typeof window !== 'undefined') {
      let slideSize = 'calc(100%/4)';
      if (screenType === 'tablet') {
        slideSize = 'calc(100%/2)';
      } else if (length === 2) {
        slideSize = 'calc(100%/2)';
      } else if (length === 3) {
        slideSize = screenType === 'desktop' ? 'calc(100%/3)' : 'calc(100%/3)';
        slideSize = screenType === 'mobile' ? 'calc(100%/2)' : slideSize;
        slideSize = screenType === undefined ? 'calc(100%/2)' : slideSize;
      } else if (screenType === 'mobile' || screenType === undefined) {
        slideSize = 'calc(100%/2)';
      } else if (screenType === 'desktop') {
        slideSize = 'calc(100%/3)';
      }
      document.documentElement.style.setProperty('--comparison-chart-slide-size', slideSize);
    }
  };

  slidesData.map((item) => {
    if (similarFields.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      item.fields.forEach((itemData) => {
        if (itemData.rowHeader && !similarFields.includes(itemData.rowHeader)) {
          similarFields.push(itemData.rowHeader);
        }
      });

      // Initialize dataFields based on unique row headers
      dataFields = similarFields.map((rowHeader) => ({
        rowHeader: rowHeader,
        data: [],
        rowHeaderKey: '',
      }));
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    item.fields.map((itemData, index: number) => {
      const dataField: DataFieldType = {
        value: itemData?.field as string,
        productName: item?.productName as string,
        rowHeader: itemData?.rowHeader,
        rowHeaderKey: itemData?.rowHeaderKey,
      };

      if (dataFields[index]) {
        (dataFields[index].data as unknown as DataFieldType[]).push(dataField);
      }
    });

    changeSlideWidth(slidesData.length);
  });

  useEffect(() => {
    if (screenType) {
      if (screenType === undefined || screenType === 'mobile' || screenType === 'tablet') {
        setResponsiveCount(2);
      } else if (screenType === 'desktop') {
        setResponsiveCount(3);
      } else {
        setResponsiveCount(4);
      }
    }
  }, [screenType]);

  if (!fields) {
    return <></>;
  }

  const handleKeyDownTab = (e: React.KeyboardEvent, index: number) => {
    // Handle keyboard interaction with Enter, Space, Left, Right keys on Tabs
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setSelectedTab(index);
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setSelectedTab((prev) => (prev > 0 ? prev - 1 : tabs.length - 1));
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      setSelectedTab((prev) => (prev < tabs.length - 1 ? prev + 1 : 0));
    }
  };

  // Handle keyboard navigation Dropdown
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedTab((prev) => (prev + 1) % tabs.length);
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedTab((prev) => (prev - 1 + tabs.length) % tabs.length);
        break;
      case 'Enter':
      case 'Space':
        event.preventDefault();
        setDropdownOpen(!dropdownOpen);
        break;
      case 'Escape':
        event.preventDefault();
        setDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <section
      className="comparison-chart-wrapper container spacing-md overflow-clip"
      style={{
        maxWidth: LHNExpanded && screenType === 'desktop' ? 'calc(100vw - 360px)' : '100%',
      }}
    >
      <Text
        field={fields?.Title}
        tag="h2"
        className="text-color-default-1 text-center relative z-10"
      />
      <RichTextA11yWrapper
        field={fields?.Description}
        className="text-color-default-1 paragraph-2-regular my-8 text-center relative z-10 rtaw"
      />
      <div className="relative z-[1]">
        <div className="mt-8 sticky top-[100px] z-[800] before:absolute before:w-full before:h-[150px] before:bg-background-default-1 before:top-[-150px] shadow-[0_0_6px_0_rgba(0,0,0,0.1)]">
          {!fields?.HideTabs?.value && (
            <>
              {/* Tabs for desktop screens */}
              <ul
                className="hidden relative bg-background-default-2 pt-5 justify-center text-center items-center w-full md:flex flex-row gap-2 flex-wrap"
                role="tablist"
              >
                {tabs.map((tab, index: number) => (
                  <li
                    key={index}
                    id={`tab-${index}`}
                    className={classNames(
                      'px-4 md:px-6 pb-1 list-none border-b-[3px] border-b-transparent',
                      {
                        '!border-b-strokes-brand': selectedTab === index,
                      }
                    )}
                    role="tab"
                    aria-selected={selectedTab === index}
                    aria-controls={`tab-${index}`}
                    tabIndex={0}
                    onClick={() => handleTabClick(index)}
                    onKeyDown={(e: React.KeyboardEvent) => handleKeyDownTab(e, index)}
                  >
                    <RichTextA11yWrapper
                      field={tab?.fields?.DisplayTitle as RichTextField}
                      tag="span"
                      className={classNames(
                        'hover:no-underline cursor-pointer text-color-default-1 hover:paragraph-2-medium hover:text-color-default-1 rtaw',
                        selectedTab === index ? 'paragraph-2-medium' : 'paragraph-2-regular'
                      )}
                    />
                  </li>
                ))}
              </ul>

              {/* Dropdown for mobile screens */}
              <div className="block md:hidden bg-background-default-2 py-2 px-4">
                Filtering by:
                <button
                  type="button"
                  aria-label="link-select"
                  aria-expanded={dropdownOpen}
                  aria-haspopup="listbox"
                  className={classNames(
                    'dropdown-btn paragraph-2-medium-lg flex items-center justify-between w-full pb-3 gap-2 relative after:absolute after:bottom-1 after:h-[3px] after:w-6 after:bg-bokf-red'
                  )}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  onKeyDown={handleKeyDown}
                >
                  <Text
                    field={tabs[selectedTab]?.fields.DisplayTitle as TextField}
                    tag="span"
                    className="paragraph-2-medium text-color-default-1"
                  />

                  <div className="w-4 h-6 flex">
                    <SVG svg="icon-carat-down" />
                  </div>
                </button>
                {dropdownOpen && (
                  <div
                    ref={selectRef}
                    role="listbox"
                    aria-labelledby="link-select"
                    tabIndex={-1}
                    className="dropdown-menu mt-2 bg-background-default-2 shadow-lg absolute z-10 w-[calc(100%-34px)] left-4 rounded-lg"
                  >
                    <ul className="flex flex-col">
                      {tabs.map((tab, index) => (
                        <li
                          key={index}
                          className={classNames(
                            'px-4 py-2 cursor-pointer hover:bg-background-dark-1',
                            {
                              'bg-background-dark-1': selectedTab === index,
                            }
                          )}
                          role="option"
                          aria-selected={selectedTab === index}
                          tabIndex={0}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                              setSelectedTab(index);
                              setDropdownOpen(false);
                            }
                          }}
                          onClick={() => handleTabClick(index)}
                        >
                          <Text
                            field={tab?.fields?.DisplayTitle as TextField}
                            tag="span"
                            className={classNames(
                              selectedTab === index
                                ? 'text-color-default-2 paragraph-2-medium'
                                : 'text-color-default-1 paragraph-2-regular'
                            )}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* Conditional Rendering based on IsCustomFilter */}
        {tabs[selectedTab]?.fields?.IsCustomFilter?.value ? (
          <>
            <CustomProduct
              addProductText={fields?.AddProductText?.value || ''}
              dropDownOption={productName as string[]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              slidesData={slideDataCustom}
              responsiveCount={responsiveCount}
            />
          </>
        ) : (
          <ProductSliderWrapper
            slidesData={dataFields as unknown as SlideData[]}
            productName={[{ data: productName }] as unknown as string[]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            calloutData={calloutData}
            totalSlide={slidesData.length}
            responsiveCount={responsiveCount}
            isTabHidden={!fields?.HideTabs?.value ? true : false}
          />
        )}
      </div>
    </section>
  );
};

export default ProductComparisonChart;
