import classNames from 'classnames';
import SVG from 'helpers/SVG/SVG';
import React, { ReactNode } from 'react';
import { tv } from 'tailwind-variants';

const ModalStyle = tv({
  slots: {
    modelOverlay: [
      'fixed inset-0 w-full min-h-screen flex justify-center bg-scrim-clickable z-50 overflow-y-auto p-4 md:p-8',
    ],
    closeBtn: [
      'absolute right-1 top-1 md:right-3.5 md:top-3.5 lg:right-6 lg:top-6 p-3.5 md:p-3 lg:p-2.5',
    ],
  },
});

const ModalWrapper = ({
  onClose,
  children,
  position,
  modalWidth,
  isCloseBtnShown = true,
  isMarginAvailable = true,
}: {
  onClose?: () => void;
  children: ReactNode;
  position: string;
  modalWidth?: string;
  isCloseBtnShown?: boolean;
  isMarginAvailable?: boolean;
}) => {
  const { modelOverlay, closeBtn } = ModalStyle();
  const modelContainerRef = React.useRef<HTMLDivElement | null>(null);

  const handleCloseClick = () => {
    onClose && onClose();
    document.body.style.overflow = 'unset';
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    const handleModelEvent = (e: MouseEvent) => {
      if (modelContainerRef.current && !modelContainerRef.current.contains(e.target as Node)) {
        handleCloseClick();
      }
    };
    document.addEventListener('click', handleModelEvent, true);
    return () => {
      document.removeEventListener('click', handleModelEvent, true);
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={modelOverlay()} role="dialog" aria-modal="true">
      <div
        className={classNames(
          modalWidth,
          position == 'center' ? 'max-w-[728px] m-auto' : 'max-w-[728px] mx-auto',
          'transition-all lg:w-[728px]'
        )}
        ref={modelContainerRef}
        style={{ height: 'fit-content' }}
      >
        <div
          className={`relative bg-background-default-2 flex flex-col ${
            isMarginAvailable &&
            'px-4 pb-4 pt-12 md:px-6 md:pb-6 md:pt-[52px] lg:px-8 lg:pb-8 lg:pt-16'
          }`}
        >
          {isCloseBtnShown && (
            <button className={closeBtn()} onClick={handleCloseClick}>
              <SVG
                svg="icon-close"
                className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 block text-icon"
              />
            </button>
          )}
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
