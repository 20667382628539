import React from 'react';
import { FilterAnyOf, FilterNot, FilterEqual, FilterAnd } from '@sitecore-search/react';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import {
  ResourceCard,
  ResourceListWidget,
  ResourceProps,
} from 'widgets/ResourceListWidget/ResourceListWidget';
import { ComponentProps } from 'lib/component-props';
import { Field, Text, Item, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Resources } from 'lib/templates/Feature.BOKF.model';
import Button from 'helpers/Button/Button';

// Type definitions
type AuthorableFilterTypes =
  | 'PageTitle'
  | 'Name'
  | 'ExpertFirstName'
  | 'ExpertLastName'
  | 'AuthorName';

type CommonFiltersRecord = 'Topics' | 'Authors' | 'Category' | 'Experts' | 'ContentType';

export type CommonArticleProp = {
  id?: string;
  url?: string;
  fields?: {
    ReadTime?: Field<string>;
    Teaser?: Field<string>;
    Hero?: ImageField;
    Headline?: Field<string>;
    PublishDate?: Field<string>;
    ContentType?: FilterType;
    VideoUrl?: Field<string>;
  };
};

type FilterType = Item & { fields?: Partial<Record<AuthorableFilterTypes, Field<string>>> };

type CommonFilters = Record<CommonFiltersRecord, Array<FilterType>>;

// Define type for component props
export type ArticleDynamicProps = Partial<ComponentProps> &
  Resources.Configuration.ArticleDynamicCollection & {
    fields: CommonFilters & {
      ArticlesToExclude?: Array<ResourceProps>;
      ArticlesToInclude?: Array<ResourceProps>;
    };
  };

export type filterValuesProps = {
  filterCategory?: Array<FilterType>;
  filterTopics?: Array<FilterType>;
  filterContentType?: Array<FilterType>;
  filterAuthors?: Array<FilterType>;
  filterExperts?: Array<FilterType>;
  filterArticlesToExclude?: Array<ResourceProps>;
  filterArticlesToInclude?: Array<ResourceProps>;
};

// Helper function to map field values
export const mapFieldValues = (
  items: FilterType[] = [],
  field: keyof FilterType['fields']
): string[] =>
  items.map((item) => {
    const fieldValue = item.fields?.[field];

    // Check if fieldValue is of type Field<GenericFieldValue> before accessing 'value'
    if (fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue) {
      if (fieldValue.value === 'Video') {
        return 'VideoResource';
      } else {
        return fieldValue.value as string;
      }
    }
    return '';
  });

export const mapFieldValuesForTopics = (items: FilterType[] = []): string[] =>
  items.map((item) => {
    return item.name;
  });

export const initialFilterQuery = ({
  filterCategory,
  filterTopics,
  filterContentType,
  filterAuthors,
  filterExperts,
  filterArticlesToExclude,
  filterArticlesToInclude,
}: filterValuesProps): (FilterAnyOf | FilterNot)[] => {
  const filters: (FilterAnyOf | FilterNot)[] = [];
  const SearchIDEnvPostFix = process.env.NEXT_PUBLIC_SEARCH_ID_ENV_POSTFIX || '';

  if (filterCategory && filterCategory.length > 0) {
    filters.push(new FilterAnyOf('category', mapFieldValues(filterCategory, 'PageTitle')));
  }
  if (filterTopics && filterTopics.length > 0) {
    filters.push(new FilterAnyOf('topics', mapFieldValuesForTopics(filterTopics)));
  }
  if (filterContentType && filterContentType.length > 0) {
    filters.push(new FilterAnyOf('type', mapFieldValues(filterContentType, 'Name')));
  }
  if (filterAuthors && filterAuthors.length > 0) {
    filters.push(new FilterAnyOf('author', mapFieldValues(filterAuthors, 'AuthorName')));
  }
  if (filterExperts && filterExperts.length > 0) {
    const expertNames = filterExperts.map(
      (expert: FilterType) =>
        `${expert.fields?.ExpertFirstName?.value} ${expert.fields?.ExpertLastName?.value}`
    );
    filters.push(new FilterAnyOf('experts', expertNames));
  }
  if (filterArticlesToExclude && filterArticlesToExclude.length > 0) {
    filterArticlesToExclude.forEach((exclude: ResourceProps) =>
      filters.push(new FilterNot(new FilterEqual('id', exclude.id + SearchIDEnvPostFix)))
    );
  }

  if (filterArticlesToInclude && filterArticlesToInclude.length > 0) {
    filterArticlesToInclude.forEach((include: ResourceProps) =>
      filters.push(new FilterNot(new FilterEqual('id', include.id + SearchIDEnvPostFix)))
    );
  }

  return filters;
};

const ArticleDynamicCollection = (props: ArticleDynamicProps) => {
  const filters: (FilterAnyOf | FilterNot)[] = initialFilterQuery({
    filterCategory: props.fields?.Category as Array<FilterType>,
    filterTopics: props.fields?.Topics as Array<FilterType>,
    filterContentType: props.fields?.ContentType as Array<FilterType>,
    filterAuthors: props.fields?.Authors as Array<FilterType>,
    filterExperts: props.fields?.Experts as Array<FilterType>,
    filterArticlesToExclude: props.fields?.ArticlesToExclude as Array<ResourceProps>,
    filterArticlesToInclude: props.fields?.ArticlesToInclude as Array<ResourceProps>,
  });

  // Function to include resources
  const IncludedResources = () => {
    const _includedResources: Array<ResourceProps> = [];
    let showAricle: ResourceProps = {};
    props?.fields?.ArticlesToInclude?.slice(0, props.fields.NumberToDisplay?.value ?? 3).map(
      (article: CommonArticleProp) => {
        showAricle = {
          article_time: article?.fields?.ReadTime?.value ?? '',
          description: article?.fields?.Teaser?.value ?? '',
          id: article?.id ?? '',
          image_url: article?.fields?.Hero?.value?.src ?? '',
          media_url: article?.fields?.VideoUrl?.value ?? '',
          name: article?.fields?.Headline?.value ?? '',
          publish_date: article?.fields?.PublishDate?.value ?? '',
          url: article?.url,
          type:
            article?.fields?.ContentType?.fields.Name?.value === 'Video'
              ? 'VideoResource'
              : 'Article',
        };
        _includedResources.push(showAricle);
      }
    );
    return _includedResources;
  };

  if (!props.fields) return null;

  const articlesToDisplay = props.fields.NumberToDisplay?.value ?? 3;
  const icludedArticlesCount = props.fields.ArticlesToInclude?.length ?? 0;
  const remainingArticles = articlesToDisplay - icludedArticlesCount;

  return (
    <WidgetWrapper>
      {remainingArticles > 0 ? (
        <ResourceListWidget
          isCategoryHidden={true}
          ribbonLabel={props.fields?.RibbonLabel}
          title={{
            tag: 'h2',
            text: props.fields?.Title?.value || '',
            classes: 'text-center mb-6',
          }}
          initialCount={String(remainingArticles)}
          SkipItemFromSearch={0}
          rfkId="articles"
          filters={filters.length > 0 ? new FilterAnd(filters) : undefined}
          IncludedResources={IncludedResources()}
        />
      ) : (
        <section className="component container spacing-md">
          <Text
            tag="h2"
            field={{ value: props.fields?.Title?.value || '' }}
            className="text-color-default-1 text-center mb-6"
          />
          <ul className="flex flex-wrap sm:flex-row gap-4">
            {IncludedResources().map((resource, index) => (
              <li
                className="text-color-default-1 basis-full sm:basis-[calc(100%_/_2_-_16px_/_2_*_(2_-_1))] lg:basis-[calc(100%_/_3_-_16px_/_3_*_(3_-_1))]"
                key={index}
              >
                <ResourceCard
                  {...resource}
                  isCategoryHidden={true}
                  ribbonLabel={props.fields?.RibbonLabel}
                />
              </li>
            ))}
          </ul>
        </section>
      )}

      <div className="container text-center mt-8">
        <Button
          type="default"
          size="large"
          title={props.fields?.CallToAction?.value?.text || ''}
          field={props.fields?.CallToAction}
        />
      </div>
    </WidgetWrapper>
  );
};

export default ArticleDynamicCollection;
