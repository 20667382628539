// Global
import React, { useEffect, useState } from 'react';
import { RichTextField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

interface PageTitleProps {
  fields: {
    data: {
      item: {
        id: string;
        name: string;
        PageTitle: { value: string };
        PageTitleCallout?: { value: string };
      };
    };
  };
}

const PageTitle = (props: PageTitleProps): JSX.Element => {
  const { fields } = props;
  const [hasBreadcrumbs, setHasBreadcrumbs] = useState(false);
  const sitecoreContext = useSitecoreContext();
  useEffect(() => {
    const breadcrumbs = document.querySelector('[aria-label="breadcrumbs"]');
    setHasBreadcrumbs(!!breadcrumbs);
  }, []);
  if (!fields) {
    return <></>;
  }
  const PageTitle = sitecoreContext?.sitecoreContext?.route?.fields?.PageTitle;
  const PageTitleCallout = sitecoreContext?.sitecoreContext?.route?.fields?.PageTitleCallout;
  return (
    <section
      className={classNames('container', {
        '': !hasBreadcrumbs,
      })}
      aria-label="page-title"
    >
      <Text
        field={PageTitleCallout as TextField}
        editable={true}
        tag="div"
        className="mt-4 md:mt-6 mb-2 bg-icon-brand text-white text-caption-lg flex items-center h-7 w-max relative px-3 after:border-b-bokf-red after:border-b-[28px] after:border-solid after:border-r-[28px] after:border-r-transparent after:content-[' '] after:absolute after:-right-[28px]"
      />
      <RichTextA11yWrapper
        field={PageTitle as RichTextField}
        editable={true}
        tag="h1"
        className="text-color-default-1 flex flex-col after:content-[''] after:pt-2 after:block after:w-[3rem] after:mb-2 after:border-b-4  after:border-strokes-brand"
      />
    </section>
  );
};

export default PageTitle;
