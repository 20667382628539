/**
 * Add this to the loading SVG list so that our unit tests can wait for it to finish.
 * @param url The url that's loading
 */
export const addSvgLoading = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).SVG_LOAD = (window as any).SVG_LOAD ?? new Set();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const svgLoading = (window as any).SVG_LOAD as Set<string>;

  svgLoading.add(url);
};

/**
 * Remove from the loading SVG list so that our unit tests can know that it's finished.
 * @param url The url that's loading
 */
export const removeDartSvgLoading = (url: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).SVG_LOAD = (window as any).SVG_LOAD ?? new Set();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const svgLoading = (window as any).SVG_LOAD as Set<string>;

  svgLoading.delete(url);
};
