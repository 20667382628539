import React from 'react';
import { usePathname } from 'next/navigation';
import { ComponentProps } from 'lib/component-props';
import { Resources } from 'lib/templates/Feature.BOKF.model';
import { Text, Field, Item, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import classNames from 'classnames';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import {
  FilterAnyOf,
  FilterAnd,
  EntityModel,
  useSearchResults,
  WidgetDataType,
  widget,
  FilterNot,
  FilterEqual,
} from '@sitecore-search/react';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { formatTimestamp } from 'lib/utils/date-utils';
import { tv, VariantProps } from 'tailwind-variants';
import { Bok } from 'lib/templates/Foundation.BOKF.model';
import { getThumnailUrl } from 'lib/utils/vimeo-thumbnail';

type IconProps = {
  fields?: { Value?: Field<string> };
};

type ElementProps = Item & {
  fields?: { Name?: Field<string>; Icon?: IconProps };
};

type CommonArticleProp = {
  id?: string;
  url?: string;
  fields?: {
    ReadTime?: Field<string>;
    Teaser?: Field<string>;
    Hero?: ImageField;
    Headline?: Field<string>;
    PublishDate?: Field<string>;
    ContentType?: FilterType;
    VideoUrl?: Field<string>;
    Topics?: Array<ElementProps>;
  };
};

export type variantsTypes = VariantProps<typeof ResourceLibrarystyle>;

export type ResourceItemProps = {
  ribbonLabel?: Field<string>;
  article_time?: string;
  description?: string;
  id?: string;
  image_url?: string;
  media_url?: string;
  name?: string;
  publish_date?: string;
  url?: string;
  type?: string;
  topics: string[];
};

type FilterType = Item & {
  fields?: {
    PageTitle?: Field<string>;
    Name?: Field<string>;
    ExpertFirstName?: Field<string>;
    ExpertLastName?: Field<string>;
    AuthorName?: Field<string>;
  };
};

export type ResourceLibraryProps = Omit<ComponentProps, 'params'> &
  Resources.Configuration.ResourceLibraryConfiguration & {
    params?: {
      HeadingType?: Bok.BaseTypes.HtmlTag['fields'];
      Alignment?: { Value: Field<string> };
      BackgroundColor?: { Value: Field<string> };
      Spacing?: { Value: Field<string> };
    };
    fields?: {
      ContentType?: Array<ElementProps>;
      Topics?: Array<ElementProps>;
    };
  };

export const ResourceLibrarystyle = tv({
  slots: {
    HeadingStyle: ['relative pb-1 mb-3'],
    checkbokStyle: [
      'relative flex items-center cursor-pointer gap-2 text-color-default-2 font-normal',
      'before:bg-transparent before:border-2 before:border-strokes-action before:inline-block before:relative before:cursor-pointer',
      'peer-checked:before:bg-button-secondary-active peer-checked:before:border-button-secondary-active',
      'peer-checked:peer-focus:before:bg-button-secondary-focus peer-focus:before:border-button-secondary-focus',
      'peer-checked:peer-hover:before:bg-button-secondary-hover peer-hover:before:border-button-secondary-hover',
      'peer-checked:after:block peer-checked:after:absolute md:peer-checked:after:top-[5px] md:peer-checked:after:left-[9px] md:peer-checked:after:w-[6px] md:peer-checked:after:h-[14px] peer-checked:after:border-white peer-checked:after:border-r-2 peer-checked:after:border-b-2 peer-checked:after:rotate-45 peer-checked:font-medium peer-checked:border-strokes-default-1',
      'hover:before:border-button-secondary-hover focus:before:border-button-secondary-focus peer-checked:text-strokes-default-1',
      'text-sm md:paragraph-2-regular before:p-2 md:before:p-[10px] peer-checked:after:top-[3px] peer-checked:after:left-2 peer-checked:after:w-[4px] peer-checked:after:h-[11px]',
    ],
  },
  variants: {
    positions: {
      center: {
        HeadingStyle: ['after:left-1/2 after:-translate-x-1/2 text-center'],
      },
      left: {
        HeadingStyle: ['after:left-0 text-left'],
      },
    },
    tagStyle: {
      'title-border title-border--thick title-border--neutral': {
        HeadingStyle: [
          'after:relative after:block after:border-b-[5px] after:border-strokes-brand after:w-12 after:mb-3 after:pt-1 md:after:pt-2 lg:after:pt-4 lg:after:mb-2',
        ],
      },
      'title-border': {
        HeadingStyle: [
          'after:relative after:block after:border-b-[4px] after:border-strokes-brand after:w-10 after:mb-3 after:pt-2 lg:after:pt-3 lg:after:mb-2',
        ],
      },
      default: {
        HeadingStyle: '',
      },
    },
  },
});

const ResourceLibrary = (props: ResourceLibraryProps) => {
  const { currentScreenWidth, isScreenTypeDetected } = useCurrentScreenType();
  if (!isScreenTypeDetected) return <></>;

  return (
    <WidgetWrapper>
      <ResourceWidget
        props={props}
        rfkId="related_resources"
        InitialCount={
          (currentScreenWidth <= getBreakpoint('desktop')
            ? props?.fields?.InitialResourceMobileCount?.value
            : props?.fields?.InitialResourceDesktopCount?.value) as number
        }
        currentScreenWidth={currentScreenWidth}
      />
    </WidgetWrapper>
  );
};

export default ResourceLibrary;

const ResourceLibrarySearch = ({
  props,
  InitialCount,
  currentScreenWidth,
}: {
  props: ResourceLibraryProps;
  InitialCount: number;
  currentScreenWidth: number;
}) => {
  const { fields = undefined, params = undefined } = props;
  const dropdownRefs = React.useRef<HTMLDivElement | null>(null);

  //Aligment Styling
  const { HeadingStyle, checkbokStyle } = ResourceLibrarystyle();
  const alignment = params?.Alignment?.Value?.value === 'text-center' ? 'center' : 'left';
  const headingClass = HeadingStyle({
    positions: alignment,
    tagStyle: (params?.HeadingType?.Styles?.value || 'default') as variantsTypes['tagStyle'],
  });

  //Partial Updates
  const [contentType, setContentType] = React.useState<string[]>([]);
  const [topics, setTopics] = React.useState<string[]>([]);
  const [finalResult, setFinalResult] = React.useState<number>(0);
  const [resourcesData, setResourcesData] = React.useState<{
    ResourceList: Array<EntityModel>;
    fetchCount: number;
  }>({ ResourceList: [], fetchCount: 1 });

  //Actual Updates
  const [finalContentType, setFinalContentType] = React.useState<string[]>([]);
  const [finalTopics, setFinalTopics] = React.useState<string[]>([]);
  const [oldResult, setOldResult] = React.useState<number>(0);
  const [actualData, setActualData] = React.useState<{
    ResourceList: Array<EntityModel>;
    fetchCount: number;
  }>({ ResourceList: [], fetchCount: 1 });

  const [directChange, setDirectChange] = React.useState<boolean>(false);
  const [isListView, setIsListView] = React.useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
  const [firstTimeCount, setFirstTimeCount] = React.useState<number>(0);

  //State for IncludeResource
  const [includedResource, setIncludedResource] = React.useState<ResourceItemProps[]>();

  //Search Query Box
  const searchSource = process.env.RESOURCES_SEARCH_SOURCE_ID;
  const searchSourceIds = searchSource?.split('|') || [];

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownOpen !== null) {
      const clickedInsideDropdown = dropdownRefs.current?.contains(event.target as Node);
      if (!clickedInsideDropdown) {
        if (dropdownOpen) {
          setTopics([...finalTopics]);
          setContentType([...finalContentType]);
          setFinalResult(oldResult);

          const updatedFilters: (FilterAnyOf | FilterNot)[] = [...baseFilter()];

          if (contentType.length > 0) {
            updatedFilters.push(new FilterAnyOf('type', contentType));
          } else {
            updatedFilters.push(new FilterAnyOf('type', typesItems));
          }

          if (topics.length > 0) {
            updatedFilters.push(new FilterAnyOf('topics', topics));
          } else {
            updatedFilters.push(new FilterAnyOf('topics', topicsItems));
          }

          query
            .getRequest()
            .setSearchFacetAll(false)
            .setSearchOffset(0)
            .setSources(searchSourceIds)
            .setSearchFilter(
              updatedFilters.length > 0 ? new FilterAnd(updatedFilters) : new FilterAnd(filters)
            );
        }
        setDropdownOpen(false);
      }
    }
  };

  // Helper function to map field values
  const mapFieldValues = (items: FilterType[] = [], field: keyof FilterType['fields']): string[] =>
    items.map((item) => {
      const fieldValue = item.fields?.[field];

      // Check if fieldValue is of type Field<GenericFieldValue> before accessing 'value'
      if (fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue) {
        return fieldValue.value as string;
      }
      return '';
    });

  // Query Array Object
  const filters: (FilterAnyOf | FilterNot)[] = [];

  const baseFilter = () => {
    const filters: (FilterAnyOf | FilterNot)[] = [];
    const SearchIDEnvPostFix = process.env.NEXT_PUBLIC_SEARCH_ID_ENV_POSTFIX || '';
    if (props.fields?.Category && props.fields?.Category.length > 0) {
      filters.push(new FilterAnyOf('category', mapFieldValues(props.fields.Category, 'PageTitle')));
    }
    if (props.fields?.Authors && props.fields?.Authors.length > 0) {
      filters.push(new FilterAnyOf('author', mapFieldValues(props.fields.Authors, 'AuthorName')));
    }
    if (props.fields?.Experts && props.fields?.Experts.length > 0) {
      const expertNames = props.fields.Experts.map(
        (expert) =>
          `${(expert.fields?.ExpertFirstName as Field<string>)?.value} ${
            (expert.fields?.ExpertLastName as Field<string>)?.value
          }`
      );
      filters.push(new FilterAnyOf('experts', expertNames));
    }
    if (props.fields?.ArticlesToExclude && props.fields?.ArticlesToExclude.length > 0) {
      props.fields.ArticlesToExclude.forEach((exclude) =>
        filters.push(new FilterNot(new FilterEqual('id', exclude.id + SearchIDEnvPostFix)))
      );
    }

    if (props.fields?.ArticlesToInclude && props.fields?.ArticlesToInclude.length > 0) {
      props.fields.ArticlesToInclude.forEach((include) =>
        filters.push(new FilterNot(new FilterEqual('id', include.id + SearchIDEnvPostFix)))
      );
    }
    return filters;
  };

  let typesItems: string[] = [];
  let topicsItems: string[] = [];

  const baseTypeTopicFilter = () => {
    const filters: (FilterAnyOf | FilterNot)[] = [];

    if (fields?.ContentType && fields?.ContentType.length > 0) {
      typesItems = fields?.ContentType?.map((item: ElementProps) =>
        item?.fields?.Name?.value.toLowerCase() == 'video'
          ? 'VideoResource'
          : item?.fields?.Name?.value.toLowerCase() == 'calculator'
          ? 'CalculatorResource'
          : (item?.fields?.Name?.value as string)
      );
      filters.push(new FilterAnyOf('type', typesItems));
    }

    if (fields?.Topics && fields?.Topics.length > 0) {
      topicsItems = fields?.Topics?.map((item: ElementProps) => item.name as string);
      filters.push(new FilterAnyOf('topics', topicsItems));
    }

    return filters;
  };

  filters.push(...baseFilter());
  filters.push(...baseTypeTopicFilter());

  const IncludedResources = () => {
    const _includedResources: Array<ResourceItemProps> = [];
    let includedResource: ResourceItemProps = {
      type: '',
      topics: [],
    };

    props?.fields?.ArticlesToInclude?.map((resource: CommonArticleProp) => {
      const articleTopics = resource?.fields?.Topics
        ? resource?.fields?.Topics.map((item) => item?.name)
        : [];
      const finalTopics = [...articleTopics.filter((item) => item !== undefined)];

      includedResource = {
        article_time: resource?.fields?.ReadTime?.value ?? '',
        description: resource?.fields?.Teaser?.value ?? '',
        id: resource?.id ?? '',
        image_url: resource?.fields?.Hero?.value?.src ?? '',
        media_url: resource?.fields?.VideoUrl?.value ?? '',
        name: resource?.fields?.Headline?.value ?? '',
        publish_date: resource?.fields?.PublishDate?.value ?? '',
        url: resource?.url,
        type:
          resource?.fields?.ContentType?.fields.Name?.value.toLowerCase() === 'video'
            ? 'VideoResource'
            : resource?.fields?.ContentType?.fields.Name?.value.toLowerCase() === 'calculator'
            ? 'CalculatorResource'
            : resource?.fields?.ContentType?.fields.Name?.value || '',
        topics: finalTopics as string[],
      };
      _includedResources.push(includedResource);
    });

    return _includedResources;
  };

  const loadMoreOptions = {
    loadMoreText: fields?.ViewMoreContent?.value as string,
    loadCount: `${fields?.NumberToDisplay?.value}`,
  };

  // Function to handle the Load more more Result
  const loadMoreResults = () => {
    const _loadCount = Number(loadMoreOptions?.loadCount);
    if (actualData.fetchCount >= 1) {
      query.getRequest().setSearchLimit(_loadCount).setSearchOffset(actualData.ResourceList.length);
    }
  };

  const activeTypes = ({
    type,
    isDropdownValue = false,
  }: {
    type: string;
    isDropdownValue?: boolean;
  }) => {
    const newType =
      type.toLowerCase() === 'video'
        ? 'VideoResource'
        : type.toLowerCase() === 'calculator'
        ? 'CalculatorResource'
        : type;
    const passObject: {
      remove: boolean;
      field: string;
      value: string;
    } = {
      remove: false,
      field: 'contentType',
      value: newType,
    };

    if (contentType) {
      if (contentType.some((item) => item === newType)) {
        const updatedTypes = contentType.filter((item) => item !== newType);
        setContentType(updatedTypes);
        passObject.remove = true;

        if (isDropdownValue) {
          setFinalContentType(updatedTypes);
        }
      } else {
        setContentType([...contentType, newType]);
        if (isDropdownValue) {
          setFinalContentType([...contentType, newType]);
        }
      }
    } else {
      setContentType([newType]);
      if (isDropdownValue) {
        setFinalContentType([newType]);
      }
    }

    if (isDropdownValue) {
      setDirectChange(true);
    }

    apiCall(passObject);
  };

  const activeTopics = ({
    topic,
    isDropdownValue = false,
  }: {
    topic: string;
    isDropdownValue?: boolean;
  }) => {
    const passObject: {
      remove: boolean;
      field: string;
      value: string;
    } = {
      remove: false,
      field: 'topic',
      value: topic,
    };

    if (topics) {
      if (topics.some((item) => item === topic)) {
        const updatedTopic = topics.filter((item) => item !== topic);
        setTopics(updatedTopic);
        passObject.remove = true;

        if (isDropdownValue) {
          setFinalTopics(updatedTopic);
        }
      } else {
        setTopics([...topics, topic]);
        if (isDropdownValue) {
          setFinalTopics([...topics, topic]);
        }
      }
    } else {
      setTopics([topics]);
      if (isDropdownValue) {
        setFinalTopics([topics]);
      }
    }

    if (isDropdownValue) {
      setDirectChange(true);
    }

    apiCall(passObject);
  };

  const filterIncludeResource = (types: string[], topics: string[]) => {
    let filteredResources: ResourceItemProps[] = [];

    if (types.length > 0 || topics.length > 0) {
      const matcheResource: ResourceItemProps[] = IncludedResources().filter((resource) => {
        const typeMatch = types.length > 0 ? types.includes(resource.type as string) : true;
        const topicMatch =
          topics.length > 0
            ? resource.topics && resource.topics.some((item) => topics.includes(item))
            : true;

        return typeMatch && topicMatch;
      });
      filteredResources = matcheResource;
    }

    if (types.length > 0 || topics.length > 0) {
      setIncludedResource(filteredResources);
    }
  };

  const apiCall = (activeObj: { field: string; value: string; remove: boolean }) => {
    const updatedFilters: (FilterAnyOf | FilterNot)[] = [...baseFilter()];
    let updatedContentType: string[] = [];
    let updatedTopics: string[] = [];

    if (activeObj.field == 'contentType' || contentType.length > 0) {
      updatedContentType = activeObj.remove
        ? [...contentType].filter((item) => item !== activeObj.value)
        : activeObj.field == 'contentType'
        ? [...contentType, activeObj.value]
        : [...contentType];

      if (updatedContentType.length > 0) {
        updatedFilters.push(new FilterAnyOf('type', updatedContentType));
      }
    }

    if (activeObj.field == 'topic' || topics.length > 0) {
      updatedTopics = activeObj.remove
        ? [...topics].filter((item) => item !== activeObj.value)
        : activeObj.field == 'topic'
        ? [...topics, activeObj.value]
        : [...topics];

      if (updatedTopics.length > 0) {
        updatedFilters.push(new FilterAnyOf('topics', updatedTopics));
      }
    }

    if (updatedContentType.length === 0) {
      const typesItems = fields?.ContentType?.map((item: ElementProps) =>
        item?.fields?.Name?.value.toLowerCase() == 'video'
          ? 'VideoResource'
          : item?.fields?.Name?.value.toLowerCase() == 'calculator'
          ? 'CalculatorResource'
          : (item?.fields?.Name?.value as string)
      );

      if (typesItems && typesItems.length > 0) {
        updatedFilters.push(new FilterAnyOf('type', typesItems));
      }
    }
    if (updatedTopics.length === 0) {
      const topicsItems = fields?.Topics?.map(
        (item: ElementProps) => item?.fields?.Name?.value as string
      );

      if (topicsItems && topicsItems.length > 0) {
        updatedFilters.push(new FilterAnyOf('topics', topicsItems));
      }
    }

    query
      .getRequest()
      .setSearchFacetAll(false)
      .setSearchOffset(0)
      .setSources(searchSourceIds)
      .setSearchFilter(
        updatedFilters.length > 0 ? new FilterAnd(updatedFilters) : new FilterAnd(filters)
      );
  };

  const viewResult = () => {
    setDropdownOpen(false);
    setOldResult(total_item);

    setFinalContentType([...contentType]);
    setFinalTopics([...topics]);

    filterIncludeResource(contentType, topics);

    if (total_item === finalResult) {
      setActualData({
        fetchCount: 1,
        ResourceList: [...resourcesData.ResourceList],
      });
    }
  };

  const clearFilter = () => {
    setContentType([]);
    setTopics([]);
    setFinalContentType([]);
    setFinalTopics([]);
    setFirstTimeCount(0);

    filterIncludeResource(typesItems, topicsItems);

    filters && filters.length > 0
      ? query
          .getRequest()
          .setSearchFacetAll(false)
          .setSources(searchSourceIds)
          .setSearchFilter(new FilterAnd(filters))
      : null;

    setDropdownOpen(false);
  };

  const closeDopdown = () => {
    setTopics([...finalTopics]);
    setContentType([...finalContentType]);
    setFinalResult(oldResult);
    setDropdownOpen(false);
  };
  const pathName = usePathname();
  //Window Load Query
  const {
    query,
    queryResult: {
      isInitialLoading,
      isFetched,
      isFetching,
      data: { total_item = 0, limit = 0, offset = 0, content: resources = [] } = {},
    },
  } = useSearchResults({
    query: (query) => {
      if (filters && filters.length > 0) {
        query
          .getRequest()
          .setSearchFacetAll(false)
          .setSources(searchSourceIds)
          .setSearchFilter(new FilterAnd(filters));
      }
    },
    state: {
      itemsPerPage: Number(InitialCount),
      page: 1,
    },
  });

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  React.useEffect(() => {
    if (firstTimeCount !== 0 && !isInitialLoading && resources.length > 0) {
      if (offset > 0) {
        setActualData({
          fetchCount: resourcesData.fetchCount + 1,
          ResourceList: [...resourcesData.ResourceList, ...resources],
        });
        setResourcesData({
          fetchCount: 1,
          ResourceList: [...resourcesData.ResourceList, ...resources],
        });
      } else {
        setResourcesData({
          fetchCount: 1,
          ResourceList: [...resources],
        });
      }
    }

    setFinalResult(total_item);
    // We only need to care about articles and offset as a dependency
    // because load more pagination requires new results to be loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (firstTimeCount !== 0 && directChange) {
      setActualData({
        fetchCount: 1,
        ResourceList: [...resources],
      });

      setOldResult(total_item);
      if (oldResult !== 0) {
        setDirectChange(false);
      }

      filterIncludeResource(
        contentType.length > 0 ? contentType : typesItems,
        topics.length > 0 ? contentType : topicsItems
      );
    }
  }, [offset, resources, pathName]);

  React.useEffect(() => {
    if (firstTimeCount == 0 && !isInitialLoading && resources.length > 0) {
      setActualData({
        fetchCount: 1,
        ResourceList: [...resources],
      });
      setResourcesData({
        fetchCount: 1,
        ResourceList: [...resources],
      });

      filterIncludeResource(typesItems, topicsItems);
      setFinalResult(total_item + IncludedResources().length);
      setOldResult(total_item + IncludedResources().length);
      setFirstTimeCount(1);
    }
  }, [resources, pathName]);

  React.useEffect(() => {
    if (currentScreenWidth < 768) {
      setIsListView(true);
    }
  }, [currentScreenWidth]);

  return (
    <section
      className={classNames(
        'container',
        params?.BackgroundColor?.Value?.value,
        params?.Spacing?.Value?.value || 'spacing-md'
      )}
    >
      <div className="mx-auto flex flex-col gap-8">
        <Text
          tag={params?.HeadingType?.Tag?.value || 'h2'}
          field={fields?.Title}
          className={classNames(
            headingClass,
            params?.HeadingType?.Styles?.value,
            'text-color-default-1'
          )}
        />
        <RichTextA11yWrapper
          field={fields?.CopyText}
          className={classNames(
            'paragraph-2-regular text-color-default-1',
            params?.Alignment?.Value?.value === 'text-center'
              ? 'text-center [&_li]:mx-auto [&_li]:w-fit'
              : 'text-left'
          )}
        />
        <div
          className={classNames(
            fields?.DisableFilterPanel?.value && currentScreenWidth < 768 && 'hidden',
            'grid md:grid-cols-3 gap-6 justify-between'
          )}
        >
          {!fields?.DisableFilterPanel?.value && (
            <div
              className="relative col-span-2"
              ref={(el) => {
                dropdownRefs.current = el;
              }}
            >
              <div className="hidden md:block border-b-2  border-strokes-default-2 pb-1">
                <Text
                  field={fields?.FilterLabel}
                  tag="span"
                  className="block w-full caption text-color-default-2 mb-1"
                />
                <button
                  className="w-full flex justify-between items-center gap-10 text-color-default-2"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <Text
                    field={fields?.FilterPlaceholder}
                    tag="span"
                    className="paragraph-2-regular"
                  />
                  <SVG svg="icon-carat-down" className="w-5 h-5" />
                </button>
              </div>
              <div className="block md:hidden">
                <button
                  className="text-sm font-medium rounded text-color-active px-6 py-[10px] border-2 border-color-active w-full flex gap-3 justify-center items-center"
                  onClick={() => setDropdownOpen(true)}
                  onKeyDown={(e) => e.key === 'Enter' && setDropdownOpen(true)}
                >
                  <SVG svg="icon-sort-filter" className="w-6 h-6" />
                  <Text field={fields?.FilterLabel} />
                </button>
              </div>

              <div
                className={classNames(
                  !dropdownOpen && 'hidden',
                  'fixed md:absolute left-0 top-0 md:top-full flex flex-col items-center md:shadow-[0px_2px_10px_0px_rgba(0,0,0,0.10)] bg-background-default-1 z-[9999] md:z-[11] w-full h-full md:h-fit'
                )}
              >
                <button
                  className="inline-flex md:hidden absolute top-4 right-4 w-8 h-8 justify-center items-center text-color-active"
                  onClick={closeDopdown}
                  onKeyDown={(e) => e.key === 'Enter' && closeDopdown()}
                  title="close"
                >
                  <SVG svg="icon-close" className="w-5 h-5 inline-block" />
                </button>
                <div className="w-full p-6 md:p-8 grow overflow-y-auto bok-scrollbar max-h-screen md:max-h-[30rem]">
                  {fields?.ContentType && fields?.ContentType.length > 1 && (
                    <>
                      <div className="mb-8">
                        <Text
                          tag="p"
                          field={fields?.FilterTypes}
                          className="paragraph-2-medium mb-4"
                        />
                        <ul className="flex flex-wrap gap-4">
                          {fields?.ContentType?.map((item, index) => {
                            if (!item) return null;

                            return (
                              <li
                                tabIndex={0}
                                className={classNames(
                                  'inline-flex items-center justify-center gap-2 p-2 rounded cursor-pointer paragraph-3-regular text-color-default-1',
                                  'border border-button-secondary-active hover:border-button-secondary-hover hover:underline',
                                  'focus:outline-none focus:border-button-secondary-focus focus:font-medium',
                                  contentType &&
                                    contentType.some((type) =>
                                      type.toLowerCase() == 'videoresource'
                                        ? 'video' ===
                                          (item?.fields?.Name as Field<string>)?.value.toLowerCase()
                                        : type.toLowerCase() == 'calculatorresource'
                                        ? 'calculator' ===
                                          (item?.fields?.Name as Field<string>)?.value.toLowerCase()
                                        : type === (item?.fields?.Name as Field<string>)?.value
                                    ) &&
                                    'text-white bg-color-active'
                                )}
                                key={index}
                                onClick={() =>
                                  activeTypes({
                                    type: (item?.fields?.Name as Field<string>)?.value,
                                  })
                                }
                                onKeyDown={(e) =>
                                  e.key === 'Enter' &&
                                  activeTypes({
                                    type: (item?.fields?.Name as Field<string>)?.value,
                                  })
                                }
                              >
                                <SVG
                                  className="w-4 h-4 md:w-6 md:h-6"
                                  svg={(item?.fields?.Icon as IconProps)?.fields?.Value?.value}
                                />
                                <Text field={item?.fields?.Name as Field<string>} />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <hr
                        className={`m-0 ${fields?.Topics && fields?.Topics.length > 0 && 'mb-8'}`}
                      />
                    </>
                  )}
                  {fields?.Topics && fields?.Topics.length > 0 && (
                    <div className="relative">
                      <Text
                        tag="p"
                        field={fields?.FilterTopics}
                        className="paragraph-2-medium mb-4"
                      />
                      <ul className="flex flex-col gap-6">
                        {fields?.Topics?.map((item, index) => {
                          if (!item) return null;
                          return (
                            <li key={index} className="flex">
                              <div
                                className={`inline-flex items-center`}
                                onClick={() =>
                                  activeTopics({
                                    topic: (item?.fields?.Name as Field<string>)?.value,
                                  })
                                }
                                onKeyDown={(e) =>
                                  e.key === 'Enter' &&
                                  activeTopics({
                                    topic: (item?.fields?.Name as Field<string>)?.value,
                                  })
                                }
                              >
                                <input
                                  type="checkbox"
                                  id={'label-' + index}
                                  checked={topics.some(
                                    (topic) =>
                                      topic === (item?.fields?.Name as Field<string>)?.value
                                  )}
                                  className={classNames(
                                    'p-0 mb-0 cursor-pointer absolute z-[1] opacity-0 peer',
                                    'h-4 w-4 md:h-6 md:w-6'
                                  )}
                                />
                                <Text
                                  tag="label"
                                  htmlFor={'#label-' + index}
                                  field={item?.fields?.Name as Field<string>}
                                  class={checkbokStyle()}
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="bg-background-default-1 py-6 px-8 w-full shadow-[0px_2px_10px_0px_rgba(0,0,0,0.10)] flex flex-wrap gap-x-5 gap-y-3 justify-between items-center">
                  <button
                    className="text-color-active text-lg leading-5 font-medium"
                    onClick={clearFilter}
                    onKeyDown={(e) => e.key === 'Enter' && clearFilter}
                  >
                    <Text field={fields?.FilterClearAll as Field<string>} />
                  </button>
                  <button
                    className="text-lg font-medium leading-5 rounded text-color-active px-6 py-3 md:p-4 border-2 border-color-active min-w-[207px] md:min-w-[304px] disabled:opacity-10"
                    onClick={() => viewResult()}
                    onKeyDown={(e) => e.key === 'Enter' && viewResult()}
                    disabled={isFetching}
                  >
                    <Text field={fields?.FilterViewResults as Field<string>} />
                    <span className="ms-1">({finalResult})</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div
            className={classNames(
              fields?.DisableFilterPanel?.value ? 'col-span-3' : 'col-span-1',
              'md:flex justify-end items-center hidden'
            )}
          >
            <div className="inline-flex items-center relative h-fit ms-auto">
              <button
                className={`transition-all inline-flex items-center border-2 border-strokes-action rounded-l py-2 px-[22px] hover:bg-color-default-3 focus:bg-background-dark-1 border-r-0 outline-none ${
                  isListView &&
                  '!bg-button-secondary-active focus:!bg-button-secondary-hover hover:!bg-button-secondary-focus !border-button-secondary-active focus:!border-button-secondary-hover hover:!border-button-secondary-focus'
                }`}
                onClick={() => setIsListView(true)}
              >
                <SVG
                  svg="icon-sort-list-view"
                  className={`w-5 h-5 mr-2 ${
                    !isListView ? 'text-icon-action' : 'text-icon-inverse'
                  }`}
                />
                <Text
                  tag="span"
                  field={{ value: 'List' }}
                  className={classNames(
                    isListView ? 'text-icon-inverse' : 'text-icon-action',
                    'caption-medium outline-none'
                  )}
                />
              </button>
              <button
                className={`transition-all inline-flex items-center border-2 border-strokes-action rounded-r py-2 px-[22px] hover:bg-color-default-3 border-l-0 focus:bg-background-dark-1 outline-none ${
                  !isListView &&
                  '!bg-button-secondary-active focus:!bg-button-secondary-hover hover:!bg-button-secondary-focus !border-button-secondary-active focus:!border-button-secondary-hover hover:!border-button-secondary-focus'
                }`}
                onClick={() => setIsListView(false)}
              >
                <SVG
                  svg="icon-grid"
                  className={`w-5 h-5 mr-2 ${
                    isListView ? 'text-icon-action' : 'text-icon-inverse'
                  }`}
                />
                <Text
                  tag="span"
                  field={{ value: 'Grid' }}
                  className={classNames(
                    !isListView ? 'text-icon-inverse' : 'text-icon-action',
                    'caption-medium outline-none'
                  )}
                />
              </button>
            </div>
          </div>
        </div>

        {(finalContentType.length > 0 || finalTopics.length > 0) && (
          <div className="flex flex-wrap gap-x-6 gap-y-3">
            {finalContentType.length > 0 &&
              finalContentType.map((item, index) => (
                <button
                  className="inline-flex gap-2 items-center p-2 text-color-default-1 border-2 border-color-active hover:underline hover:border-button-secondary-hover focus:border-button-secondary-focus rounded paragraph-3-medium outline-none"
                  onClick={() =>
                    activeTypes({
                      type: item,
                      isDropdownValue: true,
                    })
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter' &&
                    activeTypes({
                      type: item,
                      isDropdownValue: true,
                    })
                  }
                  key={index}
                >
                  <Text
                    tag="span"
                    field={{
                      value:
                        item.toLowerCase() == 'calculatorresource'
                          ? 'Calculator'
                          : item.toLowerCase() == 'videoresource'
                          ? 'Video'
                          : item,
                    }}
                  />
                  <SVG svg="icon-close" className="w-3 h-3" />
                </button>
              ))}
            {finalTopics.length > 0 &&
              finalTopics.map((item, index) => (
                <button
                  className="inline-flex gap-2 items-center p-2 text-color-default-1 border-2 border-color-active hover:border-button-secondary-hover hover:underline focus:border-button-secondary-focus rounded paragraph-3-medium outline-none"
                  onClick={() =>
                    activeTopics({
                      topic: item,
                      isDropdownValue: true,
                    })
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter' &&
                    activeTopics({
                      topic: item,
                      isDropdownValue: true,
                    })
                  }
                  key={index}
                >
                  <Text tag="span" field={{ value: item }} />
                  <SVG svg="icon-close" className="w-3 h-3" />
                </button>
              ))}
            <button
              className="text-color-active paragraph-2-regular"
              onClick={clearFilter}
              onKeyDown={(e) => e.key === 'Enter' && clearFilter}
            >
              <Text field={fields?.FilterClearAll as Field<string>} />
            </button>
          </div>
        )}

        {oldResult === 0 && isFetched ? (
          <div className="max-w-[830px] mx-auto text-center">
            <ImageWrapper
              field={fields?.NoContentImage}
              className="w-[120px] h-[120px] md:w-[140px] md:h-[140px] lg:w-[180px] lg:h-[180px] object-fit object-center aspect-square mx-auto mb-6"
              width="180"
              height="180"
            />
            <RichTextA11yWrapper tag="h3" field={fields?.NoContentTitle} className="mb-6" />
            <RichTextA11yWrapper
              tag="p"
              field={fields?.NoContentText}
              className="paragraph-2-regular"
            />
          </div>
        ) : (
          <>
            {/* Result list */}
            {isListView ? (
              <ul className="grid grid-cols-1 gap-6">
                {includedResource &&
                  includedResource.length > 0 &&
                  includedResource.map((resource, index) => (
                    <li className="col-span-1" key={index}>
                      <LinkWrapper
                        field={{ value: { href: resource.url } }}
                        tabIndex={0}
                        className="w-full bg-background-default-2 block border border-strokes-default-3 rounded-t-lg overflow-hidden border-b-color-active !no-underline"
                      >
                        <Text
                          field={fields?.StatementLabel}
                          tag="span"
                          class="inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
                        />
                        <div className="flex flex-wrap md:flex-nowrap gap-x-9 gap-y-2 px-4 py-2 md:p-4">
                          <RichTextA11yWrapper
                            className="text-color-default-1 headline-4 w-full md:w-[calc(33%-32px)]"
                            tag="h4"
                            field={{ value: resource.name }}
                          />
                          <RichTextA11yWrapper
                            className="text-color-default-2 paragraph-3-regular w-full md:w-[67%]"
                            field={{
                              value: resource.description,
                            }}
                          />
                        </div>
                        <div className="flex gap-8 px-4 pb-2 md:p-4 md:pt-0 justify-between">
                          <p className="h-full flex items-center justify-center text-color-default-1 caption">
                            {resource.publish_date &&
                              formatTimestamp(resource.publish_date, 'Month DD, YYYY')}
                            <span className="inline-block mx-2 w-px h-3 bg-strokes-default-1" />
                            {resource.article_time}
                          </p>

                          <span className="text-color-active inline-flex items-center gap-2 caption-medium">
                            {resource.type && resource.type.toLowerCase() == 'videoresource' && (
                              <Text tag="span" field={{ value: 'Watch' }} />
                            )}
                            {resource.type &&
                              resource.type.toLowerCase() == 'calculatorresource' && (
                                <Text field={{ value: 'Calculator' }} />
                              )}
                            {resource.type && resource.type.toLowerCase() == 'article' && (
                              <Text tag="span" field={{ value: 'Read' }} />
                            )}

                            {fields?.ContentType &&
                              fields?.ContentType.map((item: ElementProps, index) => {
                                if (
                                  (item.fields?.Name as Field<string>).value.toLowerCase() ==
                                  (resource.type && resource.type.toLowerCase() === 'videoresource'
                                    ? 'video'
                                    : resource.type &&
                                      resource.type.toLowerCase() === 'calculatorresource'
                                    ? 'calculator'
                                    : resource.type && resource.type.toLowerCase())
                                ) {
                                  return (
                                    <SVG
                                      className="w-5 h-5 md:w-6 md:h-6"
                                      svg={(item?.fields?.Icon as IconProps)?.fields?.Value?.value}
                                      key={index}
                                    />
                                  );
                                }
                                return null;
                              })}
                          </span>
                        </div>
                      </LinkWrapper>
                    </li>
                  ))}
                {actualData.ResourceList &&
                  actualData.ResourceList.map((resource, index) => (
                    <li className="col-span-1" key={index}>
                      <LinkWrapper
                        field={{ value: { href: resource.url } }}
                        tabIndex={0}
                        className="w-full bg-background-default-2 block border border-strokes-default-3 rounded-t-lg overflow-hidden border-b-color-active !no-underline"
                      >
                        <Text
                          field={fields?.StatementLabel}
                          tag="span"
                          class="inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
                        />
                        <div className="flex flex-wrap md:flex-nowrap gap-x-9 gap-y-2 px-4 py-2 md:p-4">
                          <RichTextA11yWrapper
                            className="text-color-default-1 headline-4 w-full md:w-[calc(33%-32px)]"
                            tag="h4"
                            field={{ value: resource.name }}
                          />
                          <RichTextA11yWrapper
                            className="text-color-default-2 paragraph-3-regular w-full md:w-[67%]"
                            field={{
                              value: resource.description,
                            }}
                          />
                        </div>
                        <div className="flex gap-8 px-4 pb-2 md:p-4 md:pt-0 justify-between">
                          <p className="h-full flex items-center justify-center text-color-default-1 caption">
                            {resource.publish_date &&
                              formatTimestamp(resource.publish_date, 'Month DD, YYYY')}
                            <span className="inline-block mx-2 w-px h-3 bg-strokes-default-1" />
                            {resource.article_time}
                          </p>

                          <span className="text-color-active inline-flex items-center gap-2 caption-medium">
                            {resource.type.toLowerCase() == 'videoresource' && (
                              <Text tag="span" field={{ value: 'Watch' }} />
                            )}
                            {resource.type.toLowerCase() == 'calculatorresource' && (
                              <Text field={{ value: 'Calculator' }} />
                            )}
                            {resource.type.toLowerCase() == 'article' && (
                              <Text tag="span" field={{ value: 'Read' }} />
                            )}

                            {fields?.ContentType &&
                              fields?.ContentType.map((item, index) => {
                                if (
                                  (item.fields?.Name as Field<string>).value.toLowerCase() ==
                                  (resource.type.toLowerCase() === 'videoresource'
                                    ? 'video'
                                    : resource.type.toLowerCase() === 'calculatorresource'
                                    ? 'calculator'
                                    : resource.type.toLowerCase())
                                ) {
                                  return (
                                    <SVG
                                      className="w-5 h-5 md:w-6 md:h-6"
                                      svg={(item?.fields?.Icon as IconProps)?.fields?.Value?.value}
                                      key={index}
                                    />
                                  );
                                }
                                return null;
                              })}
                          </span>
                        </div>
                      </LinkWrapper>
                    </li>
                  ))}
              </ul>
            ) : (
              <ul className="grid grid-cols-2 lg:grid-cols-3 grid-flow-dense gap-6">
                {includedResource &&
                  includedResource.length > 0 &&
                  includedResource.map((resource, index) => (
                    <li
                      className={classNames(
                        resource.type &&
                          resource.type.toLowerCase() == 'videoresource' &&
                          'col-span-1 row-span-2 lg:col-span-2 lg:row-span-1',
                        resource.type &&
                          resource.type.toLowerCase() == 'calculatorresource' &&
                          'col-span-1 row-span-1',
                        resource.type &&
                          resource.type.toLowerCase() == 'article' &&
                          'col-span-1 row-span-2'
                      )}
                      key={index}
                    >
                      <LinkWrapper
                        tabIndex={0}
                        field={{ value: { href: resource.url } }}
                        className={classNames(
                          resource.type && resource.type.toLowerCase() == 'videoresource'
                            ? 'flex-col lg:flex-row'
                            : 'flex-col',
                          'flex items-start h-full w-full bg-background-default-2 border border-default-3 rounded-t-lg border-b-color-active overflow-hidden focus:border-button-secondary-focus focus-visible:outline-none hover:border-icon-toggle-selected-disabled hover:shadow-[0px_2px_40px_0px_rgba(57,100,191,0.15)] hover:no-underline text-inherit hover:text-inherit focus:no-underline hover:border-b-color-active'
                        )}
                      >
                        {resource.type && resource.type.toLowerCase() != 'calculatorresource' && (
                          <div
                            className={classNames(
                              resource.type && resource.type.toLowerCase() == 'videoresource'
                                ? 'w-full lg:w-[60%] h-full min-h-[200px] lg:min-h-[260px] after:absolute after:bg-[#000] after:opacity-50 after:w-full after:h-full after:top-0 after:left-0'
                                : 'w-full min-h-[200px]',
                              'relative'
                            )}
                          >
                            {resource.type && resource.type.toLowerCase() == 'videoresource' ? (
                              <ImageWrapper
                                field={{
                                  value: {
                                    src: getThumnailUrl(resource.media_url as string),
                                  },
                                }}
                                className="w-full h-full object-cover object-center"
                                layout="fill"
                              />
                            ) : (
                              <ImageWrapper
                                field={{
                                  value: {
                                    src: resource.image_url,
                                  },
                                }}
                                className="w-full max-h-[200px] h-full object-cover object-center aspect-[356/200]"
                                width="325"
                                height="192"
                              />
                            )}
                            <Text
                              field={fields?.StatementLabel}
                              tag="span"
                              class="absolute inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
                            />
                            {resource.type && resource.type.toLowerCase() == 'videoresource' && (
                              <SVG
                                svg="icon-play"
                                hidden={false}
                                className="absolute inline-block w-10 h-10 lg:w-13 lg:h-13 text-color-inverse top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
                              />
                            )}
                          </div>
                        )}
                        {/* Category colored stripe */}

                        <div
                          className={classNames(
                            resource.type && resource.type.toLowerCase() == 'videoresource'
                              ? 'w-full lg:w-[40%]'
                              : 'w-full',
                            'flex flex-col justify-between p-4 h-full'
                          )}
                        >
                          <div>
                            <RichTextA11yWrapper
                              className="mb-3 lg:mb-4.5 text-color-default-1"
                              tag="h4"
                              field={{ value: resource.name }}
                            />
                            <RichTextA11yWrapper
                              className="mb-4 lg:mb-6 text-color-default-2 paragraph-3-regular"
                              field={{
                                value: resource.description,
                              }}
                            />
                          </div>
                          <div className="flex flex-wrap items-center justify-between caption gap-x-5 gap-y-2">
                            <p className="flex items-center justify-center text-color-default-1">
                              {resource.publish_date &&
                                formatTimestamp(resource.publish_date, 'Month DD, YYYY')}
                              <span className="inline-block mx-2 w-px h-4 bg-strokes-default-1" />
                              {resource.article_time}
                            </p>
                            <span className="text-color-active inline-flex items-center gap-2 caption-medium">
                              {resource.type && resource.type.toLowerCase() == 'videoresource' && (
                                <Text tag="span" field={{ value: 'Watch' }} />
                              )}
                              {resource.type &&
                                resource.type.toLowerCase() == 'calculatorresource' && (
                                  <Text field={{ value: 'Calculator' }} />
                                )}
                              {resource.type && resource.type.toLowerCase() == 'article' && (
                                <Text tag="span" field={{ value: 'Read' }} />
                              )}

                              {fields?.ContentType &&
                                fields?.ContentType.map((item, index) => {
                                  if (
                                    (item.fields?.Name as Field<string>).value.toLowerCase() ==
                                    (resource.type &&
                                    resource.type.toLowerCase() === 'videoresource'
                                      ? 'video'
                                      : resource.type &&
                                        resource.type.toLowerCase() === 'videoresource'
                                      ? 'calculator'
                                      : resource.type && resource.type.toLowerCase())
                                  ) {
                                    return (
                                      <SVG
                                        className="w-6 h-6"
                                        svg={
                                          (item?.fields?.Icon as IconProps)?.fields?.Value?.value
                                        }
                                        key={index}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                            </span>
                          </div>
                        </div>
                      </LinkWrapper>
                    </li>
                  ))}
                {actualData.ResourceList &&
                  actualData.ResourceList.map((resource, index) => (
                    <li
                      className={classNames(
                        resource.type.toLowerCase() == 'videoresource' &&
                          'col-span-1 row-span-2 lg:col-span-2 lg:row-span-1',
                        resource.type.toLowerCase() == 'calculatorresource' &&
                          'col-span-1 row-span-1',
                        resource.type.toLowerCase() == 'article' && 'col-span-1 row-span-2'
                      )}
                      key={index}
                    >
                      <LinkWrapper
                        tabIndex={0}
                        field={{ value: { href: resource.url } }}
                        className={classNames(
                          resource.type.toLowerCase() == 'videoresource'
                            ? 'flex-col lg:flex-row'
                            : 'flex-col',
                          'flex items-start h-full w-full bg-background-default-2 border border-default-3 rounded-t-lg border-b-color-active overflow-hidden focus:border-button-secondary-focus focus-visible:outline-none hover:border-icon-toggle-selected-disabled hover:shadow-[0px_2px_40px_0px_rgba(57,100,191,0.15)] hover:no-underline text-inherit hover:text-inherit focus:no-underline hover:border-b-color-active focus:border-button-secondary-focus'
                        )}
                      >
                        {resource.type.toLowerCase() != 'calculatorresource' && (
                          <div
                            className={classNames(
                              resource.type.toLowerCase() == 'videoresource'
                                ? 'w-full lg:w-[60%] h-full min-h-[200px] lg:min-h-[260px] after:absolute after:bg-[#000] after:opacity-50 after:w-full after:h-full after:top-0 after:left-0'
                                : 'w-full min-h-[200px]',
                              'relative'
                            )}
                          >
                            {resource.type.toLowerCase() == 'videoresource' ? (
                              <ImageWrapper
                                field={{
                                  value: {
                                    src: resource.image_url,
                                  },
                                }}
                                className="w-full h-full object-cover object-center"
                                layout="fill"
                              />
                            ) : (
                              <ImageWrapper
                                field={{
                                  value: {
                                    src: resource.image_url,
                                  },
                                }}
                                className="w-full max-h-[200px] h-full object-cover object-center aspect-[356/200]"
                                width="325"
                                height="192"
                              />
                            )}
                            <Text
                              field={fields?.StatementLabel}
                              tag="span"
                              class="absolute inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
                            />
                            {resource.type.toLowerCase() == 'videoresource' && (
                              <SVG
                                svg="icon-play"
                                hidden={false}
                                className="absolute inline-block w-10 h-10 lg:w-13 lg:h-13 text-color-inverse top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
                              />
                            )}
                          </div>
                        )}
                        {/* Category colored stripe */}

                        <div
                          className={classNames(
                            resource.type.toLowerCase() == 'videoresource'
                              ? 'w-full lg:w-[40%]'
                              : 'w-full',
                            'flex flex-col justify-between p-4 h-full'
                          )}
                        >
                          <div>
                            <RichTextA11yWrapper
                              className="mb-3 lg:mb-4.5 text-color-default-1"
                              tag="h4"
                              field={{ value: resource.name }}
                            />
                            <RichTextA11yWrapper
                              className="mb-4 lg:mb-6 text-color-default-2 paragraph-3-regular"
                              field={{
                                value: resource.description,
                              }}
                            />
                          </div>
                          <div className="flex flex-wrap items-center justify-between caption gap-x-5 gap-y-2">
                            <p className="flex items-center justify-center text-color-default-1">
                              {resource.publish_date &&
                                formatTimestamp(resource.publish_date, 'Month DD, YYYY')}
                              <span className="inline-block mx-2 w-px h-4 bg-strokes-default-1" />
                              {resource.article_time}
                            </p>
                            <span className="text-color-active inline-flex items-center gap-2 caption-medium">
                              {resource.type.toLowerCase() == 'videoresource' && (
                                <Text tag="span" field={{ value: 'Watch' }} />
                              )}
                              {resource.type.toLowerCase() == 'calculatorresource' && (
                                <Text field={{ value: 'Calculator' }} />
                              )}
                              {resource.type.toLowerCase() == 'article' && (
                                <Text tag="span" field={{ value: 'Read' }} />
                              )}

                              {fields?.ContentType &&
                                fields?.ContentType.map((item, index) => {
                                  if (
                                    (item.fields?.Name as Field<string>).value.toLowerCase() ==
                                    (resource.type.toLowerCase() === 'videoresource'
                                      ? 'video'
                                      : resource.type.toLowerCase() === 'calculatorresource'
                                      ? 'calculator'
                                      : resource.type.toLowerCase())
                                  ) {
                                    return (
                                      <SVG
                                        className="w-6 h-6"
                                        svg={
                                          (item?.fields?.Icon as IconProps)?.fields?.Value?.value
                                        }
                                        key={index}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                            </span>
                          </div>
                        </div>
                      </LinkWrapper>
                    </li>
                  ))}
              </ul>
            )}

            {loadMoreOptions && (
              <div className="text-center">
                <button
                  disabled={limit + offset === finalResult}
                  className="inline-flex items-center  text-color-active disabled:text-color-default-2"
                  onClick={loadMoreResults}
                  onKeyDown={(e) => e.key === 'Enter' && loadMoreResults()}
                >
                  <Text tag="span" field={fields?.ViewMoreContent} className="paragraph-1-medium" />
                  <SVG
                    svg="icon-chevron-down"
                    className="w-5 h-5 md:w-6 md:h-6 ms-3 [&_svg]:w-full [&_svg]:h-full"
                  />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export const ResourceWidget = widget(
  ResourceLibrarySearch,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);
