// GLobal
import type {
  ActionProp,
  ItemClickedAction,
  SearchResultsInitialState,
  SearchResultsStoreState,
} from '@sitecore-search/react';
import { WidgetDataType, useSearchResultsActions, widget } from '@sitecore-search/react';
import { ArticleCard, Pagination, Presence } from '@sitecore-search/ui';
import { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

// Lib
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';
import { useRealPathName } from 'lib/utils/useRealPathname';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';

// Local
import SVG from 'helpers/SVG/SVG';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type SearchProps = BoKNavigation.SiteSearch;

type ArticleCardItemCardProps = {
  className?: string;
  displayText?: boolean;
  article: ArticleModel;
  onItemClick: ActionProp<ItemClickedAction>;
  index: number;
};

type QueryResultsSummaryProps = {
  currentPage: number;
  itemsPerPage: number;
  totalItemsReturned: number;
  totalItems: number;
  keyPhrase?: string;
  searchInputValue?: string;
};

type SearchPaginationProps = {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  totalItemsReturned: number;
  totalItems: number;
};

type SpinnerProps = {
  loading?: boolean;
};

const ArticleHorizontalItemCard = ({
  className = '',
  article,
  onItemClick,
  index,
}: ArticleCardItemCardProps) => {
  return (
    <ArticleCard.Root
      key={article.id}
      className={`flex flex-col flex-nowrap max-h-52 w-full relative ${className}`}
    >
      <a
        className="flex w-full"
        href={article.url}
        onClick={() => {
          onItemClick({
            id: article.id,
            index,
            sourceId: article.source_id,
          });
        }}
      >
        <ArticleCard.Title className="text-color-active">
          <h4>{article.name}</h4>
        </ArticleCard.Title>
      </a>
      <ArticleCard.Subtitle className="text-color-default-2 paragraph-2-regular mt-2 md:mt-4 line-clamp-3">
        <RichTextA11yWrapper field={{ value: article.description }} />
      </ArticleCard.Subtitle>
    </ArticleCard.Root>
  );
};

const QueryResultsSummary = ({
  currentPage,
  itemsPerPage,
  // totalItems,
  totalItemsReturned,
  keyPhrase,
}: QueryResultsSummaryProps) => {
  return (
    <div className="font-bold my-auto mx-0 mt-4 md:mt-10">
      <p>
        You&apos;re viewing {itemsPerPage * (currentPage - 1) + 1}-
        {itemsPerPage * (currentPage - 1) + totalItemsReturned} results for &quot;{keyPhrase}
        &quot;
      </p>
    </div>
  );
};

const SearchPagination = ({
  currentPage,
  totalPages,
  itemsPerPage,
  totalItems,
}: SearchPaginationProps) => {
  const { onPageNumberChange } = useSearchResultsActions();

  return (
    <Pagination.Root
      currentPage={currentPage}
      defaultCurrentPage={1}
      totalPages={totalPages}
      onPageChange={(v) =>
        onPageNumberChange({
          page: v,
        })
      }
      className="flex flex-row items-center"
    >
      <Pagination.PrevPage
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
        className="cursor-pointer my-0 mx-2 data-[current=true]:hidden text-color-active focus:text-color-active"
      >
        <div className="flex flex-row items-center">
          <SVG svg="icon-chevron-left" className="h-6 w-6 max-h-6 max-w-6"></SVG>
          <p className="paragraph-2-medium ml-2 hover:underline">Prev</p>
        </div>
      </Pagination.PrevPage>
      <Pagination.Pages>
        {totalPages === currentPage ? (
          <div className="paragraph-2-regular mx-6">
            {itemsPerPage * (currentPage - 1) + 1} - {totalItems}
          </div>
        ) : (
          <div className="paragraph-2-regular mx-6">
            {itemsPerPage * (currentPage - 1) + 1} - {itemsPerPage * (currentPage - 1) + 10}
          </div>
        )}
      </Pagination.Pages>
      <Pagination.NextPage
        onClick={(e) => {
          e.preventDefault();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
        className="cursor-pointer my-0 mx-2 data-[current=true]:hidden text-color-active focus:text-color-active"
      >
        <div className="flex flex-row items-center justify-center">
          <p className="paragraph-2-medium mr-2 hover:underline">Next</p>
          <SVG svg="icon-chevron-left" className="h-6 w-6 max-h-6 max-w-6 rotate-180"></SVG>
        </div>
      </Pagination.NextPage>
    </Pagination.Root>
  );
};

const Spinner = ({ loading = false }: SpinnerProps) => {
  return (
    <Presence present={loading}>
      <div className="text-center">
        <div role="status">
          <svg
            aria-busy={loading}
            aria-hidden={!loading}
            focusable="false"
            viewBox="0 0 20 20"
            className="inline animate-spin w-10 text-color-active"
          >
            <path
              fill="currentColor"
              d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Presence>
  );
};

type ArticleModel = {
  id: string;
  type?: string;
  title?: string;
  name?: string;
  subtitle?: string;
  url?: string;
  description?: string;
  content_text?: string;
  image_url?: string;
  source_id?: string;
};

type ArticleSearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  searchBarPlaceholderText?: string;
  searchFormTarget?: string;
};

type InitialState = SearchResultsInitialState<'itemsPerPage' | 'keyphrase' | 'page' | 'sortType'>;

const searchSource = process.env.SEARCH_SOURCE_ID;
const searchSourceIds = searchSource?.split('|') || [];

export const SearchComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 10,
  searchBarPlaceholderText,
  searchFormTarget,
}: ArticleSearchResultsProps) => {
  const {
    sanitizedResults,
    widgetRef,
    actions: { onItemClick, onKeyphraseChange, onPageNumberChange },
    state: { page, itemsPerPage = defaultItemsPerPage },
    queryResult: { isLoading, isFetching, data: { total_item: totalItems = 0 } = {} },
  } = useSanitizedSearchResults<ArticleModel, InitialState>({
    query: (query) => {
      query.getRequest().setSearchFacetAll(false).setSources(searchSourceIds);
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
    },
  });

  const router = useRouter();
  const searchParams = useSearchParams();
  const query = searchParams.get('q');
  const queryPage = Number(searchParams.get('page'));

  let searchURL: URL | null = null;
  try {
    searchURL = new URL(searchFormTarget || '');
  } catch (error) {
    console.error(error);
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [keyPhrase, setKeyPhrase] = useState<string>(query || '');
  const [searchInputValue, setSearchInputValue] = useState<string>(query || '');
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const refSearchInput = useRef<HTMLInputElement>(null);

  const handleSearchButton = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>): void => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearchEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (refSearchInput.current?.value === '' && searchURL?.pathname) {
      router.replace(searchURL?.pathname, undefined, {
        shallow: true,
      });
      setShowSearch(false);
    } else {
      const url = {
        pathname: router.pathname,
        query: { ...router.query, q: refSearchInput.current?.value },
      };
      router.replace(url, undefined, { shallow: true });
      setShowSearch(true);
    }
  };

  const handleClearSearchInput = () => {
    refSearchInput.current?.focus();
    setSearchInputValue('');
    setKeyPhrase('');
    setShowSearch(false);
  };

  const pathname = useRealPathName();

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    if (page !== 0) {
      const url = {
        pathname: pathname,
        query: { ...params, page: page },
      };
      router.replace(url, undefined, { shallow: true });
    }
  }, [page]);

  useEffect(() => {
    if (query !== null) {
      setSearchInputValue(query);
      setKeyPhrase(query);
      setShowSearch(true);
      onKeyphraseChange({
        keyphrase: query,
      });
    }
  }, [query]);

  useEffect(() => {
    onPageNumberChange({ page: queryPage });
  }, [queryPage]);

  useEffect(() => {
    refSearchInput.current?.focus({ preventScroll: true });
  }, [page]);

  if (!searchURL) return <></>;

  if (isLoading) {
    return (
      <div className="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center h-full w-full bg-background-default-2 bg-opacity-90">
        <Spinner loading />
      </div>
    );
  }

  return (
    <section ref={widgetRef} className="container spacing-md mb-20">
      <div className="flex p-10 bg-background-default-2 shadow-large">
        <div className="relative block w-full">
          <input
            ref={refSearchInput}
            onKeyDown={(e) => handleSearchEnter(e)}
            className="peer border border-strokes-default-3 rounded-full w-full p-2 pl-4 pr-[94px] bg-color-inverse focus:outline-none focus:ring-0 focus:outline-offset-[-1px] focus:outline-strokes-action placeholder:paragraph-3-regular placeholder:text-color-default-2 text-color-default-1 paragraph-3-medium"
            placeholder={searchBarPlaceholderText}
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value);
            }}
          />
          <div
            className={`absolute top-0 right-[46px] bottom-0 ${
              searchInputValue?.length && searchInputValue !== '' ? '' : 'hidden'
            }`}
          >
            <button
              className="flex items-center justify-center w-[40px] h-full stroke-color-default-2"
              type="button"
              onClick={handleClearSearchInput}
            >
              <span className="sr-only">Clear Search Input</span>
              <SVG
                svg="icon-close"
                hidden={false}
                className="w-[12px] h-[12px] min-w-[12px] min-h-[12px]"
              ></SVG>
            </button>
          </div>
          <div className="absolute top-0 right-0 bottom-0">
            <button
              className="flex items-center justify-center w-[46px] h-full rounded-r-full bg-button-primary-enabled hover:bg-button-primary-hover text-color-inverse peer-focus:text-color-active"
              type="button"
              onClick={(e) => handleSearchButton(e)}
            >
              <span className="sr-only">Search</span>
              <SVG
                svg="icon-search"
                hidden={false}
                className="w-[20px] h-[20px] min-w-[20px] min-h-[20px]"
              ></SVG>
            </button>
          </div>
        </div>
      </div>
      <div className="flex relative max-w-full px-4 text-black text-opacity-75">
        {isFetching && (
          <div className="w-full h-full fixed top-0 left-0 bottom-0 right-0 z-30 bg-background-default-2 opacity-90">
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col justify-center items-center z-40 text-color-active">
              <Spinner loading />
            </div>
          </div>
        )}
        {totalItems > 0 && showSearch && (
          <section className="flex flex-col gap-4 md:gap-8 lg:gap-10">
            <QueryResultsSummary
              currentPage={page}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              totalItemsReturned={sanitizedResults?.content?.length || defaultItemsPerPage}
              keyPhrase={keyPhrase}
              searchInputValue={searchInputValue}
            />
            <div className="flex flex-col w-full gap-4 md:gap-8 lg:gap-10">
              {sanitizedResults?.content?.map((a, index) => (
                <ArticleHorizontalItemCard
                  key={a.id}
                  article={a as ArticleModel}
                  index={index}
                  onItemClick={onItemClick}
                  displayText={true}
                  className=""
                />
              ))}
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center mt-6 mb-16">
              <SearchPagination
                currentPage={page}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                totalItemsReturned={totalItems}
                totalItems={totalItems}
              />
            </div>
          </section>
        )}
        {totalItems <= 0 && !isFetching && showSearch && (
          <div className="w-full flex justify-start my-10">
            <p className="paragraph-2-regular">
              Your search term &quot;{keyPhrase}&quot; did not return any results
            </p>
          </div>
        )}
      </div>
    </section>
  );
};
const SearchWidget = widget(SearchComponent, WidgetDataType.SEARCH_RESULTS, 'content');
export default SearchWidget;
