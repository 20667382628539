// Global
import React from 'react';
import { Field, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import clientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';

// Local
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import NavigationMenuQuery from './NavigationMenu.graphql';

export type NavigationMenuProps = ComponentProps & {
  params?: {
    Styles: string;
  };
  staticProps?: {
    navigationMenu?: {
      title?: {
        jsonValue: Field<string>;
      };
      children?: {
        results: Array<{
          link: {
            jsonValue: LinkField;
          };
        }>;
      };
    };
  };
};

const NavigationMenu = (props: NavigationMenuProps): JSX.Element => {
  const Styles = props?.params?.Styles;
  const title = props?.staticProps?.navigationMenu?.title;
  const children = props?.staticProps?.navigationMenu?.children?.results;

  return (
    <div className={`${Styles}`}>
      {children?.map((item, key) => {
        return (
          <LinkWrapper
            key={key}
            field={item.link?.jsonValue?.value}
            title={title?.jsonValue?.value}
            className="caption text-color-default-1 hover:underline mt-4 md:mt-0 hover:text-color-default-1"
          ></LinkWrapper>
        );
      })}
    </div>
  );
};

export default NavigationMenu;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<NavigationMenuProps>(NavigationMenuQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
