import React from 'react';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';

import { Bok } from 'lib/templates/Foundation.BOKF.model';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type TitleCopyProps = Bok.BaseTypes.TitleCopy & {
  params: { Background: { Value: { value: string } } };
};

export const Default = (props: TitleCopyProps): JSX.Element => {
  const { fields, params } = props;

  return (
    <section
      className={classNames(
        'component spacing-md',
        params?.Background?.Value?.value ?? 'container'
      )}
    >
      <div className="container-inner">
        <Text tag="h1" field={fields?.Title} />
        <RichTextA11yWrapper className="rtaw paragraph-2-regular" field={fields?.CopyText} />
      </div>
    </section>
  );
};
