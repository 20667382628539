import React, { useEffect, useState, useCallback } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Select from 'react-select';

// Rates Imports
import { Rates } from 'lib/templates/Feature.BOKF.model';
import { RatesRequest, RatesResponse } from 'types/MarketingServices/Rates.Models';

// Helper Imports
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Bok } from 'lib/templates/Foundation.BOKF.model';
import {
  RATES_LOADING,
  RATES_UNAVAILABLE,
  BuildRatesRequest,
  CurrencyConverter,
  NEXT_RATES_API,
} from 'helpers/Rates/RatesHelper';
import SVG from 'helpers/SVG/SVG';
import classNames from 'classnames';

export type RatesGroupCalculatorProps = Rates.RateCalculator & {
  fields: {
    Disclosure: Bok.BaseTypes.TitleCopy;
    SelectedProduct: Rates.RatesConfigurationLookUp;
    SelectedTerm: Rates.RatesConfigurationLookUp;
    CompoundingInterestCodes: Rates.CompoundingPeriod;
  };
};

export type ProductOption = {
  id: string;
  title: string;
  rates: ProductTerm[];
};

export type ProductTerm = {
  id: string;
  title: string;
  limit: string;
  rate1: string;
  percentage1: string;
  rate2: string;
  percentage2: string;
  breakpoint: number;
  min: number;
  max: number;
  code: string;
};

const RatesGroupCalculator = (props: RatesGroupCalculatorProps): JSX.Element => {
  // Rates
  const [rates, setRates] = useState<RatesResponse | null>(null);
  const [rateStatus, setRatesStatus] = useState<string>(RATES_LOADING);
  const dailyCompound = props?.fields?.CompoundingInterestCodes?.fields?.Daily?.value;
  const monthlyCompound = props?.fields?.CompoundingInterestCodes?.fields?.Monthly?.value;
  const quarterlyCompound = props?.fields?.CompoundingInterestCodes?.fields?.Quarterly?.value;

  // Drop list options
  const [rateOptions, setRateOptions] = useState<ProductOption[]>([]);
  const [termOptions, setTermOptions] = useState<ProductTerm[]>([]);

  // Selected options
  const [selectedProduct, setSelectedProduct] = useState<ProductOption | null>(null);
  const [selectedTerm, setSelectedTerm] = useState<ProductTerm | null>(null);

  // Calc Options
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false);
  const [calcAmount, setCalcAmount] = useState<number>(0);
  const [calcRate, setCalcRate] = useState<string>('--');
  const [calcEarnings, setCalcEarnings] = useState<string>('--');

  const depositPlaceholder = CurrencyConverter((props?.fields?.InputValue?.value as number) || 0);
  const defaultInputValue = Number((props?.fields?.InputValue?.value as number) || 0);

  // Build Rates Request Object
  const request: RatesRequest = BuildRatesRequest(props.fields.RatesFilter as Rates.Filter);

  const [drawerOpen, setDrawerOpen] = useState(false);

  // Note creation
  const buildMinMaxNote = (min: string, max: string): string => {
    const minFormatted = min ? `${CurrencyConverter(Number(min))}` : '';
    const maxFormatted = max ? `${CurrencyConverter(Number(max))}` : '';

    if (minFormatted && maxFormatted) {
      return `${minFormatted} min/${maxFormatted} max`;
    } else if (minFormatted) {
      return `${minFormatted} min`;
    } else if (maxFormatted) {
      return `${maxFormatted} max`;
    } else {
      return '';
    }
  };

  const calculateEarnings = () => {
    const min = selectedTerm?.min || 0;
    const max = selectedTerm?.max || 0;

    const isValidRange = max === 0 ? calcAmount >= min : calcAmount >= min && calcAmount <= max;
    setIsAmountValid(isValidRange);

    if (isValidRange) {
      const principle = calcAmount;
      const breakpoint = selectedTerm?.breakpoint || 0;
      const tierIndex = breakpoint === 0 ? 0 : calcAmount >= breakpoint ? 1 : 0;
      const tierRate =
        tierIndex === 0
          ? selectedTerm?.rate1.replace('%', '')
          : selectedTerm?.rate2.replace('%', '');
      const tierPercentage =
        tierIndex === 0 ? selectedTerm?.percentage1 ?? '--' : selectedTerm?.percentage2 ?? '--';

      const rate = Number(tierRate) / 100;
      const termTitle = selectedTerm?.title.toLowerCase();
      let duration = Number(selectedTerm?.title.replace(/\D/g, ''));
      const compoundCode = selectedTerm?.code;

      let interestPeriod = 0;
      if (compoundCode) {
        if (dailyCompound?.includes(compoundCode)) {
          interestPeriod = 365;

          if (termTitle?.includes('day')) {
            duration = duration;
          }
          if (termTitle?.includes('month')) {
            duration = duration * 30;
          }
          if (termTitle?.includes('year')) {
            duration = duration * 365;
          }
        } else if (monthlyCompound?.includes(compoundCode)) {
          interestPeriod = 12;
          if (termTitle?.includes('day')) {
            duration = duration / 30;
          }
          if (termTitle?.includes('month')) {
            duration = duration;
          }
          if (termTitle?.includes('year')) {
            duration = duration * 12;
          }
        } else if (quarterlyCompound?.includes(compoundCode)) {
          interestPeriod = 4;
          if (termTitle?.includes('day')) {
            duration = duration / 90;
          }
          if (termTitle?.includes('month')) {
            duration = duration / 3;
          }
          if (termTitle?.includes('year')) {
            duration = duration * 4;
          }
        }

        if (interestPeriod > 0) {
          const t = duration / interestPeriod;
          const totalEarnings =
            principle * Math.pow(1 + rate / interestPeriod, interestPeriod * t) - principle;
          const total = CurrencyConverter(totalEarnings);
          setCalcRate(tierPercentage);
          setCalcEarnings(total);
        } else {
          setCalcRate('--');
          setCalcEarnings('--');
        }
      } else {
        setCalcRate('--');
        setCalcEarnings('--');
      }
    } else {
      setCalcRate('--');
      setCalcEarnings('--');
    }
  };

  // Input field Events
  const depositFocusIn = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      event.target.placeholder = '';
      setCalcAmount(0);
    }
  };

  const depositFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      event.target.placeholder = depositPlaceholder;
      setCalcAmount(Number(depositPlaceholder.replace(/[^0-9.-]+/g, '')));
    }
  };

  const depositChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/[^0-9.-]+/g, ''));
    if (value !== 0) {
      const currencyValue = CurrencyConverter(value);
      event.target.value = currencyValue;
      setCalcAmount(Number(value));
    } else {
      event.target.value = '';
    }
  };

  // Product Select Change Event
  const handleProductChange = (selectedOption: ProductOption | null) => {
    setSelectedProduct(selectedOption);
    if (selectedOption && selectedOption.rates.length > 0) {
      setSelectedTerm(selectedOption.rates[0]);
    } else {
      setSelectedTerm(null);
    }
  };

  // Term Select Change Event
  const handleTermChange = (selectedOption: ProductTerm | null) => {
    setSelectedTerm(selectedOption);
  };

  // Fetch Rates
  const fetchRates = useCallback(async () => {
    try {
      const response = await fetch(NEXT_RATES_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      if (response.ok) {
        setRatesStatus('');
        const data = (await response.json()) as RatesResponse;
        setRates(data);
      } else {
        setRatesStatus(RATES_UNAVAILABLE);
      }
    } catch (error) {
      setRatesStatus(RATES_UNAVAILABLE);
    }
  }, []);

  useEffect(() => {
    calculateEarnings();
  }, [selectedTerm, calcAmount]);

  useEffect(() => {
    setTermOptions(selectedProduct?.rates || []);
  }, [selectedProduct]);

  // Fetch Rates on Component Load
  useEffect(() => {
    fetchRates();
    setCalcAmount(Number(defaultInputValue));
  }, [fetchRates]);

  // Rates Loading into State
  useEffect(() => {
    setCalcAmount(Number(defaultInputValue));
    const options: ProductOption[] =
      rates?.groups.flatMap((group) =>
        group?.products.map((product) => ({
          id: product?.id.toString(),
          title: product?.title,
          rates:
            product?.terms.map((term) => ({
              id: term?.id.toString(),
              title: term?.title,
              rate1: term?.rates[0]?.interestRate || '',
              percentage1: term?.rates[0]?.annualPercentage || '',
              rate2: term?.rates[1]?.interestRate || '',
              percentage2: term?.rates[1]?.annualPercentage || '',
              tier1: product?.tiers[0]?.value || '',
              tier2: product?.tiers[1]?.value || '',
              breakpoint: Number(product?.tiers[0]?.value?.replace(/\D/g, '')) || 0,
              code: (term?.rates[0].configuration?.Code as string) || '',
              limit: buildMinMaxNote(
                term?.configuration?.Min
                  ? (term?.configuration?.Min as string)
                  : (product?.configuration?.Min as string),
                term?.configuration?.Max
                  ? (term?.configuration?.Max as string)
                  : (product?.configuration?.Max as string)
              ),
              min:
                Number(
                  term?.configuration?.Min
                    ? (term?.configuration?.Min as string)
                    : (product?.configuration?.Min as string)
                ) || 0,
              max:
                Number(
                  term?.configuration?.Max
                    ? (term?.configuration?.Max as string)
                    : (product?.configuration?.Max as string)
                ) || 0,
            })) || [],
        }))
      ) || [];
    setRateOptions(options);
    const initialProductOption = (props?.fields?.SelectedProduct as Rates.RatesConfigurationLookUp)
      ?.fields?.Id?.value as string;
    const initialTermOption = (props?.fields?.SelectedTerm as Rates.RatesConfigurationLookUp)
      ?.fields?.Id?.value as string;
    const initialOption = options.find((option) => option.id === initialProductOption);
    setSelectedProduct(initialOption || null);
    setSelectedTerm(initialOption?.rates.find((term) => term.id === initialTermOption) || null);
    setCalcAmount(Number(defaultInputValue));
  }, [rates]);

  return (
    <>
      <section className="container spacing-md">
        {rates && (
          <>
            <h2>
              <Text field={props?.fields?.Title}></Text>{' '}
            </h2>
            <div className="flex flex-col md:flex-row gap-y-6 md:gap-y-0 mt-8 lg:mt-12">
              <div className="flex flex-col gap-y-6 w-full pr-0 md:pr-4 border-0 md:border-r md:border-strokes-default-3">
                <div className="flex flex-col gap-y-1 gap-x-4 w-full lg:w-1/2 pr-0 lg:pr-2">
                  <label
                    id="calcAmountLabel"
                    className="caption mb-0 text-color-default-2"
                    htmlFor="calcAmount"
                  >
                    {props?.fields?.InputLabel?.value}
                  </label>
                  <input
                    onFocus={depositFocusIn}
                    onBlur={depositFocusOut}
                    onInput={depositChange}
                    id="calcAmount"
                    type="text"
                    data-calc="amount"
                    maxLength={14}
                    aria-labelledby="calcAmountLabel"
                    placeholder={depositPlaceholder}
                    className="paragraph-2-regular text-color-default-1 placeholder:text-color-default-1 bg-transparent border-b-2 border-strokes-default-1 focus:outline-none"
                  />
                  <div
                    id="calcLimits"
                    className={`caption mt-1 ${!isAmountValid ? 'red-heading' : ''}`}
                  >
                    {selectedTerm?.limit}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-y-6 gap-x-4">
                  <div className="w-full paragraph-2-regular">
                    <label
                      id="calcProductsLabel"
                      className="caption text-color-default-2"
                      htmlFor="calcProducts"
                    >
                      {props?.fields?.ProductLabel?.value}
                    </label>
                    <Select
                      id="calcProducts"
                      data-calc="products"
                      aria-labelledby="calcProductsLabel"
                      unstyled
                      classNames={{
                        container: ({ isDisabled, isFocused }) =>
                          classNames(
                            'border-b-2 w-full py-0.5',
                            isDisabled
                              ? 'border-background-dark-1'
                              : isFocused
                              ? 'border-color-active'
                              : 'border-color-default-2'
                          ),
                        dropdownIndicator: ({ isDisabled, isFocused }) =>
                          classNames(
                            isDisabled
                              ? 'text-background-dark-1'
                              : isFocused
                              ? 'text-color-active'
                              : 'text-color-default-2',
                            'p-2'
                          ),
                        menu: () =>
                          classNames(
                            'bg-background-default-2',
                            'rounded-b',
                            'shadow-small',
                            'mt-[2px]'
                          ),
                        menuList: () => classNames('py-1'),
                        option: ({ isSelected, isFocused }) =>
                          classNames(
                            isSelected
                              ? 'bg-background-default-1 font-medium text-color-default-1'
                              : 'bg-transparent text-color-default-2',
                            'py-2 px-3 hover:underline',
                            isFocused ? 'underline' : ''
                          ),

                        placeholder: ({ isDisabled, isFocused }) =>
                          classNames(
                            'paragraph-2-regular',
                            'mx-0.5',
                            isDisabled ? 'text-color-disabled' : 'text-color-default-1',
                            isFocused ? '' : ''
                          ),
                      }}
                      value={selectedProduct}
                      onChange={handleProductChange}
                      options={rateOptions}
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.id}
                      isSearchable={false}
                    />
                  </div>
                  <div className="w-full paragraph-2-regular">
                    <label
                      id="calcTermsLabel"
                      className="caption text-color-default-2"
                      htmlFor="calcTerms"
                    >
                      {props?.fields?.TermLabel?.value}
                    </label>
                    <Select
                      id="calcTerms"
                      data-calc="terms"
                      aria-labelledby="calcTermsLabel"
                      unstyled
                      classNames={{
                        container: ({ isDisabled, isFocused }) =>
                          classNames(
                            'border-b-2 w-full py-0.5',
                            isDisabled
                              ? 'border-background-dark-1'
                              : isFocused
                              ? 'border-color-active'
                              : 'border-color-default-2'
                          ),
                        dropdownIndicator: ({ isDisabled, isFocused }) =>
                          classNames(
                            isDisabled
                              ? 'text-background-dark-1'
                              : isFocused
                              ? 'text-color-active'
                              : 'text-color-default-2',
                            'p-2'
                          ),
                        menu: () =>
                          classNames(
                            'bg-background-default-2',
                            'rounded-b',
                            'shadow-small',
                            'mt-[2px]'
                          ),
                        menuList: () =>
                          classNames('py-1', 'bok-scrollbar', 'bok-scrollbar--no-border'),
                        option: ({ isSelected, isFocused }) =>
                          classNames(
                            isSelected
                              ? 'bg-background-default-1 font-medium text-color-default-1'
                              : 'bg-transparent text-color-default-2',
                            'py-2 px-3 hover:underline',
                            isFocused ? 'underline' : ''
                          ),

                        placeholder: ({ isDisabled, isFocused }) =>
                          classNames(
                            'paragraph-2-regular',
                            'mx-0.5',
                            isDisabled ? 'text-color-disabled' : 'text-color-default-1',
                            isFocused ? '' : ''
                          ),
                      }}
                      value={selectedTerm}
                      onChange={handleTermChange}
                      options={termOptions}
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.id}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full pl-0 md:pl-9 py-0 md:py-4">
                <Text tag="h4" field={props?.fields?.CalculatedRatesLabel} />
                <div className="flex flex-col gap-y-2 mt-2">
                  <div className="caption">
                    APY
                    {props?.fields?.Disclosure && (
                      <a href="#rates-calc-disclosure">
                        <sup>&#8224;</sup>
                      </a>
                    )}
                  </div>
                  <div id="calcRate" className="font-bold red-heading">
                    {calcRate}
                  </div>
                </div>
                <div className="flex flex-col gap-y-2 mt-6 md:mt-8">
                  <Text
                    tag="div"
                    className="caption"
                    field={props?.fields?.EstimatedEarningsLabel}
                  />
                  <div className="font-bold red-heading">
                    <div id="calcEarnings">{calcEarnings}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!rates && <div>{rateStatus}</div>}
      </section>

      {props?.fields?.Disclosure && (
        <section className="container-wide mt-10 bg-background-dark-1" id="rates-calc-disclosure">
          <div className="container-inner">
            <button
              className="flex w-full justify-between px-0 md:px-4 py-4 paragraph-2-medium items-center hover:cursor-pointer hover:ease-in focus:outline-none"
              title="Toggle"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <Text field={props?.fields?.Disclosure?.fields?.Title} editable={false}></Text>
              <SVG
                svg="icon-chevron-down"
                className={`transition-all ease-in-out ${drawerOpen ? 'rotate-180' : ''}`}
                title={drawerOpen ? 'Collapse' : 'Expand'}
              ></SVG>
            </button>
            <div
              className={`overflow-hidden transition-all duration-500 ease-in-out px-0 md:px-4 ${
                drawerOpen ? 'max-h-[1500px] pb-4' : 'max-h-0'
              }`}
            >
              <RichTextA11yWrapper
                field={props?.fields?.Disclosure?.fields?.CopyText}
                className="rtaw caption"
                editable={false}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RatesGroupCalculator;
