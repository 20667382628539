import React, { useCallback, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import ProductComparisonSlider from './ProductComparisonSlider';
import style from './ComparisonChart.module.css';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

interface SlideData {
  rowHeader: string;
  data?: Array<{
    rowHeaderKey?: string;
    value?: Field | string;
    productName?: string;
  }>;
}

type CalloutDataType = {
  calloutText: string;
  calloutProductName: string[];
}[];

interface ParentProSliderProps {
  slidesData: SlideData[];
  productName: string[];
  calloutData?: CalloutDataType[] | null;
  totalSlide: number;
  responsiveCount?: number;
  isTabHidden?: boolean;
}

type GenericFieldValue = {
  includes(arg0: string): unknown;
  href?: string;
  text?: string;
};

interface slideDataType {
  data: Array<{
    rowHeaderKey: string;
    value?: Field<GenericFieldValue> | string;
    productName?: string;
  }>;
}

const ProductSliderWrapper = ({
  slidesData,
  productName,
  calloutData,
  totalSlide,
  responsiveCount,
  isTabHidden,
}: ParentProSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
  };
  // Function to go to the next slide
  const handleNext = useCallback(() => {
    setCurrentSlide((prev) => prev + 1);
  }, []);

  // Function to go to the previous slide
  const handlePrev = useCallback(() => {
    setCurrentSlide((prev) => prev - 1);
  }, []);

  // Function to handle dot click
  const handleDotClick = useCallback((index: number) => {
    setCurrentSlide(index);
  }, []);
  return (
    <div className="relative">
      {/* Render 5 synchronized carousels */}
      {productName.map((item: string, index: number) => (
        <ProductComparisonSlider
          key={index}
          onSlideChange={handleSlideChange}
          currentSlide={currentSlide}
          slide={item as unknown as slideDataType}
          calloutData={calloutData as unknown as CalloutDataType}
          className={classNames(
            isTabHidden ? 'top-[178px] md:top-[153px]' : 'top-[100px]',
            'sticky z-20 shadow-small',
            style.header_title
          )}
        />
      ))}

      {slidesData.length ? (
        slidesData.map((slide: SlideData, slideIndex: number) => (
          <div
            className={classNames('relative', slideIndex == 0 ? style.firstBlock : '')}
            key={slideIndex}
          >
            <RichTextA11yWrapper
              field={{ value: slide?.rowHeader as string }}
              tag="span"
              className="text-center py-1 block bg-background-dark-1 rtaw"
            />
            <ProductComparisonSlider
              key={slideIndex}
              onSlideChange={handleSlideChange}
              currentSlide={currentSlide}
              slide={slide as slideDataType}
              className={slideIndex == 0 ? style.spacing_title : ''}
            />
          </div>
        ))
      ) : (
        <p>No data available for this tab.</p>
      )}

      <div
        className={classNames(
          style.comparison_chart_embla__buttons,
          'bg-background-default-2 shadow-small py-3 sticky bottom-0 flex justify-center border gap-[0.6rem] items-center border-solid border-strokes-default-3'
        )}
      >
        {/* Button Controls */}
        {responsiveCount && totalSlide - responsiveCount > 0 && (
          <>
            <button
              onClick={handlePrev}
              className={`${style.comparison_chart_embla__button} ${style.comparison_chart_embla__button__prev} disabled:opacity-10  no-underline cursor-pointer w-10 h-10 flex items-center justify-center m-0 p-0 rounded-[5px] border-0 hover:bg-background-dark-1 focus:bg-background-dark-1`}
              disabled={currentSlide == 0}
              title="previous button"
              aria-label="previous button"
              aria-labelledby="previous"
            >
              <SVG svg="icon-carousel-arrow-left" hidden={false} className="w-4 h-4"></SVG>
            </button>
            <div className="flex justify-center gap-x-[10px] flex-wrap items-center">
              {responsiveCount &&
                Array.from({
                  length:
                    totalSlide - responsiveCount + 1 <= 0 ? 1 : totalSlide - responsiveCount + 1,
                }).map((_, index) => (
                  <button
                    key={index}
                    className={classNames(
                      style.comparison_chart_embla__dot,
                      index === currentSlide ? style.comparison_chart_embla__dot__active : '',
                      'appearance-none bg-transparent no-underline cursor-pointer w-4 h-4 flex items-center justify-center m-0 p-0 rounded-[50%] border-0',
                      'after:w-4 after:h-4 after:flex after:items-center after:bg-icon-disabled-1 after:transition-all after:rounded-[50%]',
                      'before:w-6 before:h-6 before:outline-none before:absolute before:rounded-[50%]',
                      'focus:before:outline-button-secondary-focus hover:after:bg-strokes-default-2 active:after:bg-strokes-default-1'
                    )}
                    onClick={() => handleDotClick(index)}
                  />
                ))}
            </div>
            {responsiveCount && (
              <button
                onClick={handleNext}
                className={classNames(
                  style.comparison_chart_embla__button,
                  style.comparison_chart_embla__button__next,
                  'disabled:opacity-10 no-underline cursor-pointer w-10 h-10 flex items-center justify-center m-0 p-0 rounded-[5px] border-0',
                  'hover:bg-background-dark-1 focus:bg-background-dark-1'
                )}
                disabled={
                  totalSlide - responsiveCount > 0
                    ? currentSlide == totalSlide - responsiveCount
                    : currentSlide == 0
                }
                title="next button"
                aria-label="next button"
                aria-labelledby="next"
              >
                <SVG svg="icon-carousel-arrow-right" hidden={false} className="w-4 h-4"></SVG>
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductSliderWrapper;
