// Global
import React from 'react';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { Placeholder, RichText } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import clientFactory from 'lib/graphql-client-factory';
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';

// Local
import SVG from 'helpers/SVG/SVG';
import FooterQuery from './Footer.graphql';

export type FooterProps = Omit<ComponentProps, 'params'> & {
  params?: {
    Theme?: ThemeType;
    HideFooterLinks?: string;
  };
  staticProps?: {
    footer?: BoKNavigation.DataTemplates.FooterConfigurationJson;
  };
};

export type ThemeType = {
  Value: {
    value: string;
  };
};

const Footer = ({ rendering, ...props }: FooterProps): JSX.Element => {
  const { footerCopyright, footerBrandingTitle } = props?.staticProps?.footer || {};
  const { Theme, HideFooterLinks } = props?.params || {};

  return (
    <div className={`footer ${Theme?.Value?.value}`}>
      <div className="container flex flex-col py-4 md:py-8 lg:py-12 gap-y-6">
        {HideFooterLinks !== '1' && (
          <>
            <div className="footer-navigation flex">
              {rendering && <Placeholder name="footer-navigation" rendering={rendering} />}
            </div>
            {(rendering?.placeholders?.['footer-social-navigation'] &&
              rendering.placeholders['footer-social-navigation'].length > 0) ||
            (rendering?.placeholders?.['footer-navigation-menu'] &&
              rendering.placeholders['footer-navigation-menu'].length > 0) ? (
              <div className="flex flex-col md:flex-row items-start md:items-center gap-y-2 gap-x-3">
                {rendering?.placeholders?.['footer-social-navigation'] &&
                  rendering.placeholders['footer-social-navigation'].length > 0 && (
                    <Placeholder name="footer-social-navigation" rendering={rendering} />
                  )}
                {rendering?.placeholders?.['footer-navigation-menu'] &&
                  rendering.placeholders['footer-navigation-menu'].length > 0 && (
                    <Placeholder name="footer-navigation-menu" rendering={rendering} />
                  )}
              </div>
            ) : null}{' '}
          </>
        )}
        {footerCopyright?.jsonValue?.value && (
          <div className="flex mobile:flex-col flex-row gap-2">
            <SVG
              svg="icon-copyright-logo"
              title={footerBrandingTitle?.jsonValue?.value}
              className="copyright-logo"
            ></SVG>
            <RichText
              className="caption footer-copyright"
              field={footerCopyright?.jsonValue}
            ></RichText>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<FooterProps>(FooterQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
