import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import SearchWidget from 'widgets/SearchWidget/SearchWidget';
import { WidgetComponentProps } from '@sitecore-search/react/dist/esm/types';
import { useSearchOverlayContext } from 'helpers/Providers/SearchOverlayContextProvider';

import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';

export type SearchProps = WidgetComponentProps & BoKNavigation.SiteSearch;

const Search = (props: SearchProps) => {
  const { SearchBarPlaceholderText } = props?.fields || {};

  const router = useRouter();
  const { setOverlayOpen } = useSearchOverlayContext();
  useEffect(() => {
    setOverlayOpen(false);
  }, [router]);

  const currentPageURL =
    typeof window !== 'undefined' ? `${window.location.origin}${router.asPath}` : '';
  return (
    <WidgetWrapper>
      <SearchWidget
        rfkId={'rfkid_7'}
        searchBarPlaceholderText={SearchBarPlaceholderText?.value}
        searchFormTarget={currentPageURL}
      />
    </WidgetWrapper>
  );
};

export default Search;
