import SVG from 'helpers/SVG/SVG';
import { LocationSearchProps } from './LocationTypes';
import classNames from 'classnames';

// LocationSearch component
const LocationSearch: React.FC<LocationSearchProps> = ({
  setSearchValue,
  searchValue,
  onSearch,
  onGetCurrentLocation,
  searchPlaceholderText,
}) => {
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    onSearch(searchValue);
  };

  return (
    <div className="location-search mb-[21px]">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        className="block"
      >
        <div className="flex items-center h-12 min-w-full relative">
          <input
            type="search"
            placeholder={searchPlaceholderText}
            className={classNames(
              'border rounded-full h-full w-full px-4 border-strokes-input text-color-default-1 paragraph-2-medium',
              'focus:outline focus:outline-2 focus:outline-strokes-action'
            )}
            value={searchValue}
            onChange={handleSearchChange}
          />
          <button
            type="button"
            onClick={() => {
              onGetCurrentLocation();
            }}
            className="w-3 h-4 absolute right-18 focus:outline-none"
          >
            <SVG
              svg="icon-location-2"
              className="text-color-active w-3 h-4 focus:[&_svg]:outline-none"
            />
          </button>
          <button
            type="submit"
            className="absolute w-[57px] h-full rounded-r-full bg-btn-scroll-gradient hover:bg-button-secondary-hover hover:bg-none focus:bg-button-secondary-focus focus:bg-none right-0 flex items-center justify-center outline-none focus:outline-none"
          >
            <SVG svg="icon-search2" className="mb-1 mr-1 w-5 h-5 text-white" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default LocationSearch;
