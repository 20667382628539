import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type ArticleSectionFields = {
  SectionTitle: Field<string>;
  SectionCopy: Field<string>;
};

export type ArticleSectionProps = {
  fields: ArticleSectionFields;
};

const ArticleSection = (props: ArticleSectionProps) => {
  const { fields } = props;
  if (!fields) return <></>;

  const createSlug = (str: string) => {
    return str && str.replaceAll(' ', '')?.toLocaleLowerCase();
  };

  return (
    <div
      id={createSlug(fields?.SectionTitle?.value)}
      className="w-full mt-10 lg:mt-12 scroll-smooth"
    >
      <Text tag="h3" className="text-color-default-1 mb-4" field={fields?.SectionTitle} />
      <RichTextA11yWrapper
        className="text-color-default-2 paragraph-2-regular rtaw"
        field={fields?.SectionCopy}
      />
    </div>
  );
};

export default ArticleSection;
