import { useExternalScripts } from 'lib/utils/useExternalScript';
import React, { useEffect } from 'react';

export type CodeEmbeddingProps = {
  fields: {
    Javascript: {
      value: string;
    };
  };
};

// Constant value for Script Execution try
const MAX_SCRIPT_EVAL_TRY = 5;

const CodeEmbedding = (props: CodeEmbeddingProps): JSX.Element => {
  const extractScripts = (htmlString: string) => {
    const loadableScripts: string[] = [];
    const executableScripts: HTMLScriptElement[] = [];
    if (typeof document !== 'undefined') {
      const container = document.createElement('div');
      container.innerHTML = htmlString;

      const scripts = container.querySelectorAll('script');

      scripts.forEach((script) => {
        script.src ? loadableScripts.push(script.src) : executableScripts.push(script);
      });
    }

    return { loadableScripts, executableScripts };
  };

  // Function for separate Load scripts and Execute Script.
  const { loadableScripts, executableScripts } = extractScripts(props?.fields?.Javascript?.value);

  // Custom Hook For Load scripts and will return true when all scripts have loaded.
  const isScriptsLoaded = useExternalScripts(loadableScripts);

  const executeScript = (script: string, executionTryCount = 1) => {
    let _count = executionTryCount;
    try {
      new Function(script)();
    } catch (error) {
      setTimeout(() => {
        if (executionTryCount < MAX_SCRIPT_EVAL_TRY) {
          // Recursion function when any script throw error
          executeScript(script, ++_count);
        } else {
          console.error('Error in script execution in CodeEmbedding component');
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (isScriptsLoaded && executableScripts.length > 0) {
      for (let i = 0; i < executableScripts.length; i++) {
        executeScript(executableScripts[i].outerText);
      }
    }
  }, [isScriptsLoaded]);

  if (!props?.fields) return <></>;

  return (
    <>
      {isScriptsLoaded && (
        <div
          dangerouslySetInnerHTML={{
            __html: props?.fields?.Javascript?.value,
          }}
        />
      )}
    </>
  );
};

export default CodeEmbedding;
