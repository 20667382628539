import React from 'react';

import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';

import { Marketing } from 'lib/templates/Feature.BOKF.model';

type VideoCallToActionProps = Marketing.DataTemplates.Video.VideoCallToAction;

const VideoCallToAction = (props: VideoCallToActionProps): JSX.Element => {
  const { fields } = props;
  if (!fields) {
    return <></>;
  }
  // to extract the src attribute from the iframe tag
  const getIframeSrc = (videoSrc: string) => {
    const iframeMatch = videoSrc.match(/<iframe[^>]+src=["']([^"']+)["']/);
    return iframeMatch ? iframeMatch[1] : videoSrc;
  };

  const videoSrc = fields?.VideoSrc?.value ? getIframeSrc(fields?.VideoSrc?.value) : '';
  return (
    <section className="mt-8 container">
      <div className=" bg-background-default-2 shadow-small">
        <div className="flex flex-col p-4 md:p-8 lg:p-12 gap-y-4 md:gap-y-6">
          <div className="w-full">
            <RichTextA11yWrapper
              tag="h2"
              field={fields.Title}
              className="after:content-[''] rtaw after:pt-2 after:block after:w-[3rem] after:mb-2 after:border-b-4  after:border-strokes-brand"
            />
          </div>
          <div className="flex gap-x-6 items-start flex-col lg:flex-row gap-y-4 md:gap-y-8">
            <div className="w-full flex flex-col gap-y-6 lg:w-2/5">
              <RichTextA11yWrapper
                field={fields.Text}
                className="paragraph-2-regular text-color-default-2 rtaw"
              />
              <div className="hidden lg:flex lg:flex-col lg:gap-y-4">
                <div>
                  {fields?.CallToAction?.value?.href && (
                    <Button tag="a" field={fields?.CallToAction} />
                  )}
                </div>

                {fields?.SecondaryCallToAction?.value?.href && (
                  <div>
                    <Button tag="a" type="secondary" field={fields?.SecondaryCallToAction} />
                  </div>
                )}
              </div>
            </div>

            <div className="w-full lg:w-3/5 relative aspect-video">
              <iframe
                src={videoSrc}
                style={{ width: '100%', height: '100%' }}
                aria-label={fields?.Title?.value}
                loading="lazy"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                id="vimeo_id_0"
              ></iframe>
            </div>
            {/* CTA for Mobile View  */}
            <div className="w-full flex lg:hidden flex-col md:flex-row justify-center gap-y-4 gap-x-6">
              <div>
                {fields?.CallToAction?.value?.href && (
                  <Button tag="a" field={fields?.CallToAction} />
                )}
              </div>

              {fields?.SecondaryCallToAction?.value?.href && (
                <div>
                  <Button tag="a" type="secondary" field={fields?.SecondaryCallToAction} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default VideoCallToAction;
