import React, { useEffect } from 'react';
import { debounce } from 'lodash';

interface AnchorProps {
  params: {
    AnchorId: string;
  };
}

const Anchor = (props: AnchorProps): JSX.Element => {
  const anchorId = props?.params?.AnchorId;

  useEffect(() => {
    const scrollAnchor = () => {
      const anchorHash = window.location.hash.replace('#', '');
      if (anchorHash !== '') {
        const anchorElement = document.getElementById(anchorHash);
        if (anchorElement !== null) {
          window.scrollTo({
            top: anchorElement.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    };

    const debounceScroll = debounce(scrollAnchor, 2000);

    debounceScroll();

    window.addEventListener('hashchange', debounceScroll);

    return () => {
      window.removeEventListener('hashchange', debounceScroll);
    };
  }, []);

  return <div id={anchorId} className="page-anchor"></div>;
};

export default Anchor;
