import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Button from 'helpers/Button/Button';
import { hostToReplace } from 'lib/host-to-replace';
import Link from 'next/link';

export type VisualSiteMapProps = {
  fields: dataProp;
};

export type dataProp = {
  data: DataPropItems;
};

export type DataPropItems = {
  datasource: Field<datasourceProps>;
  visualSiteMap: Field<Item[]>;
};

export type datasourceProps = {
  JumpToLabel: string;
  JumpToLabelHtmlTag: string;
  JumpToLabelStyle: string;
};

export type Item = {
  itemID: string;
  title: string;
  url: string;
  children?: Item[] | null;
};

const VisualSitemap = (props: VisualSiteMapProps) => {
  const datasource = props?.fields?.data?.datasource;
  const visualSiteMap = props?.fields?.data?.visualSiteMap;

  if (!visualSiteMap || !datasource) return <></>;

  const createSlug = (str: string) => {
    return str && str.replaceAll(' ', '')?.replaceAll('/', '')?.toLocaleLowerCase();
  };

  let tagStyles = '';
  switch (datasource?.value?.JumpToLabelStyle) {
    case 'title-border title-border--thick title-border--neutral':
      tagStyles =
        'after:content-[""] after:block after:border-b-[5px] after:border-strokes-brand after:w-12 after:mb-3 after:pt-1 md:after:pt-2 lg:after:pt-4 lg:after:mb-2';
      break;
    case 'title-border':
      tagStyles =
        'after:content-[""] after:block after:border-b-[4px] after:border-strokes-brand after:w-10 after:mb-3 after:pt-2 lg:after:pt-3 lg:after:mb-2';
      break;
    default:
      tagStyles = '';
      break;
  }

  const JumpToButtons = (item: Item) => {
    return (
      <Button
        fieldClass="!w-fit text-color-active hover:text-color-active paragraph-2-medium"
        type="auxiliary"
        size="large"
        title={item?.title || ''}
        field={{ value: { href: `#${createSlug(item?.title)}`, text: item?.title } }}
      />
    );
  };

  const HeadingItem = (item: Item, index: number) => {
    return (
      <li key={index}>
        <Link
          className="text-color-active hover:text-color-active paragraph-1-medium"
          href={hostToReplace({ value: { href: item?.url } })?.value?.href as string}
        >
          {item?.title}
        </Link>
        <hr />
      </li>
    );
  };

  const ItemList = (item: Item, index: number) => {
    return (
      <ul id={`${createSlug(item?.title)}`} className="mb-12 lg:mb-15" role="list">
        {HeadingItem(item, index)}
        <ul className="lg:columns-3 md:columns-2 columns-1 space-y-2 lg:space-y-4">
          {item?.children &&
            Array.isArray(item?.children) &&
            item?.children?.length > 0 &&
            item?.children?.map((firstItem: Item, firstIndex: number) => (
              <>
                {ChildItem(firstItem, firstIndex, 'paragraph-2-medium')}
                {firstItem?.children &&
                  Array.isArray(firstItem?.children) &&
                  firstItem?.children?.length > 0 &&
                  firstItem?.children?.map((secondItem: Item, secondItemIndex: number) => (
                    <>
                      {ChildItem(secondItem, secondItemIndex, 'paragraph-3-regular')}
                      {secondItem?.children && (
                        <ul className="list-disc pl-5 text-color-active space-y-2 lg:space-y-4">
                          {Array.isArray(secondItem?.children) &&
                            secondItem?.children?.length > 0 &&
                            secondItem?.children?.map(
                              (thirdChildItem: Item, thirtChildItemIndex: number) => (
                                <>
                                  {ChildItem(
                                    thirdChildItem,
                                    thirtChildItemIndex,
                                    'paragraph-3-regular'
                                  )}
                                  {thirdChildItem?.children && (
                                    <ul className="list-disc pl-5 text-color-active space-y-2 lg:space-y-4">
                                      {Array.isArray(thirdChildItem?.children) &&
                                        thirdChildItem?.children?.length > 0 &&
                                        thirdChildItem?.children?.map(
                                          (fourthChildItem: Item, fourthChildItemIndex: number) =>
                                            ChildItem(
                                              fourthChildItem,
                                              fourthChildItemIndex,
                                              'paragraph-2-regular'
                                            )
                                        )}
                                    </ul>
                                  )}
                                </>
                              )
                            )}
                        </ul>
                      )}
                    </>
                  ))}
              </>
            ))}
        </ul>
      </ul>
    );
  };

  const ChildItem = (item: Item, index: number, classes: string) => {
    return (
      <li key={index}>
        <Link
          className={`text-color-active hover:text-color-active ${classes}`}
          href={hostToReplace({ value: { href: item?.url } })?.value?.href as string}
        >
          {item?.title}
        </Link>
      </li>
    );
  };

  return (
    <section className="container spacing-md">
      <Text
        tag={datasource?.value?.JumpToLabelHtmlTag || 'h2'}
        className={`${tagStyles} text-color-default-1 mb-6`}
        field={{ value: datasource?.value?.JumpToLabel }}
      />
      <div className="flex flex-wrap gap-2 md:gap-4 mb-10">
        {visualSiteMap?.value?.map((item: Item) => JumpToButtons(item))}
      </div>
      {visualSiteMap?.value?.map((item: Item, index: number) => ItemList(item, index))}
    </section>
  );
};

export default VisualSitemap;
