// Global
import React from 'react';
import { GetStaticComponentProps, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import clientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';

// Local
import FooterNavigationQuery from './FooterNavigation.graphql';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type FooterNavigationProps = Omit<ComponentProps, 'params'> & {
  params?: {
    MenuStyle: MenuStyleType;
    DisplayHeading: string;
  };
  staticProps?: {
    footerNavigation?: {
      title: {
        jsonValue: {
          value: string;
        };
      };
      children: {
        results: Array<{
          link: {
            jsonValue: LinkField;
          };
        }>;
      };
    };
  };
};

export type MenuStyleType = {
  Value: {
    value: string | undefined;
  };
};

const FooterNavigation = (props: FooterNavigationProps): JSX.Element => {
  const title = props?.staticProps?.footerNavigation?.title;
  const children = props?.staticProps?.footerNavigation?.children;
  const { MenuStyle, DisplayHeading } = props?.params ?? {};

  return (
    <nav
      className={`flex flex-col gap-y-3 ${
        MenuStyle?.Value?.value ? MenuStyle.Value.value : 'subscribe'
      }`}
    >
      {DisplayHeading === '1' && (
        <div className={`paragraph-3-medium`}>{title?.jsonValue?.value}</div>
      )}
      <ul>
        {children?.results.map((item, key) => {
          return (
            <li key={key} className="caption hover:underline">
              <LinkWrapper field={item.link.jsonValue}></LinkWrapper>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default FooterNavigation;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<FooterNavigationProps>(FooterNavigationQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
