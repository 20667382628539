import { useEffect } from 'react';
import Script from 'next/script';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import {
  Field,
  SitecoreContextValue,
  useSitecoreContext,
  EditingScripts,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';

// The BYOC bundle imports external (BYOC) components into the app and makes sure they are ready to be used
import BYOC from 'src/byoc';
import config from 'temp/config';
import useExperienceEditor from 'lib/use-experience-editor';
import FEAASScripts from 'components/FEAASScripts';
import CdpPageView from 'components/default/CdpPageView';
import { extractScriptsFromString } from 'lib/utils/script-utils';
// import { MotionPointScripts } from 'components/MotionPointScripts';

type ExtendedSitecoreContextProps = SitecoreContextValue & {
  OneTrustCode?: Field<string>;
  GTMTrackingId?: Field<string>;
  favIcon?: { url: string };
};

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
const publicUrl = config.publicUrl;

const Scripts = (): JSX.Element => {
  const sitecoreContext: ExtendedSitecoreContextProps = useSitecoreContext().sitecoreContext;
  const isEE = useExperienceEditor();
  useEffect(() => {
    sendGTMEvent({
      value: {
        page_type: sitecoreContext.route?.templateName,
        bok_channel: sitecoreContext.route?.fields?.['bok_channel'],
        content_title: (sitecoreContext.route?.fields?.PageTitle as Field<string>)?.value,
        content_type: (sitecoreContext?.ContentType as Field<string>)?.value || '',
        product_name: (sitecoreContext?.ProductName as Field<string>)?.value || '',
      },
    });
    // fields from sitecore context won't change as it is being retrieved from layout service
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start ClickJacking implementation
  useEffect(() => {
    // Check if window.top and window.self are available
    if (!isEE && typeof window !== 'undefined' && window.top !== window.self) {
      // Make sure window.top is not null and redirect if framed
      if (window.top?.location) {
        window.top.location = window.self.location;
      }
    }
  }, [isEE]);

  //  End ClickJacking implementation

  return (
    <>
      <Head>
        <link rel="icon" href={sitecoreContext.favIcon?.url || `${publicUrl}/favicon.ico`} />
      </Head>

      {sitecoreContext?.OneTrustCode?.value &&
        extractScriptsFromString(sitecoreContext?.OneTrustCode?.value).map((script, index) => (
          // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
          <Script key={index} {...script.attributes} strategy="beforeInteractive">
            {script.content}
          </Script>
        ))}

      {sitecoreContext.GTMTrackingId?.value && (
        <GoogleTagManager gtmId={sitecoreContext.GTMTrackingId?.value} />
      )}

      {/*  removing motionpoint old xp script, replacing it with new js received from motionpoint. 
           motionpoint js will be checked in to source control and referenced from settings object of the site.  
         <MotionPointScripts /> */}
      <BYOC />
      <CdpPageView />
      <FEAASScripts />
      <EditingScripts />
    </>
  );
};

export default Scripts;
