//Global
import { useSitecoreContext, Text, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import useExperienceEditor from 'lib/use-experience-editor';

//Helper
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { Resources } from 'lib/templates/Feature.BOKF.model';

export type AuthorDetailsCardProps = Resources.DataTemplates.Author;

const AuthorDetailsCard = () => {
  const { sitecoreContext } = useSitecoreContext();
  const { fields } = sitecoreContext?.route as AuthorDetailsCardProps;
  const hasValidLink = fields?.AuthorLink?.value?.href;
  const isEE = useExperienceEditor();
  return (
    <section className="container">
      <div className="flex flex-col md:flex-row gap-6 lg:gap-x-8 pl-6 py-6 pr-6 lg:pr-18 bg-background-default-2 border border-strokes-default-3 rounded-lg">
        <ImageWrapper className="w-full md:w-1/5 object-cover" field={fields?.AuthorImage} />
        <div className="flex flex-col gap-y-4 text-center md:text-left">
          <div className="flex flex-col">
            {isEE ? (
              <>
                <LinkWrapper
                  className="text-color-active paragraph-2-medium hover:underline"
                  field={fields?.AuthorLink as LinkField}
                />
                <Text
                  tag="p"
                  className="text-color-default-1 paragraph-2-medium"
                  field={fields?.AuthorName}
                />
              </>
            ) : hasValidLink ? (
              <LinkWrapper
                className="text-color-active paragraph-2-medium hover:underline"
                field={{
                  value: {
                    href: fields?.AuthorLink?.value?.href,
                    text: fields?.AuthorName?.value,
                    class: fields?.AuthorLink?.value?.class,
                  },
                }}
              />
            ) : (
              <Text
                tag="p"
                className="text-color-default-1 paragraph-2-medium"
                field={fields?.AuthorName}
              />
            )}
            <Text
              tag="p"
              className="text-color-default-1 paragraph-3-regular"
              field={fields?.AuthorSubtitle}
            />
          </div>
          <RichTextA11yWrapper
            tag="div"
            className="text-color-default-2 paragraph-2-regular rtaw"
            field={fields?.AuthorBio}
          />
        </div>
      </div>
    </section>
  );
};

export default AuthorDetailsCard;
