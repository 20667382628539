import React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import style from './SlideshowCollection.module.css';
import useEmblaCarousel from 'embla-carousel-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { SlideshowChildsProps, SlideShowstyle } from './SlideshowCollection';
import classNames from 'classnames';
import { DotButton, useDotButton } from 'helpers/SliderComponent/SliderDotButton';
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from 'helpers/SliderComponent/SliderArrowButtons';

const options: EmblaOptionsType = { containScroll: false };

type SlideProps = Partial<ComponentProps> & {
  typeOfSlider: string;
  sliders: Array<SlideshowChildsProps>;
  params?: {
    DynamicPlaceholderId?: string;
  };
};

const SlideshowSlider = ({ typeOfSlider, rendering, sliders, params }: SlideProps) => {
  // Slider Configration
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  const { SlideStyle, SliderDotStyle, DotWrapperStyle, ControlWrapperStyle, SliderArrowStyle } =
    SlideShowstyle();

  // Function For Handle PlaceHolder or Slide data
  const placeholderPosition = () => {
    if (typeOfSlider == 'NormalSlider' && rendering) {
      return (
        // This placeholder For Full Width Slide Slider
        <Placeholder
          name={`Slideshow-${params?.DynamicPlaceholderId}`}
          rendering={rendering}
          render={(components) => {
            return components.map((component, index) => (
              <div className={style.slideshow__slide} key={index}>
                <div className={SlideStyle()}>{component}</div>
              </div>
            ));
          }}
        />
      );
    } else if (typeOfSlider == 'SplitSlider' && rendering) {
      let splitLeftIndex = -1;
      let splitRightIndex = -1;
      let slideshowIndex = -1;

      return (
        // This placeholders For 50%/50%, 66/33, 33/66 or combine Split Slide Slider
        <>
          {sliders?.map((slide: SlideshowChildsProps, num: number) => {
            let leftIndex = 0;
            let rightIndex = 0;
            let showIndex = 0;

            if (slide?.fields?.ColumnStructure) {
              // Increment split slide indices
              splitLeftIndex += 1;
              splitRightIndex += 1;
              leftIndex = splitLeftIndex;
              rightIndex = splitRightIndex;
            } else {
              // Increment slideshow index
              slideshowIndex += 1;
              showIndex = slideshowIndex;
            }

            return (
              <div className={style.slideshow__slide} key={num}>
                <div className={SlideStyle()}>
                  {slide?.fields?.ColumnStructure ? (
                    <div className={`flex flex-col md:flex-row w-full gap-6`}>
                      <div
                        className={classNames(
                          'md:w-1/2',
                          slide?.fields?.ColumnStructure?.fields.Value?.value.split('|')[0]
                        )}
                      >
                        <Placeholder
                          name={`SplitSlideLeft-${params?.DynamicPlaceholderId}`}
                          rendering={rendering}
                          render={(components) => components[leftIndex]}
                        />
                      </div>
                      <div
                        className={classNames(
                          'md:w-1/2',
                          slide?.fields?.ColumnStructure?.fields.Value?.value.split('|')[1]
                        )}
                      >
                        <Placeholder
                          name={`SplitSlideRight-${params?.DynamicPlaceholderId}`}
                          rendering={rendering}
                          render={(components) => components[rightIndex]}
                        />
                      </div>
                    </div>
                  ) : (
                    <Placeholder
                      name={`Slideshow-${params?.DynamicPlaceholderId}`}
                      rendering={rendering}
                      render={(components) => components[showIndex]}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`overflow-hidden mb-8`} ref={emblaRef}>
        <div className={classNames(style.slideshow__container, 'flex')}>
          {placeholderPosition()}
        </div>
      </div>

      <div className={ControlWrapperStyle()}>
        <div className="w-10">
          <PrevButton
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
            className={classNames(
              SliderArrowStyle(),
              style.slideshow__button,
              'slideshow__button--prev disabled:opacity-10 disabled:cursor-not-allowed'
            )}
          />
        </div>
        <div className={DotWrapperStyle()}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={classNames(
                style.slideshow__dot,
                SliderDotStyle(),
                index === selectedIndex ? style.slideshow__dot__selected : ''
              )}
            />
          ))}
        </div>
        <div className="w-10">
          <NextButton
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
            className={classNames(
              SliderArrowStyle(),
              style.slideshow__button,
              'slideshow__button--next disabled:opacity-10 disabled:cursor-not-allowed'
            )}
          />
        </div>
      </div>
    </>
  );
};

export default SlideshowSlider;
