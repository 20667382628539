//Global
import { Field, ImageField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState, useEffect } from 'react';
import { tv } from 'tailwind-variants';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { formatTimestamp } from 'lib/utils/date-utils';

//helper
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type TopicsItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Name?: Field<string>;
    Icon?: ImageField;
  };
};

export type AuthorsItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    AuthorName: Field<string>;
    Name?: Field<string>;
    Icon?: ImageField;
  };
};

export type ColorItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Value: Field<string>;
  };
};

export type ExpertItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    ExpertLastName: Field<string>;
    ExpertFirstName: Field<string>;
  };
};

export type CategoryItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Color: ColorItemProps;
  };
};

export type ArticleType = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Value: Field<string>;
  };
};

export type ArticleProps = {
  Headline: Field<string>;
  Hero: ImageField;
  PageTitle: Field<string>;
  Author: Field<string>;
  Authors: AuthorsItemProps[];
  PublishDate: Field<string>;
  ReadTime: Field<string>;
  ResourceBody: Field<string>;
  Teaser: Field<string>;
  SEOPageTitle: Field<string>;
  Category: CategoryItemProps[];

  // SEO Related Property
  ImageCredit: Field<string>;
  Audience: Field<string>;
  Topics: TopicsItemProps[];
  Experts: ExpertItemProps[];
  MetaDescription: Field<string>;
  ArticleType: ArticleType[];
};

export type ArticalProps = ComponentProps & ArticleItemProps;

export type ArticleItemProps = {
  fields: Fields;
};
export interface Fields {
  data: Data;
}
export interface Data {
  item: Item;
}
export interface Item {
  id: string;
  name: string;
  ArticleModifiedDate: ArticleModifiedDate;
}
export interface ArticleModifiedDate {
  jsonValue: Field<string>;
}

type CategoryKey = 'category-1' | 'category-2' | 'category-3' | 'category-4';

export const card = tv({
  slots: {
    categoryStripe: [],
  },
  variants: {
    categoryType: {
      'category-1': {
        categoryStripe: 'bg-background-category-1',
      },
      'category-2': {
        categoryStripe: 'bg-background-category-2',
      },
      'category-3': {
        categoryStripe: 'bg-background-category-3',
      },
      'category-4': {
        categoryStripe: 'bg-background-category-4',
      },
    },
  },
});

const Article = (props: ArticalProps) => {
  const articlefooter = `articlefooter`;
  const articlesharebar = `articlesharebar`;

  const dict = useDictionary();
  const { sitecoreContext } = useSitecoreContext();
  const { categoryStripe } = card();

  const [scroll, setScroll] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 300);
    });
  }, []);

  let scrollToTopText = undefined;
  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    scrollToTopText = 'Scroll To Top';
  } else {
    scrollToTopText = dict?.['ArticleScrollToTop'] || 'Scroll To Top';
  }

  const {
    Hero,
    PageTitle,
    Authors,
    Author,
    PublishDate,
    ReadTime,
    ResourceBody,
    Teaser,
    Category,
    ImageCredit,
    Audience,
    Topics,
    Experts,
    MetaDescription,
    ArticleType,
    Headline,
    SEOPageTitle,
  } = sitecoreContext?.route?.fields as ArticleProps;
  const { ArticleModifiedDate } = props?.fields?.data?.item;

  const categoryValForBgColor = Category?.[0]?.fields?.Color?.fields?.Value?.value || '';
  const categoryType = (categoryValForBgColor as CategoryKey) || undefined;
  const stripClassName = categoryStripe({ categoryType });

  //Custom Fn
  const formatTopicsName = (topics: TopicsItemProps[]): string => {
    return topics
      .map((topic, index) => (index === topics.length - 1 ? topic?.name : topic?.name + ', '))
      .join('');
  };

  const formatArticleType = (articleTypes: ArticleType[]): string => {
    return articleTypes
      .map((type, index) => (index === articleTypes.length - 1 ? type?.name : type?.name + ', '))
      .join('');
  };

  const formatExpertsName = (Experts: ExpertItemProps[]): string => {
    return Experts.map((expert, index) => {
      const firstName = expert?.fields?.ExpertFirstName?.value ?? '';
      const lastName = expert?.fields?.ExpertLastName?.value ?? '';

      const fullName = `${firstName} ${lastName}`.trim();

      return index === Experts.length - 1 ? fullName : fullName + ', ';
    }).join('');
  };

  const onScrollTop = () => {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      // Set tabindex to the <html> element and focus on it
      const htmlElement = document.documentElement;
      htmlElement.setAttribute('tabindex', '0');
      htmlElement.focus();
    }
  };

  function htmlToString(str: string) {
    return str && str?.replace(/<[^>]*>/g, '');
  }

  if (!sitecoreContext?.route || !sitecoreContext?.route?.fields) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>
          {SEOPageTitle?.value?.toString() || htmlToString(Headline?.value?.toString())}
        </title>
        <meta name="description" content={MetaDescription?.value || htmlToString(Teaser?.value)} />
        {PageTitle?.value && (
          <meta
            property="og:title"
            content={SEOPageTitle?.value?.toString() || htmlToString(Headline?.value?.toString())}
          />
        )}
        <meta name="og:description" content={htmlToString(Teaser?.value)} />
        {Hero?.value?.src && <meta property="og:image" content={Hero?.value?.src} />}

        {ImageCredit?.value && <meta name="image" content={ImageCredit?.value} />}
        {PublishDate?.value && (
          <meta
            name="article:published"
            content={PublishDate?.value ? new Date(PublishDate?.value).toUTCString() : ''}
          />
        )}
        {ArticleModifiedDate?.jsonValue.value && (
          <meta
            property="article:modified"
            content={
              ArticleModifiedDate?.jsonValue?.value
                ? new Date(ArticleModifiedDate?.jsonValue?.value).toUTCString()
                : ''
            }
          />
        )}
        {Author?.value && <meta name="author" content={Author?.value} />}
        {ArticleType?.length && (
          <meta name="article_type" content={formatArticleType(ArticleType)} />
        )}
        {Audience?.value && <meta name="audience" content={Audience?.value} />}
        {Topics?.length > 0 && <meta name="topics" content={formatTopicsName(Topics)} />}
        {Experts?.length > 0 && <meta name="bokf_expert" content={formatExpertsName(Experts)} />}
      </Head>

      <section className="container mb-8 lg:mb-10">
        <div className="relative w-full flex flex-col lg:flex-row lg:gap-x-20">
          <div className="w-full max-w-[840px]">
            <div className="mb-4 md:mb-11.5 lg:mb-8 w-full relative lg:max-w-[840px]">
              {Hero?.value?.src && (
                <>
                  <ImageWrapper className="object-cover object-center" field={Hero} />

                  <div
                    className={`absolute h-1 md:h-2 bottom-0 w-full max-w-[840px] ${stripClassName}`}
                  />
                </>
              )}
            </div>
            <div className="relative mb-4 lg:mb-6">
              <RichTextA11yWrapper
                tag="h1"
                className="text-color-default-1 mb-2 lg:mb-4 rtaw"
                field={Headline}
              />
              <div className={`h-1 bottom-0 w-[50px] ${stripClassName}`} />
            </div>
            <RichTextA11yWrapper
              className="headline-4 text-color-default-2 mb-4 lg:mb-6 rtaw"
              field={Teaser}
            />
            <div className="flex flex-col md:flex-row justify-between mb-8 lg:mb-10 items-center">
              <div className="flex flex-row gap-x-6 w-full md:w-5/6 items-center">
                {((Authors && Array.isArray(Authors) && Authors.length > 0) || Author?.value) && (
                  <div className="flex flex-row flex-wrap gap-x-1">
                    <span className="paragraph-2-medium text-color-default-1">By</span>
                    <>
                      {Authors &&
                        Array.isArray(Authors) &&
                        Authors.map((author, index) => (
                          <LinkWrapper
                            key={index}
                            className="paragraph-2-medium"
                            field={{
                              value: {
                                href: author.url,
                                text:
                                  index === Authors.length - 1
                                    ? author?.fields?.AuthorName?.value
                                    : author?.fields?.AuthorName?.value + ', ',
                              },
                            }}
                          />
                        ))}
                      {(!Authors || (Array.isArray(Authors) && Authors.length === 0)) && (
                        <Text tag="span" className="paragraph-2-medium" field={Author} />
                      )}
                    </>
                  </div>
                )}

                <span className="hidden md:block caption text-color-default-1 items-center">
                  {PublishDate?.value && formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                </span>
              </div>
              <div className="flex flex-row mt-6 md:mt-0 justify-between md:justify-end w-full md:w-1/6">
                <span className="block md:hidden caption text-color-default-1">
                  {PublishDate?.value && formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                </span>
                <Text tag="span" className="text-color-default-1 caption" field={ReadTime} />
              </div>
            </div>
            <RichTextA11yWrapper
              className="paragraph-2-regular text-color-default-1 rtaw"
              field={ResourceBody}
            />
            {props.rendering && <Placeholder name={articlefooter} rendering={props?.rendering} />}
          </div>
          <div className={`relative ${scroll ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            <div className="lg:block hidden pointer-events-none h-[438px] xl:h-[504px] max-h-[504px]"></div>
            <div className="right-section lg:pb-3 right-0 lg:top-[144px] lg:sticky">
              <div className="lg:flex hidden flex-col lg:max-w-[190px] lg:w-[190px]">
                <button
                  onClick={onScrollTop}
                  type="button"
                  className="cursor-pointer group flex mb-4 gap-x-1.5 focus:p-2 focus:border focus:border-color-active focus:rounded"
                >
                  <div className="inline-flex justify-center items-center h-6 w-6 rounded-xl bg-btn-scroll-gradient group-hover:bg-btn-gradient-hover group-focus:bg-btn-scroll-gradient">
                    <SVG className="text-white [&_svg]:p-1" svg="icon-carat-up" />
                  </div>
                  <span className="paragraph-3-medium group-hover:paragraph-3-medium-underline text-color-active group-hover:text-color-link-hover">
                    {scrollToTopText}
                  </span>
                </button>

                <div className="flex mb-2">
                  <RichTextA11yWrapper
                    className="paragraph-3-regular text-wrap text-color-default-1 rtaw"
                    field={Headline}
                  />
                </div>
                <div className="flex">
                  <span className="caption text-color-default-1">
                    {PublishDate?.value && formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
                  </span>
                </div>
                <hr />
              </div>
              {props.rendering && (
                <Placeholder name={articlesharebar} rendering={props?.rendering} />
              )}
            </div>
          </div>
        </div>
        <hr className="my-0" />
      </section>
    </>
  );
};

export default Article;
