//Global
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect } from 'react';

export type CalculatorProps = {
  Url: Field<string>;
};

const preloadUrls = [
  {
    url: 'https://code.jquery.com/jquery-3.7.1.min.js',
    integrity: 'sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo=',
  },
  {
    url: 'https://www.calcxml.com/scripts/tipped/comboTipped.js',
    integrity: '',
  },
];

const Calculator = () => {
  const { sitecoreContext } = useSitecoreContext();

  const { Url } = sitecoreContext?.route?.fields as CalculatorProps;

  useEffect(() => {
    const loadScript = (url: string, integrity?: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        // Check if the script already exists in the document
        let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement;

        if (script) {
          resolve(); // If the script is already loaded, resolve immediately
          return;
        }

        // Create a new script element
        script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = url;
        script.async = false;
        if (integrity) {
          script.integrity = integrity;
          script.crossOrigin = 'anonymous';
        }

        // Resolve or reject the promise based on the script load status
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`));

        // Append the script to the document body
        document.body.appendChild(script);
      });
    };

    const loadAllScripts = async () => {
      try {
        // Sequentially load all scripts
        for (const item of preloadUrls) {
          await loadScript(item?.url, item?.integrity);
        }

        const loadCalcUrl =
          'https://www.calcxml.com/scripts/loadCalc.js?calcTarget=sav01&embed=2&skn=62';
        await new Promise((resolve) => setTimeout(resolve, 10)).then(() => {
          loadScript(loadCalcUrl);
        });

        // Load the final script with a slight delay to ensure all other scripts are fully loaded
        const finalScriptUrl = Url?.value;
        await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          loadScript(finalScriptUrl);
        });
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadAllScripts();
  }, []);

  return (
    <>
      <section className="container-wide spacing-md">
        <div className="container-inner">
          <div id="calc"></div>
        </div>
      </section>
    </>
  );
};

export default Calculator;
