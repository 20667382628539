import { useState } from 'react';
import { Field, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import ModalWrapper from 'helpers/ModalWrapper/ModalWrapper';
import SVG from 'helpers/SVG/SVG';
import Button from 'helpers/Button/Button';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type LocationPlaceholderProps = {
  onModalClose: () => void;
  isModalOpen: boolean;
  locationName: string;
};

export type LocationModalProps = {
  fields: LocationModalField;
} & LocationPlaceholderProps;

export type LocationModalField = {
  CopyText: Field<string>;
  PrimaryButtonText: Field<string>;
  SecondaryButtonText: Field<string>;
  SubTitle: Field<string>;
  Title: Field<string>;
  children: children[];
};

export type children = {
  fields: ChildrenFields;
  name: string;
};

export type ChildrenFields = {
  URL: LinkField;
  State: State;
};

export type State = {
  fields: Statefields;
};

export type Statefields = {
  StateAbbreviation: Field<string>;
  StateName: Field<string>;
};

const LocationModal = (props: LocationModalProps) => {
  const { CopyText, PrimaryButtonText, SecondaryButtonText, SubTitle, Title, children } =
    props?.fields || {};

  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);
  const [selectedLocationName, setSelectedLocationName] = useState<string>(props?.locationName);

  return props?.isModalOpen ? (
    <ModalWrapper
      onClose={() => {
        setSelectedLocationName(props?.locationName);
        setRedirectUrl(undefined);
        props?.onModalClose();
      }}
      position="center"
    >
      <div className="flex flex-col gap-8 mt-2">
        <div className="choose-location flex flex-col gap-2">
          <Text className="text-color-default-1" tag="h3" field={Title} />
          <Text className="paragraph-2-regular text-color-default-1" tag="span" field={SubTitle} />
        </div>
        <div className="state-list">
          <ul className="flex flex-wrap gap-4">
            {children.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    setSelectedLocationName(
                      item?.fields?.State?.fields?.StateName?.value || item?.name
                    );
                    setRedirectUrl(item?.fields?.URL?.value?.href);
                  }}
                  type="button"
                  className={classNames(
                    'block pl-2 py-2 pr-3 border rounded border-strokes-action text-color-default-1 paragraph-3-regular',
                    'hover:paragraph-3-medium-underline hover:text-color-default-1 hover:border-strokes-link-hover',
                    'focus:paragraph-3-medium focus:outline focus:outline-strokes-secondary-focus focus:no-underline',
                    `${
                      (item?.fields?.State?.fields?.StateName?.value || item?.name).includes(
                        selectedLocationName
                      ) &&
                      'flex paragraph-3-medium items-center justify-center gap-1 border-strokes-action bg-background-action hover:bg-background-link-hover focus:bg-background-secondary-focus focus:!outline-none text-color-inverse hover:text-color-inverse'
                    }`
                  )}
                >
                  {(item?.fields?.State?.fields?.StateName?.value || item?.name).includes(
                    selectedLocationName
                  ) && <SVG className="h-6 w-6 [&_svg]:h-6 [&_svg]:w-6" svg="icon-pin" />}
                  {item?.fields?.State?.fields?.StateName?.value || item?.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="ip-info">
          <RichTextA11yWrapper
            className="text-color-default-2 paragraph-3-regular rtaw"
            field={CopyText}
          />
        </div>
        <div className="buttons border-t">
          <div className="flex flex-col md:flex-row gap-4 md:gap-6 mt-4 md:mt-6 justify-end">
            <Button
              type="secondary"
              tag="button"
              fieldClass="order-last md:order-first"
              label={PrimaryButtonText?.value}
              onClick={() => {
                setRedirectUrl(undefined);
                setSelectedLocationName(props?.locationName);
                props?.onModalClose();
              }}
              size={'large'}
            />
            <Button
              type="default"
              tag="button"
              className=""
              label={SecondaryButtonText?.value}
              onClick={() => {
                redirectUrl && (window.location.href = redirectUrl);
              }}
              size={'large'}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  ) : (
    <></>
  );
};

export default LocationModal;
