// Global
import React, { useCallback, useState } from 'react';
import { RichTextField, Text, TextField, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Profiles } from 'lib/templates/Feature.BOKF.model';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import useExperienceEditor from 'lib/use-experience-editor';

// Local
import { DotButton, useDotButton } from './CarouselProfileDotButton';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import ModalWrapper from 'helpers/ModalWrapper/ModalWrapper';
import { NextButton, PrevButton, usePrevNextButtons } from './CarouselProfileArrowButton';
import Autoplay from 'embla-carousel-autoplay';

export type ProfileProps = Profiles.DataTypes.Profile;
export type ProfileContainerProps = Profiles.DataTypes.ProfileContainer & {
  fields: { Profiles: Array<ProfileProps> };
};

const Default = (props: ProfileContainerProps): JSX.Element => {
  const { fields } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileIndex, setProfileIndex] = useState<number>(-1);

  const options: EmblaOptionsType = { loop: true };
  const Profiles = props?.fields?.Profiles || [];

  const isEE = useExperienceEditor();

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({
      playOnInit: !isEE, // Play autoplay only if not in EE
      stopOnMouseEnter: !isEE, // Stop autoplay on mouse enter only if not in EE
      stopOnInteraction: false, // Do not stop autoplay on user interaction
      stopOnFocusIn: !isEE, // Stop autoplay on focus in only if not in EE
      delay: 5000,
    }),
  ]);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  const onModalOpen = (index: number) => {
    setShowModal(!showModal);
    setProfileIndex(index);
  };

  const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, onNavButtonClick);

  if (!fields || Profiles.length <= 0) {
    return <></>;
  }

  return (
    <>
      <section className="container-wide spacing-md p-8 lg:p-12 bg-background-default-2 shadow-large">
        <div className="container-inner flex flex-col lg:flex-row gap-y-5 lg:gap-x-6 lg:gap-y-0 items-center">
          <div className="flex flex-col gap-y-6 md:gap-y-8 lg:pr-5 w-full lg:w-1/2 text-center lg:text-left">
            <RichTextA11yWrapper
              className="text-color-default-1 rtaw"
              tag="h3"
              field={fields.Title}
            />
            <RichTextA11yWrapper
              className="text-color-default-1 paragraph-2-regular rtaw"
              field={fields.Text}
            />
            <div className="hidden lg:block">
              <Button field={fields?.CallToAction} />
            </div>
          </div>
          <div className="profiles-carousel-marquee w-full lg:w-1/2 flex flex-row gap-x-6 md:gap-x-12 lg:gap-x-10">
            {Profiles.length > 1 && (
              <>
                <div className="embla__buttons inset-y-0 left-0 flex items-center z-10">
                  <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                </div>
              </>
            )}
            <div className="profiles-carousel-marquee-viewport m-auto lg:py-8" ref={emblaRef}>
              <div className="flex justify-between text-center">
                {Profiles.map((item, key) => {
                  return (
                    <div className="profiles-carousel-marquee-slide flex flex-col" key={key}>
                      <div className="relative mb-4 m-auto w-full h-full max-w-[175px] max-h-[175px]">
                        {item?.fields?.Image && (
                          <ImageWrapper field={item?.fields?.Image as ImageField} />
                        )}
                      </div>
                      <Text
                        className="text-color-default-1 mb-2"
                        field={item?.fields?.Title as TextField}
                        tag="h4"
                      />
                      <Text
                        field={item?.fields?.SubTitle as TextField}
                        tag="span"
                        className="paragraph-1-regular mb-4 md:mb-6 text-color-default-2 relative after:absolute after:w-8 after:h-[3px] after:bg-background-stroke-brand after:bottom-[-5px] after:translate-x-[-50%] after:left-1/2 after:bg-bokf-red"
                      />
                      <div className="text-center mx-auto">
                        <RichTextA11yWrapper
                          field={item?.fields?.DescriptionPreview as RichTextField}
                          className="text-color-default-2 caption-medium rtaw"
                        />
                        <Text
                          onClick={() => {
                            onModalOpen(key);
                          }}
                          tag="span"
                          className="text-color-active h5 cursor-pointer hover:text-color-link-hover hover:underline focus:underline"
                          field={item?.fields?.ModalLinkText as TextField}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {Profiles.length > 1 && (
                <div className="embla__controls mt-8 md:mt-6">
                  <div className="carousel-marquee-dots_profile">
                    {scrollSnaps.map((_, index) => (
                      <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={'carousel-marquee-dot'.concat(
                          index === selectedIndex ? ' carousel-marquee-dot--selected' : ''
                        )}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="flex w-full mx-auto lg:hidden mt-10 md:mt-8 mb-4">
                <div className="inline-block justify-between mx-auto ">
                  <Button className="inline-block" field={fields.CallToAction} />
                </div>
              </div>
            </div>
            {Profiles.length > 1 && (
              <>
                <div className="embla__buttons inset-y-0 right-0 flex items-center z-10">
                  <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {showModal && (
        <ModalWrapper onClose={() => setShowModal(!showModal)} position="center">
          <div className="content flex flex-col gap-y-8 md:gap-y-10">
            <div className="flex flex-col gap-y-2">
              <Text
                field={
                  fields.Profiles && (fields.Profiles[profileIndex]?.fields?.Title as TextField)
                }
                tag="h4"
              />
              <Text
                field={
                  fields.Profiles && (fields.Profiles[profileIndex]?.fields?.SubTitle as TextField)
                }
                tag="span"
                className="text-6 leading-7 text-color-default-2 mb-6 relative after:absolute after:w-10 after:h-[3px] after:bg-bokf-red after:bottom-[-5px] after:translate-x-[-10%] after:left-1"
              />
            </div>
            <RichTextA11yWrapper
              className="rtaw"
              field={
                fields.Profiles &&
                (fields?.Profiles[profileIndex]?.fields?.Description as RichTextField)
              }
            />
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default Default;
