import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

export const hostToReplace = (linkField?: LinkField) => {
  const nextPublicHostToReplacePipe = process.env.NEXT_PUBLIC_HOST_TO_REPLACE?.split('|') || [];
  const checkValidURLRegex = /^(http|https|ftp):\/\//;

  let baseUrl = '';
  const linkQueryString = linkField?.value?.querystring ? '?' + linkField?.value?.querystring : '';
  try {
    baseUrl =
      (linkField?.value?.href &&
        checkValidURLRegex.test(linkField?.value?.href) &&
        linkField?.value?.href) ||
      '';
  } catch (error) {
    console.error('error==>', error, linkField);
  }
  baseUrl &&
    nextPublicHostToReplacePipe &&
    Array.isArray(nextPublicHostToReplacePipe) &&
    nextPublicHostToReplacePipe.length > 0 &&
    nextPublicHostToReplacePipe.forEach((x) => (baseUrl = baseUrl?.replace(x, '')));

  const href = (baseUrl || linkField?.value?.href) + linkQueryString;
  const hostToReplaceLinkField = {
    ...linkField,
    value: {
      ...linkField?.value,
      href,
    },
  };

  return hostToReplaceLinkField;
};
