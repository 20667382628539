export const formatTimestamp = (timestamp: string, format = 'MM DD, YYYY') => {
  let date: Date;

  // Check if the timestamp is a number (Unix timestamp in milliseconds)
  if (!isNaN(Number(timestamp))) {
    date = new Date(Number(timestamp));
  } else {
    // Otherwise, assume it's an ISO 8601 string
    date = new Date(timestamp);
  }

  // Check for invalid date
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }

  // Extract the month, day, and year
  const monthIndex = date.getMonth(); // Months are zero-based
  const day = date.getDate();
  const year = date.getFullYear();

  // Array of month names
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get the full month name
  const monthName = monthNames[monthIndex];
  const monthNumber = (monthIndex + 1).toString().padStart(2, '0'); // Month in MM format
  const formattedDay = day.toString(); // Day in DD format

  // Replace placeholders in the format string
  return format
    .replace('MM', monthNumber)
    .replace('Month', monthName)
    .replace('DD', formattedDay)
    .replace('YYYY', year.toString());
};
