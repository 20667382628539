// Global
import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';

// Lib
import { ComponentProps } from 'lib/component-props';

import { SplitColumnLayoutTypes } from './SplitColumnLayoutTypes.helper';
type SplitColumnLayoutProps = ComponentProps & SplitColumnLayoutTypes;

const SplitColumnLayout = (props: SplitColumnLayoutProps): JSX.Element => {
  const phKey1 = `SplitColumnLeft-${props.params?.DynamicPlaceholderId}`;
  const phKey2 = `SplitColumnRight-${props.params?.DynamicPlaceholderId}`;

  const sectionStyles = props.params?.Background?.Value?.value || '';
  const alignmentStyles = props.params?.Alignment?.Value?.value || '';
  const AnchorId = props.params?.AnchorId || '';

  let leftColumnClass = '';
  let rightColumnClass = '';

  // Check for ShowSingleColumn
  if (props.params.ShowSingleColumn === '1') {
    leftColumnClass = 'w-full space-y-4 md:space-y-6';
    rightColumnClass = 'w-full space-y-4 md:space-y-6';
  } else {
    switch (props.params?.ColumnStructure?.Value?.value) {
      case 'lg:w-1/2|lg:w-1/2':
        leftColumnClass = 'lg:w-1/2 w-full md:w-1/2 space-y-4 md:space-y-6';
        rightColumnClass = 'lg:w-1/2 w-full md:w-1/2 space-y-4 md:space-y-6';
        break;
      case 'lg:w-2/3|lg:w-1/3':
        leftColumnClass = 'lg:w-2/3 w-full md:w-1/2 space-y-4 md:space-y-6';
        rightColumnClass = 'lg:w-1/3 w-full md:w-1/2 space-y-4 md:space-y-6';
        break;
      case 'lg:w-1/3|lg:w-2/3':
        leftColumnClass = 'lg:w-1/3 w-full md:w-1/2 space-y-4 md:space-y-6';
        rightColumnClass = 'lg:w-2/3 w-full md:w-1/2 space-y-4 md:space-y-6';
        break;
      default:
        break;
    }
  }

  const flexClass = classNames('flex flex-col mb-0 gap-8 md:gap-6', {
    'md:flex-row': props.params.ShowSingleColumn !== '1',
  });

  let tagStyles = '';
  switch (props.params?.HeadingType?.Styles?.value) {
    case 'title-border title-border--thick title-border--neutral':
      tagStyles =
        'after:content-[""] after:block after:border-b-[5px] after:border-strokes-brand after:w-12 after:mb-3 after:pt-1 md:after:pt-2 lg:after:pt-4 lg:after:mb-2';
      break;
    case 'title-border':
      tagStyles =
        'after:content-[""] after:block after:border-b-[4px] after:border-strokes-brand after:w-10 after:mb-3 after:pt-2 lg:after:pt-3 lg:after:mb-2';
      break;
    default:
      tagStyles = '';
      break;
  }

  return (
    <section
      className={`${sectionStyles} ${alignmentStyles} spacing-md ${
        alignmentStyles === 'text-center'
          ? '[&_.flex-center]:justify-center [&_.flex-center]:items-center [&_.small-red-line]:mx-auto [&_hr]:!mx-auto'
          : ''
      }`}
      id={AnchorId || undefined}
    >
      <div className="container-inner space-y-6 md:space-y-8">
        <Text
          className={`${tagStyles} ${
            alignmentStyles === 'text-center'
              ? 'after:relative after:left-1/2 after:-translate-x-1/2'
              : ''
          }`}
          field={{ value: props.params?.Title }}
          tag={props.params?.HeadingType?.Tag?.value || 'h2'}
        />

        <div className={flexClass}>
          <div className={leftColumnClass}>
            {props?.rendering && <Placeholder name={phKey1} rendering={props?.rendering} />}
          </div>

          <div className={rightColumnClass}>
            {props?.rendering && <Placeholder name={phKey2} rendering={props?.rendering} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SplitColumnLayout;
