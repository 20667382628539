import React from 'react';
import { Resources } from 'lib/templates/Feature.BOKF.model';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import { SearchWithFilterWidget } from 'widgets/SearchWithFilterWidget/SearchWithFilterWidget';

const SearchWithFilter = (
  props: Required<Resources.Configuration.SearchFilterConfiguration>
): JSX.Element => {
  if (!props.fields) return <></>;

  return (
    <WidgetWrapper>
      <SearchWithFilterWidget rfkId="resource_search" {...props} />
    </WidgetWrapper>
  );
};

export default SearchWithFilter;
