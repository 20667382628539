// Global
import React from 'react';

// Lib
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type FooterDisclaimerProps = BoKNavigation.DataTemplates.Disclaimer;

export const FooterDisclaimer = (props: FooterDisclaimerProps): JSX.Element => {
  const { CopyText } = props?.fields || {};
  return (
    <section className="component py-6 container">
      <RichTextA11yWrapper field={CopyText} className="rtaw caption text-color-default-2" />
    </section>
  );
};

export default FooterDisclaimer;
