import React from 'react';
import { ComponentParams } from '@sitecore-jss/sitecore-jss-nextjs';

import { ExpertsListWidget } from 'widgets/ExpertsListWidget/ExpertsListWidget';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';

export interface ExpertsSearchProps {
  params: ComponentParams & {
    InitialCountDesktop?: string;
    InitialCountMobile?: string;
    LoadMoreCount?: string;
    LoadMoreText?: string;
    LabelText?: string;
    ButtonText?: string;
    PlaceholderText: string;
  };
}

const ExpertsSearch = (props: ExpertsSearchProps): JSX.Element => {
  const {
    params: {
      InitialCountDesktop = '9',
      InitialCountMobile = '4',
      LoadMoreText = 'View More Experts',
      LoadMoreCount = '4',
      LabelText = 'Filter by:',
      PlaceholderText = 'Keyword (e.g. Savings)',
    },
  } = props;

  const dictionary = useDictionary();
  const { currentScreenWidth, isScreenTypeDetected } = useCurrentScreenType();

  if (!isScreenTypeDetected) return <></>;

  return (
    <WidgetWrapper>
      <ExpertsListWidget
        rfkId="experts"
        initialCount={
          currentScreenWidth <= getBreakpoint('desktop') ? InitialCountMobile : InitialCountDesktop
        }
        filterTypeLabel={LabelText}
        placeholderText={PlaceholderText}
        verticlePaginationOptions={{ loadMoreText: LoadMoreText, loadCount: LoadMoreCount }}
        noResultsText={dictionary?.['Experts.No.Results'] ?? 'No results found'}
      />
    </WidgetWrapper>
  );
};

export default ExpertsSearch;
