/**
 * @param {import('next').NextConfig} nextConfig
 */
const atmLocationsPlugin = (nextConfig = {}) => {
    return Object.assign({}, nextConfig, {
      async rewrites() {
        return [
          ...await nextConfig.rewrites(),
          // location route
          {
            source: '/api/bokf/location/find',
            destination: '/api/atmlocations'
          },
        ];
      },
    });
  };
  
  module.exports = atmLocationsPlugin;
  