import React, { useEffect, useRef, useState } from 'react';
import {
  widget,
  WidgetDataType,
  useSearchResultsActions,
  EntityModel,
  SearchResponseFacet,
  useSearchResultsSelectedFilters,
  FilterNot,
  FilterEqual,
  FilterAnd,
} from '@sitecore-search/react';
import classNames from 'classnames';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { AccordionFacets, FacetItem, SearchResultsAccordionFacets } from '@sitecore-search/ui';
import { tv } from 'tailwind-variants';

import { Resources } from 'lib/templates/Feature.BOKF.model';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Button from 'helpers/Button/Button';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { formatTimestamp } from 'lib/utils/date-utils';
import { getThumnailUrl } from 'lib/utils/vimeo-thumbnail';
import {
  getAllFacets,
  searchSourceIds,
  getProcessedFacets,
  ProcessedFacetItem,
  getIconDetailsFromFacetId,
} from './SearchWithFilter.utils';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';
import { FacetValue } from 'lib/utils/use-ensure-facet-url';

export type CardProps = {
  isCategoryHidden?: boolean;
  ribbonLabel?: Field<string>;
  article_time?: number;
  category?: Array<string>;
  category_color?: Array<'category-1' | 'category-2' | 'category-3' | 'category-4'>;
  city?: Array<string>;
  description?: string;
  id?: string;
  image_url?: string;
  media_url?: string;
  name?: string;
  publish_date?: string;
  url?: string;
  type?: 'Article' | 'VideoResource';
};

const SearchWithFilter = ({
  fields,
}: Required<Resources.Configuration.SearchFilterConfiguration>) => {
  const { currentScreenWidth, isScreenTypeDetected } = useCurrentScreenType();

  const {
    InitialCountDesktop = { value: 9 },
    InitialCountMobile = { value: 6 },
    LoadMoreCount = { value: 4 },
    LoadMoreText = { value: 'View More' },
    ShowFilter = { value: 'Show Filter' },
    HideFilter = { value: 'Hide Filter' },
    PanelFilter = { value: 'Filter' },
    CategoryLabel = { value: 'Category' },
    TopicsLabel = { value: 'Topic' },
    TypesLabel = { value: 'Type' },
    TopicsSearchBoxPlaceholder = { value: 'Search topics' },
    TopicsNotFoundMessage = { value: 'No topic found. Please try again.' },
    TopicsToExclude = [],
    TypesToExclude = [],
    ClearAllPanel = { value: 'Clear All' },
    ClearAllChips = { value: 'Clear All' },
    NoResultsText = { value: 'No Results' },
    ViewResults = { value: 'View Results' },
    TopicsSearchBoxAriaLabel = { value: 'Topic Searchbox' },
    TopicsSearchBoxButtonAriaLabel = { value: 'Topic Search Button' },
    TopicsSearchBoxClearAriaLabel = { value: 'Clear Topic Searchbox' },
    TopicsSkipFilter = { value: 'Skip Topic Filter' },
  } = fields;

  const [allFacets, setAllFacets] = useState<Array<SearchResponseFacet>>([]);

  useEffect(() => {
    (async () => {
      // Get all facets on load
      const _allFacets = await getAllFacets(TopicsToExclude, TypesToExclude);
      setAllFacets(_allFacets);
    })();
  }, []);

  // States
  const [expertsData, setExpertsData] = useState<{
    expertList: Array<EntityModel>;
    fetchCount: number;
  }>({ expertList: [], fetchCount: 1 });

  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);

  const selectedFacetsCountRef = useRef<number>(0);

  const {
    sanitizedResults,
    widgetRef,
    query,
    state: { selectedFacets },
    queryResult: {
      isInitialLoading,
      isFetched,
      data: { total_item = 0, limit = 0, offset = 0, facet: enabledFacets = [] } = {},
    },
  } = useSanitizedSearchResults({
    query: (query) =>
      query
        .getRequest()
        .setSearchFacetAll(false)
        .setSearchFacetTypes([{ name: 'category' }, { name: 'topics' }, { name: 'type' }])
        .setSearchFilter(
          new FilterAnd([
            ...TopicsToExclude.map(
              (topic) =>
                new FilterNot(new FilterEqual('topics', (topic.fields.Name as Field<string>).value))
            ),
            ...TypesToExclude.map(
              (type) =>
                new FilterNot(
                  new FilterEqual(
                    'type',
                    (type.fields.Name as Field<string>).value?.toLowerCase() == 'video'
                      ? 'VideoResource'
                      : (type.fields.Name as Field<string>).value?.toLowerCase() == 'calculator'
                      ? 'CalculatorResource'
                      : (type.fields.Name as Field<string>).value
                  )
                )
            ),
          ])
        )
        .setSources(searchSourceIds),
    config: {
      defaultFacetType: 'text',
    },
    state: {
      itemsPerPage:
        currentScreenWidth <= getBreakpoint('desktop')
          ? InitialCountMobile.value
          : InitialCountDesktop.value,
      page: 1,
      selectedFacets: (() => {
        const hasWindow = typeof window !== 'undefined';
        const preselectedFacets: Array<FacetValue> = [];
        if (hasWindow) {
          const currentURL = new URL(window.location.href);
          const searchParams = new URLSearchParams(currentURL.search);

          for (const [key, value] of searchParams.entries()) {
            // Ignore if facet is not equal to below types
            if (key !== 'category' && key !== 'topics' && key !== 'type') {
              continue;
            }

            value.split(',').forEach((facetText) => {
              preselectedFacets.push({
                facetId: key,
                facetValueText: facetText,
              });
            });
          }
        }
        return preselectedFacets;
      })(),
    },
  });

  // Handle the scroll enable/disable functionality on filter panel visibility
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      document &&
      currentScreenWidth <= getBreakpoint('desktop')
    ) {
      if (isFilterPanelVisible) {
        // Calculate scrollbar width if present to handle the layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.paddingRight = ''; // Stop scrolling
        document.body.style.overflow = 'auto'; // Allow scrolling
      }
    }
    // Cleanup function
    return () => {
      document.body.style.paddingRight = ''; // Stop scrolling
      document.body.style.overflow = 'auto'; // Ensure overflow is reset
    };
  }, [isFilterPanelVisible, currentScreenWidth]);

  useEffect(() => {
    if (!isInitialLoading && sanitizedResults?.content) {
      // Handle facet selection and search input change
      if (
        offset === 0 ||
        (selectedFacets && selectedFacets.length !== selectedFacetsCountRef.current)
      ) {
        selectedFacetsCountRef.current = selectedFacets?.length || 0;
        setExpertsData({
          fetchCount: 1,
          expertList: sanitizedResults.content,
        });
      }
      //Handle load more
      else
        setExpertsData({
          fetchCount: expertsData.fetchCount + 1,
          expertList: [...expertsData.expertList, ...sanitizedResults.content],
        });
    }
    // We only need to care about experts and offset as a dependency
    // because load more pagination requires new results to be loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanitizedResults?.content]);

  const loadMoreResults = () => {
    const _loadCount = Number(LoadMoreCount.value);

    if (expertsData.fetchCount >= 1) {
      query.getRequest().setSearchLimit(_loadCount).setSearchOffset(expertsData.expertList.length);
    }
  };

  if (!isScreenTypeDetected) return <></>;

  return (
    <section className="component container spacing-md" ref={widgetRef}>
      <div className="flex flex-col gap-4 md:gap-6">
        <div
          tabIndex={0}
          className={classNames(
            'max-w-fit p-2 flex items-center gap-2 text-color-active paragraph-2-medium cursor-pointer',
            'border border-transparent rounded-[4px]',
            'hover:underline hover:bg-button-secondary-enabled focus:outline-none focus:border-button-secondary-active',
            isFilterPanelVisible && 'bg-button-secondary-enabled'
          )}
          onClick={() => setIsFilterPanelVisible(!isFilterPanelVisible)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setIsFilterPanelVisible(!isFilterPanelVisible);
            }
          }}
        >
          <SVG className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" svg="icon-sort-filter" />
          <p>{isFilterPanelVisible ? HideFilter.value : ShowFilter.value}</p>
        </div>
        {isFilterPanelVisible && (
          // Transparent Overlay
          <div
            className="md:hidden fixed w-screen h-lvh top-0 left-0 z-40"
            onClick={() => setIsFilterPanelVisible(false)}
          />
        )}
        <div
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.preventDefault();
              setIsFilterPanelVisible(false);
            }
          }}
          className={classNames(
            'fixed md:static flex md:hidden right-0 top-0 ease-in-out duration-100 items-start flex-col md:flex-row gap-2 bg-background-default-1 z-50 md:z-0 shadow-large md:shadow-none',
            isFilterPanelVisible ? 'translate-x-0 md:!flex' : 'translate-x-full md!:translate-x-0'
          )}
        >
          <FilterPanel
            allFacets={allFacets}
            enabledFacets={enabledFacets}
            labels={{
              panelTitle: PanelFilter.value,
              searchBoxPlaceholder: TopicsSearchBoxPlaceholder.value,
              noTopicsFoundMessage: TopicsNotFoundMessage.value,
              category: CategoryLabel.value,
              topics: TopicsLabel.value,
              type: TypesLabel.value,
              clearAll: ClearAllPanel.value,
              searchboxAria: TopicsSearchBoxAriaLabel.value,
              searchboxButtonAria: TopicsSearchBoxButtonAriaLabel.value,
              searchboxClearAria: TopicsSearchBoxClearAriaLabel.value,
              topicsSkipFilter: TopicsSkipFilter.value,
              viewResults: ViewResults.value,
            }}
            setIsFilterPanelVisible={setIsFilterPanelVisible}
          />
        </div>

        <FilterChips clearAllLabel={ClearAllChips.value} />

        {total_item > 0 && expertsData.expertList.length > 0 && (
          <ul className="flex flex-wrap flex-col md:flex-row gap-6 md:gap-4 mt-2 md:mt-4">
            {expertsData.expertList.map((expertDetails, i) => (
              <li
                className="basis-full sm:basis-[calc(100%_/_2_-_16px_/_2_*_(2_-_1))] lg:basis-[calc(100%_/_3_-_16px_/_3_*_(3_-_1))]"
                key={i}
              >
                <Card {...expertDetails} />
              </li>
            ))}
          </ul>
        )}

        {/* No Results */}
        {isFetched && total_item === 0 && (
          <p className="paragraph-2-medium text-color-default-2">{NoResultsText.value}</p>
        )}

        <div className="flex items-center justify-center mt-10 sm:mt-8">
          <Button
            disabled={limit + offset === total_item}
            label={LoadMoreText.value}
            type="auxiliary"
            fieldClass="!rounded-[4px] !w-auto focus:border-button-secondary-focus"
            onClick={loadMoreResults}
            onKeyDown={(e) => e.key === 'Enter' && loadMoreResults()}
          />
        </div>
      </div>
    </section>
  );
};

//#region Filter Chips
const FilterChips = ({ clearAllLabel }: { clearAllLabel: string }) => {
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const { onRemoveFilter, onClearFilters } = useSearchResultsActions();

  if (selectedFacetsFromApi.length === 0) return <></>;

  return (
    <ul className="flex flex-wrap gap-3 md:gap-4 items-center justify-start">
      {selectedFacetsFromApi?.map((_selectedFacet, index) => {
        const selectedFacet = _selectedFacet as typeof _selectedFacet & { facetValueId: string };

        return (
          <li
            tabIndex={0}
            className={classNames(
              'inline-flex items-center justify-center gap-2 p-2 rounded-[4px] cursor-pointer paragraph-3-regular text-color-default-1',
              'border border-button-secondary-active hover:border-button-secondary-hover hover:underline',
              'focus:outline-none focus:border-button-secondary-focus focus:paragraph-3-medium'
            )}
            key={index}
            onClick={() => onRemoveFilter(selectedFacet)}
            onKeyDown={(e) => e.key === 'Enter' && onRemoveFilter(selectedFacet)}
          >
            {getIconDetailsFromFacetId[selectedFacet.facetValueId] && (
              <div className="relative w-4 h-4 md:w-6 md:h-6">
                <SVG
                  svg={`icon-${getIconDetailsFromFacetId[selectedFacet.facetValueId].name}`}
                  className={classNames(
                    getIconDetailsFromFacetId[selectedFacet.facetValueId].color ||
                      'text-icon-action',
                    'group-data-[state=selected]:text-white [&_svg]:p-0.5 [&_svg]:absolute [&_svg]:top-0'
                  )}
                />
              </div>
            )}
            {selectedFacet.valueLabel?.toLowerCase() == 'videoresource'
              ? 'Video'
              : selectedFacet.valueLabel?.toLowerCase() == 'calculatorresource'
              ? 'Calculator'
              : selectedFacet.valueLabel}
            <SVG className="w-3 h-3 stroke-2" svg="icon-close-thick" />
          </li>
        );
      })}
      {selectedFacetsFromApi.length > 0 && (
        <li
          tabIndex={0}
          className="cursor-pointer paragraph-3-medium text-color-active"
          onClick={() => onClearFilters()}
          onKeyDown={(e) => e.key === 'Enter' && onClearFilters()}
        >
          {clearAllLabel}
        </li>
      )}
    </ul>
  );
};
//#endregion

//#region Filter Panel
type LabelTypes =
  | 'panelTitle'
  | 'topics'
  | 'type'
  | 'category'
  | 'searchBoxPlaceholder'
  | 'noTopicsFoundMessage'
  | 'clearAll'
  | 'viewResults'
  | 'searchboxAria'
  | 'searchboxButtonAria'
  | 'searchboxClearAria'
  | 'topicsSkipFilter';

type Labels = Record<LabelTypes, string>;

const FilterPanel = ({
  allFacets,
  enabledFacets,
  setIsFilterPanelVisible,
  labels,
}: {
  allFacets: Array<SearchResponseFacet>;
  enabledFacets: Array<SearchResponseFacet>;
  setIsFilterPanelVisible: React.Dispatch<boolean>;
  labels: Labels;
}) => {
  const { onFacetClick, onClearFilters } = useSearchResultsActions();
  const processedFacets = getProcessedFacets(allFacets, enabledFacets);
  const { currentScreenWidth } = useCurrentScreenType();

  const [searchBoxValue, setSearchBoxValue] = useState('');
  const topicsListRef = useRef<HTMLDivElement>(null);

  const handleTopicsMaskVisibility = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    if (topicsListRef.current && currentScreenWidth > getBreakpoint('desktop')) {
      const topicsListElement = e.currentTarget;

      if (topicsListElement.children.length > 0) {
        const topicsListElementYAxis = topicsListElement.getBoundingClientRect();
        const firstTopicElementYAxis =
          topicsListElement.firstElementChild?.getBoundingClientRect().y;
        const lastTopicElementYAxis = topicsListElement.lastElementChild?.getBoundingClientRect().y;

        const beforeElementVisibileClass = 'md:before:inline-block';
        const afterElementVisibileClass = 'md:after:inline-block';

        // Toggle topics top mask
        if (firstTopicElementYAxis && firstTopicElementYAxis < topicsListElementYAxis.top) {
          !topicsListRef.current.classList.contains(beforeElementVisibileClass) &&
            topicsListRef.current.classList.add(beforeElementVisibileClass);
        } else {
          topicsListRef.current.classList.contains(beforeElementVisibileClass) &&
            topicsListRef.current.classList.remove(beforeElementVisibileClass);
        }

        // Toggle topics bottom mask
        if (lastTopicElementYAxis && lastTopicElementYAxis > topicsListElementYAxis.bottom - 24) {
          !topicsListRef.current.classList.contains(afterElementVisibileClass) &&
            topicsListRef.current.classList.add(afterElementVisibileClass);
        } else {
          topicsListRef.current.classList.contains(afterElementVisibileClass) &&
            topicsListRef.current.classList.remove(afterElementVisibileClass);
        }
      }
    }
  };

  const handleKeyDownOnFacets = (e: React.KeyboardEvent<HTMLUListElement>) => {
    const getNewFacetIndex = (isElementDisable: boolean) => {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          if (currentFocusedFacetIndex < facetListElements.length - 1 && !isElementDisable)
            return ++currentFocusedFacetIndex;
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          if (currentFocusedFacetIndex > 0 && !isElementDisable) return --currentFocusedFacetIndex;
          break;
      }

      return currentFocusedFacetIndex;
    };

    const facetListElements = e.currentTarget.children as unknown as Array<HTMLLIElement>;
    const currentFocusedFacetElement = e.target as unknown as HTMLButtonElement;
    let currentFocusedFacetIndex = parseInt(currentFocusedFacetElement.dataset.uiIndex!);
    const ariaDisabled: string | null =
      currentFocusedFacetElement?.attributes?.getNamedItem('aria-disabled')?.value || null;
    const isElementDisable = ariaDisabled == 'false' && ariaDisabled != null ? false : true;
    try {
      if (e.key === 'Enter') {
        (
          facetListElements[getNewFacetIndex(isElementDisable)].children[0] as HTMLButtonElement
        ).click();
        return;
      }

      if (
        e.key === 'ArrowDown' ||
        e.key === 'ArrowRight' ||
        e.key === 'ArrowUp' ||
        e.key === 'ArrowLeft'
      ) {
        e.preventDefault();
        (
          facetListElements[getNewFacetIndex(isElementDisable)].children[0] as HTMLButtonElement
        ).focus();
      }
    } catch (error) {
      console.error('Error while navigating through facets using arrow keys', error);
    }
  };

  const renderTopicsFacet = (topicsFacet: SearchResponseFacet) => {
    const topicsToRender = !searchBoxValue
      ? topicsFacet.value
      : topicsFacet.value.filter((facet) =>
          facet.text.toLowerCase().includes(searchBoxValue.toLowerCase())
        );

    if (topicsToRender.length === 0)
      return (
        <Text
          tag="p"
          field={{ value: labels.noTopicsFoundMessage }}
          className="paragraph-2-regular text-color-default-1 text-center"
        />
      );

    return (topicsToRender as Array<ProcessedFacetItem>)
      .sort((a, b) => Number(b.isEnabled) - Number(a.isEnabled))
      .map((v, index: number) => (
        <FacetItem
          key={v.id}
          {...{
            index,
            facetValueId: v.id,
          }}
          className={classNames(
            'group flex items-center cursor-pointer gap-2',
            'text-color-default-2 data-[state=selected]:text-color-default-1',
            'paragraph-2-regular data-[state=selected]:paragraph-2-medium',
            !v.isEnabled && 'pointer-events-none'
          )}
          aria-disabled={!v.isEnabled}
        >
          <AccordionFacets.ItemCheckbox
            className={classNames(
              'peer relative flex-none rounded-[1px] border-2 border-icon-toggle-selected-enabled cursor-pointer',
              'w-6 h-6',
              'aria-checked:bg-button-secondary-active aria-checked:hover:border-button-secondary-hover aria-checked:hover:bg-button-secondary-hover',
              'aria-checked:focus:bg-button-secondary-focus focus:outline-none focus:border-button-secondary-focus',
              'group-aria-disabled:!border-icon-toggle-selected-disabled'
            )}
            tabIndex={v.isEnabled ? 0 : -1}
            aria-label={v.text}
            data-ui-index={index}
            aria-disabled={!v.isEnabled ? true : false}
          >
            <AccordionFacets.ItemCheckboxIndicator className="text-white">
              <SVG className="[&_svg]:p-0.5 [&_svg]:absolute [&_svg]:top-0" svg="icon-thin-check" />
            </AccordionFacets.ItemCheckboxIndicator>
          </AccordionFacets.ItemCheckbox>
          <AccordionFacets.ItemLabel className="group-aria-disabled:!text-color-disabled paragraph-2-regular">
            {v.text}
          </AccordionFacets.ItemLabel>
        </FacetItem>
      ));
  };

  return (
    <div className="relative flex flex-col h-lvh md:h-full max-w-[80vw] md:max-w-full md:w-full md:border-b md:border-strokes-default-3">
      <div className="flex-grow p-6 sm:p-8 md:p-0 flex flex-col md:flex-row gap-8 overflow-y-auto md:overflow-y-visible">
        <div className="md:hidden flex items-center gap-4 self-stretch w-full justify-between">
          <Text tag="h4" className="text-color-default-1" field={{ value: labels.panelTitle }} />
          <div tabIndex={0} className="w-4 h-4" onClick={() => setIsFilterPanelVisible(false)}>
            <SVG className="cursor-pointer" svg="icon-close" />
          </div>
        </div>

        {/* Topics Facets */}
        <SearchResultsAccordionFacets
          defaultFacetTypesExpandedList={['category', 'topics', 'type']}
          onFacetValueClick={onFacetClick}
          className="flex flex-col md:flex-row w-full gap-8"
        >
          {processedFacets.map((processedFacet) => {
            return (
              <AccordionFacets.Facet
                facetId={processedFacet.name}
                key={processedFacet.name}
                className={classNames(
                  processedFacet.name === 'topics'
                    ? 'order-3 md:order-2 !gap-2 sm:!gap-4'
                    : processedFacet.name === 'type' && 'order-2 md:order-3',
                  processedFacet.name !== 'topics' && 'flex-grow',
                  'flex flex-col gap-4'
                )}
              >
                <AccordionFacets.Header
                  asChild
                  className="bg-background-default-1 pointer-events-none"
                >
                  <p
                    tabIndex={0}
                    id={processedFacet.name}
                    className="text-left paragraph-2-medium scroll-mt-4"
                  >
                    {labels[processedFacet.name as LabelTypes] || processedFacet.name}
                  </p>
                </AccordionFacets.Header>
                {processedFacet.name === 'topics' ? (
                  <>
                    <div className="flex items-start justify-center min-h-[44px] md:min-w-[352px]">
                      <div className="relative flex-grow self-stretch">
                        <input
                          onChange={(e) => setSearchBoxValue(e.target.value)}
                          value={searchBoxValue}
                          className={classNames(
                            'border border-strokes-default-3 border-r-0 rounded-l-[28px] pl-4 w-full h-full focus:outline-none focus:border-strokes-action',
                            searchBoxValue ? 'paragraph-3-medium' : 'paragraph-3-regular'
                          )}
                          placeholder={labels.searchBoxPlaceholder}
                          aria-label={labels.searchboxAria}
                        />
                        {/* Clear Button */}
                        {searchBoxValue && (
                          <button
                            tabIndex={0}
                            className="absolute right-[14px] top-1/2 -translate-y-1/2"
                            onClick={() => setSearchBoxValue('')}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setSearchBoxValue('');
                              }
                            }}
                            aria-label={labels.searchboxClearAria}
                          >
                            <SVG
                              className="inline-block w-3 h-3 text-strokes-default-2"
                              svg="icon-close"
                            />
                          </button>
                        )}
                      </div>
                      {/* Search Button */}
                      <button
                        tabIndex={-1}
                        className="flex items-center justify-center self-stretch px-[12px] bg-button-primary-enabled rounded-r-[28px] focus:outline-none"
                        aria-label={labels.searchboxButtonAria}
                      >
                        <SVG className="inline-block w-5 h-5 text-white" svg="icon-search" />
                      </button>
                    </div>
                    <Button
                      tabIndex={0}
                      field={{ value: { href: '#type', text: labels.topicsSkipFilter } }}
                      fieldClass={classNames(
                        'absolute opacity-0 pointer-events-none bg-transparent bottom-0 right-0',
                        'focus:static focus:opacity-100 focus:pointer-events-auto focus:border-button-secondary-focus'
                      )}
                      size="full"
                      type="auxiliary"
                    />
                    <AccordionFacets.Content
                      ref={topicsListRef}
                      className={classNames(
                        'relative flex flex-col flex-1 px-2 sm:px-4',
                        'before:hidden before:absolute before:top-0 before:left-0 before:w-full before:h-6 before:bg-[linear-gradient(180deg,_#FBFAFA_0%,_rgba(251,_250,_250,_0.00)_100%)]',
                        'after:hidden after:absolute after:bottom-0 after:left-0 after:w-full after:h-6 after:bg-[linear-gradient(0deg,_#FBFAFA_0%,_rgba(251,_250,_250,_0.00)_100%)]'
                      )}
                    >
                      <AccordionFacets.ValueList
                        onScroll={(e) => handleTopicsMaskVisibility(e)}
                        onKeyDown={(e) => handleKeyDownOnFacets(e)}
                        className="group list-none flex flex-col gap-2 md:max-h-[262px] overflow-y-auto md:pb-4"
                      >
                        {renderTopicsFacet(processedFacet)}
                      </AccordionFacets.ValueList>
                    </AccordionFacets.Content>
                  </>
                ) : (
                  <AccordionFacets.Content className="flex flex-col flex-1 overflow-y-auto md:pb-4">
                    <AccordionFacets.ValueList
                      className="list-none flex flex-wrap gap-3"
                      onKeyDown={(e) => handleKeyDownOnFacets(e)}
                    >
                      {processedFacet.value.map((v: ProcessedFacetItem, index: number) => (
                        <FacetItem
                          key={v.id}
                          {...{
                            index,
                            facetValueId: v.id,
                          }}
                          className="group"
                          aria-disabled={!v.isEnabled}
                        >
                          <AccordionFacets.ItemCheckbox
                            tabIndex={v.isEnabled ? 0 : -1}
                            className="peer absolute opacity-0"
                            aria-label={v.text}
                            data-ui-index={index}
                          ></AccordionFacets.ItemCheckbox>
                          <AccordionFacets.ItemLabel
                            className={classNames(
                              'flex items-center justify-center gap-2 p-2 rounded border paragraph-3-regular text-color-default-1 cursor-pointer',
                              'border-button-secondary-active hover:border-button-secondary-hover peer-focus:border-button-secondary-focus peer-focus:paragraph-3-medium',
                              'hover:underline peer-focus:outline-none',
                              'group-data-[state=selected]:bg-button-secondary-active group-data-[state=selected]:text-white group-data-[state=selected]:paragraph-3-medium group-data-[state=selected]:hover:bg-button-secondary-hover group-data-[state=selected]:focus:bg-button-secondary-focus',
                              'peer-data-[state=checked]:peer-focus:bg-button-secondary-focus',
                              !v.isEnabled &&
                                'pointer-events-none !border-button-auxiliary-disabled !bg-transparent !text-color-disabled !paragraph-3-regular'
                            )}
                          >
                            {getIconDetailsFromFacetId[v.id] && (
                              <div className="relative w-4 h-4 md:w-6 md:h-6">
                                <SVG
                                  svg={`icon-${getIconDetailsFromFacetId[v.id].name}`}
                                  className={classNames(
                                    getIconDetailsFromFacetId[v.id].color || 'text-strokes-action',
                                    'group-data-[state=selected]:text-white group-aria-disabled:text-icon-disabled-1',
                                    '[&_svg]:absolute [&_svg]:top-0'
                                  )}
                                />
                              </div>
                            )}
                            {v.text?.toLowerCase() == 'videoresource'
                              ? 'Video'
                              : v.text.toLowerCase() == 'calculatorresource'
                              ? 'Calculator'
                              : v.text}
                          </AccordionFacets.ItemLabel>
                        </FacetItem>
                      ))}
                    </AccordionFacets.ValueList>
                  </AccordionFacets.Content>
                )}
              </AccordionFacets.Facet>
            );
          })}
        </SearchResultsAccordionFacets>
      </div>
      {/* Button Controls */}
      <div className="md:hidden w-full p-6 lg:p-8 shadow-small flex items-center justify-between flex-wrap bg-background-default-2 focus:[&_button]:outline-none">
        <div className="text-center paragraph-2-medium text-color-active [&_button]:border-none">
          <Button
            label={labels.clearAll}
            fieldClass="!min-w-max !p-0"
            type="auxiliary"
            onClick={() => {
              onClearFilters();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClearFilters();
              }
            }}
          />
        </div>
        <div className="focus:[&_button]:border-button-secondary-focus">
          <Button
            label={labels.viewResults}
            size="full"
            type="auxiliary"
            onClick={() => setIsFilterPanelVisible(false)}
            onKeyDown={(e) => e.key === 'Enter' && setIsFilterPanelVisible(false)}
          />
        </div>
      </div>
    </div>
  );
};
//#endregion

//#region Card
export const Card = ({
  isCategoryHidden,
  ribbonLabel,
  category_color,
  url,
  image_url,
  media_url,
  category,
  name,
  type,
  description,
  publish_date,
  article_time,
}: CardProps) => {
  const card = tv({
    slots: {
      base: [
        'flex flex-col items-start h-full w-full bg-background-default-2',
        'rounded border border-default-3 overflow-hidden',
        'focus:border-button-secondary-focus focus-visible:outline-none',
        'hover:border-icon-toggle-selected-disabled hover:shadow-[0px_2px_40px_0px_rgba(57,100,191,0.15)] hover:no-underline text-inherit hover:text-inherit focus:no-underline',
      ],
      imageContainer: 'w-full min-h-[200px] relative',
      playIconWrapper:
        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full border-4 border-white z-10',
      playIcon:
        'absolute inline-block w-5 h-5 text-color-inverse top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
      image: 'w-full max-h-[200px] h-full object-cover object-center',
      content: 'flex flex-col justify-between p-4 w-full h-full',
      categoryStripe: 'min-h-2 w-full absolute bottom-0 left-0 z-10',
      category: 'mb-1 caption text-color-default-1',
      title: 'mb-3 lg:mb-4.5',
      description: 'mb-4 lg:mb-6 text-color-default-2 paragraph-3-regular',
      metaDetails: 'flex items-center justify-between caption',
      arrowIcon: 'w-[18px] h-[18px] lg:w-[22px] lg:h-[22px] text-color-active',
    },
    variants: {
      categoryType: {
        'category-1': {
          categoryStripe: 'bg-background-category-1',
        },
        'category-2': {
          categoryStripe: 'bg-background-category-2',
        },
        'category-3': {
          categoryStripe: 'bg-background-category-3',
        },
        'category-4': {
          categoryStripe: 'bg-background-category-4',
        },
      },
    },
  });

  const {
    base,
    imageContainer,
    image,
    categoryStripe,
    content,
    category: categoryClasses,
    title,
    description: descriptionClasses,
    metaDetails,
    playIconWrapper,
    playIcon,
    arrowIcon,
  } = card(category_color && { categoryType: category_color?.[0] });

  return (
    <LinkWrapper tabIndex={0} field={{ href: url }} className={base()}>
      <div
        className={classNames(
          imageContainer(),
          type === 'VideoResource' &&
            'after:absolute after:bg-[#000] after:opacity-50 after:w-full after:h-full after:top-0 after:left-0'
        )}
      >
        <ImageWrapper
          field={{
            value: { src: type === 'Article' ? image_url : getThumnailUrl(media_url as string) },
          }}
          className={image()}
          width="325"
          height="192"
        />
        {type === 'VideoResource' && (
          <div className={playIconWrapper()}>
            <SVG svg="icon-play" hidden={false} className={playIcon()} />
          </div>
        )}

        {ribbonLabel ? (
          <Text
            field={ribbonLabel}
            tag="span"
            class="absolute inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
          />
        ) : (
          <div className={categoryStripe()}></div>
        )}
      </div>
      {/* Category colored stripe */}

      <div className={content()}>
        <div>
          {!isCategoryHidden && category && category?.length > 0 && (
            <p className={categoryClasses()}>{category[0]}</p>
          )}
          <RichTextA11yWrapper className={title()} tag="h4" field={{ value: name }} />
          <RichTextA11yWrapper className={descriptionClasses()} field={{ value: description }} />
        </div>
        <div className={metaDetails()}>
          <p className="h-full flex items-center justify-center">
            {publish_date && formatTimestamp(publish_date, 'Month DD, YYYY')}
            <span className="inline-block mx-2 w-[1px] h-[calc(100%_-_8px)] bg-strokes-default-1" />
            {article_time}
          </p>
          <SVG svg="icon-arrow-right" hidden={false} className={arrowIcon()}></SVG>
        </div>
      </div>
    </LinkWrapper>
  );
};
//#endregion

export const SearchWithFilterWidget = widget(
  SearchWithFilter,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);
