// Global
import React, { useState } from 'react';

// Lib
import { BoK } from 'lib/templates/Feature.BOKF.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

export type AlertNoticeProps = BoK.Content.AlertNotice & AlertNoticeParams;

export type AlertNoticeParams = {
  params: {
    Icon?: {
      Value?: {
        value: string;
      };
    };
    BgColor?: {
      Value?: {
        value: string;
      };
    };
  };
};

export const AlertNotice = (props: AlertNoticeProps): JSX.Element => {
  const { Icon, BgColor } = props?.params || {};
  const { CopyText } = props?.fields || {};
  const [closeAlert, setCloseAlert] = useState<boolean>(false);

  const closeAlertNotice = () => {
    setCloseAlert(true);
    // After state update, recalculate total height
    setTimeout(() => {
      updateAlertBannerHeight();
    }, 0);
  };

  const updateAlertBannerHeight = () => {
    // Get all alert containers that aren't hidden
    const alertContainers = document.querySelectorAll('.alert-container:not(.hidden)');

    if (alertContainers.length === 0) {
      document.documentElement.style.removeProperty('--alert-notice-height');
    } else {
      const headerElement = document.querySelector('header');
      let totalHeight = 0;
      // Only process alerts that are above the header
      alertContainers.forEach((container) => {
        if (headerElement) {
          const alertRect = container.getBoundingClientRect();
          const headerRect = headerElement.getBoundingClientRect();

          // Check if alert is above header
          if (alertRect.top < headerRect.top) {
            totalHeight += container.clientHeight;
          }
        }
      });
      // Update the CSS variable with total height
      document.documentElement.style.setProperty('--alert-notice-height', `${totalHeight}px`);
    }
  };

  if (!props?.fields) {
    return <></>;
  }

  return (
    <section
      className={`component alert-container flex flex-row items-center w-full text-color-inverse py-3 mobile:pr-7 pl-8 pr-6 md:pr-8 md:pl-10 gap-x-2 md:gap-x-4 ${
        BgColor?.Value?.value || 'bg-notifications-default'
      } ${closeAlert ? 'hidden' : ''}`}
    >
      <SVG
        className="w-6 h-6 min-w-6 min-h-6"
        svg={Icon?.Value?.value || 'icon-filled-info'}
        hidden={false}
      ></SVG>

      <RichTextA11yWrapper
        field={CopyText}
        className="paragraph-3-medium w-full rtaw alert-notice"
      />
      <button className="w-6 h-6 min-w-6 min-h-6" onClick={closeAlertNotice} type="button">
        <span className="sr-only">Close Alert Notice</span>
        <SVG svg="icon-close" hidden={false}></SVG>
      </button>
    </section>
  );
};

export default AlertNotice;
