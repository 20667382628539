//Global
import {
  Field,
  ImageField,
  LinkFieldValue,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { defaultConfig, tv } from 'tailwind-variants';

//helper
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

defaultConfig.twMerge = false;

export type topicItemProps = {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields: {
    Name?: Field<string>;
    Icon?: ImageField;
  };
};

export type TopicsProps = {
  fields: Fields;
};
export type Fields = {
  data: Data;
};
export type Data = {
  item: Item;
};
export type Item = {
  ancestors: Ancestor[];
};
export type Ancestor = {
  TopicsLink: TopicsLink;
};
export type TopicsLink = {
  jsonValue: JsonValue;
};
export type JsonValue = {
  value: LinkFieldValue;
};

export const topicsStyle = tv({
  slots: {
    containerStyle: [],
    topicsWrapperStyle: ['my-8 lg:my-10 w-full'],
    topicsBoxStyle: ['flex gap-x-4 gap-y-1 flex-wrap'],
    topicDividerStyle: [
      'relative after:absolute after:-right-2 after:h-[15px] after:top-1/2 after:-translate-y-1/2 after:w-[1px] after:bg-strokes last:after:hidden',
    ],
    topicLinkStyle: [
      'caption-underline text-color-default-1 transition-all focus:outline-1 focus:outline focus:outline-button-auxiliary-default focus:px-1 py-[2px] focus:rounded-[4px]',
    ],
  },
});

const Topics = (props: TopicsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const topicsData = sitecoreContext?.route?.fields?.Topics as Array<topicItemProps>;

  const { topicsWrapperStyle, topicsBoxStyle, topicDividerStyle, topicLinkStyle } = topicsStyle();

  const { ancestors } = props?.fields?.data?.item;

  const prepareTopicsUrl = (topicName: string) => {
    return ancestors && Array.isArray(ancestors) && ancestors.length > 0
      ? ancestors[0]?.TopicsLink?.jsonValue?.value?.href + `?topics=${topicName}`
      : '';
  };

  return (
    <>
      <div className={topicsWrapperStyle()}>
        <Text
          field={{ value: 'Topics' }}
          tag="span"
          className="block text-color-default-1 caption mb-2 md:mb-4"
        />
        <ul className={topicsBoxStyle()}>
          {topicsData.map((item, index) => {
            const topic = {
              value: {
                href: prepareTopicsUrl(item.name as string),
                text: item.fields.Name?.value,
                title: item.fields.Name?.value,
              },
            };
            return (
              <li className={topicDividerStyle()} key={index}>
                <LinkWrapper field={topic} className={topicLinkStyle()} />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Topics;
