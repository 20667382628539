import React from 'react';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

type LayoutProps = Marketing.DataTemplates.EmbedVideo;

export type VideoBlockProps = {
  params: { AnchorId?: string; HeadingType?: { Styles?: Field<string>; Tag?: Field<string> } };
} & LayoutProps;

const VideoBlock = (props: VideoBlockProps): JSX.Element => {
  const hasTitleUnderline = props.params.HeadingType?.Styles?.value.includes('title-border');
  const hasThickUnderline = props.params?.HeadingType?.Styles?.value.includes('thick');
  return (
    <>
      <RichTextA11yWrapper
        field={props.fields?.Title}
        tag={props.params?.HeadingType?.Tag?.value || 'h2'}
        className="mt-4 md:mt-6 lg:mt-10"
      ></RichTextA11yWrapper>
      {hasTitleUnderline && (
        <div
          className={`${
            hasThickUnderline ? 'border-b-[5px]' : 'border-b-[3px]'
          } mt-2 border-strokes-brand w-[3rem]`}
        ></div>
      )}

      <RichTextA11yWrapper
        field={props.fields?.VideoEmbed}
        tag="div"
        className="my-4 lg:my-6 lg:w-3/4 mx-auto"
        id={props.params?.AnchorId}
      ></RichTextA11yWrapper>

      <RichTextA11yWrapper
        field={props.fields?.CopyText}
        tag="div"
        className="mt-4 lg:mt-6 paragraph-2-regular"
      ></RichTextA11yWrapper>
    </>
  );
};

export default VideoBlock;
