const FooterSocialNavigationQuery = `
query FooterSocialNavigation($datasource: String!, $language: String!) {
  footerSocialNavigation: item(path: $datasource, language: $language) {
    children(first: 100) {
      results {
        ... on SocialLink {
          link {
            jsonValue
          }
          socialIcon {
            jsonValue
          }
          title {
            jsonValue
          }  
        }
      }
    }
  }
}
`;

export default FooterSocialNavigationQuery;
