import React, { useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import classNames from 'classnames';
import style from './ComparisonChart.module.css';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import SVG from 'helpers/SVG/SVG';

type EmblaCarouselProps = {
  onSlideChange: (index: number) => void;
  currentSlide: number;
  slide: {
    data: Array<{
      rowHeaderKey: string;
      value?: Field<GenericFieldValue> | string;
      productName?: string;
    }>;
  };
  calloutData?:
    | {
        calloutText: string;
        calloutProductName: string[];
      }[]
    | null;
  className?: string;
};

type Field<T> = {
  value: T;
};

type GenericFieldValue = {
  includes(arg0: string): unknown;
  href?: string;
  text?: string;
  class?: string;
};

const ProductComparisonSlider: React.FC<EmblaCarouselProps> = ({
  onSlideChange,
  currentSlide,
  slide,
  calloutData,
  className,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, align: 'start', dragFree: false });
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    const selectedIndex = emblaApi.selectedScrollSnap();
    onSlideChange(selectedIndex);
  }, [emblaApi, onSlideChange]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (emblaApi && currentSlide !== emblaApi.selectedScrollSnap()) {
      emblaApi.scrollTo(currentSlide);
    }
  }, [currentSlide, emblaApi]);

  useEffect(() => {
    const container = containerRef.current;
    const preventDragHandler = (event: PointerEvent) => {
      event.preventDefault();
    };

    if (container) {
      container.addEventListener('pointerdown', preventDragHandler);
    }

    return () => {
      if (container) {
        container.removeEventListener('pointerdown', preventDragHandler);
      }
    };
  }, [containerRef]);

  return (
    <div className={classNames(style.comparison_chart_embla, className, 'max-w-full m-auto')}>
      <div className={style.comparison_chart_embla__viewport} ref={emblaRef}>
        <div className={style.comparison_chart_embla__container} ref={containerRef}>
          {slide?.data?.map((item, index: number) => {
            let hasCallout = false;
            let calloutText = '';

            // Check if item is a string and find if it's in calloutData
            if (typeof item !== 'object') {
              const callout =
                calloutData && calloutData.find((data) => data?.calloutProductName.includes(item));
              if (callout) {
                hasCallout = true;
                calloutText = callout.calloutText;
              }
            }
            return (
              <div
                className={classNames(
                  style.comparison_chart_embla__slide,
                  style.slide_com,
                  'min-w-0 p-5 text-center border border-l-[0.5px] border-r-[0.5px] border-solid border-strokes-default-3'
                )}
                key={index}
              >
                {item?.rowHeaderKey === 'ApplyLink' || item?.rowHeaderKey === 'DetailLink' ? (
                  <Button
                    tag="a"
                    type="auxiliary"
                    href={typeof item?.value === 'object' ? item?.value?.value?.href : undefined}
                    label={
                      typeof item?.value === 'object'
                        ? item?.value?.value?.text || item?.productName
                        : item?.productName
                    }
                    title={
                      typeof item?.value === 'object'
                        ? item?.value?.value?.text || item?.productName
                        : item?.productName
                    }
                    fieldClass={typeof item?.value === 'object' ? item?.value?.value?.class : ''}
                  />
                ) : item?.value &&
                  typeof item?.value === 'object' &&
                  item?.value?.value?.includes('<svg') ? (
                  item?.value?.value?.includes('icon-minus') ? (
                    <div className="w-6 h-6 mx-auto flex">
                      <SVG svg="icon-minus" />
                    </div>
                  ) : item?.value?.value.includes('icon-filled-check') ? (
                    <div className="w-6 h-6 mx-auto">
                      <SVG className="text-notifications-success" svg="icon-filled-check" />
                    </div>
                  ) : (
                    <RichTextA11yWrapper
                      field={{ value: item?.value?.value as string }}
                      className="text-color-default-1 paragraph-3-regular rtaw"
                    />
                  )
                ) : item?.value ? (
                  <RichTextA11yWrapper
                    field={item?.value as Field<string>}
                    className="text-color-default-1 paragraph-2-regular rtaw"
                  />
                ) : (
                  <>
                    {/* Product Name and Callout */}
                    {hasCallout && (
                      <RichTextA11yWrapper
                        field={{ value: calloutText }}
                        tag="label"
                        className="absolute top-0 left-0 bg-icon-brand rtaw text-white text-[12px] flex items-center h-[18px] w-auto px-2 after:border-t-bokf-red after:border-t-[18px] after:border-solid after:border-r-[18px] after:border-r-transparent after:content-[' '] after:absolute after:-right-[18px]"
                      />
                    )}
                    <RichTextA11yWrapper
                      field={{ value: item as unknown as string }}
                      className="text-color-default-1 paragraph-2-medium rtaw"
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductComparisonSlider;
