import React, { useEffect, useState, useRef, FormEvent } from 'react';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';
import SVG from 'helpers/SVG/SVG';
import { Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import Cookies from 'js-cookie';
import { useLoginContext } from 'helpers/Providers/LoginContextProvider';

export type LoginProps = BoKNavigation.Login & {
  fields?: {
    children: LoginSubFolder[];
  };
};

type LoginSubFolder = {
  templateId?: string;
  fields: {
    children: LoginLinkCollection[];
  };
};

type LoginLinkCollection = BoKNavigation.NavigationLink & {
  fields: {
    children: BoKNavigation.NavigationLink[];
  };
};

type AdditionalLinksCollection = BoKNavigation.LoginModalLinks & {
  fields: {
    children: LoginLinkCollection[];
  };
};

enum DialogType {
  None,
  Login,
  Links,
}

const LoginMultiplex = (props: LoginProps): JSX.Element => {
  const { isMarqueeLoginEnabled } = useLoginContext();

  const loginLinks = props?.fields?.children?.filter(
    (item) => item.templateId === 'f2693d7b-0b38-42ab-8269-2cf717b708e9'
  )[0];
  const additionalLinks = props?.fields?.children?.filter(
    (item) => item.templateId === '51da10f9-6b4a-4c1b-b7a4-18f71f999447'
  )[0] as AdditionalLinksCollection;

  const [dialogType, setDialogType] = useState(DialogType.None);
  const [filter, setFilter] = useState('');
  const [hasResults, setHasResults] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [displayPass, setDisplayPass] = useState(false);
  const [enableClearSearch, setClearSearch] = useState(false);
  const [isCapsLocked, setCapsLocked] = useState(false);
  const [username, setUsername] = useState('');

  const searchInputRef = useRef<HTMLInputElement>(null);
  const userNameRef = useRef<HTMLInputElement>(null);
  const linkDialog = useRef<HTMLDivElement>(null);
  const loginDialog = useRef<HTMLDivElement>(null);

  const cookieUserName = 'username';

  function handleEscape(e: { key: string }) {
    if (e.key === 'Escape') {
      setDialogType(DialogType.None);
      setFilter('');
    }
  }

  // One time effects
  useEffect(() => {
    // Find navigation items that launch dialog
    const navLogins = Array.from(document.getElementsByClassName('nav-login'));
    for (let i = 0; i < navLogins.length; i++) {
      navLogins[i].addEventListener(
        'click',
        function (e) {
          e.preventDefault();
          setDialogType(DialogType.Login);
        },
        false
      );
    }

    // One time handle cookie setting
    const userCookie = Cookies.get(cookieUserName);
    if (userCookie !== undefined) {
      setRememberMe(true);
      setUsername(userCookie);
    }
  }, []);

  // Handle escape on dialog type change
  useEffect(() => {
    if (dialogType !== DialogType.None) {
      document.addEventListener('keydown', handleEscape);
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  }, [dialogType]);

  // If no results after filter then display no results
  useEffect(() => {
    const activeCategories = document.querySelectorAll('[data-link-category]');
    activeCategories.length > 0 ? setHasResults(true) : setHasResults(false);
  }, [filter]);

  function filterLinks(e: string) {
    if (e.length === 1) {
      setClearSearch(true);
    } else if (e.length >= 2) {
      setClearSearch(true);
      setFilter(e);
    } else {
      setClearSearch(false);
      setFilter('');
      setHasResults(true);
    }
  }

  // Tabindex management
  useEffect(() => {
    // Primary Login
    if (dialogType === DialogType.Login && loginDialog !== undefined) {
      const loginElements = loginDialog?.current?.querySelectorAll(
        'a[href], button, input, [tabindex]:not([tabindex="-1"])'
      );

      if (loginElements !== undefined) {
        const firstElement = loginElements[0] as HTMLElement;
        const lastElement = loginElements[loginElements.length - 1] as HTMLElement;
        firstElement.focus();

        const catchTabKey = (e: KeyboardEvent) => {
          if (e.key === 'Tab') {
            // Detect TAB key
            if (e.shiftKey) {
              if (document.activeElement === firstElement) {
                e.preventDefault();
                lastElement.focus();
              }
            } else {
              if (document.activeElement === lastElement) {
                e.preventDefault();
                firstElement.focus();
              }
            }
          }
        };

        document.addEventListener('keydown', catchTabKey);
        return () => {
          document.removeEventListener('keydown', catchTabKey);
        };
      }
    }

    if (dialogType === DialogType.Links && linkDialog !== undefined) {
      const linkElements = linkDialog?.current?.querySelectorAll(
        'a[href], button, input, [tabindex]:not([tabindex="-1"])'
      );

      if (linkElements !== undefined) {
        const firstElement = linkElements[0] as HTMLElement;
        const lastElement = linkElements[linkElements.length - 1] as HTMLElement;
        if (document.activeElement !== searchInputRef.current) {
          firstElement.focus();
        }

        const catchTabKey = (e: KeyboardEvent) => {
          if (e.key === 'Tab') {
            // Detect TAB key
            if (e.shiftKey) {
              if (document.activeElement === firstElement) {
                e.preventDefault();
                lastElement.focus();
              }
            } else {
              if (document.activeElement === lastElement) {
                e.preventDefault();
                firstElement.focus();
              }
            }
          }
        };

        document.addEventListener('keydown', catchTabKey);
        return () => {
          document.removeEventListener('keydown', catchTabKey);
        };
      }
    }
    return;
  }, [dialogType, filter]);

  function onKeyDownRememberMe(input: { key: string }) {
    if (input.key === 'Enter' || input.key === ' ') {
      handleRememberMe();
    }
  }

  function handleRememberMe() {
    if (rememberMe === false) {
      setRememberMe(true);
    } else {
      if (Cookies.get(cookieUserName) !== undefined) {
        Cookies.remove(cookieUserName);
      }
      setRememberMe(false);
    }
  }

  function onUserNameBlur(input: string) {
    if (input.length < 1) {
      setUsername('');
    } else {
      setUsername(input);
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const uName = userNameRef.current?.value;
    if (rememberMe && uName && uName.length > 0) {
      Cookies.set(cookieUserName, uName, { expires: 30, path: '/' });
      (e.target as HTMLFormElement).submit();
    } else if (uName && uName.length > 0) {
      (e.target as HTMLFormElement).submit();
    }
  }

  function clearSearch() {
    setFilter('');
    setClearSearch(false);
    if (searchInputRef.current != null) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
  }

  return (
    <>
      {/* Marquee Login */}
      {isMarqueeLoginEnabled && (
        <div
          data-component="login"
          className={`hidden ${
            dialogType != DialogType.None ? 'hidden' : 'lg:block'
          }  max-w-[467px] bg-background-default-2 shadow-large`}
        >
          <div className="p-6">
            <RichTextA11yWrapper
              field={props?.fields?.Title}
              tag="div"
              className="text-center text-color-default-1 headline-4"
            ></RichTextA11yWrapper>
            <form
              action={props?.fields?.['Submit Link']?.value}
              method="post"
              autoComplete="on"
              onSubmit={handleSubmit}
            >
              <div className="pt-10">
                <div className="grid gap-y-2 grid-cols-2 gap-x-6 bokf-login">
                  <label className="relative bokf-login-username" htmlFor="username">
                    <input
                      id="username"
                      type="text"
                      placeholder={props?.fields?.['Username Placeholder']?.value}
                      name="txtusername"
                      aria-roledescription={props?.fields?.['Username Placeholder']?.value}
                      className="pb-1 paragraph-2-regular text-color-default-1 border-b border-color-default-2 w-full outline-none caret-color-active focus:border-b-color-active"
                      onBlur={(e) => onUserNameBlur(e.target.value)}
                      defaultValue={username}
                      ref={userNameRef}
                    ></input>
                  </label>
                  <label className="relative bokf-login-password" htmlFor="password">
                    <input
                      id="password"
                      type={displayPass ? 'text' : 'password'}
                      placeholder={props?.fields?.['Password Placeholder']?.value}
                      name="txtPassword"
                      aria-roledescription={props?.fields?.['Password Placeholder']?.value}
                      className="pr-6 pb-1 paragraph-2-regular text-color-default-1 border-b border-color-default-2 w-full outline-none caret-color-active focus:border-b-color-active"
                      onKeyUp={(e) => {
                        setCapsLocked(e.getModifierState('CapsLock'));
                      }}
                      onClick={(e) => {
                        setCapsLocked(e.getModifierState('CapsLock'));
                      }}
                    ></input>
                    <div className="w-5 h-5 absolute right-0 top-1 text-icon-default-2">
                      <button
                        type="button"
                        aria-hidden="false"
                        aria-label="Toggle"
                        onClick={(e) => {
                          e.preventDefault();
                          setDisplayPass(!displayPass);
                        }}
                      >
                        <SVG svg={displayPass ? 'icon-eye' : 'icon-eye-slash'}></SVG>
                      </button>
                    </div>
                    <div
                      className={`text-right text-color-negative text-caption-md mt-1 ${
                        isCapsLocked ? '' : 'hidden'
                      }`}
                    >
                      {props?.fields?.['Capslock Warning']?.value}
                    </div>
                  </label>
                  <label
                    className="text-color-default-2 text-paragraph-3-regular-sm bokf-login-remembeme"
                    htmlFor="rememberme"
                  >
                    <input
                      id="rememberme"
                      type="checkbox"
                      name="rememberme"
                      aria-roledescription={props?.fields?.['Remember Username Text']?.value}
                      aria-checked={rememberMe}
                      className="hidden"
                    />
                    <span
                      tabIndex={0}
                      className="flex items-center cursor-pointer"
                      onClick={handleRememberMe}
                      onKeyDown={onKeyDownRememberMe}
                    >
                      <span
                        className={`h-4 w-4 border-2 rounded border-color-active mr-2 ${
                          rememberMe ? 'bokf-checked' : ''
                        }`}
                        data-login-checkbox
                      ></span>
                      {props?.fields?.['Remember Username Text']?.value}
                    </span>
                  </label>
                  <Link
                    field={props?.fields?.['Forgot Username Link'] as LinkField}
                    className={`text-color-active text-paragraph-3-regular-sm text-right bokf-login-forgotpassword ${props?.fields?.['Forgot Username Link']?.value.class}`}
                  ></Link>
                </div>
              </div>
              <div className="text-center pt-5">
                <input
                  type="submit"
                  role="button"
                  value={props?.fields?.['Button Text']?.value}
                  className="btn btn-primary w-fit"
                ></input>
              </div>
            </form>
          </div>
          <div className="text-center px-6 py-4 bg-background-dark-3 grid grid-rows-1 gap-y-3">
            <button
              className="flex items-center justify-center text-color-active caption-medium"
              onClick={() => {
                setDialogType(DialogType.Links);
              }}
            >
              <div>{additionalLinks?.fields?.['Call Modal Text']?.value}</div>
              <div className="ml-1">
                <SVG svg="icon-user-group-female-male"></SVG>
              </div>
            </button>

            <div className="caption text-color-default-2">
              Enroll in{' '}
              <Link
                field={loginLinks?.fields.children[0].fields?.Link as LinkField}
                className={`text-color-default-2 font-normal underline ${loginLinks?.fields.children[0].fields?.Link?.value.class}`}
              ></Link>{' '}
              or{' '}
              <Link
                field={loginLinks?.fields.children[1].fields?.Link as LinkField}
                className={`text-color-default-2 font-normal underline ${loginLinks?.fields.children[1].fields?.Link?.value.class}`}
              ></Link>
            </div>
          </div>
        </div>
      )}
      {/* Dialog start */}
      {dialogType !== DialogType.None && (
        <dialog
          className="fixed w-full h-full inset-0 overflow-y-auto z-50 bg-scrim-clickable"
          open
        >
          <div
            className="fixed w-full h-full"
            onClick={() => {
              setDialogType(DialogType.None);
              setFilter('');
            }}
          ></div>

          {/* Login Box */}
          {dialogType === DialogType.Login && (
            <div className="min-h-screen" ref={loginDialog}>
              <div className="absolute top-0 md:top-1/4 left-0 right-0 mx-auto w-full md:max-w-[480px] lg:max-w-[540px] border bg-background-default-2">
                <div className=" absolute right-2 top-2 m-2 w-6 h-6 text-color-default-2">
                  <button
                    aria-label="Close Dialog"
                    onClick={() => {
                      setDialogType(DialogType.None);
                    }}
                    tabIndex={0}
                  >
                    <SVG svg="icon-close"></SVG>
                  </button>
                </div>

                <div className="p-4 pt-10 md:p-6 md:pt-12 lg:px-10 lg:pb-10">
                  <RichTextA11yWrapper
                    field={props?.fields?.Title}
                    tag="div"
                    className="text-center text-color-default-1 headline-3 pt-3 lg:pt-0"
                  ></RichTextA11yWrapper>
                  <form
                    action={props?.fields?.['Submit Link']?.value}
                    method="post"
                    autoComplete="on"
                    // onSubmit={handleSubmit}
                  >
                    <div className="pt-4 pb-6 md:pb-0 md:pt-6 lg:pt-8 lg:pb-4">
                      <div className="grid grid-rows-1 gap-y-2 md:grid-cols-2 md:gap-x-6 lg:gap-x-8 bokf-login">
                        <label
                          className="relative mt-[30px] md:mt-5 bokf-login-username"
                          htmlFor="username"
                        >
                          <input
                            id="username"
                            type="text"
                            placeholder={props?.fields?.['Username Placeholder']?.value}
                            name="txtusername"
                            aria-roledescription={props?.fields?.['Username Placeholder']?.value}
                            className="paragraph-2-regular text-color-default-1 border-b border-color-default-2 w-full outline-none caret-color-active focus:border-b-color-active"
                            onBlur={(e) => onUserNameBlur(e.target.value)}
                            defaultValue={username}
                            ref={userNameRef}
                          ></input>
                        </label>
                        <label
                          className="relative mt-[6px] md:mt-5 bokf-login-password"
                          htmlFor="password"
                        >
                          <input
                            id="password"
                            type={displayPass ? 'text' : 'password'}
                            placeholder={props?.fields?.['Password Placeholder']?.value}
                            name="txtPassword"
                            aria-roledescription={props?.fields?.['Password Placeholder']?.value}
                            className="pr-6 paragraph-2-regular text-color-default-1 border-b border-color-default-2 w-full outline-none caret-color-active focus:border-b-color-active"
                            onKeyUp={(e) => {
                              setCapsLocked(e.getModifierState('CapsLock'));
                            }}
                            onClick={(e) => {
                              setCapsLocked(e.getModifierState('CapsLock'));
                            }}
                          ></input>
                          <div className="w-5 h-5 absolute right-0 top-0 text-icon-default-2">
                            <button
                              type="button"
                              aria-hidden="false"
                              aria-label="Toggle"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayPass(!displayPass);
                              }}
                            >
                              <SVG svg={displayPass ? 'icon-eye' : 'icon-eye-slash'}></SVG>
                            </button>
                          </div>
                          <div
                            className={`text-right text-color-negative text-caption-md mt-1 ${
                              isCapsLocked ? '' : 'hidden'
                            }`}
                          >
                            {props?.fields?.['Capslock Warning']?.value}
                          </div>
                        </label>
                        <label
                          className="text-color-default-2 caption bokf-login-remembeme"
                          htmlFor="rememberme"
                        >
                          <input
                            id="rememberme"
                            type="checkbox"
                            name="rememberme"
                            aria-roledescription={props?.fields?.['Remember Username Text']?.value}
                            aria-checked={rememberMe}
                            className="hidden"
                          />
                          <span
                            tabIndex={0}
                            className="flex cursor-pointer h-11"
                            onClick={handleRememberMe}
                            onKeyDown={onKeyDownRememberMe}
                          >
                            <span
                              className={`h-5 w-5 inline-block border-2 rounded border-color-active mr-2 ${
                                rememberMe ? 'bokf-checked' : ''
                              }`}
                              data-login-checkbox
                            ></span>
                            {props?.fields?.['Remember Username Text']?.value}
                          </span>
                        </label>
                        <Link
                          field={props?.fields?.['Forgot Username Link'] as LinkField}
                          className={`text-color-active caption hover:underline md:text-right bokf-login-forgotpassword ${props?.fields?.['Forgot Username Link']?.value.class}`}
                        ></Link>
                      </div>
                    </div>
                    <div className="text-center">
                      <input
                        type="submit"
                        role="button"
                        value={props?.fields?.['Button Text']?.value}
                        className="btn btn-primary w-fit"
                      ></input>
                    </div>
                  </form>
                </div>
                <div className="text-center px-6 py-4 lg:py-4 bg-background-dark-3 grid grid-rows-1 gap-y-3">
                  <button
                    className="flex items-center justify-center text-color-active caption-medium"
                    onClick={() => {
                      setDialogType(DialogType.Links);
                    }}
                  >
                    <div>{additionalLinks?.fields?.['Call Modal Text']?.value}</div>
                    <div className="ml-1">
                      <SVG svg="icon-user-group-female-male"></SVG>
                    </div>
                  </button>

                  <div className="caption text-color-default-2">
                    Enroll in{' '}
                    <Link
                      field={loginLinks?.fields.children[0].fields?.Link as LinkField}
                      className={`text-color-default-2 font-normal underline ${loginLinks?.fields.children[0].fields?.Link?.value.class}`}
                    ></Link>{' '}
                    or{' '}
                    <Link
                      field={loginLinks?.fields.children[1].fields?.Link as LinkField}
                      className={`text-color-default-2 font-normal underline ${loginLinks?.fields.children[1].fields?.Link?.value.class}`}
                    ></Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Additional Links  */}
          {dialogType === DialogType.Links && (
            <div className="flex items-center min-h-screen">
              <div
                className="absolute top-0 left-0 right-0 md:top-14 w-full md:max-w-[480px] lg:max-w-[540px] mx-auto border p-4 md:p-6 lg:p-8 bg-white overflow-auto bok-scrollbar bok-scrollbar--no-border max-h-[83.3%]"
                ref={linkDialog}
              >
                <div className="flex justify-between">
                  <button
                    className="text-icon-default-2 paragraph-3-regular flex items-center"
                    onClick={() => {
                      setDialogType(DialogType.Login);
                      setFilter('');
                    }}
                  >
                    <div className="h-4 w-4 flex text-color-default-2">
                      <SVG svg="icon-back"></SVG>
                    </div>
                    <span className="pl-2">{additionalLinks?.fields?.['Go Back Text']?.value}</span>
                  </button>
                  <div className="w-6 h-6 text-color-default-2">
                    <button
                      aria-label="Close Dialog"
                      onClick={() => {
                        setDialogType(DialogType.None);
                        clearSearch();
                      }}
                    >
                      <SVG svg="icon-close"></SVG>
                    </button>
                  </div>
                </div>
                <RichTextA11yWrapper
                  field={additionalLinks?.fields?.['Modal Header Text']}
                  tag="h3"
                  className="text-color-default-1 text-center mt-2"
                ></RichTextA11yWrapper>
                <RichTextA11yWrapper
                  field={additionalLinks?.fields?.['Modal Subheader Text']}
                  className="paragraph-2-regular text-color-default-1 text-center mt-2"
                ></RichTextA11yWrapper>
                {/* Search box */}
                <div className="text-center flex items-center my-6">
                  <input
                    type="search"
                    name="Search"
                    maxLength={39}
                    placeholder={additionalLinks?.fields?.['Search Placeholder']?.value}
                    aria-label="Filter links"
                    className="bokf-search border rounded-l-full border-r-0 py-2 pl-4 w-full h-10 caret-color-active placeholder-color-default-2 border-color-default-3"
                    onChange={(e) => filterLinks(e.target.value)}
                    ref={searchInputRef}
                  />

                  <button
                    id="clear-filter-input"
                    aria-label="Clear Filter"
                    className={`bok-clear-input border border-l-0 border-color-default-3 h-10 px-[14px] ${
                      enableClearSearch ? '' : 'hidden'
                    }`}
                    onClick={clearSearch}
                  >
                    <div className="h-3 w-3 text-color-default-2 hover:text-color-active">
                      <SVG svg="icon-close"></SVG>
                    </div>
                  </button>

                  <div className="p-2 h-10 min-w-[46px] bg-button-primary-enabled rounded-r-full">
                    <div className="text-color-inverse h-5 w-5">
                      <SVG svg="icon-search2" className="h-5 w-5 flex"></SVG>
                    </div>
                  </div>
                </div>
                {/* Error message  */}
                {filter !== '' && !hasResults && (
                  <div className="text-center ml-2 text-color-default-1 font-bold paragraph-2-medium word-break-break-word">
                    {`${
                      additionalLinks?.fields['No Results Message']?.value ||
                      'No results found for '
                    } "${filter}"`}
                  </div>
                )}
                {/* Additional Links List */}
                <div className="flex flex-col gap-y-4">
                  {additionalLinks?.fields?.children?.map((category, categoryKey) => {
                    const filteredLinks = category?.fields?.children?.filter((item) => {
                      if (
                        item?.fields?.Text?.value.toLowerCase().includes(filter.toLowerCase()) &&
                        item.fields?.Link?.value?.href
                      ) {
                        return true;
                      }
                      return false;
                    });
                    return (
                      <div
                        key={categoryKey}
                        data-link-category={category?.fields?.Text?.value}
                        className={`${filteredLinks.length > 0 ? '' : 'hidden'}`}
                      >
                        <RichTextA11yWrapper
                          tag="div"
                          field={category?.fields?.Text}
                          editable={false}
                          className="text-color-default-1 paragraph-3-medium w-full border-b border-bokf-red"
                        />
                        <ul
                          className={`list-none mt-2 ${
                            filter.length >= 2 ? 'columns-1' : 'columns-2'
                          }`}
                        >
                          {filteredLinks.map((link, linkKey) => {
                            return (
                              <li
                                key={linkKey}
                                className={linkKey % 2 ? '' : 'bg-background-dark-1'}
                              >
                                <a
                                  href={link?.fields?.Link?.value?.href}
                                  target={link?.fields?.Link?.value?.target}
                                  className="px-2 lg:pl-2 lg:pr-3 py-3 md:py-4 lg:py-[.875rem] gap-x-2 caption text-color-active flex justify-between items-center hover:underline word-break-break-word"
                                >
                                  {link?.fields?.Text?.value}
                                  <div className="min-h-4 min-w-4 h-4 w-4 stroke-strokes-action flex">
                                    <SVG svg="icon-box-with-arrow"></SVG>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </dialog>
      )}
    </>
  );
};

export default LoginMultiplex;
