// Global
import {
  RichTextField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
// import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
// import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

// @TODO: delete me
type HeadlineComponents = {
  rendering: object;
  params: object;
  fields: { headlineTitle: object; headlineDescription: object };
};
export type HeadlineProps = ComponentProps & HeadlineComponents;

// type Alignment = 'Center' | 'Left' | undefined;

// type BackgroundColor = 'BrandColor' | 'Default' | 'Light' | undefined;

// Tailwind variables
// const tailwindVariants = tv({
//   slots: {
//     componentBG: [],
//     base: [
//       'pt-components-heading-page-title-spacing-large-padding-y',
//       'pr-components-heading-page-title-spacing-large-padding-x',
//       'pb-components-heading-page-title-spacing-large-padding-y',
//       'pl-components-heading-page-title-spacing-large-padding-x',
//       'flex',
//       'flex-col',
//       'gap-2',
//       'items-start',
//       'justify-start',
//       'relative',
//       'overflow-hidden',
//     ],
//     contentWrapper: [
//       'flex',
//       'flex-col',
//       'gap-components-heading-page-title-spacing-large-title-margin-bottom',
//       'items-start',
//       'justify-start',
//       'self-stretch',
//       'relative',
//     ],
//     description: [
//       'font-bodySans-medium',
//       'leading-bodySans-medium',
//       'tracking-bodySans-medium',
//       'text-bodySans-medium',
//       'sm:font-bodySans-large',
//       'sm:font-bodySans-large',
//       'sm:leading-bodySans-large',
//       'sm:tracking-bodySans-large',
//       'sm:text-bodySans-large',
//     ],
//     headlineText: [
//       'font-header-small-large',
//       'leading-header-small-large',
//       'tracking-header-small-large',
//       'text-header-small-medium',
//       'sm:font-header-large-large',
//       'sm:font-header-large-large',
//       'sm:leading-header-large-large',
//       'sm:tracking-header-large-large',
//       'sm:text-header-large-large',
//       'mb-components-heading-page-title-spacing-large-title-margin-bottom',
//     ],
//     textAlignment: [],
//   },
//   variants: {
//     alignment: {
//       Center: {
//         contentWrapper: ['items-center'],
//         textAlignment: ['w-full', 'text-center'],
//       },
//       Left: {
//         contentWrapper: ['items-start'],
//         textAlignment: ['w-full', 'md:text-left'],
//       },
//     },
//     background: {
//       BrandColor: {
//         componentBG: ['bg-components-heading-page-title-color-color-bg'],
//         description: ['text-components-heading-page-title-color-color-body'],
//         headlineText: ['text-components-heading-page-title-color-color-title'],
//       },
//       Default: {
//         componentBG: ['bg-components-heading-page-title-color-default-bg'],
//         description: ['text-components-heading-page-title-color-default-body'],
//         headlineText: ['text-components-heading-page-title-color-default-title'],
//       },
//       Light: {
//         componentBG: ['bg-components-heading-page-title-color-light-bg'],
//         description: ['text-components-heading-page-title-color-light-body'],
//         headlineText: ['text-components-heading-page-title-color-light-title'],
//       },
//     },
//   },
// });

const Headline = (props: HeadlineProps): JSX.Element => {
  //   const { backgroundColor = 'Default', alignContent = 'Center' } = props?.params || {};

  //   const { base, contentWrapper, headlineText, description, textAlignment, componentBG } =
  //     tailwindVariants({
  //       background: backgroundColor as BackgroundColor,
  //       alignment: alignContent as Alignment,
  //     });

  const { sitecoreContext } = useSitecoreContext();

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      /*className={componentBG()}*/ data-component="authorable/headline"
      id={id ? id : undefined}
    >
      <div /*className={base()}*/>
        <div /*className={(contentWrapper(), textAlignment())}*/>
          {props?.fields ? (
            <>
              <Text
                //   className={headlineText()}
                encode={false}
                field={props?.fields?.headlineTitle as TextField}
                tag="h2"
              />
              <RichTextA11yWrapper
                //   className={description()}
                field={props?.fields?.headlineDescription as RichTextField}
              />
            </>
          ) : null}
          {!props?.fields && sitecoreContext?.route?.fields ? (
            <>
              <Text
                //   className={headlineText()}
                encode={false}
                field={sitecoreContext?.route?.fields?.headlineTitle as TextField}
                tag="h1"
              />
              <RichTextA11yWrapper
                //   className={description()}
                field={sitecoreContext?.route?.fields?.headlineDescription as RichTextField}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Headline;
