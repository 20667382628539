import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'helpers/Button/Button';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

type TabChild = Marketing.DataTemplates.Tab;
type paramsTypes = {
  params: {
    defaultIndex?: number;
    TabsAlignment?: {
      Value: {
        value: string;
      };
    };
    BackgroundColor?: {
      Value: {
        value: string;
      };
    };
  };
};
type TabsCollectionProps = paramsTypes &
  Marketing.DataTemplates.TabsCollection & {
    fields: {
      children: TabChild[];
    };
  };

const TabContent = ({
  item,
  contentAlignment,
}: {
  item: TabChild;
  contentAlignment: string | undefined;
}): JSX.Element => (
  <div className={classNames(contentAlignment)}>
    <div className="flex flex-col md:flex-row mt-4 md:mt-6 gap-2">
      <RichTextA11yWrapper
        className="text-color-default-2 mr-6 rtaw"
        tag="div"
        field={item?.fields?.TabContent1}
      />
      <RichTextA11yWrapper
        className="text-color-default-2 rtaw"
        tag="div"
        field={item?.fields?.TabContent2}
      />
    </div>
    {item?.fields?.CallToAction?.value?.href && (
      <div className="text-left block w-full mt-6">
        <Button tag="a" type="auxiliary" field={item.fields.CallToAction} />
      </div>
    )}
  </div>
);

const TabItems = ({
  tabItems,
  defaultIndex,
  alignment,
  contentAlignment,
}: {
  tabItems: TabChild[];
  defaultIndex: number;
  alignment: string;
  contentAlignment: string | undefined;
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<number>(defaultIndex);

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    // Handle keyboard interaction with Enter, Space, Left, Right keys
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setActiveTab(index);
    } else if (e.key === 'ArrowLeft') {
      e.preventDefault();
      setActiveTab((prev) => (prev > 0 ? prev - 1 : tabItems.length - 1));
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      setActiveTab((prev) => (prev < tabItems.length - 1 ? prev + 1 : 0));
    }
  };

  return (
    <div className="w-full">
      <div className="relative">
        <nav className="w-full flex border-b border-b-strokes-default-3 relative">
          <ul className={`${alignment} w-full flex flex-row`} role="tablist">
            {tabItems.map((item, index) => (
              <li
                className={classNames(
                  'px-4 md:px-6 md:mr-2 border-b-[3px] border-transparent list-none focus-visible:outline-0 outline-0 focus:border-b-button-secondary-focus [&>a]:focus:paragraph-2-medium',
                  {
                    '!border-b-strokes-brand': activeTab === index,
                  }
                )}
                key={index}
                role="tab"
                tabIndex={0}
                aria-selected={activeTab === index}
                onClick={() => setActiveTab(index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              >
                <RichTextA11yWrapper
                  tag="span"
                  className={classNames(
                    'hover:no-underline rtaw cursor-pointer hover:paragraph-2-medium hover:text-color-default-1 focus:paragraph-2-medium focus:text-color-default-1',
                    activeTab === index
                      ? 'paragraph-2-medium text-color-default-1'
                      : 'paragraph-2-regular text-color-default-2'
                  )}
                  field={item?.fields?.TabName}
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {/* tab-content */}
      <section>
        {tabItems.map(
          (item, index) =>
            activeTab === index && (
              <TabContent key={index} item={item} contentAlignment={contentAlignment} />
            )
        )}
      </section>
    </div>
  );
};

const TabItemsDropdown = ({
  tabItems,
  defaultIndex,
  alignment,
  contentAlignment,
}: {
  tabItems: TabChild[];
  defaultIndex: number;
  alignment: string;
  contentAlignment: string | undefined;
}): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(defaultIndex);
  const selectRef = useRef<HTMLDivElement>(null);

  // Close the dropdown if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  // Handle keyboard navigation
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedTabIndex((prev) => (prev + 1) % tabItems.length);
        break;
      case 'ArrowUp':
        event.preventDefault();
        setSelectedTabIndex((prev) => (prev - 1 + tabItems.length) % tabItems.length);
        break;
      case 'Enter':
      case 'Space':
        event.preventDefault();
        setDropdownOpen(!dropdownOpen);
        break;
      case 'Escape':
        event.preventDefault();
        setDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full block">
      <div
        className={classNames(
          'flex relative text-left mr-4 border-b border-b-strokes-default-3',
          alignment
        )}
        ref={selectRef}
      >
        <button
          type="button"
          aria-label="link-select"
          aria-expanded={dropdownOpen}
          aria-haspopup="listbox"
          className="block w-full sm:w-[350px] border-b-strokes-default-2 border-b-2 cursor-pointer"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          onKeyDown={handleKeyDown}
        >
          <div className="flex items-center w-full justify-between py-2">
            <Text
              tag="span"
              className="paragraph-2-medium text-center cursor-pointer"
              field={tabItems[selectedTabIndex]?.fields?.TabName}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              height="20"
              width="20"
              viewBox="0 0 330 330"
              className={`${dropdownOpen ? 'rotate-180' : 'rotate-0'} transition duration-200`}
            >
              <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
            </svg>
          </div>
        </button>
        {dropdownOpen && (
          <div
            className="absolute mt-1 rounded-md shadow-lg bg-background-default-2 z-10 w-full sm:w-[350px] top-10"
            role="listbox"
            aria-labelledby="link-select"
            tabIndex={-1}
          >
            <ul className="max-h-60 py-1 overflow-auto focus:outline-none">
              {tabItems.map((item, index) => (
                <li
                  key={index}
                  className={classNames(
                    'cursor-pointer select-none relative paragraph-2-regular list-none hover:text-color-default-2 hover:underline',
                    { 'paragraph-2-medium bg-background-default-1': selectedTabIndex === index }
                  )}
                  onClick={() => {
                    setSelectedTabIndex(index);
                    setDropdownOpen(false);
                  }}
                  role="option"
                  aria-selected={selectedTabIndex === index}
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                      setSelectedTabIndex(index);
                      setDropdownOpen(false);
                    }
                  }}
                >
                  <div className="flex items-center py-2 pl-3">
                    <Text
                      tag="span"
                      className={classNames('block paragraph-2-regular text-color-default-1', {
                        'paragraph-2-medium ': selectedTabIndex === index,
                      })}
                      field={item?.fields?.TabName}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* tab-content */}
      <section className="tab-content">
        {tabItems.map(
          (item, index) =>
            selectedTabIndex === index && (
              <TabContent key={index} item={item} contentAlignment={contentAlignment} />
            )
        )}
      </section>
    </div>
  );
};

const TabsCollection = (props: TabsCollectionProps): JSX.Element => {
  const { fields, params } = props;
  const defaultIndex = params?.defaultIndex || 0;
  const sectionStyles =
    params?.BackgroundColor?.Value?.value || 'bg-white border-default padding-bkgd';
  const TabsAlignment = params?.TabsAlignment?.Value?.value || 'tab-centered';

  let alignment;
  let contentAlignment;
  let isDropdown = false;

  switch (TabsAlignment) {
    case 'tab-left':
      alignment = 'justify-start';
      break;
    case 'tab-centered':
      alignment = 'justify-center text-center items-center';
      contentAlignment = 'mx-auto max-w-fit';
      break;
    case 'dropdown-left':
      alignment = 'justify-start';
      isDropdown = true;
      break;
    case 'dropdown-center':
      alignment = 'justify-center text-center items-center';
      contentAlignment = 'mx-auto max-w-fit';
      isDropdown = true;
      break;
    default:
      alignment = 'justify-center text-center items-center';
      contentAlignment = 'mx-auto max-w-fit';
  }

  if (!fields) {
    return <></>;
  }

  return (
    <section
      className={classNames(
        'container-wide flex flex-col px-12 mt-4 md:mt-6 spacing-md',
        sectionStyles,
        alignment
      )}
    >
      <div className="container-inner">
        <RichTextA11yWrapper field={fields.Headline} tag="h2" clssName="rtaw" />
        <RichTextA11yWrapper
          field={fields.SubText}
          tag="div"
          className="text-color-default-2 mt-4 paragraph-2-regular rtaw"
        />

        {/* Responsive Tabs and Dropdown */}
        <div className="w-full mt-6">
          {isDropdown ? (
            // Dropdown at all breakpoints if isDropdown is true
            <TabItemsDropdown
              tabItems={fields.children}
              defaultIndex={defaultIndex}
              alignment={alignment}
              contentAlignment={contentAlignment}
            />
          ) : (
            <>
              {/* Tabs for screens larger than (md) */}
              <div className="hidden md:flex">
                <TabItems
                  tabItems={fields.children}
                  defaultIndex={defaultIndex}
                  alignment={alignment}
                  contentAlignment={contentAlignment}
                />
              </div>
              {/* Dropdown for screens smaller than (md) */}
              <div className="md:hidden">
                <TabItemsDropdown
                  tabItems={fields.children}
                  defaultIndex={defaultIndex}
                  alignment={alignment}
                  contentAlignment={contentAlignment}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default TabsCollection;
