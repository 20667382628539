import { Field, Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import {
  EntityModel,
  Prettify,
  SearchResponseWidget,
  SearchResultsDefaultState,
  SearchResultsStoreState,
  SearchResultsWidgetRequest,
  useSearchResults,
  PageController,
} from '@sitecore-search/react';
import {
  WidgetQueryInitializer,
  WidgetWrapperResult,
  SearchResultsWidget,
  ExternalState,
  SearchResultsConfig,
  WidgetConfigInitializer,
  WidgetStateInitializer,
} from '@sitecore-search/react/dist/esm/types';
import { LOCALE_CONFIG, SupportedLanguage } from 'lib/constants/locale-constant';

// Define the type for the source object
export type SourceObject = Record<string, Field | Item | Item[] | string | null>;

// Define the type for the token object
export type TokenObject = Record<string, string>;

export const replaceTokens = (
  sourceObject: SourceObject,
  tokenObject: TokenObject
): SourceObject => {
  if (
    typeof tokenObject !== 'object' ||
    typeof sourceObject !== 'object' ||
    !sourceObject ||
    !tokenObject
  )
    return sourceObject;

  try {
    // Iterate over all properties of the sourceObject
    for (const key in sourceObject) {
      if (Object.prototype.hasOwnProperty.call(sourceObject, key)) {
        // If the property is a string, replace tokens
        if (typeof sourceObject[key] === 'string') {
          for (const [tokenName, tokenValue] of Object.entries(tokenObject)) {
            if (typeof sourceObject[key] !== 'undefined') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Not sure why but below line throws irrelevant object null error only at build time
              sourceObject[key] = sourceObject[key].replaceAll(tokenName, tokenValue);

              // Sitecore prepends "http" even if its not provided in the LinkField.
              // This causes the issue where there can be multiple http/https in the link which generates wrong URL.
              // e.g "http://https://onlinebanking.bankofoklahoma.com/Enrollment/"
              // Following condition will removes first occurance of "http:// or https://" if URL contains multiple protocols.
              if (
                Object.keys(sourceObject)[0] === 'url' ||
                Object.keys(sourceObject)[0] === 'href'
              ) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Not sure why but below line throws irrelevant object null error only at build time
                sourceObject[key] = sourceObject[key].replace(/^(https?:\/\/)(https?:\/\/)/, '$2');
              }
            }
          }
        }
        // If the property is an object, call the function recursively
        else if (typeof sourceObject[key] === 'object') {
          replaceTokens(sourceObject[key] as unknown as SourceObject, tokenObject);
        }
      }
    }
  } catch (error) {
    console.error('Error occured in token replacer', error);
  } finally {
    return sourceObject;
  }
};

export const useSanitizedSearchResults = <
  Model extends EntityModel,
  State extends Partial<ExternalState<SearchResultsStoreState>>
>(
  props: Prettify<
    WidgetStateInitializer<State> &
      Partial<WidgetQueryInitializer<SearchResultsWidgetRequest>> &
      Partial<WidgetConfigInitializer<SearchResultsConfig>>
  >
): {
  sanitizedResults: SearchResponseWidget<Model> | undefined;
} & WidgetWrapperResult<
  SearchResultsWidget,
  SearchResultsDefaultState & Partial<ExternalState<SearchResultsStoreState>>,
  Model
> => {
  const { sitecoreContext } = useSitecoreContext();

  useEffect(() => {
    const context = PageController.getContext();
    let language = 'en';

    if (typeof document !== 'undefined') {
      language = document.getElementsByTagName('html')[0].getAttribute('lang') || language;
    }
    const resolvedLanguage = (language in LOCALE_CONFIG ? language : 'en') as SupportedLanguage;

    const country = LOCALE_CONFIG[resolvedLanguage];

    context.setLocaleLanguage(language);
    context.setLocaleCountry(country);
  }, []);

  const searchResults = useSearchResults<Model, State>(props);

  const [sanitizedResults, setSanitizedResults] = useState<SearchResponseWidget<Model>>();

  useEffect(() => {
    if (searchResults.queryResult?.data?.content) {
      const sanitizedData = replaceTokens(
        searchResults.queryResult.data as unknown as SourceObject,
        sitecoreContext.tokenMappings as TokenObject
      );

      // Update sanitizedResults with the modified data
      setSanitizedResults(sanitizedData as unknown as SearchResponseWidget<Model>);
    } else setSanitizedResults(searchResults.queryResult.data);
    // sitecoreContext.tokenMappings won't change as its directly comes from middleware via layout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults.queryResult.data]);

  return { sanitizedResults, ...searchResults };
};
