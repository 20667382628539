import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import Button from 'helpers/Button/Button';
import ModalWrapper from 'helpers/ModalWrapper/ModalWrapper';
import React from 'react';

const SpeedBumpModal: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [targetHref, setTargetHref] = React.useState('');
  const [isSpanish, setIsSpanish] = React.useState(true);
  const speedBumpDiv = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleLinkClick = (event: MouseEvent | KeyboardEvent) => {
      const target = (event.target as HTMLElement).closest('a');

      if (target && target.classList.contains('external')) {
        event.preventDefault();
        const href = target.getAttribute('href');
        if (href) {
          setTargetHref(href);
          setShowModal(true);
        }
      }
    };

    document.addEventListener('click', handleLinkClick);
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []);

  const handleContinue = () => {
    setShowModal(false);
    window.open(targetHref, '_blank');
  };

  // Handle keyboard controls
  React.useEffect(() => {
    const linkElements = speedBumpDiv?.current?.querySelectorAll(
      'a[href], button, input, [tabindex]:not([tabindex="-1"])'
    );

    if (linkElements) {
      (linkElements[0] as HTMLElement).focus();
    }

    const handleKeyboard = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowModal(false);
      }

      if (e.key === 'Tab' && linkElements && linkElements.length > 0) {
        const firstElement = linkElements[0] as HTMLElement;
        const lastElement = linkElements[linkElements.length - 1] as HTMLElement;

        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    if (showModal) {
      document.addEventListener('keydown', handleKeyboard);
      return () => {
        document.removeEventListener('keydown', handleKeyboard);
      };
    }
    return;
  }, [showModal, speedBumpDiv]);

  React.useEffect(() => {
    const lang = document?.documentElement.lang;
    setIsSpanish(lang === 'es');
  }, []);

  const englishFormateData = {
    heading: {
      value: 'Before You Leave Our Site...',
    },
    decription: {
      value:
        "You're almost there! But before you go, we just want to advise that we do not manage or control the content of third-party sites. We recommend that you review their privacy and security policies, as they may differ from ours.",
    },
  };

  const spanishFormateData = {
    heading: {
      value: 'Antes de que se vaya de nuestro sitio...',
    },
    decription: {
      value:
        '¡Ya casi estamos listos! Antes de que se vaya, queremos que sepa que no administramos ni controlamos el contenidos de sitios de terceros. Le recomendamos que revise sus políticas de privacidad y seguridad, ya que pueden diferir de las nuestras.',
    },
  };

  return (
    <>
      {showModal && (
        <div ref={speedBumpDiv}>
          <ModalWrapper onClose={() => setShowModal(false)} position={'center'}>
            <Text
              field={isSpanish ? spanishFormateData.heading : englishFormateData.heading}
              tag="h3"
              className="mb-2"
            />
            <Text
              field={isSpanish ? spanishFormateData.decription : englishFormateData.decription}
              tag="p"
              className="paragraph-2-regular mb-6"
            />
            <hr />
            <div className="flex flex-col-reverse md:flex-row gap-x-6 gap-y-4 mt-6 justify-end">
              <Button
                type="secondary"
                size="large"
                label={isSpanish ? 'Cancelar' : 'Cancel'}
                onClick={() => setShowModal(false)}
              />
              <Button
                type="default"
                size="large"
                label={isSpanish ? 'Continuar' : 'Continue'}
                onClick={handleContinue}
              />
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  );
};

export default SpeedBumpModal;
