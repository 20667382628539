import { Environment, WidgetsProvider } from '@sitecore-search/react';
import React, { ReactNode } from 'react';

type WidgetWrapper = {
  children?: ReactNode;
};

const WidgetWrapper = ({ children }: WidgetWrapper) => {
  const env = process.env.SEARCH_ENV as Environment;
  const customerKey = process.env.SEARCH_CUSTOMER_KEY;
  const searchApiKey = process.env.SEARCH_API_KEY;
  const discoverDomainID = process.env.NEXT_PUBLIC_DISCOVER_DOMAIN_ID || '';

  return (
    <WidgetsProvider
      env={env}
      customerKey={customerKey}
      apiKey={searchApiKey}
      discoverDomainId={discoverDomainID}
    >
      {children}
    </WidgetsProvider>
  );
};

export default WidgetWrapper;
