// Library Imports
import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';

// Rates Imports
import { Rates } from 'lib/templates/Feature.BOKF.model';
import { RatesRequest, RatesResponse } from 'types/MarketingServices/Rates.Models';
import {
  RATES_LOADING,
  RATES_UNAVAILABLE,
  BuildRatesRequest,
  NEXT_RATES_API,
} from 'helpers/Rates/RatesHelper';

// Helper Imports
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

const MortgageRates = (props: Rates.Filter): JSX.Element => {
  const [rates, setRates] = useState<RatesResponse | null>(null);
  const [rateStatus, setRatesStatus] = useState<string>(RATES_LOADING);

  // Build Rates Request Object
  const request: RatesRequest = BuildRatesRequest(props as Rates.Filter);

  // Counter for disclosure links
  let disclosureCounter = 0;

  // Fetch Rates
  const fetchRates = useCallback(async () => {
    try {
      // Do rates Fetch
      const response = await fetch(NEXT_RATES_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      if (response.ok) {
        setRatesStatus('');
        const data = (await response.json()) as RatesResponse;
        setRates(data);
      } else {
        setRatesStatus(RATES_UNAVAILABLE);
      }
    } catch (error) {
      setRatesStatus(RATES_UNAVAILABLE);
    }
  }, []);

  useEffect(() => {
    if (request.BusinessLineId === 4) {
      fetchRates();
    }
  }, [fetchRates]);

  return (
    <>
      {request.BusinessLineId === 4 && (
        <section className="container @container">
          {rates && (
            <>
              <div className="mt-4 caption text-color-default-1">
                {`These rates are current as of ${format(
                  new Date(rates?.effective),
                  'MMMM d, yyyy p'
                )} (CT)`}
              </div>
              <div className="mt-8">
                {rates?.groups.map((group) => {
                  return (
                    <>
                      <div className="grid @lg:grid-rows-2 grid-cols-1 @xl:grid-cols-2 gap-6">
                        {group?.products.map((product, productIndex) => {
                          return (
                            <div key={productIndex} className="flex flex-col">
                              <h5 className="bg-color-default-2 px-4 py-2 text-color-inverse rounded-t">
                                {product?.title}
                              </h5>
                              <p className="caption p-4 border-x border-strokes-default-3 grow text-color-default-1">
                                {product?.head}
                              </p>
                              {product?.terms.map((term) =>
                                term?.rates.map((rate, rateIndex) => {
                                  return (
                                    <div
                                      className="flex border border-solid border-strokes-default-3"
                                      key={rateIndex}
                                    >
                                      <div className="w-1/2 px-4 py-2 border-r border-strokes-default-3">
                                        <p className="caption-medium text-color-default-2">
                                          Interest Rate
                                        </p>
                                        <p className="paragraph-1-regular text-color-positive">
                                          {rate?.interestRate}
                                        </p>
                                      </div>
                                      <div className="w-1/2 px-4 py-2">
                                        <p className="caption-medium text-color-default-2">APR</p>
                                        {product?.configuration ? (
                                          <p className="paragraph-1-regular text-color-default-2">
                                            {rate?.annualPercentage}
                                            <sup>
                                              <a
                                                className="caption-medium"
                                                href={`#disclosure-${disclosureCounter}`}
                                              >
                                                {++disclosureCounter}
                                              </a>
                                            </sup>
                                          </p>
                                        ) : (
                                          <div>{rate?.annualPercentage}</div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {disclosureCounter > 0 &&
                        ((disclosureCounter = 0),
                        (
                          <div className="flex flex-col gap-y-4 px-4 mt-8 text-color-default-1">
                            {group?.products.map((product, productIndex) => {
                              return (
                                <p key={productIndex} id={`disclosure-${disclosureCounter}`}>
                                  <sup>{++disclosureCounter}</sup>{' '}
                                  {product?.configuration?.Disclosure as string}
                                </p>
                              );
                            })}
                          </div>
                        ))}
                      {group?.tail && (
                        <div className="mt-4 px-4 text-color-default-1">
                          <p className="paragraph-2-medium">Disclaimer</p>
                          <RichTextA11yWrapper
                            className="caption mt-2"
                            field={{ value: group?.tail }}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}
          {!rates && <div>{rateStatus}</div>}
        </section>
      )}
    </>
  );
};

export default MortgageRates;
