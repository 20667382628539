import { DistanceOption } from './LocationTypes';

// DistanceFilter component
const DistanceFilter: React.FC<{
  onDistanceChange: (distance: number) => void;
  distanceProp: number;
}> = ({ onDistanceChange, distanceProp }) => {
  const distanceOptions: DistanceOption[] = [
    { label: '15 Miles', value: 15 },
    { label: '50 Miles', value: 50 },
    { label: '100 Miles', value: 100 },
    { label: '200 Miles', value: 200 },
  ];

  return (
    <>
      <div className="mb-4">
        <span className="caption-medium block mb-4">Refine results by distance</span>
        <div className="columns-2 space-y-4">
          {distanceOptions.map((option, index) => (
            <label
              htmlFor={option.label}
              key={index}
              onClick={() => {
                onDistanceChange(option.value);
              }}
              onKeyDown={(e) => e.key === 'Enter' && onDistanceChange(option.value)}
              className={`flex relative items-center ${
                distanceProp === option.value
                  ? 'paragraph-2-medium text-color-default-1'
                  : 'paragraph-2-regular text-color-default-2'
              }`}
              tabIndex={0}
            >
              <input
                tabIndex={-1}
                key={index}
                id={option.label}
                className={`me-4 h-6 w-6 peer relative appearance-none border-2 border-strokes-action rounded-full focus:outline-none`}
                type="radio"
                name="distance"
                value={option.value}
                checked={distanceProp === option.value}
              />
              <div className="absolute w-3 h-3 rounded-full peer-checked:bg-background-action ml-[6px]" />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    </>
  );
};

export default DistanceFilter;
