// Global
import React from 'react';
import { ComponentRendering, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Marketing } from 'lib/templates/Feature.BOKF.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import classNames from 'classnames';

import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';

type DisplayOptions = { Value: { value: string } }; // using this to overwrite incomplete leprechaun types

type HeroProps = {
  rendering: ComponentRendering;
  params: {
    HeadingType: {
      Tag: {
        value: string;
      };
      Styles: {
        value: string;
      };
    };
    'Display Options': {
      Value: {
        value: string;
      };
    };
    ImageBleed?: string;
    EnableFluidWidth?: string;
    AnchorId?: string;
  };
} & Marketing.DataTemplates.Hero;

const Hero = (props: HeroProps): JSX.Element => {
  const imageBleed = props.params.ImageBleed?.length ? 'imgBleed' : '';
  const fluidWidth = props.params.EnableFluidWidth?.length ? 'imgFluidWidth' : '';

  const { currentScreenWidth } = useCurrentScreenType();

  const anchor = props.params?.AnchorId ?? '';
  const imageOptions = classNames(imageBleed, fluidWidth);

  const displayOptions = (props.params['Display Options'] as unknown as DisplayOptions)?.Value
    ?.value;
  const hasBackground = !displayOptions?.includes('hero--noBkgd');
  const imageLeft = displayOptions?.includes('hero--imgLeft');
  const headingTag = props.params?.HeadingType?.Tag.value.length
    ? props.params?.HeadingType?.Tag.value
    : 'h2';

  const hasTitleUnderline =
    props.params?.HeadingType?.Styles?.value.includes('title-border') || false;
  const hasThickUnderline = props.params?.HeadingType?.Styles?.value.includes('thick');

  const { CallToAction, SecondaryCallToAction } = props.fields || {};

  const hasButtons = CallToAction?.value?.text || SecondaryCallToAction?.value?.text;

  const { PrimarySubLink, SecondarySubLink, TertiarySubLink } = props.fields || {};

  const hasLinks =
    PrimarySubLink?.value?.href || SecondarySubLink?.value?.href || TertiarySubLink?.value?.href;

  return (
    <section
      className={`component ${
        fluidWidth.length ? '' : 'container-wide'
      } spacing-md min-h-[26.25rem]`}
      id={anchor}
    >
      <div
        className={`${
          hasBackground ? 'border border-strokes-default-3 bg-background-default-2' : ''
        }`}
      >
        <div
          className={`flex flex-col md:flex-row gap-8 ${
            imageLeft ? 'justify-start' : 'justify-end'
          }`}
        >
          <div
            className={`flex flex-col pb-8 md:py-8 px-4 md:px-0 gap-y-6  ${
              imageLeft
                ? `order-last basis-1/2 md:pr-4 md:basis-[30rem] xl:basis-[38rem] 2xl:basis-[46rem]`
                : `order-last md:order-first md:pl-4 md:basis-[30rem] xl:basis-[38rem] 2xl:basis-[46rem] `
            }`}
          >
            <RichTextA11yWrapper
              className="text-color-default-1 rtaw"
              tag={headingTag}
              field={props.fields?.Title}
            />
            {hasTitleUnderline && (
              <div
                className={`${
                  hasThickUnderline ? 'border-b-[5px]' : 'border-b-[3px]'
                } mt-2 border-strokes-brand w-[3rem]`}
              ></div>
            )}
            <div className="flex flex-col gap-y-6 text-center md:text-start">
              <RichTextA11yWrapper
                className={`paragraph-2-regular text-color-default-2 rtaw ${imageLeft ? '' : ''}`}
                field={props.fields?.Text}
              />
              {hasButtons && (
                <div className="flex flex-col md:flex-row gap-y-3 gap-x-6">
                  {props.fields?.CallToAction?.value?.text && (
                    <Button
                      type="default"
                      size="large"
                      title={props.fields?.CallToAction?.value?.text}
                      field={props.fields?.CallToAction as LinkField}
                    />
                  )}
                  {props.fields?.SecondaryCallToAction?.value?.text && (
                    <Button
                      type="secondary"
                      size="large"
                      title={props.fields?.SecondaryCallToAction?.value?.text}
                      field={props.fields?.SecondaryCallToAction as LinkField}
                    />
                  )}
                </div>
              )}
              {hasLinks && (
                <div className="flex flex-col gap-y-4 items-center md:items-start">
                  <Text tag="p" className="text-grey-1" field={props?.fields?.SublinkTitle} />
                  {props.fields?.PrimarySubLink?.value.href && (
                    <LinkWrapper
                      field={props.fields?.PrimarySubLink as LinkField}
                      className="paragraph-2-medium"
                    ></LinkWrapper>
                  )}
                  {props.fields?.SecondarySubLink?.value.href && (
                    <LinkWrapper
                      field={props.fields?.SecondarySubLink as LinkField}
                      className="paragraph-2-medium"
                    ></LinkWrapper>
                  )}
                  {props.fields?.TertiarySubLink?.value.href && (
                    <LinkWrapper
                      field={props.fields?.TertiarySubLink as LinkField}
                      className="paragraph-2-medium"
                    ></LinkWrapper>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="basis-1/2 hero">
            {currentScreenWidth >= getBreakpoint('desktop-lg') &&
              props.fields?.DesktopImage?.value?.src?.length && (
                <ImageWrapper
                  className={`w-full h-full object-cover lg:${
                    (props.fields?.DesktopImagePosition?.fields as DisplayOptions)?.Value?.value
                  } ${imageOptions}`}
                  field={props.fields?.DesktopImage}
                />
              )}

            {currentScreenWidth >= getBreakpoint('desktop') &&
              currentScreenWidth < getBreakpoint('desktop-lg') && (
                <ImageWrapper
                  className={`w-full h-full object-cover md:${
                    (props.fields?.TabletImagePosition?.fields as DisplayOptions)?.Value?.value
                  } ${imageOptions}`}
                  field={
                    props.fields?.TabletImage?.value?.src?.length
                      ? props.fields?.TabletImage
                      : props.fields?.DesktopImage
                  }
                />
              )}

            {currentScreenWidth < getBreakpoint('desktop') && (
              <ImageWrapper
                className={`w-full h-full object-cover sm:${
                  (props.fields?.MobileImagePosition?.fields as DisplayOptions)?.Value?.value
                } ${imageOptions}`}
                field={
                  props.fields?.MobileImage?.value?.src?.length
                    ? props.fields?.MobileImage
                    : props.fields?.DesktopImage
                }
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
