// GLobal
import { useEffect, useRef, useState } from 'react';
import type {
  ActionProp,
  ItemClickedAction,
  SearchResultsInitialState,
  SearchResultsStoreState,
} from '@sitecore-search/react';
import { WidgetDataType, widget } from '@sitecore-search/react';
import { ArticleCard, Presence } from '@sitecore-search/ui';
import { debounce } from '@sitecore-search/common';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { useDictionary } from 'lib/hooks/use-dictionary';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';

// Local
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

type ArticleCardItemCardProps = {
  className?: string;
  displayText?: boolean;
  article: ArticleModel;
  onItemClick: ActionProp<ItemClickedAction>;
  index: number;
};

type QueryResultsSummaryProps = {
  currentPage: number;
  itemsPerPage: number;
  totalItemsReturned: number;
  totalItems: number;
  searchInputValue?: string;
  searchFormTarget?: LinkField;
};

type SpinnerProps = {
  loading?: boolean;
};

const ArticleHorizontalItemCard = ({
  className = '',
  article,
  onItemClick,
  index,
}: ArticleCardItemCardProps) => {
  return (
    <ArticleCard.Root
      key={article.id}
      className={`flex flex-row flex-nowrap max-h-52 w-full relative ${className}`}
    >
      <div className="flex-col border-b-[1px] w-full border-color-inverse mt-2">
        <a
          className="w-full"
          href={article.url}
          onClick={() => {
            onItemClick({
              id: article.id,
              index,
              sourceId: article.source_id,
            });
          }}
        >
          <ArticleCard.Title className="text-color-inverse underline">
            <h4>{article.name}</h4>
          </ArticleCard.Title>
        </a>
        <ArticleCard.Subtitle className="text-color-inverse paragraph-3-regular my-2 line-clamp-3">
          <RichTextA11yWrapper className="rtaw" field={{ value: article?.description }} />
        </ArticleCard.Subtitle>
      </div>
    </ArticleCard.Root>
  );
};

const QueryResultsSummary = ({
  totalItems,
  searchInputValue,
  searchFormTarget,
}: QueryResultsSummaryProps) => {
  const dict = useDictionary();
  const dictResults = dict?.['Global.SearchSeeAllAppend'] || 'results';
  const dictSeeAll = dict?.['Global.SearchSeeAllPrepend'] || 'See all';

  const url = searchFormTarget?.value?.href || '';

  return (
    <LinkWrapper
      field={{ value: { href: `${url}?q=${searchInputValue}&page=1` } }}
      className="btn btn-secondary paragraph-2-medium max-w-fit"
    >
      {dictSeeAll} {totalItems} {dictResults}
    </LinkWrapper>
  );
};

const Spinner = ({ loading = false }: SpinnerProps) => {
  return (
    <Presence present={loading}>
      <div className="text-center">
        <div role="status">
          <svg
            aria-busy={loading}
            aria-hidden={!loading}
            focusable="false"
            viewBox="0 0 20 20"
            className="inline animate-spin w-10"
          >
            <path
              fill="#fff"
              d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Presence>
  );
};

type ArticleModel = {
  id: string;
  type?: string;
  title?: string;
  name?: string;
  subtitle?: string;
  url?: string;
  description?: string;
  content_text?: string;
  image_url?: string;
  source_id?: string;
};

type ArticleSearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  searchBarPlaceholderText?: string;
  searchFormTarget?: LinkField;
};

type InitialState = SearchResultsInitialState<'itemsPerPage' | 'keyphrase' | 'page' | 'sortType'>;

const searchSource = process.env.SEARCH_SOURCE_ID;
const searchSourceIds = searchSource?.split('|') || [];

export const SearchOverlayComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 24,
  searchBarPlaceholderText,
  searchFormTarget,
}: ArticleSearchResultsProps) => {
  const {
    sanitizedResults,
    widgetRef,
    actions: { onItemClick, onKeyphraseChange },
    state: { page, itemsPerPage = defaultItemsPerPage },
    queryResult: { isLoading, isFetching, data: { total_item: totalItems = 0 } = {} },
  } = useSanitizedSearchResults<ArticleModel, InitialState>({
    query: (query) => {
      query.getRequest().setSearchFacetAll(false).setSources(searchSourceIds);
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
    },
  });

  const [keyPhrase, setKeyPhrase] = useState<string>('');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [noSearchResults, setNoSearchResults] = useState<boolean>(true);
  const [showSummaryDiv, setshowSummaryDiv] = useState<boolean>(true);

  const dict = useDictionary();
  const dictNoSearchResults = dict?.['Global.NoSearchResults'] || 'No results found';

  const refSearchInput = useRef<HTMLInputElement>(null);

  const keyphraseChangeFn = debounce((e) => {
    onKeyphraseChange({
      keyphrase: e.target.value,
    });
    setKeyPhrase(e.target.value);
  }, 200);

  const handleClearSearchInput = () => {
    refSearchInput.current?.focus();
    setSearchInputValue('');
    setKeyPhrase('');
  };

  useEffect(() => {
    refSearchInput.current?.focus();
  });

  useEffect(() => {
    setNoSearchResults(totalItems <= 0 && !isFetching && keyPhrase !== '');
  }, [totalItems, isFetching, keyPhrase]);

  useEffect(() => {
    setshowSummaryDiv(totalItems > 0 && keyPhrase !== '');
  }, [totalItems, keyPhrase]);

  if (isLoading) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-0">
        <div className="flex w-full h-full justify-center items-center">
          <Spinner loading />
        </div>
      </div>
    );
  }

  return (
    <div ref={widgetRef}>
      <div className="fixed top-0 right-0 left-0 z-[60] md:max-w-[612px] mt-16 md:mt-[104px] mx-6 md:mx-auto">
        <input
          onChange={(e) => {
            keyphraseChangeFn(e);
            setSearchInputValue(e.target.value);
          }}
          className="peer border border-strokes-default-3 rounded-full w-full p-2 pl-4 pr-[94px] !bg-color-inverse focus:bg-color-inverse focus:outline-none focus:ring-0 focus:outline-offset-[-1px] focus:outline-strokes-action placeholder:paragraph-3-regular placeholder:text-color-default-2 text-color-default-1 paragraph-3-medium"
          placeholder={searchBarPlaceholderText}
          value={searchInputValue}
          ref={refSearchInput}
        />
        <div
          className={`absolute top-0 right-[46px] bottom-0 ${
            keyPhrase?.length && keyPhrase !== '' ? '' : 'hidden'
          }`}
        >
          <button
            className="flex items-center justify-center w-[40px] h-full stroke-color-default-2"
            type="button"
            onClick={handleClearSearchInput}
          >
            <span className="sr-only">Clear Search Input</span>
            <SVG
              svg="icon-close"
              hidden={false}
              className="w-[12px] h-[12px] min-w-[12px] min-h-[12px]"
            ></SVG>
          </button>
        </div>
        <div className="absolute top-0 right-0 bottom-0">
          <button
            className="flex items-center justify-center w-[46px] h-full rounded-r-full bg-button-primary-enabled hover:bg-button-primary-hover text-color-inverse peer-focus:text-color-active"
            type="button"
            onClick={(e) => e.preventDefault}
          >
            <span className="sr-only">Search</span>
            <SVG
              svg="icon-search"
              hidden={false}
              className="w-[20px] h-[20px] min-w-[20px] min-h-[20px]"
            ></SVG>
          </button>
        </div>
      </div>
      <div className="flex">
        {isFetching && (
          <div className="w-full h-full fixed top-0 left-0 bottom-0 right-0 z-30 bg-scrim-clickable">
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col justify-center items-center z-40">
              <Spinner loading />
            </div>
          </div>
        )}
        {totalItems > 0 && (
          <>
            <section className="flex flex-col md:max-w-[768px] mx-6 md:mx-auto mt-32 md:mt-[184px]">
              {keyPhrase?.length && keyPhrase !== '' ? (
                <>
                  {sanitizedResults?.content?.map((a, index) => (
                    <ArticleHorizontalItemCard
                      key={a.id}
                      article={a as ArticleModel}
                      index={index}
                      onItemClick={onItemClick}
                      displayText={true}
                    />
                  ))}
                </>
              ) : (
                <></>
              )}
              <section
                className=" justify-end max-w-[768px] py-6 md:py-8 lg:py-10"
                style={{ display: showSummaryDiv ? 'flex' : 'none' }}
              >
                <QueryResultsSummary
                  currentPage={page}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  totalItemsReturned={sanitizedResults?.content?.length || defaultItemsPerPage}
                  searchInputValue={searchInputValue}
                  searchFormTarget={searchFormTarget}
                />
              </section>
            </section>
          </>
        )}

        <div
          className="w-full justify-center text-color-inverse mt-32 md:mt-44 md:max-w-[768px] mx-6 md:mx-auto"
          style={{ display: noSearchResults ? 'flex' : 'none' }}
        >
          <h2>
            {dictNoSearchResults} &quot;{keyPhrase}&quot;
          </h2>
        </div>
      </div>
    </div>
  );
};
const SearchOverlayWidget = widget(
  SearchOverlayComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);
export default SearchOverlayWidget;
