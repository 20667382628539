/**
 * @param {import('next').NextConfig} nextConfig
 */
const rssPlugin = (nextConfig = {}) => {
  return Object.assign({}, nextConfig, {
    async rewrites() {
      return [
        ...await nextConfig.rewrites(),
        // rss route
        {
          source: '/rss',
          destination: '/api/rss'
        },
      ];
    },
  });
};

module.exports = rssPlugin;
