import React, { createContext, useContext, useState, ReactNode } from 'react';

interface LoginContextType {
  isMarqueeLoginEnabled: boolean;
  setMarqueeLoginEnabled: (value: boolean) => void;
}

const LoginContext = createContext<LoginContextType>({
  isMarqueeLoginEnabled: false,
  setMarqueeLoginEnabled: () => {
    return;
  },
});

export function useLoginContext() {
  const context = useContext(LoginContext);
  return context;
}

interface LoginContextProviderProps {
  children: ReactNode;
}

export function LoginContextProvider({ children }: LoginContextProviderProps) {
  const [isMarqueeLoginEnabled, setMarqueeLoginEnabled] = useState<boolean>(false);

  return (
    <LoginContext.Provider value={{ isMarqueeLoginEnabled, setMarqueeLoginEnabled }}>
      {children}
    </LoginContext.Provider>
  );
}
