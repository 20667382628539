import React from 'react';
import { ComponentProps } from 'lib/component-props';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

type LayoutProps = { fields: { items: Marketing.DataTemplates.Stat[] } };

export type StatsListProps = ComponentProps & LayoutProps;

type ColorItem = { Value: { value: string } };

const StatsList = (props: StatsListProps): JSX.Element => {
  return (
    <div className={`component`}>
      {props.fields.items.map((item, i) => (
        <div
          key={i}
          className="flex flex-col gap-x-2 gap-y-2 md:gap-y-3 lg:gap-y-4 mt-5 md:mt-6 lg:mt-10"
        >
          <div className="flex items-center flex-center">
            {item.fields?.Image?.value?.src && (
              <ImageWrapper
                className={`mr-3 !h-[${item.fields?.Image?.value?.height}px]`}
                field={item.fields?.Image}
              />
            )}
            <RichTextA11yWrapper
              field={item.fields?.Title}
              tag="h2"
              className={`font-normal ${
                (item.fields?.HeadingColor?.fields as ColorItem)?.Value?.value === 'red-heading'
                  ? 'text-bokf-red'
                  : (item.fields?.HeadingColor?.fields as ColorItem)?.Value?.value ===
                    'green-heading'
                  ? 'text-color-brand-green'
                  : 'text-black'
              }`}
            ></RichTextA11yWrapper>
          </div>
          <RichTextA11yWrapper
            className=" paragraph-2-regular text-color-default-2 rtaw"
            field={item.fields?.CopyText}
          ></RichTextA11yWrapper>
        </div>
      ))}
    </div>
  );
};

export default StatsList;
