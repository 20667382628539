import { useState } from 'react';
import classNames from 'classnames';
import { Field, Text, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import SVG from 'helpers/SVG/SVG';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type LocationBannerProp = {
  fields: LocationField;
} & ComponentProps;

export type LocationField = {
  ChooseLocation: Field<string>;
  Info: Field<string>;
  LocationName: Field<string>;
  DefaultLocation: Field<string>;
};

const LocationBanner = (props: LocationBannerProp) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { currentScreenWidth } = useCurrentScreenType();

  const locationModal = `locationmodal`;
  if (!props?.fields) return <></>;

  const { ChooseLocation, Info, LocationName, DefaultLocation } = props?.fields || {};

  return (
    <section className="container spacing-md">
      <div
        tabIndex={0}
        onClick={() => {
          setIsModalOpen(!isModalOpen);
        }}
        className={classNames(
          'group cursor-pointer flex gap-4 md:gap-2 relative p-4 border border-strokes-orange bg-section-location-gradient border rounded',
          'hover:border-[1.5px] hover:border-strokes-vibrant-red',
          'focus:outline-[1.5px] focus:outline-strokes-secondary-focus focus:bg-section-location-gradient-focus'
        )}
      >
        <div className="w-[100px]">
          <SVG
            className="absolute top-0 !h-full !w-[72px] md:!w-[88px] lg:!w-[100px] [&_svg]:!h-full [&_svg]:!w-full"
            svg="icon-location-selector"
          />
        </div>
        <div className="flex flex-col md:flex-row w-full">
          <div
            className={`${
              currentScreenWidth < getBreakpoint('tablet') ? 'inline' : 'flex'
            } items-center md:grow`}
          >
            <span>
              <Text
                className={classNames(
                  'text-color-default-1 paragraph-2-regular',
                  'group-hover:underline',
                  'group-focus:underline'
                )}
                field={Info}
              />
              &nbsp;
              <RichTextA11yWrapper
                className={classNames(
                  'text-color-default-1 paragraph-2-medium inline-flex rtaw',
                  'group-hover:underline',
                  'group-focus:underline'
                )}
                field={LocationName?.value ? LocationName : DefaultLocation}
              />
            </span>
            <SVG className="inline-block !h-6 !w-6 [&_svg]:h-6 [&_svg]:w-6 ml-1" svg="icon-pin" />
          </div>
          <div className="flex items-center gap-2 mt-3 md:mt-0">
            <Text
              className={classNames(
                'text-color-default-1 paragraph-2-medium',
                'group-hover:underline',
                'group-focus:underline'
              )}
              tag="span"
              field={ChooseLocation}
            />
            <SVG svg="icon-chevron-right" className="h-5 w-5 [&_svg]:h-5 [&_svg]:w-5" />
          </div>
        </div>
      </div>
      {props.rendering && (
        <Placeholder
          onModalClose={() => {
            setIsModalOpen(false);
          }}
          locationName={LocationName?.value ? LocationName?.value : DefaultLocation?.value}
          isModalOpen={isModalOpen}
          name={locationModal}
          rendering={props?.rendering}
        />
      )}
    </section>
  );
};
export default LocationBanner;
