import React from 'react';
import {
  ComponentRendering,
  Field,
  ImageField,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Bok as FoundationBOKFBok } from '../../../lib/templates/Foundation.BOKF.model';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import classNames from 'classnames';

type GridItem =
  | {
      id?: string;
      url?: string;
      name?: string;
      displayName?: string;
      fields: {
        CopyText?: { value: string };
        Image?: { value: { src: string; alt: string; width: string; height: string } };
        Title?: { value: string };
        AspectRatio: { fields: { Value: { value: string } } } | undefined;
        'AspectRatio-Tablet': { fields: { Value: { value: string } } } | undefined;
        'AspectRatio-Mobile': { fields: { Value: { value: string } } } | undefined;
        ImageDisplayUltraWide: { fields: { Value: { value: string } } } | undefined;
        ImageDisplayDesktop: { fields: { Value: { value: string } } } | undefined;
        ImageDisplayTablet: { fields: { Value: { value: string } } } | undefined;
        ImageDisplayMobile: { fields: { Value: { value: string } } } | undefined;
        Position: { fields: { Value: { value: string } } } | undefined;
        'Position-Tablet': { fields: { Value: { value: string } } } | undefined;
        'Position-Mobile': { fields: { Value: { value: string } } } | undefined;
      };
    }
  | undefined;

type LayoutProps = FoundationBOKFBok.BaseTypes.TextAndCta &
  FoundationBOKFBok.BaseTypes.RichText & {
    fields?: {
      /**
       * Represents the Footer Text field (68b105c1-b252-4886-80e4-37d8518e8a8c).
       */
      'Footer Text'?: Field<string>;

      /**
       * Represents the Grid Items field (67306afd-9e04-408e-bc9d-6a3f36e0a067).
       */
      GridItems?: GridItem[];
      children?: GridItem[];
    };
  };
type ItemType = { Value: { value: string } };

export type ProgressiveGridProps = {
  rendering: ComponentRendering;
  params: {
    AnchorId?: string;
    'Random Order'?: string;
    Alignment?: ItemType;
    BackgroundColor?: ItemType;
    HeadingType?: { Styles?: { value: string }; Tag?: { value: string } };
    Spacing?: ItemType;
    Width?: ItemType;
  };
} & LayoutProps;

const ProgressiveGrid = (props: ProgressiveGridProps): JSX.Element => {
  const hasAlignment = props?.params?.Alignment?.Value?.value?.includes('center')
    ? 'center'
    : 'left';

  const hasBackground = props?.params?.BackgroundColor?.Value?.value.length
    ? `${props?.params?.BackgroundColor?.Value?.value}`
    : '';

  const hasBorder = props?.params?.BackgroundColor?.Value?.value?.includes('border-default') || '';

  const headingTag = props.params?.HeadingType?.Tag?.value.length
    ? props.params?.HeadingType?.Tag.value
    : 'h2';

  const hasTitleUnderline =
    props.params?.HeadingType?.Styles?.value.includes('title-border') || false;

  const hasThickUnderline = props.params?.HeadingType?.Styles?.value.includes('thick');

  const getBackgroundClass = (backgroundValue: string) => {
    const classes = backgroundValue
      .split(' ')
      .map((value) => (value === 'container-wide' ? 'container-fixed' : value));
    if (!classes.includes('padding-bkgd')) {
      classes.push('padding-bkgd');
    }
    return classes.join(' ');
  };

  let flexcols = 'lg:w-[calc(25%-24px)]';
  if (props.params?.Width?.Value?.value?.includes('25')) {
    flexcols = 'lg:w-[calc(25%-24px)]'; // 25; // 4 items/row
  } else if (props.params?.Width?.Value?.value?.includes('33')) {
    flexcols = 'lg:w-[calc(33%-24px)]'; // 33.333; // 3 items/row
  } else if (props.params?.Width?.Value?.value?.includes('50')) {
    flexcols = 'lg:w-[calc(50%-24px)]'; // 50; // 2 items/row
  }
  // Fisher-Yates shuffle
  const shuffle = (array: GridItem[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  let renderItems: GridItem[] = [];
  if (props.fields?.GridItems?.length && props.fields?.GridItems?.length > 0) {
    props.params['Random Order']
      ? (renderItems = shuffle(props.fields?.GridItems))
      : (renderItems = props.fields?.GridItems);
  } else if (props.fields?.children?.length && props.fields?.children?.length > 0) {
    props.params['Random Order']
      ? (renderItems = shuffle(props.fields?.children))
      : (renderItems = props.fields?.children);
  }

  return (
    <section
      id={props.params.AnchorId}
      className={`component ${
        props.params?.Spacing?.Value?.value || 'spacing-md'
      } ${getBackgroundClass(hasBackground)} ${hasBorder ? 'border border-strokes-default-3' : ''}`}
    >
      <div className={`${hasAlignment === 'left' ? 'text-left' : 'text-center'}`}>
        <RichTextA11yWrapper className="w-full" field={props.fields?.Text} tag={headingTag} />
        {hasTitleUnderline && (
          <div
            className={`${
              props.params?.Alignment?.Value?.value.includes('center') ? 'mx-auto' : ''
            } ${
              hasThickUnderline ? 'border-b-[5px]' : 'border-b-[3px]'
            } mt-2 border-strokes-brand w-[3rem]`}
          ></div>
        )}

        <RichTextA11yWrapper
          field={props.fields?.CopyText}
          className="mt-4 md:mt-6 w-full paragraph-2-regular"
        />
      </div>
      {/* grid items container */}
      <div className={`mt-8`}>
        <ul
          className={`inline-flex items-start flex-wrap w-full list-none gap-6 pt-0 pl-0 mb-0  ${
            hasAlignment === 'left' ? 'justify-start' : 'justify-center'
          }`}
        >
          {renderItems.map((item, i) => {
            const combinedImageClass = classNames(
              item?.fields?.['AspectRatio-Mobile']?.fields?.Value?.value,
              item?.fields?.['AspectRatio-Tablet']?.fields?.Value?.value,
              item?.fields?.AspectRatio?.fields?.Value?.value,

              item?.fields?.ImageDisplayMobile?.fields?.Value?.value
                ? item.fields.ImageDisplayMobile.fields.Value.value
                : 'w-1/4',
              item?.fields?.ImageDisplayTablet?.fields?.Value?.value
                ? item.fields.ImageDisplayTablet.fields.Value.value
                : '',
              item?.fields?.ImageDisplayDesktop?.fields?.Value?.value
                ? item.fields.ImageDisplayDesktop.fields.Value.value
                : '',
              item?.fields?.ImageDisplayUltraWide?.fields?.Value?.value
                ? item.fields.ImageDisplayUltraWide.fields.Value.value
                : '2xl:max-w-[15%]',

              item?.fields['Position-Mobile']?.fields.Value.value,
              item?.fields['Position-Tablet']?.fields.Value.value,
              item?.fields.Position?.fields.Value.value
            );

            return (
              <li
                key={i}
                className={`flex flex-col w-full md:w-[calc(50%-24px)] ${flexcols} ${
                  hasAlignment === 'left' ? 'items-start' : 'items-center'
                }`}
              >
                <ImageWrapper
                  className={`${combinedImageClass} min-w-[100px]`}
                  field={item?.fields?.Image}
                  height={(item?.fields?.Image as ImageField)?.value?.height as string}
                  width={(item?.fields?.Image as ImageField)?.value?.width as string}
                />
                <div
                  className={`flex flex-col text-wrap ${
                    hasAlignment === 'left' ? 'text-left' : 'text-center'
                  }`}
                >
                  <RichTextA11yWrapper field={item?.fields.Title as RichTextField} tag="h4" />
                  <RichTextA11yWrapper
                    field={item?.fields.CopyText as RichTextField}
                    className="rtaw"
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {props?.fields?.CallToAction?.value?.text && (
        <div className="flex w-full justify-center mt-4 md:mt-8">
          <Button
            type={'default'}
            field={props?.fields?.CallToAction}
            tag={'button'}
            size={'large'}
          />
        </div>
      )}
      {/* Footer text */}
      {props?.fields?.['Footer Text']?.value && (
        <>
          <hr className="px-6 solid mt-4 md:mt-6"></hr>
          <RichTextA11yWrapper
            className="text-start mt-4 md:mt-6 paragraph-2-regular text-icon-default-2"
            field={props.fields?.['Footer Text'] as RichTextField}
          />
        </>
      )}
    </section>
  );
};

export default ProgressiveGrid;
