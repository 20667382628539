import React, { useEffect, useState } from 'react';
import { widget, WidgetDataType, EntityModel, Filter } from '@sitecore-search/react';
import classNames from 'classnames';
import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv, defaultConfig } from 'tailwind-variants';

import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { formatTimestamp } from 'lib/utils/date-utils';
import Button from 'helpers/Button/Button';
import { getThumnailUrl } from 'lib/utils/vimeo-thumbnail';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';
import { usePathname } from 'next/navigation';

// Prevent overriding same kind of class
defaultConfig.twMerge = false;

type ResourceListProps = {
  isCategoryHidden?: boolean;
  ribbonLabel?: Field<string>;
  title?: {
    text?: string;
    tag?: 'h2' | 'h3' | 'h4';
    classes?: string;
  };
  initialCount?: string;
  SkipItemFromSearch: number;
  verticlePaginationOptions?: {
    loadMoreText?: string;
    loadCount?: string;
  };
  noResultsText?: string;
  filters?: Filter | undefined;
  IncludedResources?: Array<ResourceProps>;
};

export type ResourceProps = {
  isCategoryHidden?: boolean;
  ribbonLabel?: Field<string>;
  article_time?: string;
  category?: Array<string>;
  category_color?: Array<'category-1' | 'category-2' | 'category-3' | 'category-4'>;
  city?: Array<string>;
  description?: string;
  id?: string;
  image_url?: string;
  media_url?: string;
  name?: string;
  publish_date?: string;
  url?: string;
  type?: 'Article' | 'VideoResource';
};

const ResourceList = ({
  isCategoryHidden,
  ribbonLabel,
  title,
  initialCount = '3',
  verticlePaginationOptions,
  noResultsText = 'No results found',
  filters,
  IncludedResources,
  SkipItemFromSearch,
}: ResourceListProps) => {
  const [resourcesData, setResourcesData] = useState<{
    ResourceList: Array<EntityModel>;
    fetchCount: number;
  }>({ ResourceList: [], fetchCount: 1 });

  const searchSource = process.env.RESOURCES_SEARCH_SOURCE_ID;
  const searchSourceIds = searchSource?.split('|') || [];
  const pathName = usePathname();

  const {
    sanitizedResults,
    widgetRef,
    query,
    queryResult: {
      isInitialLoading,
      isFetched,
      data: { total_item = 0, limit = 0, offset = 0 } = {},
    },
  } = useSanitizedSearchResults({
    query: (query) => {
      filters
        ? query
            .getRequest()
            .setSearchFacetAll(false)
            .setSources(searchSourceIds)
            .setSearchFilter(filters)
        : query
            .getRequest()
            .setSearchFacetAll(false)
            .setSources(searchSourceIds)
            .setSearchOffset(SkipItemFromSearch);
    },
    state: {
      itemsPerPage: Number(initialCount),
      page: 1,
      sortType: 'publish_desc',
    },
  });

  useEffect(() => {
    !isInitialLoading &&
      sanitizedResults?.content &&
      setResourcesData({
        fetchCount: resourcesData.fetchCount + 1,
        ResourceList: [...resourcesData.ResourceList, ...sanitizedResults?.content],
      });
    // We only need to care about resources and offset as a dependency
    // because load more pagination requires new results to be loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanitizedResults?.content, pathName]);

  const loadMoreResults = () => {
    const _loadCount = Number(verticlePaginationOptions?.loadCount);

    if (resourcesData.fetchCount >= 1) {
      query
        .getRequest()
        .setSearchLimit(_loadCount)
        .setSearchOffset(resourcesData.ResourceList.length + SkipItemFromSearch);
    }
  };

  return (
    <section className="component container spacing-md" ref={widgetRef}>
      <div className="component-content">
        <Text
          tag={title?.tag}
          field={{ value: title?.text }}
          className={classNames('mb-4 text-color-default-1', title?.classes)}
        />

        {!IncludedResources && total_item === 0 && isFetched && (
          <Text tag="p" field={{ value: noResultsText }} />
        )}

        {IncludedResources && IncludedResources.length <= 0 && total_item === 0 && isFetched && (
          <Text tag="p" field={{ value: noResultsText }} />
        )}

        <ul className="flex flex-wrap sm:flex-row gap-4">
          {IncludedResources?.map((resource: ResourceProps, index) => (
            <li
              className="text-color-default-1 basis-full sm:basis-[calc(100%_/_2_-_16px_/_2_*_(2_-_1))] lg:basis-[calc(100%_/_3_-_16px_/_3_*_(3_-_1))]"
              key={index}
            >
              <ResourceCard
                {...resource}
                isCategoryHidden={isCategoryHidden}
                ribbonLabel={ribbonLabel}
              />
            </li>
          ))}
          {resourcesData.ResourceList.map((resource: ResourceProps, index) => (
            <li
              className="text-color-default-1 basis-full sm:basis-[calc(100%_/_2_-_16px_/_2_*_(2_-_1))] lg:basis-[calc(100%_/_3_-_16px_/_3_*_(3_-_1))]"
              key={index}
            >
              <ResourceCard
                {...resource}
                isCategoryHidden={isCategoryHidden}
                ribbonLabel={ribbonLabel}
              />
            </li>
          ))}
        </ul>

        {verticlePaginationOptions && (
          <div className="flex items-center justify-center mt-6 sm:mt-8 lg:mt-[23px] [&_button]:!rounded-[4px] [&_button]:!w-auto ">
            <Button
              disabled={limit + offset === total_item}
              label={verticlePaginationOptions.loadMoreText}
              type="auxiliary"
              onClick={loadMoreResults}
              onKeyDown={(e) => e.key === 'Enter' && loadMoreResults()}
            />
          </div>
        )}
      </div>
    </section>
  );
};

// region Resource Card component
export const ResourceCard = ({
  isCategoryHidden,
  ribbonLabel,
  category_color,
  url,
  image_url,
  media_url,
  category,
  name,
  type,
  description,
  publish_date,
  article_time,
}: ResourceProps) => {
  const card = tv({
    slots: {
      base: [
        'flex flex-col items-start h-full w-full bg-background-default-2',
        'rounded border border-default-3 overflow-hidden',
        'focus:border-button-secondary-focus focus-visible:outline-none',
        'hover:border-icon-toggle-selected-disabled hover:shadow-[0px_2px_40px_0px_rgba(57,100,191,0.15)] hover:no-underline text-inherit hover:text-inherit focus:no-underline',
      ],
      imageContainer: ['w-full min-h-[200px] relative'],
      playIconWrapper:
        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16 rounded-full border-4 border-white z-10',
      playIcon:
        'absolute inline-block w-5 h-5 text-color-inverse top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
      image: 'w-full max-h-[200px] h-full object-cover object-center aspect-[356/200]',
      content: 'flex flex-col justify-between p-4 w-full h-full',
      categoryStripe: 'min-h-2 w-full absolute bottom-0 left-0 z-10',
      category: 'mb-1 caption text-color-default-1',
      title: 'mb-3 lg:mb-4.5',
      description: 'mb-4 lg:mb-6 text-color-default-2 paragraph-3-regular',
      metaDetails: 'flex items-center justify-between caption',
      arrowIcon: 'w-[18px] h-[18px] lg:w-[22px] lg:h-[22px] text-color-active',
    },
    variants: {
      categoryType: {
        'category-1': {
          categoryStripe: 'bg-background-category-1',
        },
        'category-2': {
          categoryStripe: 'bg-background-category-2',
        },
        'category-3': {
          categoryStripe: 'bg-background-category-3',
        },
        'category-4': {
          categoryStripe: 'bg-background-category-4',
        },
      },
    },
  });

  const {
    base,
    imageContainer,
    image,
    categoryStripe,
    content,
    category: categoryClasses,
    title,
    description: descriptionClasses,
    metaDetails,
    playIconWrapper,
    playIcon,
    arrowIcon,
  } = card(category_color && { categoryType: category_color?.[0] });

  return (
    <LinkWrapper tabIndex={0} field={{ value: { href: url } }} className={base()}>
      <div
        className={classNames(
          imageContainer(),
          type === 'VideoResource' &&
            'after:absolute after:bg-[#000] after:opacity-50 after:w-full after:h-full after:top-0 after:left-0'
        )}
      >
        <ImageWrapper
          field={{
            value: { src: type === 'Article' ? image_url : getThumnailUrl(media_url as string) },
          }}
          className={image()}
          width="325"
          height="192"
        />
        {type === 'VideoResource' && (
          <div className={playIconWrapper()}>
            <SVG svg="icon-play" hidden={false} className={playIcon()} />
          </div>
        )}

        {ribbonLabel ? (
          <Text
            field={ribbonLabel}
            tag="span"
            className="absolute inline-block bottom-0 left-0 py-1 px-3 bg-background-brand text-white text-sm"
          />
        ) : (
          <div className={categoryStripe()}></div>
        )}
      </div>
      {/* Category colored stripe */}

      <div className={content()}>
        <div>
          {!isCategoryHidden && category && category?.length > 0 && (
            <p className={categoryClasses()}>{category[0]}</p>
          )}
          <RichTextA11yWrapper className={title()} tag="h4" field={{ value: name }} />
          <RichTextA11yWrapper className={descriptionClasses()} field={{ value: description }} />
        </div>
        <div className={metaDetails()}>
          <p className="h-full flex items-center justify-center">
            {publish_date && formatTimestamp(publish_date, 'Month DD, YYYY')}
            <span className="inline-block mx-2 w-[1px] h-[calc(100%_-_8px)] bg-strokes-default-1" />
            {article_time}
          </p>
          <SVG svg="icon-arrow-right" hidden={false} className={arrowIcon()}></SVG>
        </div>
      </div>
    </LinkWrapper>
  );
};
//#endregion

export const ResourceListWidget = widget(ResourceList, WidgetDataType.SEARCH_RESULTS, 'content');
