import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

interface AppBannerFields {
  AndroidAppImage: { value: { src: string; alt: string; width: string; height: string } };
  AndroidAppUrl: { value: { url: string } };
  Title: { value: string };
  Author: { value: string };
  Price: { value: string };
  AndroidPriceSuffix?: { value: string };
  ButtonText: { value: string };
}

interface RouteFields {
  MetaMarkup?: { value: string };
}

interface SitecoreRoute {
  fields: RouteFields;
}

interface SitecoreContext {
  route?: SitecoreRoute;
}

interface AppBannerProps {
  fields: AppBannerFields;
  sitecoreContext?: { sitecoreContext?: SitecoreContext };
}

const AppBanner: React.FC<AppBannerProps> = ({ fields }) => {
  const { AndroidAppImage, AndroidAppUrl, Title, Author, Price, AndroidPriceSuffix, ButtonText } =
    fields;

  const [isBannerVisible, setBannerVisible] = useState<boolean>(true);
  const [deviceType, setDeviceType] = useState<string | null>(null);
  useEffect(() => {
    const detectDevice = (): string => {
      if (typeof navigator !== 'undefined') {
        const userAgent = navigator.userAgent;
        if (/Android/.test(userAgent)) {
          return 'Android';
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
          return 'iOS';
        } else {
          return 'Web';
        }
      }
      return 'Web';
    };

    const currentDeviceType = detectDevice();
    setDeviceType(currentDeviceType);

    // Check cookie for banner visibility
    const appBannerClosed = Cookies.get('appBannerClosed');
    if (appBannerClosed) {
      setBannerVisible(false);
    }

    // SmartBanner for Android
    if (currentDeviceType === 'Android') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import('smartbanner.js')
        .then((SmartBannerModule) => {
          const SmartBanner = SmartBannerModule.default || SmartBannerModule.SmartBanner;
          const options = {
            daysHidden: 0,
            daysReminder: 30,
            title: Title?.value || '',
            author: Author?.value || '',
            price: Price?.value || '',
            icon: AndroidAppImage?.value?.src || '',
            button: ButtonText?.value || '',
            buttonUrl: AndroidAppUrl?.value?.url || '',
            force: 'android',
          };

          if (SmartBanner) {
            new SmartBanner(options);
          } else {
            console.error('SmartBanner not found in the module');
          }
        })
        .catch((err) => console.error('Failed to load SmartBanner:', err));
    }

    // Only apply margin-top for Android when the banner is visible
    if (currentDeviceType === 'Android' && isBannerVisible) {
      document.body.style.marginTop = '84px';
    } else {
      document.body.style.marginTop = '0px';
    }

    // Clean up function
    return () => {
      document.body.style.marginTop = '0px';
    };
  }, [
    Title,
    Author,
    Price,
    AndroidAppImage,
    ButtonText,
    AndroidAppUrl,
    deviceType,
    isBannerVisible,
  ]);

  const handleCloseBanner = () => {
    setBannerVisible(false);
    document.body.style.marginTop = '0px';
    try {
      Cookies.set('appBannerClosed', 'true', { expires: 1 });
    } catch (err) {
      console.warn('Cookie storage failed, using session storage instead.');
    }
  };

  if (!deviceType) return null;

  return (
    <>
      {/* Android app banner */}
      {deviceType === 'Android' && isBannerVisible && (
        <div
          className="smartbanner smartbanner--android bg-background-default-2 flex items-center w-full"
          style={{ top: isBannerVisible ? '0px' : '-999px' }}
        >
          <a href="#" className="ml-2 mr-2 cursor-pointer" onClick={handleCloseBanner}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="9"
              viewBox="0 0 10 9"
              fill="none"
            >
              <path
                d="M9 0.556101L1 8.45427M8.9828 8.5L1.11529 0.5"
                stroke="#BBBBC0"
                stroke-linecap="round"
              />
            </svg>
          </a>

          <div className="flex flex-row w-full items-center">
            <ImageWrapper
              field={AndroidAppImage}
              width="64"
              height="64"
              className="w-16 h-16 mr-2"
            />
            <div className="flex flex-col">
              <div className="text-color-default-1 paragraph-1-regular">{Title?.value}</div>
              <div className="text-color-default-2 caption">{Author?.value}</div>
              <div className="text-color-default-2 caption">
                {Price?.value}
                {AndroidPriceSuffix?.value}
              </div>
            </div>
            <a
              href={AndroidAppUrl?.value?.url}
              className="smartbanner__button rounded text-center px-4 py-2 ml-auto mr-5 text-color-inverse hover:text-color-inverse"
            >
              {ButtonText?.value}
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default AppBanner;
