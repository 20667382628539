import { DictionaryPhrases } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';

export const useDictionary = () => {
  const i18n = useI18n<DictionaryPhrases>();
  if (process.env.IS_STORYBOOK) {
    return {};
  }
  const table = i18n.table('en');
  return table ?? ({} as DictionaryPhrases);
};
