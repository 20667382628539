//Global
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { formatTimestamp } from 'lib/utils/date-utils';

export type LongArticleProps = {
  PublishDate: Field<string>;
  ReadTime: Field<string>;
};

export type ArticalProps = ComponentProps;

const LongArticle = (props: ArticalProps) => {
  const articlesharebar = `articlesharebar`;
  const articleaside = `articleaside`;
  const articlecontent = `articlecontent`;
  const articlefooter = `articlefooter`;

  const { sitecoreContext } = useSitecoreContext();

  const { PublishDate, ReadTime } = sitecoreContext?.route?.fields as LongArticleProps;

  return (
    <section className="container">
      <div className="relative mt-4 md:mt-6 mb-4 md:mb-6 lg:mb-0 flex lg:gap-x-14">
        <div className="relative">
          <span className="relative text-color-default-1 caption after:absolute after:-right-2 after:h-[15px] after:top-1/2 after:-translate-y-1/2 after:w-[1px] after:bg-strokes-default-1">
            {formatTimestamp(PublishDate?.value, 'Month DD, YYYY')}
          </span>
          <span className="relative text-color-default-1 caption ml-4">{ReadTime?.value}</span>
        </div>
        {props.rendering && (
          <div className="hidden lg:inline-block">
            <Placeholder name={articlesharebar} rendering={props?.rendering} />
          </div>
        )}
      </div>
      {props.rendering && (
        <div className="block lg:hidden">
          <Placeholder name={articlesharebar} rendering={props?.rendering} />
        </div>
      )}
      <div className="mt-6 md:mt-10">
        <div className="flex lg:gap-10">
          <div className="left-section">
            {props?.rendering && <Placeholder name={articleaside} rendering={props?.rendering} />}
          </div>
          <div className="right-section lg:w-auto lg:max-w-[712px]">
            {props?.rendering && <Placeholder name={articlecontent} rendering={props?.rendering} />}
          </div>
        </div>
        {props?.rendering && <Placeholder name={articlefooter} rendering={props?.rendering} />}
      </div>
    </section>
  );
};

export default LongArticle;
