import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SearchOverlayContextType {
  overlayOpen: boolean;
  setOverlayOpen: (value: boolean) => void;
}

const SearchOverlayContext = createContext<SearchOverlayContextType>({
  overlayOpen: false,
  setOverlayOpen: () => {
    return;
  },
});

export function useSearchOverlayContext() {
  const context = useContext(SearchOverlayContext);
  return context;
}

interface SearchOverlayContextProviderProps {
  children: ReactNode;
}

export function SearchOverlayContextProvider({ children }: SearchOverlayContextProviderProps) {
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);

  return (
    <SearchOverlayContext.Provider value={{ overlayOpen, setOverlayOpen }}>
      {children}
    </SearchOverlayContext.Provider>
  );
}
