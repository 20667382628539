import React from 'react';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Text, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

type FullWidthCardProps = Marketing.DataTemplates.Card;

const FullWidthCard = (props: FullWidthCardProps): JSX.Element => {
  const { Title, TitleLink, CardImage, Subtitle, CardContent } = props.fields || {};

  const hasValidLink = TitleLink && TitleLink.value && TitleLink.value.href;

  return (
    <section className="container spacing-md">
      <div className="bg-background-default-2 flex flex-col md:flex-row gap-y-6 md:gap-x-6 lg:gap-x-8 p-6 border border-solid border-strokes-default-3">
        {CardImage?.value?.src && (
          <div className="md:w-1/4">
            <ImageWrapper field={CardImage} className="w-full h-full object-cover"></ImageWrapper>
          </div>
        )}
        <div
          className={`w-full text-center md:text-left flex flex-col gap-y-4 ${
            !CardImage?.value?.src ? '' : 'md:w-3/4'
          }`}
        >
          <div className="flex flex-col">
            {hasValidLink ? (
              <LinkWrapper field={TitleLink as LinkField} className="link">
                <Text field={Title} tag="span" className="paragraph-2-medium" />
              </LinkWrapper>
            ) : (
              <Text field={Title} tag="p" className="paragraph-2-medium text-color-default-1" />
            )}
            <Text field={Subtitle} tag="p" className="caption text-color-default-1" />
          </div>
          <RichTextA11yWrapper
            field={CardContent}
            tag="div"
            className="rtaw paragraph-2-regular text-color-default-2"
          />
        </div>
      </div>
    </section>
  );
};

export default FullWidthCard;
