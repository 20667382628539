import { Field, Item, useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  FilterAnyOf,
  FilterAnd,
  EntityModel,
  useSearchResults,
  WidgetDataType,
  widget,
} from '@sitecore-search/react';
import classNames from 'classnames';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import { ComponentProps } from 'lib/component-props';
import React, { useEffect, useState } from 'react';
import SVG from 'helpers/SVG/SVG';
import Button from 'helpers/Button/Button';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';
import { tv } from 'tailwind-variants';
import { SitecoreIds } from 'lib/constants/sitecore-ids';
import { ResourceCard, ResourceProps } from 'widgets/ResourceListWidget/ResourceListWidget';

type PageTypes = 'Category';

type TopicItemProp = Item & {
  fields?: {
    Name?: Field<string>;
  };
};

export type TrendingTopicsProps = Partial<ComponentProps> & {
  fields?: {
    TrendingTopicsList?: Array<Item & TopicItemProp>;
    AllTopicsText?: Field<string>;
    TopicsCount?: Field<number>;
    InitialCountDesktop?: Field<number>;
    LoadMoreCount?: Field<number>;
    InitialCountMobile?: Field<number>;
    NoResultsText?: Field<string>;
    LoadMoreText?: Field<string>;
  };
};

export const SlideShowstyle = tv({
  slots: {
    ArrowButtonWrapperStyle: [
      'absolute top-1/2 -translate-y-1/2 after:bg-gradient-to-r after:absolute after:w-full after:h-full after:top-0 after:z-[-1] after:pointer-events-none',
    ],
    ArrowButtonStyle: [
      'w-8 h-8 flex justify-center items-center rounded-[4px] outline-2 outline outline-background-default-1 hover:outline-strokes-action active:bg-background-dark-1 active:outline-background-dark-1 bg-background-default-1 disabled:hidden',
    ],
    TabsContainerStyle: [
      'flex gap-2 overflow-auto no-scrollbar border-b border-b-strokes-default-3 scrollbar-none',
    ],
    ArticleContainerStyle: ['flex flex-wrap sm:flex-row gap-4'],
    ArticleWrapperStyle: [
      'text-color-default-1 basis-full sm:basis-[calc(100%_/_2_-_16px_/_2_*_(2_-_1))] lg:basis-[calc(100%_/_3_-_16px_/_3_*_(3_-_1))]',
    ],
    ButtonContainerStyle: [
      'flex items-center justify-center mt-6 sm:mt-8 lg:mt-[23px] [&_button]:!rounded-[4px] [&_button]:!w-auto',
    ],
  },
});

const TrendingTopics = (props: TrendingTopicsProps) => {
  const { currentScreenWidth, isScreenTypeDetected } = useCurrentScreenType();
  if (!isScreenTypeDetected) return <></>;

  return (
    <WidgetWrapper>
      <TrendingWidget
        props={props}
        rfkId="articles"
        InitialCount={
          (currentScreenWidth <= getBreakpoint('desktop')
            ? props?.fields?.InitialCountMobile?.value
            : props?.fields?.InitialCountDesktop?.value) as number
        }
        currentScreenWidth={currentScreenWidth}
      />
    </WidgetWrapper>
  );
};

const TrendingSearchTabSearch = ({
  props,
  InitialCount,
  currentScreenWidth,
}: {
  props: TrendingTopicsProps;
  InitialCount: number;
  currentScreenWidth: number;
}) => {
  const { fields = undefined } = props;
  const { ArticleContainerStyle, ArticleWrapperStyle, ButtonContainerStyle } = SlideShowstyle();

  const { sitecoreContext } = useSitecoreContext();
  const { PageTitle } = sitecoreContext?.route?.fields as { PageTitle?: Field<string> | undefined };

  const pageType: PageTypes | undefined = (() => {
    for (const [, { templateId, templateName }] of Object.entries(
      SitecoreIds.FeaturedResource.Route
    )) {
      if (templateId === sitecoreContext.route?.templateId) {
        return templateName;
      }
    }
    return undefined;
  })() as PageTypes;

  const searchSource = process.env.SEARCH_SOURCE_ID;
  const searchSourceIds = searchSource?.split('|') || [];

  // Tab Related States
  const [activeTopic, setActiveTopic] = React.useState<string>(
    fields?.AllTopicsText?.value.toLowerCase() as string
  );

  // Search Related States
  const [articlesData, setArticlesData] = useState<{
    articleList: Array<EntityModel>;
    fetchCount: number;
  }>({ articleList: [], fetchCount: 1 });

  // Function to handle Tab Click
  const handleTopicClick = (topic: string, isKey?: boolean) => {
    if ((topic && topic?.toLowerCase() !== activeTopic) || (topic && isKey)) {
      setActiveTopic(topic?.toLowerCase());
      setArticlesData({
        fetchCount: 1,
        articleList: [],
      });

      if (topic?.toLowerCase() !== fields?.AllTopicsText?.value.toLowerCase()) {
        const filterTopic: FilterAnyOf[] = [];
        if (PageTitle && pageType) {
          filterTopic.push(new FilterAnyOf('category', [PageTitle?.value]));
        }
        filterTopic.push(new FilterAnyOf('topics', [topic]));
        query
          .getRequest()
          .setSearchFacetAll(false)
          .setSources(searchSourceIds)
          .setSearchLimit(
            (currentScreenWidth <= getBreakpoint('desktop')
              ? props?.fields?.InitialCountMobile?.value
              : props?.fields?.InitialCountDesktop?.value) as number
          )
          .setSearchFilter(new FilterAnd(filterTopic))
          .setSearchOffset(0);
      } else {
        query
          .getRequest()
          .setSearchFacetAll(false)
          .setSources(searchSourceIds)
          .setSearchLimit(
            (currentScreenWidth <= getBreakpoint('desktop')
              ? props?.fields?.InitialCountMobile?.value
              : props?.fields?.InitialCountDesktop?.value) as number
          )
          .setSearchFilter(new FilterAnd(filters))
          .setSearchOffset(
            (props?.params?.SkipItemFromSearch && +props?.params?.SkipItemFromSearch) || 0
          );
      }
    }
  };

  // Function to handle the Load more more Result
  const loadMoreResults = () => {
    const _loadCount = Number(loadMoreOptions?.loadCount);
    let skipItemFromSearch = 0;
    if (activeTopic === fields?.AllTopicsText?.value.toLowerCase()) {
      skipItemFromSearch =
        (props?.params?.SkipItemFromSearch && +props?.params?.SkipItemFromSearch) || 0;
    }

    if (articlesData.fetchCount >= 1) {
      query
        .getRequest()
        .setSearchLimit(_loadCount)
        .setSearchFacetAll(false)
        .setSources(searchSourceIds)
        .setSearchOffset(articlesData.articleList.length + skipItemFromSearch);
    }
  };

  // Query Array Object
  const filters: FilterAnyOf[] = [];

  const loadMoreOptions = {
    loadMoreText: fields?.LoadMoreText?.value as string,
    loadCount: String(fields?.LoadMoreCount?.value),
  };

  // Filters
  if (PageTitle && pageType) {
    filters.push(new FilterAnyOf('category', [PageTitle.value]));
  }

  if (fields?.TrendingTopicsList && fields?.TrendingTopicsList.length > 0) {
    const topicsItems = fields?.TrendingTopicsList?.map((item) => item.name);
    filters.push(new FilterAnyOf('topics', topicsItems));
  }
  const {
    widgetRef,
    query,
    queryResult: {
      isInitialLoading,
      isFetched,
      data: { total_item = 0, limit = 0, offset = 0, content: articles = [] } = {},
    },
  } = useSearchResults({
    query: (query) => {
      filters
        ? query
            .getRequest()
            .setSearchFacetAll(false)
            .setSources(searchSourceIds)
            .setSearchFilter(new FilterAnd(filters))
            .setSearchOffset(
              (props?.params?.SkipItemFromSearch && +props?.params?.SkipItemFromSearch) || 0
            )
        : query.getRequest().setSearchFacetAll(false).setSources(searchSourceIds);
    },
    state: {
      itemsPerPage: Number(InitialCount),
      page: 1,
    },
  });

  useEffect(() => {
    !isInitialLoading &&
      articles.length > 0 &&
      setArticlesData({
        fetchCount: articlesData.fetchCount + 1,
        articleList: [...articlesData.articleList, ...articles],
      });
    // We only need to care about articles and offset as a dependency
    // because load more pagination requires new results to be loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, articles]);

  return (
    <section className="spacing-md container-wide">
      <div className="container-inner">
        <TopicsTab tabHandler={handleTopicClick} props={props} activeTopic={activeTopic} />
        <div ref={widgetRef}>
          {total_item === 0 && isFetched && <Text tag="p" field={fields?.NoResultsText} />}

          <ul className={ArticleContainerStyle()}>
            {articlesData.articleList.map((article: ResourceProps, index) => {
              const updatedArticle = {
                ...article,
                category: [],
              };
              return (
                <li className={ArticleWrapperStyle()} key={index}>
                  <ResourceCard {...updatedArticle} />
                </li>
              );
            })}
          </ul>

          {loadMoreOptions && (
            <div className={ButtonContainerStyle()}>
              <Button
                disabled={limit + offset === total_item}
                label={loadMoreOptions.loadMoreText}
                type="auxiliary"
                onClick={loadMoreResults}
                onKeyDown={(e) => e.key === 'Enter' && loadMoreResults()}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const TopicsTab = ({
  tabHandler,
  props,
  activeTopic,
}: {
  tabHandler: (topic: string, isKey?: boolean) => void;
  props: TrendingTopicsProps;
  activeTopic: string;
}) => {
  const { fields = undefined } = props;

  const { ArrowButtonWrapperStyle, ArrowButtonStyle, TabsContainerStyle } = SlideShowstyle();

  // Tab Container
  const containerRef = React.useRef<HTMLUListElement | null>(null);
  const [disabled, setDisabled] = React.useState<{ leftArrow: boolean; rightArrow: boolean }>();

  // Scroll and Drag related States
  const [isDown, setIsDown] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [scrollLeft, setScrollLeft] = React.useState(0);

  const mouseIsDown = (e: React.MouseEvent) => {
    if (!containerRef.current) return;

    setIsDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const mouseUpOrLeave = () => {
    setIsDown(false);
  };

  const mouseMove = (e: React.MouseEvent) => {
    if (!isDown || !containerRef.current) return;

    e.preventDefault();

    // Move horizontally
    const x = e.pageX - containerRef.current.offsetLeft;
    const walkX = x - startX;
    containerRef.current.scrollLeft = scrollLeft - walkX;
  };

  // Function to handle Left Button Click in Tab element
  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  // Function to handle Right Button Click in Tab element
  const handleScrolllRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  // Function to handle the scroll event on the container element
  const handleScroll = () => {
    const element = containerRef.current;
    if (
      element &&
      disabled &&
      element.scrollLeft + element.clientWidth >= element.scrollWidth - 1
    ) {
      setDisabled({ leftArrow: false, rightArrow: true });
    } else if (element && disabled && element.scrollLeft <= 0) {
      setDisabled({ rightArrow: false, leftArrow: true });
    } else {
      setDisabled({ rightArrow: false, leftArrow: false });
    }
  };

  React.useEffect(() => {
    const handleResize = () => {
      setDisabled(
        containerRef.current &&
          containerRef.current?.clientWidth >= containerRef.current?.scrollWidth
          ? {
              leftArrow: true,
              rightArrow: true,
            }
          : {
              leftArrow: true,
              rightArrow: false,
            }
      );
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle keyboard controls
  React.useEffect(() => {
    const handleKeyboard = (e: KeyboardEvent) => {
      try {
        const elemnet = document.activeElement?.closest('li');
        if (e.key === 'ArrowLeft' && containerRef?.current) {
          tabHandler(elemnet?.previousSibling?.textContent as string, true);
          (elemnet?.previousSibling?.childNodes[0] as HTMLElement).focus();
        } else if (e.key === 'ArrowRight' && containerRef?.current) {
          tabHandler(elemnet?.nextSibling?.textContent as string, true);
          (elemnet?.nextSibling?.childNodes[0] as HTMLElement).focus();
        }
      } catch (error) {
        (document.activeElement as HTMLElement)?.blur();
      }
    };

    document.addEventListener('keydown', handleKeyboard);
    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    };
  }, []);

  return (
    <div className="relative mb-8 p-1 grid">
      <div
        className={classNames(
          ArrowButtonWrapperStyle(),
          'left-0 after:from-background-default-1 after:to-transparent after:left-full'
        )}
      >
        <button
          className={ArrowButtonStyle()}
          onClick={handleScrollLeft}
          disabled={disabled?.leftArrow}
        >
          <SVG
            svg="icon-carousel-arrow-left"
            className="w-5 h-5 block [&>svg]:w-full [&>svg]:h-full"
          />
        </button>
      </div>
      <ul
        ref={containerRef}
        onMouseDown={mouseIsDown}
        onMouseUp={mouseUpOrLeave}
        onMouseLeave={mouseUpOrLeave}
        onMouseMove={mouseMove}
        onScroll={handleScroll}
        className={TabsContainerStyle()}
      >
        <li>
          <button
            onClick={() => tabHandler(fields?.AllTopicsText?.value as string)}
            className={classNames(
              'px-6 py-1 whitespace-nowrap border-b-[3px] hover:font-medium hover:text-color-default-1 paragraph-2-regular',
              activeTopic === fields?.AllTopicsText?.value.toLowerCase()
                ? 'text-color-default-1 font-medium border-background-brand'
                : 'text-color-default-2 border-transparent'
            )}
          >
            {fields?.AllTopicsText?.value}
          </button>
        </li>
        {fields?.TrendingTopicsList?.slice(0, fields.TopicsCount?.value).map((topics, index) => (
          <li key={index}>
            <button
              onClick={() => tabHandler(topics?.fields?.Name?.value as string)}
              className={classNames(
                'px-6 py-1 whitespace-nowrap border-b-[3px] hover:font-medium hover:text-color-default-1 paragraph-2-regular',
                activeTopic === topics?.fields?.Name?.value.toLowerCase()
                  ? 'text-color-default-1 font-medium border-background-brand'
                  : 'text-color-default-2 border-transparent'
              )}
            >
              {topics?.fields?.Name?.value}
            </button>
          </li>
        ))}
      </ul>
      <div
        className={classNames(
          ArrowButtonWrapperStyle(),
          'right-0 after:from-transparent after:to-background-default-1 after:right-full'
        )}
      >
        <button
          className={ArrowButtonStyle()}
          onClick={handleScrolllRight}
          disabled={disabled?.rightArrow}
        >
          <SVG
            svg="icon-carousel-arrow-right"
            className="w-5 h-5 block [&>svg]:w-full [&>svg]:h-full"
          />
        </button>
      </div>
    </div>
  );
};

export const TrendingWidget = widget(
  TrendingSearchTabSearch,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

export default TrendingTopics;
