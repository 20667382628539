// Global
import React, { useState, useEffect } from 'react';
//import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { BoK } from 'lib/templates/Feature.BOKF.model';
import { ComponentProps } from 'lib/component-props';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import shuffle from 'lodash/shuffle';

export type StaticAdProps = { fields: { items: BoK.Content.StaticAd[] } };
export type PromoBoxProps = StaticAdProps & ComponentProps;

export const PromoBox = (props: PromoBoxProps): JSX.Element => {
  const staticAd = props?.fields?.items || [];

  const numberToDisplay = Number(props?.params[`Number to Display`]) || 3;
  const randomOrder = Boolean(!!+props?.params[`Random Order`]) || false;

  const staticAdsOriginal = staticAd.slice(0, numberToDisplay);
  const [staticAds, setStaticAds] = useState<BoK.Content.StaticAd[]>(staticAdsOriginal);
  const [hasShuffled, setHasShuffled] = useState(false);

  useEffect(() => {
    if (randomOrder && !hasShuffled) {
      const newStaticAds = shuffle(staticAds);
      setStaticAds(newStaticAds);
      setHasShuffled(true);
    }
  }, [randomOrder, hasShuffled, staticAds]);
  if (!props?.fields) {
    return <></>;
  }

  const gridColumnNumber = () => {
    if (numberToDisplay === 1) {
      return 'grid-cols-1 md:grid-cols-1 lg:grid-cols-1';
    } else if (numberToDisplay === 2) {
      return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-2';
    } else {
      return 'grid-cols-1 md:grid-cols-3 lg:grid-cols-3';
    }
  };

  return (
    <section className="component promo-box container spacing-md">
      <div className={`grid ${gridColumnNumber()} w-full gap-6 py-6 lg:py-8`}>
        {staticAds?.map((item, key) => (
          <LinkWrapper
            field={{ value: { href: item?.fields?.Link?.value?.href } }}
            className="grid grid-cols-[max-content_1fr] h-full shadow-small hover:shadow-hover focus:outline focus:outline-2 focus:outline-strokes-action focus:outline-offset-[-2px] hover:no-underline"
            key={key}
          >
            {item?.fields?.Image && (
              <ImageWrapper
                key={key}
                field={item?.fields?.Image}
                className="max-w-[86px] md:max-w-[76px] lg:max-w-[120px] h-full object-cover"
                height="120"
                width="120"
              />
            )}
            {item?.fields?.Title && (
              <RichTextA11yWrapper
                field={item?.fields?.Title}
                className="flex items-center paragraph-2-medium ml-4 pr-4 py-4 text-color-default-1"
              />
            )}
          </LinkWrapper>
        ))}
      </div>
    </section>
  );
};

export default PromoBox;
