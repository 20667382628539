import React from 'react';
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

type LinkListCollectionProps = {
  fields: {
    Title: {
      value: string;
    };
    children: {
      id: string;
      displayName: string;
      fields: {
        Link: LinkField;
      };
    }[];
  };
};

const LinkListCollection = (props: LinkListCollectionProps): JSX.Element => {
  const { fields } = props;
  if (!fields) {
    return <></>;
  }

  return (
    <div>
      <Text
        className="pb-4 border-solid border-b border-b-strokes-default-3"
        field={fields?.Title}
        tag="h4"
      />
      <ul className="mt-4 space-y-4 lg:space-y-3">
        {fields?.children?.map((child) => (
          <li key={child.id}>
            <LinkWrapper
              field={child?.fields?.Link as LinkField}
              className="text-color-active paragraph-2-medium"
            ></LinkWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default LinkListCollection;
