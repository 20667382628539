// Global
import React from 'react';
import { Field, GetStaticComponentProps, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import clientFactory from 'lib/graphql-client-factory';

// Local
import FooterSocialNavigationQuery from './FooterSocialNavigation.graphql';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

export type FooterSocialNavigationProps = {
  staticProps?: {
    footerSocialNavigation?: {
      children: {
        results: Array<{
          link: {
            jsonValue: LinkField;
          };
          socialIcon?: {
            jsonValue: {
              fields: {
                Value: {
                  value: string;
                };
              };
            };
          };
          title?: {
            jsonValue: Field<string>;
          };
        }>;
      };
    };
  };
};

export const FooterSocialNavigation = (props: FooterSocialNavigationProps): JSX.Element => {
  const children = props?.staticProps?.footerSocialNavigation?.children.results;

  return (
    <div className="flex flex-row -ml-2 flex-wrap">
      {children?.map((item, key) => {
        return (
          <LinkWrapper
            key={key}
            field={item?.link?.jsonValue}
            title={item.title?.jsonValue?.value}
            className="flex items-center p-2 rounded-md text-color-default-1 hover:bg-icon-background-hover-1 hover:text-color-active last:m-0"
          >
            <SVG svg={item.socialIcon?.jsonValue?.fields?.Value?.value || ''} hidden={false}></SVG>
          </LinkWrapper>
        );
      })}
    </div>
  );
};

export default FooterSocialNavigation;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<FooterSocialNavigationProps>(
    FooterSocialNavigationQuery,
    {
      datasource: rendering.dataSource,
      language: layoutData?.sitecore?.context?.language,
    }
  );
  return {
    staticProps: result,
  };
};
