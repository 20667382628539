import React, { useEffect, useMemo } from 'react';
import Head from 'next/head';
import {
  Placeholder,
  LayoutServiceData,
  Field,
  HTMLLink,
  ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Scripts from 'src/Scripts';
import { roboto } from 'lib/fonts';
import { useNavContext } from 'lib/react-context/NavContext';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { SearchOverlayContextProvider } from 'helpers/Providers/SearchOverlayContextProvider';
import SpeedBumpModal from 'helpers/SpeedBumpModal/SpeedBumpModal';
import { LoginContextProvider } from 'helpers/Providers/LoginContextProvider';
import { useRouter } from 'next/router';
import { SitecoreIds } from 'lib/constants/sitecore-ids';

type PageTypes = 'Article';

interface LayoutProps {
  layoutData: LayoutServiceData;
  headLinks: HTMLLink[];
}

interface MetaMarkupField extends Field {
  value: string;
}

interface RouteFields {
  SEOPageTitle?: Field<string>;
  PageTitle?: Field<string>;
  MetaMarkup?: MetaMarkupField;
  MetaStructuredData?: Field<string>;
  MetaDescription?: Field<string>;
  MetaOGDescription?: Field<string>;
  MetaKeywords?: Field<string>;
  MetaOGImage?: ImageField;
}

interface ContextFields {
  DiscludeStructuredMarkup?: Field<string>;
  SpanishPath?: Field<string>;
}

const Layout = ({ layoutData, headLinks }: LayoutProps): JSX.Element => {
  const urlrouter = useRouter();
  const [currentUrl, setCurrentUrl] = React.useState({
    origin: '',
    pathArray: '',
  });

  const { route, context } = layoutData.sitecore;
  const fields = route?.fields as RouteFields;
  const isPageEditing = layoutData.sitecore.context.pageEditing;
  const mainClassPageEditing = isPageEditing ? 'editing-mode' : 'prod-mode';

  const { DiscludeStructuredMarkup = undefined, SpanishPath = undefined } =
    context as ContextFields;

  const navContext = useNavContext();
  const { sitecoreContext } = useSitecoreContext();

  let leftNavExists: boolean | undefined;
  useEffect(() => {
    leftNavExists = document.getElementById('sidebar-navigation')?.hasChildNodes();

    if (urlrouter) {
      const pathArray = urlrouter.asPath.split('?')[0].split('#')[0];
      setCurrentUrl({
        origin: window.location.origin,
        pathArray: pathArray,
      });
    }
  }, []);

  // Metadata MetaMarkup string
  const metaData = fields?.MetaMarkup?.value || '';
  // Function to manually parse the metadata string
  const parseMetaData = (metaString: string) => {
    const metaRegex = /<meta\s+name="([^"]+)"\s+content="([^"]+)"\s*\/?>/g;
    let match;
    const metaTags = [];
    while ((match = metaRegex.exec(metaString)) !== null) {
      metaTags.push({
        name: match[1],
        content: match[2],
      });
    }
    return metaTags;
  };

  const pageType: PageTypes | undefined = (() => {
    for (const [, { templateId, templateName }] of Object.entries(SitecoreIds.Route)) {
      if (templateId === sitecoreContext.route?.templateId) {
        return templateName;
      }
    }
    return undefined;
  })() as PageTypes;

  const parsedMeta = useMemo(() => parseMetaData(metaData), [metaData]);

  let structuredData = '';
  try {
    // Replace escaped quotes with regular quotes
    const cleanJsonString = fields?.MetaStructuredData?.value
      .replace(/(\\r\\n|\\n|\\r)/g, '')
      .replace(/\\+/g, '');
    // Parse the string as JSON
    structuredData = cleanJsonString && JSON.parse(cleanJsonString);
  } catch (error) {
    console.error('Invalid JSON string:', error);
  }

  return (
    <>
      <Scripts />
      <Head>
        {!pageType && (
          <>
            <title>
              {fields?.SEOPageTitle?.value?.toString() ||
                fields?.PageTitle?.value?.toString() ||
                'Page'}
            </title>
            {fields?.MetaDescription?.value && (
              <meta name="description" content={fields?.MetaDescription?.value} />
            )}
            <meta
              property="og:title"
              content={
                fields?.SEOPageTitle?.value?.toString() ||
                fields?.PageTitle?.value?.toString() ||
                'Page'
              }
            />
            {fields?.MetaOGDescription?.value && (
              <meta property="og:description" content={fields?.MetaOGDescription?.value} />
            )}
          </>
        )}
        {fields?.MetaOGImage?.value?.src && (
          <meta property="og:image" content={fields?.MetaOGImage?.value?.src} />
        )}
        <meta property="og:type" content="website" />

        {fields?.MetaMarkup?.value}

        {typeof window !== 'undefined' && (
          <meta property="og:url" content={window?.location?.href} />
        )}
        {fields?.MetaKeywords?.value && (
          <meta name="keywords" content={fields?.MetaKeywords?.value} />
        )}
        <link rel="canonical" href={`${currentUrl.origin}${currentUrl.pathArray}`} />
        {SpanishPath && SpanishPath?.value && (
          <link
            rel="alternate"
            hrefLang="es"
            href={`${SpanishPath?.value}${currentUrl.pathArray}`}
          />
        )}
        {DiscludeStructuredMarkup && !DiscludeStructuredMarkup.value && (
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        )}
        {headLinks.map((headLink) => (
          <link rel={headLink.rel} key={headLink.href} href={headLink.href} />
        ))}
      </Head>
      {parsedMeta.length > 0 && (
        <Head>
          {parsedMeta.map((meta, index) => (
            <meta key={index} name={meta.name} content={meta.content} />
          ))}
        </Head>
      )}

      {route && <Placeholder name="HtmlHead" rendering={route} />}
      <LoginContextProvider>
        <SearchOverlayContextProvider>
          <div className={`${mainClassPageEditing} ${roboto.className}`}>
            <a className="skip-link" href="#main">
              Skip to content
            </a>
            {route && <Placeholder name="header-top" rendering={route} />}
            {route && <Placeholder name="header" rendering={route} />}
            <div className="relative flex flex-row">
              {route && <Placeholder name="sidebar-navigation" rendering={route} />}
              <div className="w-full">
                <main id="main" className="min-h-screen">
                  <div id="dl-notifications">
                    {route && <Placeholder name="dl-notifications" rendering={route} />}
                  </div>
                  <div
                    id="dl-content"
                    className={`${
                      !navContext.navItem
                        ? '' // if we are not on a primary nav page, break out of this nested ternary early and leave no space for left nav
                        : sitecoreContext.itemPath === '/'
                        ? 'main-lhn'
                        : navContext.leftNavState === 'expanded'
                        ? 'md:pl-10 sub-lhn-expanded'
                        : `${leftNavExists ? 'md:pl-10 ' : 'md:pl-10 sub-lhn-collapsed'} `
                    }`}
                  >
                    {route && <Placeholder name="dl-content" rendering={route} />}
                  </div>
                </main>
                <footer id="footer" className="spacing-md">
                  {route && <Placeholder name="footer" rendering={route} />}
                </footer>
              </div>
            </div>
            <SpeedBumpModal />
          </div>
        </SearchOverlayContextProvider>
      </LoginContextProvider>
    </>
  );
};

export default Layout;
