export interface BreadcrumbsProps {
  fields: Fields;
}

export interface Fields {
  data: Data;
}

export interface Data {
  item: Item;
}

export interface Item {
  id: string;
  name: string;
  PageTitle: PageTitle;
  ancestors: Ancestor[];
}

export interface PageTitle {
  value: string;
}

export interface Ancestor {
  id: string;
  name: string;
  PageTitle: PageTitle;
  url: Url;
}

export interface Url {
  path: string;
}
