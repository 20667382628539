import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  widget,
  WidgetDataType,
  Filter,
  useSearchResultsActions,
  EntityModel,
  SearchResponseFacet,
  useSearchResultsSelectedFilters,
} from '@sitecore-search/react';
import classNames from 'classnames';
import { debounce } from '@sitecore-search/common';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { AccordionFacets, FacetItem, SearchResultsAccordionFacets } from '@sitecore-search/ui';

import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Button from 'helpers/Button/Button';
import { useDictionary } from 'lib/hooks/use-dictionary';
import { useSanitizedSearchResults } from 'lib/utils/token-replacer';

type ExpertsListWidgetProps = {
  initialCount: string;
  verticlePaginationOptions?: {
    loadMoreText: string;
    loadCount: string;
  };
  noResultsText?: string;
  placeholderText?: string;
  filterTypeLabel?: string;
  filters?: Filter;
};

type ExpertProps = {
  name: string;
  image_url: string;
  job_title: string;
  expertise: Array<string>;
  url: string;
};

const ExpertsList = ({
  initialCount,
  verticlePaginationOptions,
  noResultsText,
  filterTypeLabel,
  placeholderText,
}: ExpertsListWidgetProps) => {
  // States
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [expertsData, setExpertsData] = useState<{
    expertList: Array<EntityModel>;
    fetchCount: number;
  }>({ expertList: [], fetchCount: 1 });

  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);

  const selectedFacetsCountRef = useRef<number>(0);

  const searchSource = process.env.SEARCH_SOURCE_ID;
  const searchSourceIds = searchSource?.split('|') || [];

  const {
    sanitizedResults,
    widgetRef,
    query,
    state: { selectedFacets },
    actions: { onKeyphraseChange },
    queryResult: {
      isInitialLoading,
      isFetched,
      data: { total_item = 0, limit = 0, offset = 0, facet: facets = [] } = {},
    },
  } = useSanitizedSearchResults({
    query: (query) =>
      query
        .getRequest()
        .setSearchFacetAll(false)
        .addSearchFacetType({ name: 'expertise' })
        .setSearchSuggestion([{ max: 5, name: 'auto_named_suggester_1', keyphrase_fallback: true }])
        .setSources(searchSourceIds),
    state: {
      itemsPerPage: Number(initialCount),
      page: 1,
    },
  });

  // Handle the scroll enable/disable functionality on filter panel visibility
  useEffect(() => {
    if (typeof window !== 'undefined' && document) {
      if (isFilterPanelVisible && total_item > 0) {
        // Calculate scrollbar width if present to handle the layout shift
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.paddingRight = `${scrollbarWidth}px`;
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.paddingRight = ''; // Stop scrolling
        document.body.style.overflow = 'auto'; // Allow scrolling
      }
    }
    // Cleanup function
    return () => {
      document.body.style.paddingRight = ''; // Stop scrolling
      document.body.style.overflow = 'auto'; // Ensure overflow is reset
    };
  }, [isFilterPanelVisible]);

  useEffect(() => {
    if (!isInitialLoading && sanitizedResults?.content) {
      // Handle facet selection and search input change
      if (
        offset === 0 ||
        (selectedFacets && selectedFacets.length !== selectedFacetsCountRef.current)
      ) {
        selectedFacetsCountRef.current = selectedFacets?.length || 0;
        setExpertsData({
          fetchCount: 1,
          expertList: sanitizedResults.content,
        });
      }
      //Handle load more
      else
        setExpertsData({
          fetchCount: expertsData.fetchCount + 1,
          expertList: [...expertsData.expertList, ...sanitizedResults.content],
        });
    }
    // We only need to care about experts and offset as a dependency
    // because load more pagination requires new results to be loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, sanitizedResults?.content, searchBoxValue]);

  const onKeyphraseChangedDebounced = useMemo(
    () =>
      debounce((e) => {
        query
          .getRequest()
          [e.target.value ? 'setSearchQueryKeyphrase' : 'resetSearchQueryKeyphrase'](
            e.target.value
          );
      }, 200),
    // We don't need to add query as dependency as we need results only if the search input is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onKeyphraseChange]
  );

  const loadMoreResults = () => {
    const _loadCount = Number(verticlePaginationOptions?.loadCount);

    if (expertsData.fetchCount >= 1) {
      query.getRequest().setSearchLimit(_loadCount).setSearchOffset(expertsData.expertList.length);
    }
  };

  return (
    <section className="component container spacing-md" ref={widgetRef}>
      {/* Search and Filter Panels */}
      <div className="flex flex-col gap-x-4 gap-y-6 mb-12">
        <div className="flex md:flex-row flex-col gap-4">
          <div className="flex items-start justify-center min-h-[44px] md:min-w-[352px]">
            <div className="relative flex-grow self-stretch ">
              <input
                onChange={(e) => {
                  onKeyphraseChangedDebounced(e);
                  setSearchBoxValue(e.target.value);
                }}
                value={searchBoxValue}
                className={classNames(
                  'border border-strokes-default-3 border-r-0 rounded-l-[28px] pl-4 w-full h-full focus:outline-none focus:border-strokes-action',
                  searchBoxValue ? 'paragraph-3-medium' : 'paragraph-3-regular'
                )}
                placeholder={placeholderText}
                aria-label="search for expert"
              />
              {/* Clear Button */}
              {searchBoxValue && (
                <button
                  tabIndex={0}
                  className="absolute right-[14px] top-1/2 -translate-y-1/2"
                  onClick={(e) => {
                    setSearchBoxValue('');
                    onKeyphraseChangedDebounced(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearchBoxValue('');
                      onKeyphraseChangedDebounced(e);
                    }
                  }}
                  aria-label="clear input"
                >
                  <SVG className="inline-block w-3 h-3 text-strokes-default-2" svg="icon-close" />
                </button>
              )}
            </div>
            {/* Search Button */}
            <button
              tabIndex={-1}
              className="flex items-center justify-center self-stretch px-[12px] bg-button-primary-enabled rounded-r-[28px] focus:outline-none"
              aria-label="search expert"
            >
              <SVG className="inline-block w-5 h-5 text-white" svg="icon-search" />
            </button>
          </div>
          <div className="flex items-center justify-start flex-grow gap-x-[14px]">
            {/* Filter Toggle */}
            <div
              tabIndex={0}
              className="p-2 flex items-center justify-center gap-2 text-color-active paragraph-3-medium cursor-pointer rounded-[4px] hover:bg-button-secondary-enabled focus:outline-none border border-transparent focus:border-button-secondary-active"
              onClick={() => setIsFilterPanelVisible(!isFilterPanelVisible)}
              onKeyDown={(e) => e.key === 'Enter' && setIsFilterPanelVisible(!isFilterPanelVisible)}
            >
              <SVG className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" svg="icon-sort-filter" />
              <p className="paragraph-3-medium">{filterTypeLabel}</p>
            </div>
            <span className="inline-block flex-grow h-[1px] bg-strokes-default-2"></span>
          </div>
        </div>
        {selectedFacets && selectedFacets.length > 0 && <FilterChips />}
      </div>

      {total_item === 0 && isFetched && <Text tag="p" field={{ value: noResultsText }} />}

      {isFilterPanelVisible && (
        // Transparent Overlay
        <div
          className="fixed w-screen h-svh top-0 left-0 z-40"
          onClick={() => setIsFilterPanelVisible(false)}
        />
      )}
      {/* Filter Panel */}
      <div
        className={classNames(
          'fixed right-0 top-0 flex ease-in-out duration-100 items-start flex-col gap-2 bg-background-default-1 z-50 shadow-large',
          isFilterPanelVisible ? 'translate-x-0' : 'translate-x-full'
        )}
      >
        <FilterPanel
          facets={facets}
          filterTypeLabel={filterTypeLabel}
          totalItemsReturned={total_item}
          setIsFilterPanelVisible={setIsFilterPanelVisible}
        />
      </div>

      {total_item > 0 && expertsData.expertList.length > 0 && (
        <ul className="flex flex-wrap flex-col md:flex-row gap-6">
          {expertsData.expertList.map((expertDetails: ExpertProps, i) => (
            <li className="basis-full lg:basis-[calc(100%_/_2_-_24px_/_2_*_(2_-_1))]" key={i}>
              <ExpertCard {...expertDetails} />
            </li>
          ))}
        </ul>
      )}

      {verticlePaginationOptions && (
        <div className="flex items-center justify-center mt-14 [&_button]:!rounded-[4px] [&_button]:!w-auto lg:[&_button]:py-4">
          <Button
            disabled={limit + offset === total_item}
            label={verticlePaginationOptions.loadMoreText}
            type="auxiliary"
            onClick={loadMoreResults}
            onKeyDown={(e) => e.key === 'Enter' && loadMoreResults()}
          />
        </div>
      )}
    </section>
  );
};

//#region Filter Chips
const FilterChips = () => {
  const dictionary = useDictionary();
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const { onRemoveFilter, onClearFilters } = useSearchResultsActions();

  return (
    <ul className="flex flex-wrap gap-x-6 gap-y-4 items-center justify-start">
      {selectedFacetsFromApi?.map((selectedFacet, index) => (
        <li
          tabIndex={0}
          className={classNames(
            'inline-flex items-center justify-center gap-2 p-2 rounded-[4px] cursor-pointer paragraph-3-medium text-color-default-1',
            'border-2 border-button-secondary-active hover:border-button-secondary-hover hover:underline',
            'focus:outline-none focus:border-button-secondary-focus'
          )}
          key={index}
          onClick={() => onRemoveFilter(selectedFacet)}
          onKeyDown={(e) => e.key === 'Enter' && onRemoveFilter(selectedFacet)}
        >
          {selectedFacet.valueLabel}
          <SVG className="w-3 h-3" svg="icon-close" />
        </li>
      ))}
      {selectedFacetsFromApi.length > 0 && (
        <li
          tabIndex={0}
          className="cursor-pointer paragraph-2-regular text-color-active"
          onClick={() => onClearFilters()}
          onKeyDown={(e) => e.key === 'Enter' && onClearFilters()}
        >
          {dictionary['Experts.Filter.Clear.All'] || 'Clear all filters'}
        </li>
      )}
    </ul>
  );
};
//#endregion

//#region Filter Panel
const FilterPanel = ({
  facets,
  filterTypeLabel,
  totalItemsReturned,
  setIsFilterPanelVisible,
}: {
  facets: Array<SearchResponseFacet>;
  filterTypeLabel?: string;
  totalItemsReturned?: number;
  setIsFilterPanelVisible: React.Dispatch<boolean>;
}) => {
  const dictionary = useDictionary();
  const { onFacetClick, onClearFilters } = useSearchResultsActions();

  return (
    <SearchResultsAccordionFacets
      defaultFacetTypesExpandedList={['expertise']}
      onFacetValueClick={onFacetClick}
      className="w-full"
    >
      {facets.map((f) => (
        <AccordionFacets.Facet
          facetId={f.name}
          key={f.name}
          className="flex flex-col h-svh max-h-screen"
        >
          <AccordionFacets.Header
            asChild
            className="bg-background-default-1 p-6 pointer-events-none"
          >
            <AccordionFacets.Trigger className="text-left paragraph-1-medium">
              {filterTypeLabel || f.name}
            </AccordionFacets.Trigger>
          </AccordionFacets.Header>
          <AccordionFacets.Content className="flex flex-col flex-1 py-2 mx-6 overflow-y-auto">
            {
              <AccordionFacets.ValueList className="group list-none pr-4 flex flex-col gap-y-6">
                {f.value.map((v, index: number) => (
                  <FacetItem
                    key={v.id}
                    {...{
                      index,
                      facetValueId: v.id,
                    }}
                    className="flex items-center cursor-pointer gap-2 paragraph-2-regular data-[state=selected]:paragraph-2-medium"
                  >
                    <AccordionFacets.ItemCheckbox
                      className={classNames(
                        'flex-none relative rounded-[1px] border-2 border-icon-toggle-selected-enabled cursor-pointer',
                        'w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6',
                        'aria-checked:bg-button-secondary-active aria-checked:hover:border-button-secondary-hover aria-checked:hover:bg-button-secondary-hover',
                        'aria-checked:focus:bg-button-secondary-focus focus:outline-none focus:border-button-secondary-focus'
                      )}
                      aria-label={v.text}
                    >
                      <AccordionFacets.ItemCheckboxIndicator className="text-white">
                        <SVG
                          className="[&_svg]:p-0.5 [&_svg]:absolute [&_svg]:top-0"
                          svg="icon-thin-check"
                        />
                      </AccordionFacets.ItemCheckboxIndicator>
                    </AccordionFacets.ItemCheckbox>
                    <AccordionFacets.ItemLabel>{v.text}</AccordionFacets.ItemLabel>
                  </FacetItem>
                ))}
              </AccordionFacets.ValueList>
            }
          </AccordionFacets.Content>
          {/* Button Controls */}
          <div className="w-full p-6 lg:p-8 shadow-small flex flex-col gap-y-4 items-center left-0 bottom-0 bg-background-default-2 focus:[&_button]:outline-none">
            <div className="w-full text-center paragraph-2-medium text-color-active [&_button]:border-none">
              <Button
                label={dictionary['Experts.Filter.Panel.Cancel'] || 'Cancel'}
                size="full"
                type="auxiliary"
                onClick={() => {
                  onClearFilters();
                  setIsFilterPanelVisible(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClearFilters();
                    setIsFilterPanelVisible(false);
                  }
                }}
              />
            </div>
            <div className="w-full focus:[&_button]:border-button-secondary-focus">
              <Button
                label={`${
                  dictionary['Experts.Filter.Panel.ViewResults'] || 'View results'
                } (${totalItemsReturned})`}
                size="full"
                type="auxiliary"
                onClick={() => setIsFilterPanelVisible(false)}
                onKeyDown={(e) => e.key === 'Enter' && setIsFilterPanelVisible(false)}
              />
            </div>
          </div>
        </AccordionFacets.Facet>
      ))}
    </SearchResultsAccordionFacets>
  );
};
//#endregion

//#region Expert Card
const ExpertCard = ({
  image_url = '',
  name = '',
  job_title = '',
  expertise = [],
  url = '',
}: ExpertProps) => {
  const dictionary = useDictionary();

  return (
    <LinkWrapper
      tabIndex={0}
      field={{ href: url }}
      className={classNames(
        'flex items-start gap-4 p-4 bg-background-default-2 border border-strokes-default-3 rounded-[4px] no-underline',
        'focus:outline-none focus:no-underline focus:shadow-[0px_2px_10px_0px_theme(colors.background.dark-2)]',
        'hover:no-underline hover:shadow-[0px_2px_40px_0px_rgba(57,100,191,0.15)]'
      )}
    >
      <div className="w-[107px] h-[198px] sm:w-[264px] sm:h-[336px] lg:w-[186px] lg:h-[251px] self-stretch">
        <ImageWrapper
          field={{ value: { src: image_url } }}
          className="w-full h-full object-cover object-center aspect-[107/198] sm:aspect-[264/336] lg:aspect-[186/251]"
          width="264"
          height="336"
        />
      </div>
      <div className="relative flex flex-[1_0_0] flex-col self-stretch">
        <h4 className="text-color-default-1 mb-2">{name}</h4>
        <p className="paragraph-2-regular text-color-default-2 mb-4">{job_title}</p>
        <p className="paragraph-3-regular text-color-default-1 mb-2">
          {dictionary?.['Experts.Expertise.Label'] || 'Expertise'}
        </p>

        {expertise.length > 0 &&
          expertise?.map((item, index) => (
            <p key={index} className="paragraph-3-regular text-color-default-1 mb-2">
              {item}
            </p>
          ))}

        <SVG
          className="absolute w-[22px] h-[22px] right-0 bottom-0 text-color-active"
          svg="icon-arrow-right"
        />
      </div>
    </LinkWrapper>
  );
};
//#endregion

export const ExpertsListWidget = widget(ExpertsList, WidgetDataType.SEARCH_RESULTS, 'content');
