import React, { useState } from 'react';
import classNames from 'classnames';
import {
  ImageField,
  RichTextField,
  LinkField,
  SitecoreContextValue,
  Field,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import SVG from 'helpers/SVG/SVG';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

interface MinimalHeaderProps {
  fields: {
    Image?: ImageField;
    Link?: LinkField;
    children?: Array<{
      fields?: {
        children?: Array<{
          id: string;
          fields?: {
            Link?: LinkField;
          };
        }>;
      };
    }>;
  };
  params?: {
    CenterHeader?: string;
  };
}

type ExtendedSitecoreContextProps = SitecoreContextValue & {
  FDICText?: Field<string>;
  FDICLogo?: { url: string; altText: string };
  IncludeFDICLogo?: Field<boolean>;
  DiscludeFDIC?: Field<boolean>;
};

const MinimalHeader: React.FC<MinimalHeaderProps> = ({ fields, params }) => {
  const CenterHeader = params?.CenterHeader === '1';
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const extendedSitecoreContext: ExtendedSitecoreContextProps =
    useSitecoreContext().sitecoreContext;

  const includeFDICLogo = extendedSitecoreContext.IncludeFDICLogo?.value;
  const discludeFDIC = extendedSitecoreContext.route?.fields?.['DiscludeFDIC'] as Field<boolean>;
  const FDICImage = extendedSitecoreContext.FDICLogo?.url;
  const FDICImageAltText = extendedSitecoreContext.FDICLogo?.altText;
  const FDICText = extendedSitecoreContext.FDICText?.value;
  const isFDICExists = includeFDICLogo && !discludeFDIC?.value;

  if (!fields) {
    return <></>;
  }

  const Navigations = fields?.children?.[0];

  const hasLinks = Navigations?.fields?.children?.some((child) => child.fields?.Link !== undefined);

  const renderLinks = (navigation?: {
    fields?: {
      children?: Array<{
        id?: string;
        fields?: {
          Link?: LinkField;
        };
      }>;
    };
  }) => {
    if (navigation?.fields?.children) {
      return navigation.fields.children.map((item) => {
        const linkData = item.fields?.Link;

        if (linkData) {
          return (
            <li
              key={item.id}
              className={classNames(
                'border-strokes-default-3 last:border-0 border-b-2 w-full py-2',
                'md:border-r md:border-b-0 md:px-3 md:py-0 md:border-strokes-default-2 md:w-auto'
              )}
            >
              <LinkWrapper
                field={linkData}
                className="text-color-default-2 paragraph-3-regular hover:underline hover:text-color-default-2"
              >
                <RichTextA11yWrapper field={{ value: linkData?.value?.text } as RichTextField} />
              </LinkWrapper>
            </li>
          );
        }
        return null;
      });
    }
    return null;
  };

  const renderLogo = () => (
    <>
      {fields?.Link?.value?.href ? (
        <LinkWrapper
          field={fields?.Link as LinkField}
          className={classNames('flex w-full max-w-max', { 'justify-center': CenterHeader })}
        >
          <ImageWrapper
            className="w-full max-h-7 sm:max-h-9 md:max-h-12"
            field={fields?.Image}
            height="48"
            width="427"
          />
        </LinkWrapper>
      ) : (
        <div className={classNames('flex w-full max-w-max', { 'justify-center': CenterHeader })}>
          <ImageWrapper
            className="w-full  max-h-7 sm:max-h-9 md:max-h-12"
            field={fields?.Image}
            height="48"
            width="427"
          />
        </div>
      )}
    </>
  );

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <header className="sticky top-0 z-50">
        <div className="z-50 relative w-full h-15 md:h-18 flex items-center md:justify-between px-3 sm:px-6 md:px-8 mx-auto bg-background-default-2 after:content-[''] after:absolute after:left-full after:top-full after:z-[50] after:h-[2.75rem] after:w-[2.75rem] after:-ml-[2.75rem] after:bg-[linear-gradient(to_bottom_left,_#fff_50%,_transparent_0)]">
          {hasLinks && (
            <div className="flex items-center md:hidden">
              <button
                className={classNames(
                  'text-icon-default-2 hover:text-color-active mr-6 focus:text-button-secondary-focus focus:outline-none"'
                )}
                onClick={toggleSidebar}
                aria-label="Toggle sidebar menu"
              >
                <div className="w-6 h-6">
                  <SVG svg="icon-menu" />
                </div>
              </button>
            </div>
          )}

          {CenterHeader && (
            <div className="container flex-grow flex justify-center">{renderLogo()}</div>
          )}

          {!CenterHeader && <>{renderLogo()}</>}

          {hasLinks && (
            <nav className="hidden md:flex ml-auto">
              <ul className="flex items-center">{renderLinks(Navigations)}</ul>
            </nav>
          )}
        </div>
        {/* FDIC */}
        {isFDICExists && (
          <div
            className={classNames(
              'absolute z-[42] md:z-0 top-15 md:top-18 left-0 w-full h-[45px] md:h-11 bg-background-dark-1 pr-9 md:pr-12 mobile:pl-2 pl-8 lg:pl-10 py-3 text-[13px] flex items-center gap-x-2 shadow-small',
              { 'justify-center': CenterHeader }
            )}
          >
            <ImageWrapper
              className="w-full h-full max-w-fit"
              field={{ value: { src: FDICImage, alt: FDICImageAltText } }}
              height="17"
              width="41"
            />
            <p className="italic">{FDICText}</p>
          </div>
        )}{' '}
        {/* END FDIC */}
      </header>

      {hasLinks && (
        <aside
          className={classNames(
            'fixed left-0 w-[320px] bg-background-default-2 shadow-lg z-40 transform transition-transform duration-300 ease-in-out flex flex-col p-6 gap-6',
            {
              'translate-x-0': isSidebarOpen,
              '-translate-x-full': !isSidebarOpen,
              'top-15 md:top-18 h-[calc(100vh-60px)] md:h-[calc(100vh-72px)]': !isFDICExists,
              'top-[6.5rem] md:top-[7.25rem] h-[calc(100vh-105px)] md:h-[calc(100vh-116px)]':
                isFDICExists,
            }
          )}
        >
          <ul className="flex flex-col items-start">{renderLinks(Navigations)}</ul>
        </aside>
      )}
    </>
  );
};

export default MinimalHeader;
