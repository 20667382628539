// Global
import React from 'react';
import { Field, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Marketing } from 'lib/templates/Feature.BOKF.model';

// Local
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import styles from './singlelinepromo.module.css';

export type SingleLinePromoProps = SingleLineComponentFields & SingleLineComponentParams;

export type SingleLineComponentFields = {
  Title?: Field<string>;
  Link?: LinkField;
} & Marketing.DataTemplates.SingleLineMarketingPromo;

export type SingleLineComponentParams = {
  params: {
    Theme: {
      Value: Field<string>;
    };
  };
};

const SingleLineMarketingPromo = (props: SingleLinePromoProps): JSX.Element => {
  const Theme = props?.params?.Theme?.Value;

  const derivedStyles = () => {
    const classes = Theme?.value.split(' ');
    return classes && Array.isArray(classes)
      ? classes.map((style) => styles[style] || '').join(' ')
      : '';
  };

  return (
    <section
      className={`${derivedStyles()} px-0 md:px-4 lg:px-12 2xl:px-12 mt-12 md:mt-14 lg:mt-16 bg-[position:bottom_right,_bottom] shadow-container`}
    >
      <div className="container flex items-center">
        <div className="container py-4 space-y-4 md:space-y-0 flex flex-col md:flex-row md:items-center">
          <div className="flex flex-col space-y-4 pr-4 w-full md:w-7/12 xl:w-8/12">
            <Text className=" text-paragraph-1-medium-lg " field={props?.fields?.Title} tag="h3" />
            <LinkWrapper
              className={`${styles['link-wrapper']} my-4 hover:underline w-fit text-left`}
              field={props?.fields?.Link as LinkField}
            />
          </div>
          {(props?.fields?.PromoLinkOne?.value?.href ||
            props?.fields?.PromoLinkTwo?.value?.href) && (
            <div className="w-full md:w-5/12 xl:w-4/12 max-w-fit grid grid-cols-2 gap-x-4 md:gap-x-6 lg:gap-x-10 ">
              {props?.fields?.PromoLinkOne?.value?.href && (
                <LinkWrapper
                  field={props?.fields?.PromoLinkOne as LinkField}
                  className="flex items-center"
                >
                  <ImageWrapper
                    field={props?.fields?.PromoImageOne}
                    height="56"
                    width="168"
                    className="w-auto h-[44px] md:h-[48px] lg:h-[56px]"
                  />
                </LinkWrapper>
              )}
              {props?.fields?.PromoLinkTwo?.value?.href && (
                <LinkWrapper
                  field={props?.fields?.PromoLinkTwo as LinkField}
                  className="flex items-center"
                >
                  <ImageWrapper
                    field={props?.fields?.PromoImageTwo}
                    height="56"
                    width="168"
                    className="w-auto h-[44px] md:h-[48px] lg:h-[56px]"
                  />
                </LinkWrapper>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SingleLineMarketingPromo;
