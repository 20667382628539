// Global
import React from 'react';
import { Link, LinkProps, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import useExperienceEditor from 'lib/use-experience-editor';
import { hostToReplace } from 'lib/host-to-replace';
import classNames from 'classnames';

/**
 * This component adds some needed accessibility updates to the JSS Link component
 */
export interface LinkWrapperProps extends LinkProps {
  srOnlyText?: string;
  suppressLinkText?: boolean;
  suppressNewTabIcon?: boolean;
}

const INTERNAL_LINK_REGEX = /^zzzzzzzz\/|^\#/g;

const LinkWrapper = React.forwardRef(
  ({
    children,
    field,
    srOnlyText,
    suppressLinkText,
    suppressNewTabIcon,
    ref,
    className,
    ...props
  }: LinkWrapperProps): // _ref
  JSX.Element => {
    // Format field as LinkField for consistency
    const asLinkField = !field?.value ? { value: { ...field } } : (field as LinkField | undefined);
    const text = suppressLinkText || children ? '' : asLinkField?.value?.text;
    const target = asLinkField?.value?.target;

    // Combine class of Link Wrapper attribute and Layout Link Field Class
    const combineClass = classNames(asLinkField?.value?.class, className);

    const processedField = asLinkField ? hostToReplace(asLinkField) : undefined;

    const isEE = useExperienceEditor();

    // In experience editor, do not pass any children but retain basic styling so that double components do not appear when using <Link>
    if (isEE) {
      return (
        <Link
          field={processedField as LinkField}
          showLinkTextWithChildrenPresent={false}
          internalLinkMatcher={INTERNAL_LINK_REGEX}
          ref={typeof ref !== 'string' ? ref : null}
          className={combineClass}
          {...props}
          href={processedField?.value?.href}
        >
          {children}
        </Link>
      );
    }

    // If no content is present, don't print
    if (!suppressLinkText && !asLinkField?.value.text && !asLinkField?.value.href) return <></>;

    return (
      <Link
        field={processedField as LinkField}
        internalLinkMatcher={INTERNAL_LINK_REGEX}
        ref={typeof ref !== 'string' ? ref : null}
        showLinkTextWithChildrenPresent={false}
        className={combineClass}
        {...props}
        href={processedField?.value?.href}
        aria-label={
          processedField?.value?.title ? processedField?.value?.title : processedField?.value?.text
        }
        aria-describedby={
          processedField?.value?.title ? processedField?.value?.title : processedField?.value?.text
        }
      >
        {text}
        {children}
        {(target === '_blank' || srOnlyText) && (
          <>
            <span className="sr-only">
              {srOnlyText && srOnlyText}
              {/* Preserve a single space character before SR Tab Text */}
              {target === '_blank' && ' (Opens in a new tab)'}
            </span>
            {/* Icon Goes Here */}
            {!suppressNewTabIcon && target === '_blank' && <span></span>}
          </>
        )}
      </Link>
    );
  }
);

LinkWrapper.displayName = 'LinkWrapper';

export default LinkWrapper;
