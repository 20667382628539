//Global
import React, { useState, useEffect } from 'react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

//lib
import { useDictionary } from 'lib/hooks/use-dictionary';

//helper
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Button from 'helpers/Button/Button';

interface SharebarProps {
  params: Params;
  fields: SharebarFieldsProps;
}
interface SharebarFieldsProps {
  children: SharebarItemProps[];
}
interface SharebarItemProps {
  id: string;
  displayName: string;
  name: string;
  templateId: string;
  templateName: string;
  url: string;
  fields: Fields;
}
interface Fields {
  ShareLinkMarkup: Field<string>;
}

interface Params {
  SharebarNetworks: string;
  ShowSubscribeButton: string;
}

export type PageTitleProps = {
  MetaOGDescription: Field<string>;
  Headline: Field<string>;
  PageTitle: Field<string>;
  Teaser: Field<string>;
};

const Sharebar = (props: SharebarProps): JSX.Element => {
  const { children } = props?.fields;
  const [svgs, setSvgs] = useState<string[]>([]);
  const [displayIcon, setDisplayIcon] = useState<boolean>(false);
  const { sitecoreContext } = useSitecoreContext();
  const { PageTitle, Teaser } = sitecoreContext?.route?.fields as PageTitleProps;

  const dict = useDictionary();
  let subscribeBtnText = undefined;
  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    subscribeBtnText = 'Subscribe';
  } else {
    subscribeBtnText = dict?.['ArticleSubscribeButton'] || 'Subscribe';
  }

  const [isFixedDivVisible, setIsFixedDivVisible] = useState(true);

  const isScrolledIntoView = (elem: HTMLElement | null): boolean => {
    if (!elem) {
      return false;
    }

    const docViewTop = window.scrollY;
    const docViewBottom = docViewTop + window.innerHeight;

    const elemTop = elem.getBoundingClientRect().top + docViewTop;

    return elemTop < docViewBottom;
  };

  const removeHTMLTags = (html: string) => {
    const refineHtmlString = html && html.replace(/<[^>]*>/g, '');
    return refineHtmlString;
  };

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = (document.querySelector('.footer') as HTMLElement) || undefined;
      if (isScrolledIntoView(footerElement)) {
        setIsFixedDivVisible(false);
      } else {
        setIsFixedDivVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const arr: string[] = [];
    children.map(async (fields) => {
      let actualString = fields?.fields?.ShareLinkMarkup?.value;
      const pageTitleWithoutHtml = removeHTMLTags(PageTitle?.value);
      const TeaserWithoutHtml = removeHTMLTags(Teaser?.value);

      actualString = actualString.replace('{Title}', pageTitleWithoutHtml);
      actualString = actualString.replace('{BodyTeaser}', TeaserWithoutHtml);
      actualString = actualString.replace('{ShareUrl}', decodeURI(window?.location?.href));
      arr.push(actualString);
    });
    setSvgs(arr);
  }, [children]);

  const openMobileSharebar = () => {
    window &&
      window?.navigator?.share &&
      window?.navigator?.share({
        title: PageTitle.value,
        url: decodeURI(window && window?.location?.href),
      });
  };

  return (
    <>
      <section className="lg:w-[190px]">
        {/* Subscribe */}
        {props?.params?.ShowSubscribeButton && (
          <div className="hidden lg:block jsSubscribe">
            <Button type="auxiliary" size="large" label={subscribeBtnText} />
          </div>
        )}

        {/* END Subscribe */}
        <div
          onMouseLeave={() => {
            setDisplayIcon(false);
          }}
          className="flex items-center mt-4 justify-between bottom-10"
        >
          <div className="lg:block hidden flex-col">
            <div className="flex flex-col gap-y-4 items-center">
              <div
                tabIndex={0}
                className="flex items-center justify-center [&>span]:w-6 [&>span]:h-6 p-1 text-color-active focus:outline focus:outline-strokes-action focus:rounded"
                onMouseEnter={() => {
                  setDisplayIcon(true);
                }}
                onFocus={() => {
                  setDisplayIcon(true);
                }}
              >
                <SVG
                  className="icon-action items-center [&_svg]:h-6 [&_svg]:w-6 cursor-pointer"
                  svg="icon-share2"
                />
              </div>
            </div>
          </div>

          {svgs &&
            svgs.map((item, index) => (
              <div
                key={index}
                className={`${
                  displayIcon ? 'opacity-100 cursor-pointer' : 'opacity-0 pointer-events-none'
                } lg:block hidden bg-none h-8 w-8 [&_a]:h-8 [&_a]:w-8 [&_a]:p-1 [&_a]:inline-block [&_svg]:h-6 [&_svg]:w-6 [&_a]:items-center [&_a]:text-color-default-2 hover:[&_a]:text-icon-action focus:[&_a]:outline-strokes-action focus:[&_a]:outline focus:[&_a]:rounded`}
                dangerouslySetInnerHTML={{ __html: item }}
              ></div>
            ))}
        </div>
      </section>
      {/* Mobile / Tablet */}
      <div
        className={`${
          isFixedDivVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
        } z-20 bottom-10 lg:hidden fixed right-[39px] bg-white inline-flex gap-x-[9px] text-color-active px-4 py-2 items-center border-[1px] border-color-dark-2 h-10 rounded-[20px]`}
      >
        {props?.params?.ShowSubscribeButton && (
          <LinkWrapper
            onClick={(e) => {
              e.preventDefault();
            }}
            className="jsSubscribe outline-none text-color-active hover:text-color-active paragraph-2-medium-sm hover:paragraph-2-medium-sm-underline md:paragraph-1-medium hover:paragraph-1-medium-underline"
            field={{
              text: subscribeBtnText,
              href: '#',
            }}
          />
        )}
        <div className="border-l-[1px] h-[20px] border border-color-dark-2"></div>
        <button
          onClick={() => {
            openMobileSharebar();
          }}
          className="outline-none h-6 w-6 cursor-pointer flex"
        >
          <SVG className="icon-action" svg="icon-share2" />
        </button>
      </div>
      {/* END */}
    </>
  );
};

export default Sharebar;
