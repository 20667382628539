import React from 'react';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import style from './SlideshowCollection.module.css';
import SlideshowSlider from './SlideshowSlider';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { tv } from 'tailwind-variants';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { Bok } from 'lib/templates/Foundation.BOKF.model';
import { ComponentProps } from 'lib/component-props';
import classNames from 'classnames';

// Types for Slider slide
export type SlideshowChildsProps = Marketing.DataTemplates.Slideshow.Slide &
  Marketing.DataTemplates.Slideshow.SplitSlide & {
    fields?: {
      ColumnStructure?: {
        fields?: {
          Value?: Field<string>;
        };
      };
    };
  };

// Types For Overall SlideshowCollection Component
export type SlideshowCollectionProps = ComponentProps & {
  params?: {
    Alignment?: {
      Value?: Field<string>;
    };
    Background?: {
      Value?: Field<string>;
    };
    HeadingType?: Bok.BaseTypes.HtmlTag['fields'];
    DynamicPlaceholderId?: string;
  };
} & Marketing.DataTemplates.Slideshow.SlideshowContainer & {
    fields?: {
      children?: Array<SlideshowChildsProps>;
    };
  };

// Styles For Component
export const SlideShowstyle = tv({
  slots: {
    HeadingStyle: [
      'relative pb-2 mb-6',
      'after:absolute after:bg-bokf-red after:bottom-0 after:w-12 after:h-[3px]',
    ],
    HeadingWrapperStyle: ['mx-auto mb-6', 'md:max-w-[90%]', 'xl:max-w-[80%]'],
    CopyTextStyle: ['text-default-2 text-paragraph-2-regular rtaw'],
    SlideStyle: ['rounded-[8px] border-[1px] border-background-dark-2 h-full p-6'],
    ControlWrapperStyle: ['flex justify-center items-center gap-[5px] pb-3'],
    DotWrapperStyle: ['flex flex-wrap justify-center items-center gap-x-2'],
    SliderDotStyle: [
      'appearance-none bg-transparent flex outline-none justify-center items-center rounded-full h-3 w-8 m-0 p-0 border-0 no-underline',
      'after:w-3 after:h-3 after:rounded-full after:flex after:items-center after:transition-all after:bg-color-disabled',
      'before:w-6 before:h-6 before:rounded-full before:absolute before:outline before:outline-2 before:outline-transparent',
      'focus:before:outline-button-secondary-focus hover:after:bg-color-default-2',
    ],
    SliderArrowStyle: [
      'appearance-none cursor-pointer border-0 p-0 m-0 w-10 h-10 bg-transparent flex justify-center items-center rounded-md no-underline hover:bg-background-dark-1 focus:bg-background-dark-1 focus:outline focus:outline-2 focus:outline-button-secondary-focus',
    ],
  },
  variants: {
    postions: {
      center: {
        HeadingStyle: ['after:left-1/2 after:-translate-x-1/2 text-center'],
      },
      left: {
        HeadingStyle: ['after:left-0 text-left'],
      },
    },
  },
});

const SlideshowCollection = ({ fields, params, rendering }: SlideshowCollectionProps) => {
  const { HeadingStyle, HeadingWrapperStyle, CopyTextStyle } = SlideShowstyle();

  const alignment = params?.Alignment?.Value?.value === 'text-center' ? 'center' : 'left';
  const headingClass = HeadingStyle({ postions: alignment });

  const typeOfSlider =
    fields?.children &&
    fields?.children.find(
      (slide: SlideshowChildsProps) => slide?.fields?.ColumnStructure !== undefined
    )
      ? 'SplitSlider'
      : 'NormalSlider';

  return (
    <section className={classNames(params?.Background?.Value?.value, 'spacing-md')}>
      <div className="container-inner">
        <div className={HeadingWrapperStyle()}>
          <Text
            tag={params?.HeadingType?.Tag?.value}
            field={fields?.Title}
            className={`${headingClass} ${params?.HeadingType?.Styles?.value}`}
          />
          <RichTextA11yWrapper
            field={fields?.CopyText}
            className={classNames(
              CopyTextStyle(),
              params?.Alignment?.Value?.value === 'text-center'
                ? 'text-center [&_li]:mx-auto [&_li]:w-fit'
                : 'text-left'
            )}
          />
        </div>
        {fields?.children && (
          <div className={classNames(style.slideshow, 'max-w-full m-auto')}>
            <SlideshowSlider
              sliders={fields?.children}
              typeOfSlider={typeOfSlider}
              rendering={rendering}
              params={params}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SlideshowCollection;
