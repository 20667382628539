// GLobal
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Field,
  Item,
  LinkField,
  ImageField,
  RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { EmblaOptionsType, EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import AutoScroll from 'embla-carousel-auto-scroll';

// Lib
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { ComponentProps } from 'lib/component-props';
import { getBreakpoint, useCurrentScreenType } from 'lib/utils/get-screen-type';

// Local
import { DotButton, useDotButton } from './CarouselMarqueeDotButton';
import { PrevButton, NextButton, usePrevNextButtons } from './CarouselMarqueeArrowButton';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { useLoginContext } from 'helpers/Providers/LoginContextProvider';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { Bok } from 'lib/templates/Foundation.BOKF.model';

type LayoutProps = Marketing.DataTemplates.CarouselMarquee & ComponentProps;

export type CarouselMarqueeProps = {
  fields: {
    children: Marketing.DataTemplates.CarouselMarqueeSlide[];
  };
} & LayoutProps;

const CarouselMarquee = (props: CarouselMarqueeProps): JSX.Element => {
  const { currentScreenWidth } = useCurrentScreenType();

  const options: EmblaOptionsType = { loop: true, duration: 25 };
  const marquees =
    (props?.fields?.Marquees as Array<Item>)?.length > 0
      ? props?.fields?.Marquees
      : props?.fields?.children;
  const width =
    props.params?.EnableFluidWidth === '1' ? 'w-full max-w-[100vw]' : 'w-full container-wide';

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({
      delay: props.fields?.AutoScrollDelay?.value,
      stopOnMouseEnter: true,
      stopOnInteraction: false,
    }),
    AutoScroll({ playOnInit: false, speed: 0 }),
  ]);

  const [enableAutoScroll, setEnableAutoScroll] = useState<boolean>();
  const [enableCarousel, setEnableCarousel] = useState<boolean>();
  const [isPlaying, setIsPlaying] = useState<boolean>();
  const { isMarqueeLoginEnabled, setMarqueeLoginEnabled } = useLoginContext();
  const loginMarqueeRef = useRef<HTMLInputElement>(null);
  const [enableHeroExperience, setEnableHeroExperience] = useState<boolean>();

  const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    const resetOrStop =
      autoplay.options.stopOnInteraction === false ? autoplay.reset : autoplay.stop;
    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi, onNavButtonClick);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi, onNavButtonClick);

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;
    const playOrStop = autoScroll.isPlaying() ? autoScroll.stop : autoScroll.play;
    playOrStop();
  }, [emblaApi]);

  useEffect(() => {
    setEnableAutoScroll(props?.fields?.EnableAutoScroll?.value);
    if (!enableAutoScroll) emblaApi?.plugins()?.autoplay?.stop();
  }, [emblaApi, enableAutoScroll, props?.fields?.EnableAutoScroll?.value]);

  useEffect(() => {
    setEnableCarousel(props?.fields?.EnableCarousel?.value);
    if (!enableCarousel) emblaApi?.plugins()?.autoplay?.stop();
  }, [emblaApi, enableCarousel, props?.fields?.EnableCarousel?.value]);

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;

    setIsPlaying(autoScroll.isPlaying());
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()));
  }, [emblaApi, isPlaying]);

  // Login Effects
  useEffect(() => {
    setMarqueeLoginEnabled(props?.fields?.EnableLoginBox?.value as boolean);
  }, [setMarqueeLoginEnabled, props?.fields?.EnableLoginBox?.value]);

  useEffect(() => {
    if (isMarqueeLoginEnabled) {
      const loginElement = document.querySelector('[data-component="login"]');
      if (loginElement && loginMarqueeRef.current) {
        loginMarqueeRef.current.appendChild(loginElement);
      }
    }
  }, [loginMarqueeRef, isMarqueeLoginEnabled]);

  useEffect(() => {
    setEnableHeroExperience(props?.fields?.EnableHeroExperience?.value);
  }, [enableHeroExperience, props?.fields?.EnableHeroExperience?.value]);

  const { fields = undefined } = props;
  if (!fields || marquees?.length === 0) {
    return <></>;
  }

  return (
    <section className={`component carousel-marquee ${width}`} data-component="marquee">
      <div className="carousel-marquee-viewport group" ref={emblaRef}>
        <div className="carousel-marquee-container">
          {marquees?.map((item, key) => {
            return (
              <div
                className="carousel-marquee-slide desktop:max-h-[500px] tablet:max-h-[424px] mobile:max-h-[424px]"
                key={key}
              >
                {!enableHeroExperience && (
                  <div className="absolute left-0 bottom-0 w-full bg-background-brand-85 py-8 mobile:py-4 tablet:py-6">
                    <div className="flex flex-col tablet:flex-col desktop:flex-row items-center mobile:gap-2 desktop:gap-8 mobile:pr-0 md:justify-between mobile:mx-4 tablet:mx-8 desktop:mx-40">
                      <span className="text-color-inverse mobile:mb-4 tablet:mb-4 desktop:mb-0 text-center md:text-left">
                        <RichTextA11yWrapper
                          field={item?.fields?.Text as RichTextField}
                          tag={
                            (((item?.fields?.TitleTag as Item)?.fields?.Tag as Field)
                              ?.value as string) || 'h1'
                          }
                        />
                      </span>
                      <Button
                        type="secondary"
                        size="large"
                        field={item?.fields?.CallToAction as LinkField}
                      />
                    </div>
                  </div>
                )}
                {enableHeroExperience && (
                  <div className="flex justify-start items-center absolute top-0 left-0 bottom-0 w-full">
                    <div className="container">
                      <div className="my-auto md:max-w-[540px] sm:mx-4 md:ml-8 lg:ml-10">
                        <RichTextA11yWrapper
                          field={item?.fields?.Title as RichTextField}
                          tag={
                            (((item?.fields?.TitleTag as Item)?.fields?.Tag as Field)
                              ?.value as string) || 'h1'
                          }
                          className={`pb-2 ${
                            ((item?.fields?.LightModeToggle as Field)?.value as boolean)
                              ? 'text-color-inverse'
                              : 'text-color-default-1'
                          }`}
                        ></RichTextA11yWrapper>

                        <div
                          className={`w-[48px] h-[4px] ${
                            ((item?.fields?.TitleBorderStyle as Item)?.fields?.Value as Field)
                              ?.value
                          }`}
                        ></div>

                        <RichTextA11yWrapper
                          field={item?.fields?.RichText as RichTextField}
                          className={`py-6 rtaw ${
                            ((item?.fields?.LightModeToggle as Field)?.value as boolean)
                              ? 'text-color-inverse'
                              : 'text-color-default-1'
                          }`}
                        ></RichTextA11yWrapper>

                        <div className="grid grid-cols-1 md:flex gap-4">
                          {item?.fields?.ButtonOne && (
                            <LinkWrapper
                              field={item?.fields?.ButtonOne as LinkField}
                              className={
                                (item?.fields?.ButtonOneStyle as Bok.BaseTypes.Selector)?.fields
                                  ?.Value?.value
                              }
                            />
                          )}
                          {item?.fields?.ButtonTwo && (
                            <LinkWrapper
                              field={item?.fields?.ButtonTwo as LinkField}
                              className={
                                (item?.fields?.ButtonTwoStyle as Bok.BaseTypes.Selector)?.fields
                                  ?.Value?.value
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {currentScreenWidth >= getBreakpoint('desktop-lg') && (
                  <ImageWrapper
                    field={item?.fields?.DesktopImage as ImageField}
                    className="min-h-[500px] w-full object-cover object-left"
                  />
                )}
                {currentScreenWidth >= getBreakpoint('desktop') &&
                  currentScreenWidth < getBreakpoint('desktop-lg') && (
                    <ImageWrapper
                      field={item?.fields?.TabletImage as ImageField}
                      className="min-h-[424px] w-full object-cover object-center"
                    />
                  )}
                {currentScreenWidth < getBreakpoint('desktop') && (
                  <ImageWrapper
                    field={item?.fields?.MobileImage as ImageField}
                    className="min-h-[424px] w-full object-cover"
                  />
                )}
              </div>
            );
          })}
        </div>
        <div
          className={`previous-button-container ${
            !enableCarousel ? 'hidden' : ''
          } opacity-0 group-hover:opacity-100`}
        >
          <PrevButton
            className="previous-button"
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          />
        </div>
        <div
          className={`next-button-container ${
            !enableCarousel ? 'hidden' : ''
          } opacity-0 group-hover:opacity-100`}
        >
          <NextButton
            className="next-button"
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          />
        </div>
        {isMarqueeLoginEnabled && (
          <div className="carousel-marquee-login" ref={loginMarqueeRef}></div>
        )}
      </div>
      <div
        className={`carousel-marquee-controls ${
          !enableCarousel ? 'hidden' : ''
        } relative bg-transparent`}
      >
        <div className="flex flex-row h-[44px] justify-center items-center px-4 md:px-6 stroke-strokes-default-3 bg-background-default-2 border absolute left-0">
          <label className="carousel-toggle inline-flex items-center mr-2 w-auto">
            <input type="checkbox" onClick={toggleAutoplay} aria-label="Pause Carousel" />
            <span className="carousel-slider w-[46px] bg-background-dark-2"></span>
            <div className="paragraph-2-regular ps-2 ms-12 text-color-default-2">
              Pause Carousel
            </div>
          </label>
        </div>
        <div className="carousel-marquee-dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'carousel-marquee-dot'.concat(
                index === selectedIndex ? ' carousel-marquee-dot--selected' : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CarouselMarquee;
