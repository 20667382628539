export const extractScriptsFromString = (scriptString: string) => {
  const scriptRegex = /<script([^>]*)>([\s\S]*?)<\/script>/g;

  let match;
  const scripts = [];

  while ((match = scriptRegex.exec(scriptString)) !== null) {
    const attributes = getAttributesFromString(match[1].trim());
    const content = match[2].trim();
    scripts.push({ attributes, content });
  }

  return scripts;
};

const getAttributesFromString = (attributeString: string) => {
  // Split the input string into individual attributes
  const attributes = attributeString.split(/\s+(?=[\w-]+=)/); // Split on spaces followed by a key=value pattern

  // Create an object from the attributes
  const attributesObject = Object.fromEntries(
    attributes.map((attr) => {
      const [key, value] = attr.split('=');
      // Clean up the key and value
      return [key.trim(), value.replace(/(^["'])|(["']$)/g, '').trim()];
    })
  );

  return attributesObject;
};
