export interface SplitColumnLayoutTypes {
  params: {
    HeadingType: {
      Tag: {
        value: string;
      };
      Styles: {
        value: string;
      };
    };
    Title: string;
    Alignment: {
      Value: {
        value: string;
      };
    };
    Background: {
      Value: {
        value: string;
      };
    };
    ColumnStructure: {
      Value: {
        value: string;
      };
    };
    ShowSingleColumn?: string;
    DynamicPlaceholderId: string;
    AnchorId: string;
  };
}
