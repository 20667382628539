// Library Imports
import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';

// Rates Imports
import { Rates } from 'lib/templates/Feature.BOKF.model';
import { RatesRequest, RatesResponse } from 'types/MarketingServices/Rates.Models';
import {
  RATES_LOADING,
  RATES_UNAVAILABLE,
  BuildRatesRequest,
  CurrencyConverter,
  NEXT_RATES_API,
} from 'helpers/Rates/RatesHelper';

// Helper Imports
import { useDictionary } from 'lib/hooks/use-dictionary';
import Button from 'helpers/Button/Button';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

const MultiUseRates = (props: Rates.Filter): JSX.Element => {
  const [rates, setRates] = useState<RatesResponse | null>(null);
  const [rateStatus, setRatesStatus] = useState<string>(RATES_LOADING);

  // Build Rates Request Object
  const request: RatesRequest = BuildRatesRequest(props as Rates.Filter);

  // Counter for disclosure links
  let disclosureCounter = 0;

  // Dictionary Values
  const dict = useDictionary();
  const productMin = dict?.['Rates.Footer.Min'] || 'opening deposit required';
  const productMax = dict?.['Rates.Footer.Max'] || 'max';
  const productDivider = dict?.['Rates.Footer.Divider'] || '/';
  const termMin = dict?.['Rates.Term.Row.Min'] || 'opening deposit required';
  const termMax = dict?.['Rates.Term.Row.Max'] || 'max';
  const termDivider = dict?.['Rates.Term.Row.Divider'] || '/';
  const depositLearnMoreLinkText = dict?.['Rates.Deposits.Learn.LinkText'] || 'Learn More';
  const depositOpenAccountLinkText = dict?.['Rates.Deposit.Open.Account.LinkText'] || 'Open Now';
  const loanLearnMoreLinkText = dict?.['Rates.Loans.Learn.LinkText'] || 'Learn More';
  const loanOpenAccountLinkText = dict?.['Rates.Loans.Open.Account.LinkText'] || 'Apply Now';

  enum RateType {
    Product,
    Term,
  }

  // Notes leverage the dictionary values with a toggle between product and term.
  const buildMinMaxNote = (min: string, max: string, type: RateType): string => {
    const minFormatted = min
      ? `${CurrencyConverter(Number(min))} ${type === RateType.Product ? productMin : termMin}`
      : '';

    const maxFormatted = max
      ? `${CurrencyConverter(Number(max))} ${type === RateType.Product ? productMax : termMax}`
      : '';

    if (minFormatted && maxFormatted) {
      return `${minFormatted}${
        type === RateType.Product ? productDivider : termDivider
      }${maxFormatted}`;
    } else if (minFormatted) {
      return minFormatted;
    } else if (maxFormatted) {
      return maxFormatted;
    } else {
      return '';
    }
  };

  const BuildDepositView = (
    <>
      {rates?.groups.map((group, groupIndex) => (
        <div key={groupIndex} className="spacing-md first-of-type:mt-8">
          {group?.products.map((product, productIndex) => (
            <div key={productIndex} className="flex flex-col gap-y-4 spacing-md first-of-type:mt-0">
              <h3 className="">{product?.title}</h3>
              <div className="flex flex-col gap-y-4 sm:gap-y-6">
                <table className="w-full" cellPadding={16}>
                  <thead>
                    {product?.tiers.length > 1 ? (
                      <tr className="bg-background-dark-2 paragraph-2-medium">
                        <th className="w-1/3 pb-0 text-left"></th>
                        <th className="w-1/4 pb-0 text-left">{product?.tiers[0]?.value}</th>
                        <th className="w-1/4 pb-0 text-left">{product?.tiers[1]?.value}</th>
                      </tr>
                    ) : (
                      <tr className="bg-background-dark-2 paragraph-2-medium">
                        <th className="w-8/12 text-left">Term</th>
                        <th className="w-4/12 text-left">
                          APY
                          <sup>
                            <a href="#disclosure">†</a>
                          </sup>
                          <br />
                          {product?.head}
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {product?.tiers.length > 1 && (
                      <tr className="bg-background-dark-2 paragraph-2-medium">
                        <td className="w-1/2 pt-2">Term</td>
                        <td className="w-1/4 pt-2">
                          APY
                          <sup>
                            <a href="#disclosure">†</a>
                          </sup>
                          <br />
                          {product?.head}
                        </td>
                        <td className="w-1/4 pt-2">
                          APY
                          <sup>
                            <a href="#disclosure">†</a>
                          </sup>
                          <br />
                          {product?.head}
                        </td>
                      </tr>
                    )}
                    {product?.terms.map((term, termIndex) => (
                      <>
                        <tr key={termIndex} className="border-b paragraph-2-regular">
                          <td className="">
                            {term?.title}
                            <br />
                            {buildMinMaxNote(
                              term?.configuration?.Min as string,
                              term?.configuration?.Max as string,
                              RateType.Term
                            )}
                          </td>
                          {term?.rates.map((rate, rateIndex) => (
                            <td className="" key={rateIndex}>
                              {rate?.annualPercentage}
                            </td>
                          ))}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                {((product?.configuration?.Min as string) ||
                  (product?.configuration?.Max as string)) && (
                  <p className="caption">
                    {buildMinMaxNote(
                      product?.configuration?.Min as string,
                      product?.configuration?.Max as string,
                      RateType.Product
                    )}
                  </p>
                )}
                {((product?.configuration?.Apply as string) ||
                  (product?.configuration?.Learn as string)) && (
                  <div className="flex flex-col sm:flex-row gap-4">
                    {(product?.configuration?.Apply as string) && (
                      <Button
                        type="default"
                        tag="a"
                        href={product?.configuration?.Apply as string}
                        label={depositOpenAccountLinkText}
                      />
                    )}
                    {(product?.configuration?.Learn as string) && (
                      <Button
                        type="secondary"
                        tag="a"
                        href={product?.configuration?.Learn as string}
                        label={depositLearnMoreLinkText}
                      />
                    )}
                  </div>
                )}
                {product?.tail && <div className="mt-4">{product?.tail}</div>}
                {rates?.tail && (
                  <a href="#disclosure" aria-label="See disclosures">
                    See Disclosures
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}

      {/* Deposit Disclosures */}
      {rates?.tail && (
        <div className="bg-background-dark-1 py-4 px-8 mt-8 flex flex-col gap-y-4">
          <p className="paragraph-2-medium" id="disclosure">
            Disclosures
          </p>
          <RichTextA11yWrapper className="rtaw caption" field={{ value: rates?.tail }} />
        </div>
      )}
    </>
  );

  const BuildLoanView = (
    <>
      {rates?.groups.map((group, groupIndex) => {
        let previousTail = '';
        return (
          <div key={groupIndex} className="spacing-md first-of-type:mt-8">
            {group?.products.map((product, productIndex) => {
              const currentTail = product?.tail as string;
              const isDifferentTail = currentTail && currentTail !== previousTail;
              previousTail = currentTail;
              isDifferentTail ? ++disclosureCounter : disclosureCounter;
              return (
                <div
                  key={productIndex}
                  className="flex flex-col gap-y-4 sm:gap-y-6 spacing-md first-of-type:mt-0"
                >
                  <h3 className="">{product?.title}</h3>
                  <table className="w-full" cellPadding={16}>
                    <thead>
                      {product?.tiers.length == 2 ? (
                        <tr className="bg-background-dark-2 paragraph-2-medium">
                          <td className="w-1/2"></td>
                          <td className="w-1/4">{product?.tiers[0]?.value}</td>
                          <td className="w-1/4">{product?.tiers[1]?.value}</td>
                        </tr>
                      ) : (
                        <tr className="bg-background-dark-2 paragraph-2-medium">
                          <td className="w-8/12">Term</td>
                          <td className="w-4/12">
                            APR
                            {product?.tail && (
                              <sup>
                                <a href="#disclosure">{disclosureCounter}</a>
                              </sup>
                            )}
                            <br />
                            {product?.head}
                          </td>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {product?.tiers.length == 2 && (
                        <tr className="bg-background-dark-2 paragraph-2-medium">
                          <td className="w-1/2">Term</td>
                          <td className="w-1/4">
                            APR
                            {product?.tail && (
                              <sup>
                                <a href="#disclosure">{disclosureCounter}</a>
                              </sup>
                            )}
                            <br />
                            {product?.head}
                          </td>
                          <td className="w-1/4">
                            APR
                            {product?.tail && (
                              <sup>
                                <a href="#disclosure">{disclosureCounter}</a>
                              </sup>
                            )}
                            <br />
                            {product?.head}
                          </td>
                        </tr>
                      )}
                      {product?.terms.map((term, termIndex) => (
                        <>
                          <tr key={termIndex} className="border-b paragraph-2-regular">
                            <td>
                              {term?.title}
                              <br />
                              {term?.tail}
                            </td>
                            {product?.tiers.length == 2 && term?.rates.length == 2 ? (
                              <>
                                <td>{term?.rates[0]?.annualPercentage}</td>
                                <td>{term?.rates[1]?.annualPercentage}</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {term?.rates[0]?.annualPercentage ?? term?.rates[0]?.interestRate}
                                </td>
                              </>
                            )}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {((product?.configuration?.Apply as string) ||
                    (product?.configuration?.Learn as string)) && (
                    <div className="flex flex-col sm:flex-row gap-4">
                      {(product?.configuration?.Apply as string) && (
                        <Button
                          type="default"
                          tag="a"
                          href={product?.configuration?.Apply as string}
                          label={loanOpenAccountLinkText}
                        />
                      )}
                      {(product?.configuration?.Learn as string) && (
                        <Button
                          type="secondary"
                          tag="a"
                          href={product?.configuration?.Learn as string}
                          label={loanLearnMoreLinkText}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      {/* Loan Disclosures */}
      {disclosureCounter > 0 &&
        ((disclosureCounter = 0),
        (
          <div className="bg-background-dark-1 py-4 px-8 mt-8 flex flex-col gap-y-4">
            <p className="paragraph-2-medium" id="disclosure">
              Disclosures
            </p>
            {rates?.groups.map((group) => {
              let previousTail = '';
              return (
                <>
                  {group?.products.map((product, productIndex) => {
                    const currentTail = product?.tail as string;
                    const isDifferentTail = currentTail && currentTail !== previousTail;
                    previousTail = currentTail;
                    isDifferentTail ? ++disclosureCounter : disclosureCounter;
                    return (
                      <>
                        {product?.tail && isDifferentTail && (
                          <>
                            <RichTextA11yWrapper
                              key={productIndex}
                              className="rtaw"
                              field={{
                                value: (product?.tail).replace(
                                  /(<[^>]+>)/,
                                  `$1<sup>${disclosureCounter}</sup> `
                                ),
                              }}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                </>
              );
            })}
          </div>
        ))}
    </>
  );

  // Fetch Rates
  const fetchRates = useCallback(async () => {
    try {
      const response = await fetch(NEXT_RATES_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });
      if (response.ok) {
        setRatesStatus('');
        const data = (await response.json()) as RatesResponse;
        setRates(data);
      } else {
        setRatesStatus(RATES_UNAVAILABLE);
      }
    } catch (error) {
      setRatesStatus(RATES_UNAVAILABLE);
    }
  }, []);

  useEffect(() => {
    if (request.BusinessLineId === 2 || request.BusinessLineId === 3) {
      fetchRates();
    }
  }, [fetchRates]);

  return (
    <>
      {(request.BusinessLineId == 2 || request.BusinessLineId === 3) && (
        <section className="container">
          {rates && (
            <>
              <p className="paragraph-2-medium pt-4 text-right">
                Effective: {format(new Date(rates?.effective), 'MMMM d, yyyy')}
              </p>
              <>
                {/* Deposit View */}
                {request.BusinessLineId === 2 && BuildDepositView}
                {/* Loan View */}
                {request.BusinessLineId === 3 && BuildLoanView}
              </>
            </>
          )}
          {!rates && <div className="flex justify-center my-4">{rateStatus}</div>}
        </section>
      )}
    </>
  );
};

export default MultiUseRates;
