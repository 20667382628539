// Element implementations for Sitecore Component Builder can be overriden here

import { useEffect } from 'react';

const toggleTemplate =
  '<label class="snippet-toggle-label" for="$TOGGLEID"><input type="checkbox" id="$TOGGLEID" name="$TOGGLEID" class="hidden" /><span class="snippet-toggle-btn" href="#" tabindex="0">$TOGGLELABEL<span class="snippet-toggle-down"></span></span><span class="snippet-toggle-content">$TOGGLETEXT</span></label>';

const ToggleContentScript = (): JSX.Element => {
  useEffect(() => {
    function handleToggles(): void {
      const toggles = document.getElementsByClassName('jsToggleSnippetSource');

      if (toggles.length > 0) {
        for (let i = 0; i < toggles.length; i++) {
          if (toggles[i].classList.contains('hidden')) {
            let toggleLabelText = 'Toggle';
            const toggleLabel = toggles[i].previousElementSibling;

            if (toggleLabel && toggleLabel.classList.contains('jsToggleSnippetLabel')) {
              toggleLabelText = toggleLabel.innerHTML;
            }

            const toggleInner = toggleTemplate
              .replace('$TOGGLETEXT', toggles[i].innerHTML)
              .replace('$TOGGLELABEL', toggleLabelText)
              .split('$TOGGLEID')
              .join('toggleBox' + i);
            toggles[i].innerHTML = toggleInner;

            const toggleBtn = toggles[i].querySelector('.snippet-toggle-btn');
            if (toggleBtn) {
              toggleBtn.addEventListener('click', () => {
                const toggleContent = toggleBtn.nextElementSibling;
                const toggleChevron = toggleBtn.querySelector('span');
                if (toggleContent && toggleContent.classList.contains('snippet-toggle-content')) {
                  toggleChevron?.classList.toggle('snippet-toggle-down');
                  toggleChevron?.classList.toggle('snippet-toggle-up');
                }
              });
            }

            toggles[i].classList.remove('hidden');
          }
        }
      }
    }

    if (document.readyState === 'complete') {
      handleToggles();
    } else {
      document.addEventListener('DOMContentLoaded', handleToggles);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('DOMContentLoaded', handleToggles);
    };
  });
  return <></>;
};

export default ToggleContentScript;
