// Global
import React from 'react';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Marketing } from 'lib/templates/Feature.BOKF.model';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';

type ChildrenType = {
  fields: {
    children: {
      id?: string;
      displayName?: string;
      name?: string;
      templateId?: string;
      templateName?: string;
      url?: string;
      fields: {
        Link: {
          value: {
            href: string;
            text: string;
            linktype: string;
            target: string;
            id: string;
            class?: string;
          };
        };
      };
    }[];
  };
};

type LayoutProps = Marketing.DataTemplates.Ctacollection & ChildrenType;

export type CopyBlockProps = {
  rendering: ComponentRendering;
  params: { AnchorID?: string; HeadingType?: { Styles?: Field<string>; Tag?: Field<string> } };
} & LayoutProps;

const CopyBlock = (props: CopyBlockProps): JSX.Element => {
  const hasTitleUnderline =
    props.params?.HeadingType?.Styles?.value.includes('title-border') || false;
  const hasThickUnderline = props.params?.HeadingType?.Styles?.value.includes('thick');
  const hasButtons = props.fields?.children?.some((item) => item?.url);

  return (
    <div className={`component flex flex-col gap-y-4`} id={props.params?.AnchorID || ''}>
      <RichTextA11yWrapper
        className="text-color-default-1"
        field={props.fields?.Title}
        tag={props.params?.HeadingType?.Tag?.value || 'h2'}
      />
      {hasTitleUnderline && (
        <div
          className={`${
            hasThickUnderline ? 'border-b-[5px]' : 'border-b-[3px]'
          } mt-2 border-strokes-brand w-[3rem] small-red-line`}
        ></div>
      )}
      <RichTextA11yWrapper
        className="paragraph-2-regular text-color-default-2 rtaw"
        field={props.fields?.CopyText}
      />
      {hasButtons && (
        <div className="flex flex-col md:flex-row gap-4 flex-center">
          {props.fields?.children?.map((item, key) => {
            return (
              <Button
                key={key}
                tag="a"
                type={item.templateName === 'PrimaryButton' ? 'default' : 'secondary'}
                size="large"
                field={item?.fields?.Link}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CopyBlock;
