import React from 'react';
import { Text, GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { BreadcrumbsProps } from './Breadcrumbs.types';
import BreadcrumbQuery from './Breadcrumb.graphql';
import clientFactory from 'lib/graphql-client-factory';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

const Breadcrumbs = (staticProps: BreadcrumbsProps): JSX.Element => {
  const { fields } = staticProps;
  if (!fields) {
    return <></>;
  }
  const { item } = fields?.data;
  const reversedAncestors = item && [...item.ancestors].reverse();
  const totalAncestors = reversedAncestors.length;
  return (
    <section className="pb-1" aria-label="breadcrumbs">
      <nav className="container">
        <div className="flex flex-row">
          <ol className="flex text-color-default-2 flex-wrap">
            {reversedAncestors.map((ancestor, index) => {
              const isHidden = index < totalAncestors - 1;
              const isEllipsis = index >= totalAncestors - 1;
              return (
                <li key={ancestor.id}>
                  {isEllipsis ? <span className="md:hidden mr-2"> ... / </span> : ''}
                  <LinkWrapper
                    field={{ value: { href: ancestor.url.path } }}
                    className={classNames(
                      'caption-underline text-color-default-2 hover:text-color-link-hover',
                      {
                        'hidden md:inline': isHidden,
                      }
                    )}
                  >
                    <Text field={ancestor.PageTitle} />
                  </LinkWrapper>

                  {index < item.ancestors.length && (
                    <span
                      className={classNames('mx-2', {
                        'hidden md:inline': isHidden,
                      })}
                    >
                      /
                    </span>
                  )}
                </li>
              );
            })}
            <li aria-current="page">
              <Text
                field={item.PageTitle}
                tag="span"
                className={classNames('caption text-color-default-2')}
              />
            </li>
          </ol>
        </div>
      </nav>
    </section>
  );
};
export default Breadcrumbs;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<BreadcrumbsProps>(BreadcrumbQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
    contextItem: layoutData?.sitecore?.route?.itemId,
  });
  return {
    staticProps: result,
  };
};
