import {
  Field,
  Text,
  ImageField,
  LinkField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import React from 'react';
import { useDictionary } from 'lib/hooks/use-dictionary';

type ExpertiseProps = {
  fields: {
    Expertise: Field<string>;
  };
};

export type ExportDetailsProps = {
  ExpertImage: ImageField;
  ExpertFirstName: Field<string>;
  ExpertLastName: Field<string>;
  ExpertSubtitle: Field<string>;
  CompanyName: { fields: { CompanyName: Field<string> } };
  Expertise: Array<ExpertiseProps>;
  ExpertBio: Field<string>;
  ExpertLink: LinkField;
};

const ExpertDetailsCard = () => {
  const { sitecoreContext } = useSitecoreContext();
  const dictionary = useDictionary();

  const {
    ExpertImage,
    ExpertFirstName,
    ExpertLastName,
    ExpertSubtitle,
    Expertise,
    CompanyName,
    ExpertBio,
    ExpertLink,
  } = sitecoreContext?.route?.fields as unknown as ExportDetailsProps;

  const FullName = ExpertFirstName ? `${ExpertFirstName?.value} ${ExpertLastName?.value}` : '';

  return (
    <section className="component container spacing-sm">
      <div className="p-4 md:p-8 bg-white border border-strokes-default-3 rounded-lg flex flex-col md:flex-row gap-8">
        {ExpertImage?.value?.src && (
          <div className="max-w-full md:min-w-[180px] md:max-w-[180px] lg:min-w-[327px] lg:max-w-[327px]">
            <ImageWrapper
              field={ExpertImage}
              height="327"
              width="409"
              className={'aspect-[327/409] object-cover w-full'}
            />
          </div>
        )}

        <div
          className={classNames(
            'flex flex-col gap-6 w-full',
            ExpertImage ? 'lg:w-[calc(100%-327px)]' : ''
          )}
        >
          <div className="relative ps-4 after:absolute after:bg-background-brand after:left-0 after:top-0 after:h-full after:w-[2px]">
            <Text tag="h3" field={{ value: FullName }} className="text-color-default-1" />

            <Text
              tag="p"
              field={ExpertSubtitle}
              className="paragraph-2-regular text-color-default-2"
            />

            <Text
              tag="p"
              field={CompanyName?.fields?.CompanyName}
              className="paragraph-3-regular text-color-default-2"
            />
          </div>
          <div>
            <p className="caption-medium mb-2">
              {dictionary?.['Experts.Expertise.Label'] || 'Expertise'}
            </p>
            {Expertise && Expertise.length > 0 && (
              <ul className="flex flex-wrap sm:flex-nowrap gap-x-10 gap-y-2">
                {Expertise?.slice(0, 3)?.map((item: ExpertiseProps, index: number) => (
                  <li key={index} className="grow">
                    <Text
                      field={item?.fields?.Expertise}
                      tag="span"
                      className="paragraph-1-regular text-color-default-2"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>

          <hr className="solid border-color-default-3 my-0" />

          <RichTextA11yWrapper
            className="paragraph-2-regular rtaw text-color-default-1"
            field={ExpertBio}
          />
          <div>
            <LinkWrapper
              className="text-color-active hover:underline hover:cursor-pointer paragraph-2-medium"
              field={ExpertLink}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExpertDetailsCard;
