import React from 'react';
import { Profiles } from 'lib/templates/Feature.BOKF.model';
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { useDictionary } from 'lib/hooks/use-dictionary';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Button from 'helpers/Button/Button';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import useExperienceEditor from 'lib/use-experience-editor';

export type AdvisorProfileProps = Profiles.DataTypes.AdvisorProfile & {
  fields: {
    PageTitle?: Field<string>;
    MetaDescription?: Field<string>;
    MetaOGDescription?: Field<string>;
    MetaKeywords?: Field<string>;
  };
};

const AdvisorProfile = (props: AdvisorProfileProps) => {
  const { fields = undefined } = props;

  const dict = useDictionary();

  const isEE = useExperienceEditor();

  const AdvisorConnect = process.env.IS_STORYBOOK
    ? "Let's Connect"
    : dict?.['Feature.Profiles.AdvisorProfileConnect'] || "Let's Connect";

  return (
    <section className="component container spacing-sm">
      <div className="container-inner">
        <div className="p-4 bg-white border border-strokes-default-3 rounded flex flex-col gap-y-4 gap-x-6 md:p-6 md:gap-y-8 lg:p-8 lg:flex-row">
          <div className="flex flex-row gap-x-6 gap-y-8 lg:flex-col lg:gap-y-6 lg:min-w-[208px] lg:max-w-[208px]">
            <ImageWrapper
              field={fields?.Image}
              height="258"
              width="208"
              className="w-1/3 md:w-[164px] lg:w-full object-cover min-w-[114px] max-w-[208px] aspect-[208/258]"
            />
            <div className="flex flex-col mobile:w-full w-2/3 md:w-[calc(100%-164px)] lg:w-full gap-2 justify-center lg:justify-normal">
              <h4 className="flex flex-row flex-wrap text-color-brand mt-0 mobile:mt-4">
                <Text tag="span" field={fields?.FirstName} />
                &nbsp;
                <Text tag="span" field={fields?.LastName} />
              </h4>

              <Text
                tag="p"
                field={fields?.Title}
                className="paragraph-2-medium text-color-default-1"
              />
              <Text
                tag="p"
                field={fields?.['Advisor Team']}
                className="paragraph-3-regular text-color-default-2"
              />
              <ImageWrapper
                field={fields?.Logo}
                width="208"
                height="50"
                className="w-[158px] md:w-[208px] md:mt-4"
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-3 md:gap-y-4">
            <Text
              tag="h4"
              field={{ value: AdvisorConnect }}
              className="text-color-default-1 md:mb-2 lg:mb-0"
            />
            <div className="flex flex-col sm:flex-row gap-y-3 gap-x-6 w-full lg:min-h-[200px]">
              <div className="flex flex-col gap-y-3 md:gap-y-4 flex-1">
                {(fields?.Phone?.value !== '' || isEE) && (
                  <p className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                    <Text tag="span" field={{ value: 'T' }} />
                    {
                      <LinkWrapper
                        className="paragraph-2-regular text-color-default-1"
                        field={{
                          value: {
                            href: `tel:+${fields?.Phone?.value.replace(/-/g, '')}`,
                          },
                        }}
                      >
                        <Text field={fields?.Phone} />
                      </LinkWrapper>
                    }
                  </p>
                )}
                {(fields?.Mobile?.value !== '' || isEE) && (
                  <p className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                    <Text tag="span" field={{ value: 'M' }} />
                    {
                      <LinkWrapper
                        className="paragraph-2-regular text-color-default-1"
                        field={{
                          value: {
                            href: `tel:+${fields?.Mobile?.value.replace(/-/g, '')}`,
                          },
                        }}
                      >
                        <Text field={fields?.Mobile} />
                      </LinkWrapper>
                    }
                  </p>
                )}
                {(fields?.Fax?.value !== '' || isEE) && (
                  <p className="flex flex-row text-color-brand gap-x-2 paragraph-2-medium">
                    <Text tag="span" field={{ value: 'F' }} />
                    {
                      <LinkWrapper
                        className="paragraph-2-regular text-color-default-1"
                        field={{
                          value: {
                            href: `tel:+${fields?.Fax?.value.replace(/-/g, '')}`,
                          },
                        }}
                      >
                        <Text field={fields?.Fax} />
                      </LinkWrapper>
                    }
                  </p>
                )}

                <LinkWrapper
                  className="text-color-active paragraph-2-medium hover:underline"
                  field={{
                    value: {
                      href: `mailto:${fields?.Email?.value}`,
                    },
                  }}
                >
                  <Text field={fields?.Email} />
                </LinkWrapper>
              </div>
              <div className="flex flex-col flex-1 gap-y-4">
                <div>
                  {(fields?.Address1 || isEE) && (
                    <RichTextA11yWrapper
                      className="text-color-default-1 paragraph-2-regular"
                      tag="div"
                      field={fields?.Address1}
                    />
                  )}
                  {(fields?.Address2 || isEE) && (
                    <RichTextA11yWrapper
                      className="text-color-default-1 paragraph-2-regular"
                      tag="div"
                      field={fields?.Address2}
                    />
                  )}
                  <div className="text-color-default-1 paragraph-2-regular">
                    <Text field={fields?.City} />
                    ,&nbsp;
                    <Text field={fields?.State?.fields.StateName as Field<string>} />
                    &nbsp;
                    <Text field={fields?.Zip} />
                  </div>
                </div>
                <LinkWrapper
                  className="text-color-active hover:underline hover:cursor-pointer paragraph-2-medium"
                  field={fields?.Link || {}}
                />
                <div className="lg:mt-4">
                  <Button
                    type="default"
                    size="full"
                    tag="a"
                    field={fields?.ApplicationLink}
                    fieldClass="!py-[11px]"
                  />
                </div>
              </div>
            </div>
            <hr className="solid divide-strokes-default-3 my-1 md:my-0"></hr>
            <div>
              <RichTextA11yWrapper
                className="paragraph-2-regular rtaw text-color-default-2"
                field={fields?.ProfileBio}
              />
              {(fields?.['ApplicationLink 2'] || isEE) && (
                <div className="mt-4">
                  <Button
                    type="auxiliary"
                    size="large"
                    tag="a"
                    field={fields?.['ApplicationLink 2']}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvisorProfile;
