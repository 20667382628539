import React from 'react';
import { ComponentParams, Item } from '@sitecore-jss/sitecore-jss-nextjs';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { FilterAnd, FilterAnyOf, FilterNot } from '@sitecore-search/react';

import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import { SitecoreIds } from 'lib/constants/sitecore-ids';
import { ResourceListWidget, ResourceProps } from 'widgets/ResourceListWidget/ResourceListWidget';
import { useCurrentScreenType, getBreakpoint } from 'lib/utils/get-screen-type';
import { useDictionary } from 'lib/hooks/use-dictionary';
import {
  CommonArticleProp,
  filterValuesProps,
  initialFilterQuery,
} from '../ArticleDynamicCollection/ArticleDynamicCollection';

type AuthorableFilterTypes =
  | 'PageTitle'
  | 'Name'
  | 'ExpertFirstName'
  | 'ExpertLastName'
  | 'AuthorName';

type CommonFiltersRecord = 'Topics' | 'Authors' | 'Category' | 'Experts' | 'ContentType';

type CategoryType = 'category-1' | 'category-2' | 'category-3' | 'category-4';

type FilterType = Item & { fields?: Partial<Record<AuthorableFilterTypes, Field<string>>> } & {
  fields?: { Color: { fields: { Value: Field<string> } } };
};

type CommonFilters = Record<CommonFiltersRecord, Array<FilterType>>;

type includedArticleProps = CommonArticleProp & {
  fields?: CommonFilters;
};

export interface ResourceSearchProps {
  params: ComponentParams & {
    FeaturedTitleText?: string;
    InitialCountDesktop?: string;
    InitialCountMobile?: string;
    LoadMoreCount?: string;
    LoadMoreText?: string;
    SkipItemFromSearch?: string;
  };
  fields: CommonFilters & {
    ArticlesToExclude?: Array<ResourceProps>;
    ArticlesToInclude?: Array<ResourceProps & { Color: { fields: { Value: Field<string> } } }>;
  };
}

type PageTypes = 'AuthorDetailsPage' | 'ExpertDetailsPage';

const RelatedResources = (props: ResourceSearchProps): JSX.Element => {
  const {
    params: {
      FeaturedTitleText = '',
      InitialCountDesktop = '9',
      InitialCountMobile = '4',
      LoadMoreText = 'View More',
      LoadMoreCount = '4',
      SkipItemFromSearch = '0',
    },
    fields = undefined,
  } = props;

  const { sitecoreContext } = useSitecoreContext();
  const dictionary = useDictionary();
  const { currentScreenWidth, isScreenTypeDetected } = useCurrentScreenType();

  const pageType: PageTypes | undefined = (() => {
    for (const [, { templateId, templateName }] of Object.entries(SitecoreIds.Route)) {
      if (templateId === sitecoreContext.route?.templateId) {
        return templateName;
      }
    }
    return undefined;
  })() as PageTypes;

  const filterTypes: Record<PageTypes, string> = {
    AuthorDetailsPage: 'author',
    ExpertDetailsPage: 'experts',
  };

  const filterValues: Record<PageTypes, Array<string>> = {
    AuthorDetailsPage: [(sitecoreContext.route?.fields?.AuthorName as Field<string>)?.value],
    ExpertDetailsPage: [
      `${(sitecoreContext.route?.fields?.ExpertFirstName as Field<string>)?.value || ''} ${
        (sitecoreContext.route?.fields?.ExpertLastName as Field<string>)?.value
      }`,
    ],
  };

  function isValidCategory(value: string): value is CategoryType {
    return ['category-1', 'category-2', 'category-3', 'category-4'].includes(value);
  }

  let filters: (FilterAnyOf | FilterNot)[] = [];

  const datafilterValues: filterValuesProps = {
    filterCategory: fields?.Category as Array<FilterType>,
    filterTopics: fields?.Topics as Array<FilterType>,
    filterContentType: fields?.ContentType as Array<FilterType>,
    filterAuthors: fields?.Authors as Array<FilterType>,
    filterExperts: fields?.Experts as Array<FilterType>,
    filterArticlesToExclude: fields?.ArticlesToExclude as Array<ResourceProps>,
    filterArticlesToInclude: fields?.ArticlesToInclude as Array<ResourceProps>,
  };

  if (fields) {
    filters = initialFilterQuery(datafilterValues);
  }

  // Function to include resources
  const IncludedResources = () => {
    const _includedResources: Array<ResourceProps> = [];
    let showAricle: ResourceProps = {};

    props?.fields?.ArticlesToInclude?.map((article: includedArticleProps) => {
      showAricle = {
        article_time: article?.fields?.ReadTime?.value ?? '',
        description: article?.fields?.Teaser?.value ?? '',
        id: article?.id ?? '',
        image_url: article?.fields?.Hero?.value?.src ?? '',
        media_url: article?.fields?.VideoUrl?.value ?? '',
        name: article?.fields?.Headline?.value ?? '',
        publish_date: article?.fields?.PublishDate?.value ?? '',
        url: article?.url,
        type:
          article?.fields?.ContentType?.fields.Name?.value === 'Video'
            ? 'VideoResource'
            : 'Article',
        category_color: article?.fields?.Category?.map(
          (item) => item?.fields?.Color?.fields?.Value?.value
        ).filter(isValidCategory),
        category: article?.fields?.Category?.map((item) => item?.fields?.PageTitle?.value ?? ''),
      };
      _includedResources.push(showAricle);
    });

    return _includedResources;
  };

  if (!isScreenTypeDetected) return <></>;

  return (
    <WidgetWrapper>
      <ResourceListWidget
        rfkId="related_resources"
        title={{ tag: 'h3', text: FeaturedTitleText, classes: 'mb-4 text-color-default-1' }}
        initialCount={
          currentScreenWidth <= getBreakpoint('desktop') ? InitialCountMobile : InitialCountDesktop
        }
        filters={
          fields
            ? filters.length > 0
              ? new FilterAnd(filters)
              : undefined
            : filterTypes[pageType] && filterValues[pageType]
            ? new FilterAnyOf(filterTypes[pageType], filterValues[pageType])
            : undefined
        }
        SkipItemFromSearch={+SkipItemFromSearch || 0}
        verticlePaginationOptions={{ loadMoreText: LoadMoreText, loadCount: LoadMoreCount }}
        noResultsText={
          dictionary?.['Article.No.Results'] || 'No articles found matching your search criteria.'
        }
        IncludedResources={IncludedResources().length > 0 ? IncludedResources() : undefined}
      />
    </WidgetWrapper>
  );
};

export default RelatedResources;
