// Global
import React from 'react';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { useCurrentScreenType } from 'lib/utils/get-screen-type';

// Local
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';

export type CTAProps = {
  params?: {
    AnchorID?: string;
    EnableLightMode?: string;
    Alignment?: {
      Value?: {
        value?: string;
      };
    };
    BackgroundColor?: {
      Value?: {
        value?: string;
      };
    };
    Anchor?: {
      Value?: {
        value?: string;
      };
    };
  };
  fields?: {
    children?: {
      templateName: string;
      templateId: string;
      fields: {
        Link: LinkField;
      };
    }[];
  };
};

export type CTACollectionProps = Marketing.DataTemplates.Ctacollection &
  Marketing.RenderingParameters.CtacollectionParameters &
  CTAProps;

const CTACollection = (props: CTACollectionProps): JSX.Element => {
  const { Title, CopyText, DesktopImage, TabletImage, MobileImage } = props?.fields || {};
  const { EnableLightMode, Alignment, BackgroundColor } = props?.params || {};
  const children = props?.fields?.children || [];
  const { screenType } = useCurrentScreenType();
  const getBackgroundImage = (): string => {
    if (screenType === 'mobile' && MobileImage?.value?.src) {
      return MobileImage?.value?.src as string;
    } else if (screenType === 'tablet' && TabletImage?.value?.src) {
      return TabletImage?.value?.src as string;
    } else if (screenType === 'mobile' && !MobileImage?.value?.src) {
      return DesktopImage?.value?.src as string;
    } else if (screenType === 'tablet' && !TabletImage?.value?.src) {
      return DesktopImage?.value?.src as string;
    } else {
      return DesktopImage?.value?.src as string;
    }
  };
  const backgroundImageUrl = getBackgroundImage();

  return (
    <section
      className={`component spacing-md ${
        BackgroundColor?.Value?.value ? BackgroundColor?.Value?.value : ''
      }`}
      style={{
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundSize: backgroundImageUrl ? 'cover' : '',
        backgroundPosition: backgroundImageUrl ? 'center' : '',
      }}
      id={props.params?.AnchorID || ''}
    >
      <div className="container-inner">
        <div
          className={`${EnableLightMode !== '1' ? 'text-color' : 'text-color-inverse'} ${
            Alignment?.Value?.value === 'text-center' ? 'text-center' : ''
          } pt-4 pb-2 lg:py-8 px-0 lg:px-8 mx-4 md:mx-4 lg:mx-auto`}
        >
          <RichTextA11yWrapper field={Title} tag="h2" className="rtaw" />
          {CopyText?.value ? <RichTextA11yWrapper field={CopyText} className="rtaw my-4" /> : null}
          <div
            className={`flex flex-col md:flex-row flex-wrap gap-y-4 gap-x-4 mt-6 ${
              Alignment?.Value?.value === 'text-center' ? 'justify-center' : 'justify-start'
            }`}
          >
            {children?.map((item, key) => {
              return (
                <>
                  {(item.templateId.toUpperCase() === '99F1797B-70A8-4FC2-9AC1-810A3F7DEAD6' ||
                    item.templateName === 'PrimaryButton') && (
                    <Button
                      key={key}
                      type="default"
                      size="large"
                      title={(item?.fields?.Link as LinkField).value?.text}
                      field={item?.fields?.Link as LinkField}
                    />
                  )}
                  {(item.templateId.toUpperCase() === 'F7D492A3-B73E-4F92-8EF7-0C5923A8B6FB' ||
                    item.templateName === 'SecondaryButton') && (
                    <Button
                      key={key}
                      type="secondary"
                      size="large"
                      title={(item?.fields?.Link as LinkField).value?.text}
                      field={item?.fields?.Link as LinkField}
                    />
                  )}
                  {(item.templateId.toUpperCase() === 'C52E1186-541E-4044-9173-FEF201A01A5C' ||
                    item.templateName === 'ActionButton') && (
                    <span
                      className={`text-color-active hover:underline paragraph-2-medium ${
                        EnableLightMode !== '1' ? 'text-color-active' : 'text-color-inverse'
                      } bg-button-action-icon bg-no-repeat bg-right`}
                    >
                      <Button
                        key={key}
                        type="action"
                        size="large"
                        tag="a"
                        field={item?.fields?.Link as LinkField}
                      />
                    </span>
                  )}
                  {(item.templateId.toUpperCase() === '4AF3DF91-8218-4891-92C7-98EF4E49DA68' ||
                    item.templateName === 'InlineTextButton') && (
                    <Button
                      key={key}
                      type="auxiliary"
                      size="large"
                      title={(item?.fields?.Link as LinkField).value?.text}
                      field={item?.fields?.Link as LinkField}
                    />
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTACollection;
