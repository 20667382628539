const BreadcrumbQuery = `query ItemWithAncestorsQuery ($contextItem: String!, $language: String!) {
  item(path: $contextItem, language: $language) {
    id
    name
    PageTitle: field(name: "PageTitle") {
      value
    }
    ancestors(hasLayout:true) {
      id
      name
      PageTitle: field(name: "PageTitle") {
        value
      }
      url{
        path
      }
    }
  }
}`;
export default BreadcrumbQuery;
