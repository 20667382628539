export const createMyLocationIcon = () => {
  const svgMarkerIcon = {
    path: 'M26.589 3.705l-11.393 25.807c-0.192 0.435-0.574 0.709-0.99 0.71-0.090 0.001-0.18-0.012-0.267-0.037-0.501-0.144-0.854-0.673-0.854-1.281v-10.849c0-0.121-0.084-0.22-0.187-0.22h-9.211c-0.515-0.001-0.964-0.415-1.086-1.005s0.113-1.201 0.57-1.482l21.911-13.422c0.433-0.264 0.962-0.167 1.307 0.24s0.426 1.030 0.2 1.54z',
    fillColor: '#3964BF',
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: '#3964BF',
    strokeOpacity: 0.3,
    rotation: 0,
    scale: 1.125,
    anchor: new google.maps.Point(16, 32),
  };
  return svgMarkerIcon;
};

export const createSvgGradientIcon = () => {
  const svgIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 21 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.7022 1.32812C5.45807 1.32812 1.19141 5.72812 1.19141 11.1361C1.19141 16.3255 9.79456 29.8228 10.163 30.3935C10.2807 30.5815 10.485 30.6935 10.7022 30.6935C10.9181 30.6935 11.1211 30.5815 11.2401 30.3935C11.6072 29.8228 20.2117 16.3255 20.2117 11.1361C20.2117 5.72812 15.945 1.32812 10.7022 1.32812Z" fill="#3964BF"/><path d="M10.163 30.3935L10.5869 30.1282L10.5831 30.1222L10.163 30.3935ZM11.2401 30.3935L10.8196 30.1229L10.8175 30.1261L11.2401 30.3935ZM10.7022 0.828125C5.16754 0.828125 0.691406 5.4666 0.691406 11.1361H1.69141C1.69141 5.98965 5.74861 1.82812 10.7022 1.82812V0.828125ZM0.691406 11.1361C0.691406 12.5382 1.26371 14.4329 2.07802 16.4419C2.89977 18.4692 3.99586 20.6783 5.09846 22.7332C7.30413 26.844 9.55494 30.3734 9.743 30.6647L10.5831 30.1222C10.4027 29.8428 8.16765 26.3383 5.97963 22.2604C4.88537 20.221 3.80765 18.047 3.00479 16.0663C2.19449 14.0672 1.69141 12.3287 1.69141 11.1361H0.691406ZM9.7392 30.6587C9.94816 30.9926 10.3125 31.1935 10.7022 31.1935V30.1935C10.6575 30.1935 10.6132 30.1703 10.5869 30.1282L9.7392 30.6587ZM10.7022 31.1935C11.0927 31.1935 11.4537 30.9909 11.6626 30.6608L10.8175 30.1261C10.7885 30.172 10.7435 30.1935 10.7022 30.1935V31.1935ZM11.6605 30.664C11.8474 30.3735 14.0983 26.8441 16.3041 22.7332C17.4068 20.6783 18.503 18.4693 19.3249 16.4419C20.1393 14.4329 20.7117 12.5382 20.7117 11.1361H19.7117C19.7117 12.3287 19.2085 14.0672 18.3981 16.0662C17.5952 18.047 16.5173 20.221 15.423 22.2604C13.2349 26.3382 10.9998 29.8427 10.8196 30.1229L11.6605 30.664ZM20.7117 11.1361C20.7117 5.46664 16.2356 0.828125 10.7022 0.828125V1.82812C15.6544 1.82812 19.7117 5.98961 19.7117 11.1361H20.7117Z" fill="#F2F2F2"/></svg>';

  const iconUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgIcon);

  const myIcon = {
    url: iconUrl,
    labelOrigin: new google.maps.Point(24, 18),
  };

  return myIcon;
};

export const createSvgMarkerIcon = () => {
  const svgMarkerIcon = {
    path: 'M16.2632 1.3335C11.0191 1.3335 6.75244 5.7335 6.75244 11.1415C6.75244 16.3308 15.3556 29.8282 15.7241 30.3988C15.8417 30.5868 16.046 30.6988 16.2632 30.6988C16.4791 30.6988 16.6821 30.5868 16.8011 30.3988C17.1683 29.8282 25.7727 16.3308 25.7727 11.1415C25.7727 5.7335 21.5061 1.3335 16.2632 1.3335Z',
    fillColor: '#3964BF',
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: 'white',
    strokeOpacity: 0,
    rotation: 0,
    scale: 1.5,
    scaledSize: new google.maps.Size(32, 32),
    anchor: new google.maps.Point(16, 32), // Adjust anchor point if needed
    labelOrigin: new google.maps.Point(16.3, 12),
  };

  return svgMarkerIcon;
};
