import React from 'react';
import WidgetWrapper from 'helpers/WidgetWrapper/WidgetWrapper';
import BankerProfileSearchWidget from 'widgets/BankerProfileSearchWidget/BankerProfileSearchWidget';
import { Profiles } from 'lib/templates/Feature.BOKF.model';
import { ComponentProps } from 'lib/component-props';
import { WidgetComponentProps } from '@sitecore-search/react/dist/esm/types';

export type BankerSearchProps = Profiles.DataTypes.BankerSearch &
  ComponentProps &
  WidgetComponentProps;

const BankerProfileSearch = (props: BankerSearchProps) => {
  return (
    <WidgetWrapper>
      <BankerProfileSearchWidget props={props} rfkId={'banker_profile'} defaultItemsPerPage={100} />
    </WidgetWrapper>
  );
};

export default BankerProfileSearch;
