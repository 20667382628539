//Global
import React, { useState } from 'react';

import { useSitecoreContext, Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';

//helper
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import ModalWrapper from 'helpers/ModalWrapper/ModalWrapper';

export type ArticleTitles = {
  SectionTitle: string;
};

export type Fields = {
  ArticleTitles: ArticleTitles[];
};

export type TableOfContentProps = {
  fields: Fields;
};

export type TableOfContentPageProps = {
  Headline: Field<string>;
};

const TableOfContent = (props: TableOfContentProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const { Headline } = sitecoreContext?.route?.fields as TableOfContentPageProps;

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const createSlug = (str: string) => {
    return str && str.replaceAll(' ', '')?.toLocaleLowerCase();
  };

  const getNavItems = () => {
    return (
      <nav>
        <ul role="list">
          <li>
            <Text
              field={Headline}
              tag="span"
              className="block p-4 text-color-default-1 paragraph-2-medium"
            />
            <hr className="my-0" />
          </li>
          {props?.fields?.ArticleTitles.map((item, index) => (
            <>
              <li
                key={index}
                onClick={() => {
                  setActiveIndex(index);
                  isModalOpen && setIsModalOpen(!isModalOpen);
                }}
              >
                <LinkWrapper
                  field={{
                    value: { text: item.SectionTitle, href: `#${createSlug(item?.SectionTitle)}` },
                  }}
                  className={`block p-4 hover:bg-background-dark-1 focus:bg-background-dark-1 text-color-active hover:text-color-active hover:no-underline focus:rounded-sm focus:outline-2 focus:bg-background-dark-1 focus:outline-strokes-action focus:no-underline  ${
                    activeIndex == index
                      ? 'text-color-default-1 hover:text-color-default-1 paragraph-3-regular'
                      : 'paragraph-3-medium'
                  }`}
                ></LinkWrapper>
                <hr className="my-0" />
              </li>
            </>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <>
      <div
        className={`fixed bottom-12 right-8 block lg:hidden z-[9999] ${
          !isModalOpen ? 'block' : 'hidden'
        }`}
      >
        <button
          onClick={() => {
            setIsModalOpen(true);
          }}
          className="flex h-12 w-12 px-4 py-2 items-center justify-center rounded-3xl border bg-background-default-2 shadow-small outline-none"
        >
          <SVG className="[&_svg]:h-6 [&_svg]:w-6 text-color-active" svg={'icon-ascending'} />
        </button>
      </div>

      <aside className="hidden lg:block max-w-[353px] w-[353px] shadow-small bg-background-default-1 sticky top-20">
        {getNavItems()}
      </aside>

      {isModalOpen && (
        <ModalWrapper
          position="center"
          modalWidth="w-full"
          //onClose={() => {}}
          isCloseBtnShown={false}
          isMarginAvailable={false}
        >
          <div className={`absolute -bottom-16 md:-bottom-24 right-0 block lg:hidden z-[9999]`}>
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="flex h-12 w-12 px-4 py-2 items-center justify-center rounded-3xl border bg-background-default-2 shadow-small outline-none"
            >
              <SVG className="[&_svg]:h-6 [&_svg]:w-6 text-color-active" svg={'icon-close'} />
            </button>
          </div>
          {getNavItems()}
        </ModalWrapper>
      )}
    </>
  );
};

export default TableOfContent;
