import { useEffect, useRef } from 'react';
import { LocationData } from './LocationTypes';
import classNames from 'classnames';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';

import { useDictionary } from 'lib/hooks/use-dictionary';
import { useCurrentScreenType, getBreakpoint } from 'lib/utils/get-screen-type';

// LocationList component with scrollbar
const LocationList: React.FC<{
  errorMessage: string | null;
  locations: LocationData[];
  scrollToLocationIndex: number | null;
  setLocationItemIndex: (index: number | null) => void;
  setScrollToLocationIndex: (index: number | null) => void;
}> = ({
  errorMessage,
  locations,
  scrollToLocationIndex,
  setLocationItemIndex,
  setScrollToLocationIndex,
}) => {
  const locationRefs = useRef<(HTMLDivElement | null)[]>([]);
  const locationListRef = useRef<HTMLDivElement>(null);
  const innerLocationListRef = useRef<HTMLDivElement>(null);

  const { currentScreenWidth } = useCurrentScreenType();
  const dict = useDictionary();

  let locationName = '';
  if (process.env.IS_STORYBOOK) {
    // if we're in storybook, just mock banker data because useDictionary() does not work in storybook
    locationName = 'Financial Institution';
  } else {
    locationName = dict?.['Foundation.Locations.DefaultLocationName'] || 'Financial Institution';
  }

  const handleTopicsMaskVisibility = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (locationListRef.current) {
      const locationListElement = e.currentTarget;
      if (locationListElement.children.length > 0) {
        const topicsListElementYAxis = locationListElement.getBoundingClientRect();
        const firstTopicElementYAxis =
          locationListElement.firstElementChild?.getBoundingClientRect().y;

        const lastTopicElementYAxis =
          locationListElement.lastElementChild?.getBoundingClientRect().y;

        const beforeElementVisibileClass = 'before:!inline-block';
        const afterElementVisibileClass = 'after:!inline-block';

        // Toggle topics top mask
        if (firstTopicElementYAxis && firstTopicElementYAxis < topicsListElementYAxis.top) {
          !locationListRef.current.classList.contains(beforeElementVisibileClass) &&
            locationListRef.current.classList.add(beforeElementVisibileClass);
        } else {
          locationListRef.current.classList.contains(beforeElementVisibileClass) &&
            locationListRef.current.classList.remove(beforeElementVisibileClass);
        }

        // Toggle topics bottom mask
        if (lastTopicElementYAxis && lastTopicElementYAxis > topicsListElementYAxis.bottom - 16) {
          !locationListRef.current.classList.contains(afterElementVisibileClass) &&
            locationListRef.current.classList.add(afterElementVisibileClass);
        } else {
          locationListRef.current.classList.contains(afterElementVisibileClass) &&
            locationListRef.current.classList.remove(afterElementVisibileClass);
        }
      }
    }
  };

  useEffect(() => {
    if (innerLocationListRef.current) {
      // Manually trigger handleTopicsMaskVisibility on load
      handleTopicsMaskVisibility({ currentTarget: innerLocationListRef.current } as React.UIEvent<
        HTMLDivElement,
        UIEvent
      >);
    }
  }, [locations]);

  useEffect(() => {
    if (scrollToLocationIndex !== null && locationRefs.current[scrollToLocationIndex]) {
      locationRefs.current[scrollToLocationIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [scrollToLocationIndex]);

  return (
    <>
      {locations && Array.isArray(locations) && locations.length > 0 && (
        <div
          ref={locationListRef}
          className={classNames(
            'overflow-auto md:overflow-hidden relative',
            'before:hidden before:pointer-events-none before:z-10 before:absolute before:top-0 before:left-0 before:w-full before:h-24 before:bg-[linear-gradient(180deg,_#FBFAFA_0%,_rgba(251,_250,_250,_0.00)_100%)]',
            'after:hidden after:pointer-events-none after:z-10 after:absolute after:bottom-0 after:left-0 after:w-full after:h-24 after:bg-[linear-gradient(0deg,_#FBFAFA_0%,_rgba(251,_250,_250,_0.00)_100%)]'
          )}
        >
          <div
            ref={innerLocationListRef}
            onScroll={(e) => {
              handleTopicsMaskVisibility(e);
            }}
            className={classNames(
              `${
                currentScreenWidth < getBreakpoint('desktop') &&
                locations.length > 0 &&
                'h-[470px] mt-4'
              }`,
              'md:h-full',
              'location-list relative overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-gray-400 scrollbar-track-gray-100'
            )}
          >
            {locations.map((location, index) => (
              <>
                <div
                  onClick={() => {
                    setLocationItemIndex(index + 1);
                    setScrollToLocationIndex(index + 1);
                  }}
                  ref={(el) => {
                    locationRefs.current[index + 1] = el;
                  }}
                  key={index + 1}
                  className={classNames(
                    scrollToLocationIndex == index + 1 &&
                      'shadow-small bg-background-default-2 shadow-small bg-background-default-2',
                    'location-item cursor-pointer [&:not(:last-child)]:border-b border-strokes-default-3 flex justify-between py-2 px-2 md:py-4 md:px-4 min-h-[83px] md:min-h-[105px] lg:min-h-[111px]',
                    'hover:shadow-small hover:bg-background-default-2',
                    'focus:shadow-small focus:bg-background-default-2 focus:outline-none'
                  )}
                  tabIndex={0}
                >
                  <div className="location-address flex">
                    {/* Location Icon and Number */}
                    <div className="location-icon relative flex justify-center">
                      <SVG
                        svg="icon-pin-2"
                        className="w-10 h-10 px-1 box-content fill-current text-icon-action p-0"
                      />
                      <span className="absolute top-1 caption-medium text-white">{index + 1}</span>
                    </div>
                    {/* Location Details */}
                    <div className="relative">
                      <p className="block mb-1 paragraph-2-medium text-color-default-1">
                        {location.locationName || locationName}
                      </p>
                      <LinkWrapper
                        className="block paragraph-3-regular text-color-active hover:text-color-active hover:no-underline text-wrap break-all"
                        field={{
                          value: {
                            href: `https://www.google.com/maps/dir/Current+Location/${location.latitude},${location.longitude}`,
                          },
                        }}
                      >
                        <address className="not-italic">
                          {location.address} <br />
                          {location.city + ', ' + location.state + ' ' + location.zip}
                        </address>
                      </LinkWrapper>

                      {!location.surchargeFree && (
                        <div className="mt-2">
                          <span className="paragraph-3-medium text-color-default-1">
                            Surcharge:{' '}
                          </span>
                          <span className="paragraph-3-medium text-color-positive">No</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Location Distance */}
                  <div className="relative">
                    <LinkWrapper
                      field={{
                        value: {
                          href: `https://www.google.com/maps/dir/Current+Location/${location.latitude},${location.longitude}`,
                        },
                      }}
                      className="location-distance flex text-action-active hover:text-color-active hover:no-underline min-w-20 justify-center"
                    >
                      <div className="flex gap-2">
                        <SVG
                          svg="icon-location-2"
                          className="w-4 h-4 [&_svg]:h-4 [&_svg]:w-4 -scale-x-100"
                        />
                        <span className="relative caption-medium">
                          {location.distanceFromCenter?.toFixed(2) || '-'}
                        </span>
                      </div>
                    </LinkWrapper>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      )}
      {errorMessage && locations.length <= 0 && (
        <div className="flex flex-row items-center mt-4 md:mt-0">
          <SVG
            svg="icon-error"
            className="max-w-[20px] max-h-[20px] h-5 w-5 text-icon-error mr-2"
          ></SVG>
          <p className="caption text-color-negative">{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default LocationList;
