const FooterQuery = `
query Footer($datasource: String!, $language: String!) {
  footer: item(path: $datasource, language: $language) {
    ... on FooterConfiguration {
      footerCopyright {
        jsonValue
      }
      footerBrandingTitle {
        jsonValue
      }
    }
  }
}
`;

export default FooterQuery;
