import React, { useState } from 'react';
import { ComponentProps } from 'lib/component-props';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';

interface ItemType {
  fields: {
    CopyText: { value: string };
    Title: { value: string };
  };
}

interface LayoutProps {
  fields: {
    items: Array<ItemType>;
  };
}

type AccordionItemProps = {
  item: ItemType;
  isOpen: boolean;
  onClick: () => void;
};

export type AccordionCollectionProps = ComponentProps & LayoutProps;

const AccordionItem = ({ item, isOpen, onClick }: AccordionItemProps): JSX.Element => {
  return (
    <div className="w-full">
      <button
        tabIndex={0}
        onClick={onClick}
        role="button"
        className={`w-full flex items-center justify-between text-left px-5 py-2.5 rounded-full whitespace-normal bg-button-secondary-background border border-button-secondary-enabled *:hover:cursor-pointer enabled:duration-150 hover:border-color-active hover:font-medium enabled:transition hover:ease-in  ${
          isOpen ? 'border-color-active text-color-active' : 'text-color-default-2'
        } focus:outline-none focus:border-button-secondary-focus focus:text-button-secondary-focus focus:font-medium`}
      >
        <RichTextA11yWrapper
          className={` ${
            isOpen
              ? 'text-paragraph-2-medium-md lg:text-paragraph-2-medium-lg'
              : 'paragraph-2-regular'
          }`}
          field={item?.fields?.Title}
        />
        <div className={`flex transition-all transform ${isOpen ? 'rotate-180' : ''}`}>
          {<SVG className="" svg="icon-chevron-down"></SVG>}
        </div>
      </button>
      <div
        className={`accordion-collection mt-3 mx-5 text-color-default-2 ${
          isOpen ? 'showText' : 'hidden'
        }`}
      >
        <RichTextA11yWrapper
          className={`px-5 rtaw paragraph-3-regular`}
          field={item?.fields?.CopyText}
        />
      </div>
    </div>
  );
};

const AccordionCollection = (props: AccordionCollectionProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={`component`}>
      <div className="flex">
        <div className="flex flex-col gap-4 mx-4 w-full">
          {props.fields.items.map((item: ItemType, i) => {
            return (
              <div key={i}>
                <AccordionItem
                  item={item}
                  isOpen={activeIndex === i}
                  onClick={() => handleItemClick(i)}
                />{' '}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AccordionCollection;
