// left navigation datasource: "015DB16B-E91A-44DA-B73D-98E2015F94F7"

const LeftNavigationQuery = `query($datasource: String!, $language: String!) {
    header: item(path: $datasource, language: $language) {
      displayName
      id
      name
      ... on NavigationConfiguration {
        logo {
          jsonValue
        } 
        logoLink {
          jsonValue
        }
      }
      children {
        results {
        displayName
          name
          template {
            name
          }
          ... on NavigationLink {
            hideLabelSmallDesktop {
              jsonValue
            }
            icon:field(name:"icon") {
              jsonValue
            }
            link {
              jsonValue
            }
            text {
              jsonValue
            }
          }
          children(first: 20) {
            results {
              ... on NavigationLabel {
                  labelText {
                    jsonValue
                }
              }
              ... on NavigationLink {
                hideLabelSmallDesktop {
                  jsonValue
                }
                icon:field(name:"icon") { # we are doing this because sitecore is giving us an error when we tried using "icon"
                  jsonValue
                }
                link {
                  jsonValue
                }
                text {
                  jsonValue
                }
                children {
                  results {
                    ... on NavigationLink {
                      hideLabelSmallDesktop {
                        jsonValue
                      }
                      icon:field(name:"icon") {
                        jsonValue
                      }
                      link {
                        jsonValue
                      }
                      text {
                        jsonValue
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LeftNavigationQuery;
