const jssConfig = require('./src/temp/config');
const plugins = require('./src/temp/next-config-plugins') || {};
const publicUrl = jssConfig.publicUrl;
// Not a perfect check, but good enough.  Check if there's an API key that looks like a guid.
const isCM = process.env.IS_CM === 'true';

// Start ClickJacking  //
const ContentSecurityPolicy = `
  frame-ancestors 'self' pages.sitecorecloud.io;
`;
const securityHeaders = [
  {
    key: 'X-Frame-Options',
    value: 'SAMEORIGIN',
  },
  {
    key: 'Content-Security-Policy',
    value: ContentSecurityPolicy.replace(/\s{2,}/g, ' ').trim(),
  },
];
// End ClickJacking  //
/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
  productionBrowserSourceMaps: true,
  // Set assetPrefix to our public URL
  assetPrefix: isCM ? publicUrl : undefined,
  // Allow specifying a distinct distDir when concurrently running app in a container
  distDir: process.env.NEXTJS_DIST_DIR || '.next',
  // Make the same PUBLIC_URL available as an environment variable on the client bundle
  env: {
    PUBLIC_URL: publicUrl,
    SEARCH_ENV: process.env.SEARCH_ENV,
    SEARCH_CUSTOMER_KEY: process.env.SEARCH_CUSTOMER_KEY,
    SEARCH_API_KEY: process.env.SEARCH_API_KEY,
    SEARCH_SOURCE_ID: process.env.SEARCH_SOURCE_ID,
    RESOURCES_SEARCH_SOURCE_ID: process.env.RESOURCES_SEARCH_SOURCE_ID,
    BANKER_SEARCH_SOURCE_ID: process.env.BANKER_SEARCH_SOURCE_ID,
    TOKEN_MAPPINGS_ITEM_PATH: process.env.TOKEN_MAPPINGS_ITEM_PATH,
    MARKETING_SERVICES_HOST: process.env.MARKETING_SERVICES_HOST,
    OKTA_DOMAIN: process.env.OKTA_DOMAIN,
    OKTA_CLIENT_ID: process.env.OKTA_CLIENT_ID,
    OKTA_CLIENT_SECRET: process.env.OKTA_CLIENT_SECRET,
    OKTA_AUDIENCE: process.env.OKTA_AUDIENCE,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    BASIC_AUTHENTICATION: process.env.BASIC_AUTHENTICATION,
    LOCATION_SERVICE_URI: process.env.LOCATION_SERVICE_URI,
  },
  images: {
    domains: [
      'vumbnail.com',
      'bok-dev-preview.vercel.app',
      'dev.bankofoklahoma.com',
      'uat.bankofoklahoma.com',
      'edge.sitecorecloud.io',
      'xmcloud.localhost',
    ],
    dangerouslyAllowSVG: true,
    remotePatterns: [
      {
        protocol: 'https',
        hostname: '*.vercel.app',
      },
      {
        protocol: 'https',
        hostname: '*.bankofoklahoma.com',
      },
      {
        protocol: 'https',
        hostname: '*.sitecorecloud.io',
      },
    ],
  },
  i18n: {
    // These are all the locales you want to support in your application.
    // These should generally match (or at least be a subset of) those in Sitecore.
    locales: ['en'],
    // This is the locale that will be used when visiting a non-locale
    // prefixed path e.g. `/styleguide`.
    defaultLocale: jssConfig.defaultLanguage,
  },
  // Enable React Strict Mode
  reactStrictMode: true,
  async redirects() {
    const edgeInstanceUrl = process.env.EDGE_INSTANCE_URL;
    const addRule = process.env.ADD_MEDIA_REDIRECT_RULE;

    const redirectRules = [];

    if (addRule && addRule === 'TRUE') {
      redirectRules.push(
        {
          source: '/(-|~)/media/:path*.ashx',
          destination: `${edgeInstanceUrl}/media/:path*.pdf`,
          permanent: true,
        },
        // Redirect paths without extensions to `.pdf`
        {
          source: '/(-|~)/media/:path((?!.*\\.).+)',
          destination: `${edgeInstanceUrl}/media/:path.pdf`,
          permanent: true,
        },
        // Redirect paths with any extension to the same path
        {
          source: '/(-|~)/media/:path*',
          destination: `${edgeInstanceUrl}/media/:path*`,
          permanent: true,
        }
      );
    }

    return redirectRules;
  },
  async rewrites() {
    // When in connected mode we want to proxy Sitecore paths off to Sitecore
    return [
      // API endpoints
      {
        source: '/sitecore/api/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
      },
      // media items
      {
        source: '/-/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
      },
      // healthz check
      {
        source: '/healthz',
        destination: '/api/healthz',
      },
      // rewrite for Sitecore service pages
      {
        source: '/sitecore/service/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
      },
    ];
  },
  // Start ClickJacking  //
  async headers() {
    return [
      {
        // Apply these headers to all routes
        source: '/:path*',
        headers: securityHeaders,
      },
    ];
  },
  // end ClickJacking  //
};
//console.log('jssConfig:', jssConfig);
module.exports = () => {
  // Run the base config through any configured plugins
  return Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);
};
