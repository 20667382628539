import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { useCurrentScreenType } from 'lib/utils/get-screen-type';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

type MultiVideoPlayerModuleProps = ComponentProps &
  Marketing.DataTemplates.Video.MultiVideoPlayerModule;

const MultiVideoPlayerModule = (props: MultiVideoPlayerModuleProps): JSX.Element => {
  const { fields } = props;
  const videoItems = fields?.Videos as Marketing.DataTemplates.Video.VideoItem[];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { screenType } = useCurrentScreenType();
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const handleThumbnailClick = (index: number) => {
    setSelectedIndex(index);
  };

  const calculateHeight = useCallback(() => {
    if (videoContainerRef.current && screenType === 'desktop-lg') {
      setHeight(videoContainerRef.current.clientHeight);
    }
  }, [screenType]);

  useEffect(() => {
    if (screenType === 'desktop-lg') {
      calculateHeight();
      window.addEventListener('resize', calculateHeight);
    }

    return () => {
      if (screenType === 'desktop-lg') {
        window.removeEventListener('resize', calculateHeight);
      }
    };
  }, [screenType, calculateHeight]);

  if (!fields) {
    return <></>;
  }

  const selectedVideo = videoItems[selectedIndex];

  return (
    <section className="container spacing-md bg-background-default-2 shadow-lg">
      <div className="flex flex-wrap px-4 md:px-8 lg:px-4 pt-12 text-color-default-1">
        <div className="w-full lg:w-3/4 pr-0 lg:pr-6" ref={videoContainerRef}>
          <Text
            field={selectedVideo?.fields?.Title}
            tag="h2"
            className="after:content-[''] after:pt-2 after:block after:w-[3rem] after:mb-2 after:border-b-4 after:border-strokes-brand"
          />
          <div className="mt-4 lg:mt-6">
            <RichTextA11yWrapper
              field={selectedVideo?.fields?.iFrame}
              onLoadedMetadata={calculateHeight}
              className="[&_iframe]:h-full text-left [&_iframe]:w-full aspect-video"
            />
          </div>
          <div className="mt-4 lg:my-6">
            <RichTextA11yWrapper
              field={selectedVideo?.fields?.Description}
              className="caption rtaw"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/4 mt-4 md:mt-8 lg:mt-0 lg:flex lg:flex-col relative grid">
          <span className="absolute border-t-[1px] lg:border-t-0 lg:border-l-[1px] border-strokes-default-3 w-full lg:w-0 lg:h-[100%] inline-block"></span>
          <div
            className="flex lg:flex-col flex-nowrap items-start overflow-x-auto lg:overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-[#868080]"
            style={screenType === 'desktop-lg' ? { height: `${height}px` } : {}}
          >
            {videoItems.map((thumbnail: Marketing.DataTemplates.Video.VideoItem, index) => (
              <div
                key={index}
                className={`px-3 py-4 lg:px-6 lg:py-2 min-w-[50%] lg:min-w-full lg:w-full w-1/2 cursor-pointer transition-colors duration-300 ease-in-out ${
                  selectedIndex === index ? 'bg-background-dark-1' : 'hover:bg-background-dark-1'
                }`}
                onClick={() => handleThumbnailClick(index)}
                style={{ position: 'relative' }}
                tabIndex={0}
                role="button"
              >
                <ImageWrapper
                  field={thumbnail?.fields?.Thumbnail}
                  //alt={thumbnail?.fields?.Title?.value}
                />
                <Text field={thumbnail?.fields?.Title} className="caption mt-2" />
                {selectedIndex === index && (
                  <div className="absolute inset-0 border-t-4 lg:border-t-0 lg:border-l-4 border-color-brand pointer-events-none"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MultiVideoPlayerModule;
