import React, { useEffect, useState } from 'react';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Button from 'helpers/Button/Button';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
// import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { DotButton, useDotButton } from '../CarouselMarquee/CarouselMarqueeDotButton';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import classNames from 'classnames';
import shuffle from 'lodash/shuffle';

type LayoutProps = {
  rendering: ComponentRendering;
  params: {
    Alignment: ItemType;
    ShowCarouselAtMobile: string;
    Spacing: ItemType;
    DisableViewMore: string;
    CardWidth: ItemType;
    AnchorID?: string;
    BackgroundColor?: ItemType;
    'Random Order'?: string;
  };
  fields: {
    ProductCards: Marketing.DataTemplates.InfoCards.InfoCard[];
    children: Marketing.DataTemplates.InfoCards.InfoCard[];
    ToggleCollapsedText: {
      value: string;
    };
    ToggleExpandedText: {
      value: string;
    };
    DynamicCallToActionText: {
      value: string;
    };
    DynamicCount: {
      value: number;
    };
    DynamicQuery: {
      value: string;
    };
    EnableDynamicContent: {
      value: boolean;
    };
    Title: {
      value: string;
    };
  };
};

export type InfoCardsProps = LayoutProps;

type ItemType = { Value: { value: string } };

const InfoCards = (props: InfoCardsProps): JSX.Element => {
  const options: EmblaOptionsType = {};

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const [showMore, setShowMore] = useState(true);

  const [hasShuffled, setHasShuffled] = useState(false);

  const cardContentAlignment = props.params?.Alignment?.Value?.value;

  const backgroundColor = props.params?.BackgroundColor?.Value.value;

  const spacing = props.params?.Spacing?.Value.value;

  const cardWidth = props.params?.CardWidth?.Value.value;

  const anchorId = props.params?.AnchorID;

  const cardListOriginal = props?.fields?.ProductCards?.length
    ? props?.fields?.ProductCards
    : props?.fields?.children;

  const [cardList, setCardList] =
    useState<Marketing.DataTemplates.InfoCards.InfoCard[]>(cardListOriginal);

  useEffect(() => {
    if (
      !hasShuffled &&
      props.params['Random Order']?.length &&
      props.params['Random Order'] !== '0'
    ) {
      const newCardList = shuffle(cardList);
      setCardList(newCardList);
      setHasShuffled(true);
    }
  }, []);

  const shortenedList = cardList?.slice(0, 4) || [];

  const [renderList, setRenderList] = useState(shortenedList);

  useEffect(() => {
    if (props.params.DisableViewMore !== '1') {
      !showMore ? setRenderList(cardList) : setRenderList(shortenedList);
    } else {
      setRenderList(cardList);
    }
  }, [showMore, cardList]);

  return (
    <section
      id={anchorId}
      className={`component ${spacing?.length ? spacing : 'spacing-md'} ${
        backgroundColor?.length ? backgroundColor : 'container'
      }`}
    >
      <div className="container-inner">
        <RichTextA11yWrapper
          className={`${
            props.params?.Alignment?.Value?.value.includes('center') ? 'text-center' : ''
          } mb-8`}
          field={props.fields?.Title}
          tag={'h2'}
        />
        {/* GRID */}
        <div
          className={`${
            cardWidth ? cardWidth : 'grid grid-cols-1 md:grid-cols-2 auto-cols-fr lg:grid-cols-4'
          } ${props?.params?.Spacing?.Value?.value} gap-4 `}
        >
          {renderList?.map((card, i) => {
            const cardImageClass = classNames(
              ' max-w-full h-auto object-cover',
              (card.fields?.['AspectRatio-Mobile']?.fields as ItemType)?.Value?.value,
              (card.fields?.['AspectRatio-Tablet']?.fields as ItemType)?.Value?.value,
              (card.fields?.AspectRatio?.fields as ItemType)?.Value?.value,
              (card.fields?.ImageDisplayDesktop?.fields as ItemType)?.Value?.value,
              (card.fields?.ImageDisplayMobile?.fields as ItemType)?.Value?.value,
              (card.fields?.ImageDisplayTablet?.fields as ItemType)?.Value?.value,
              (card.fields?.Position?.fields as ItemType)?.Value?.value,
              (card.fields?.['Position-Tablet']?.fields as ItemType)?.Value?.value,
              (card.fields?.['Position-Mobile']?.fields as ItemType)?.Value?.value
            );

            return (
              <div
                className={`${
                  props.params.ShowCarouselAtMobile === '1' ? 'hidden' : ''
                } md:flex flex-col border  border-strokes-default-3 bg-background-default-2 p-4 info-cards__item ${
                  cardContentAlignment === 'text-center'
                    ? `${cardContentAlignment} items-center`
                    : `${cardContentAlignment}`
                } `}
                key={i}
              >
                <ImageWrapper
                  field={card.fields?.CardImage}
                  className={`mb-4 mx-auto ${cardImageClass}`}
                />

                {card.fields?.Title && (
                  <RichTextA11yWrapper
                    field={card.fields.Title}
                    className={`text-subtitle-1-medium-lg after:content-[""] after:block after:border-b-[3px] after:h-1 after:mt-2 after:border-strokes-brand after:w-[3rem] ${
                      props.params?.Alignment?.Value?.value.includes('center')
                        ? 'after:mx-auto'
                        : ''
                    }`}
                  />
                )}

                <RichTextA11yWrapper
                  field={card.fields?.Text}
                  className="mt-6 paragraph-2-regular rtaw"
                />

                {card.fields?.LearnMoreLink?.value?.href && (
                  <LinkWrapper
                    field={card.fields?.LearnMoreLink}
                    className="mt-2 text-strokes-action font-medium"
                  ></LinkWrapper>
                )}
                {card.fields?.CallToAction?.value?.href && (
                  <div className="mt-auto">
                    <Button
                      field={card.fields?.CallToAction}
                      type="auxiliary"
                      size="full"
                      fieldClass="mt-6"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {cardList?.length > 4 && props.params.DisableViewMore !== '1' && (
          <div
            className={`${
              props.params.ShowCarouselAtMobile === '1' ? 'hidden' : ''
            } md:flex w-full text-color-active items-center justify-center mt-6`}
          >
            <Button
              type="action"
              tag="button"
              className=""
              label={`${
                showMore
                  ? props.fields.ToggleCollapsedText.value
                  : props.fields.ToggleExpandedText.value
              }`}
              onClick={() => setShowMore(!showMore)}
              size={'large'}
              iconRight={showMore ? 'icon-chevron-down' : 'icon-chevron-up'}
            />
          </div>
        )}

        {/* mobile (embla carousel) */}
        <section
          className={` ${
            !props?.params?.ShowCarouselAtMobile || props.params?.ShowCarouselAtMobile !== '1'
              ? 'hidden'
              : 'info-cards md:hidden'
          } `}
        >
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="carousel-marquee-container">
              {cardList?.map((card, i) => {
                const getAspectRatioClasses = () => {
                  // Retrieve aspect ratio classes from the fields
                  const aspectRatioMobile =
                    (card.fields?.['AspectRatio-Mobile']?.fields as ItemType)?.Value?.value || '';
                  const aspectRatioTablet =
                    (card.fields?.['AspectRatio-Tablet']?.fields as ItemType)?.Value?.value || '';
                  const aspectRatioDesktop =
                    (card.fields?.AspectRatio?.fields as ItemType)?.Value?.value || '';

                  return classNames(aspectRatioMobile, aspectRatioTablet, aspectRatioDesktop);
                };

                const cardImageClass = classNames(
                  ' max-w-full h-auto object-cover',
                  getAspectRatioClasses(),
                  (card.fields?.['AspectRatio-Mobile']?.fields as ItemType)?.Value?.value,
                  (card.fields?.['AspectRatio-Tablet']?.fields as ItemType)?.Value?.value,
                  (card.fields?.AspectRatio?.fields as ItemType)?.Value?.value,
                  (card.fields?.ImageDisplayDesktop?.fields as ItemType)?.Value?.value,
                  (card.fields?.ImageDisplayMobile?.fields as ItemType)?.Value?.value,
                  (card.fields?.ImageDisplayTablet?.fields as ItemType)?.Value?.value,
                  (card.fields?.Position?.fields as ItemType)?.Value?.value,
                  (card.fields?.['Position-Tablet']?.fields as ItemType)?.Value?.value,
                  (card.fields?.['Position-Mobile']?.fields as ItemType)?.Value?.value
                );
                return (
                  <div className={`flex flex-col carousel-marquee-slide`} key={i}>
                    <div
                      className={`flex flex-col mx-3 border border-strokes-default-3 p-4 ${
                        backgroundColor?.length ? backgroundColor : 'bg-background-default-2'
                      } ${
                        cardContentAlignment === 'text-center'
                          ? `${cardContentAlignment} items-center`
                          : `${cardContentAlignment}`
                      } `}
                    >
                      <ImageWrapper
                        field={card.fields?.CardImage}
                        className={`mb-4 ${cardImageClass}`}
                      />

                      <RichTextA11yWrapper
                        field={card.fields?.Title}
                        className="subtitle-1-medium"
                      />
                      <div className="border-b-4 h-1 mt-2 border-strokes-brand w-[3rem]"></div>

                      <RichTextA11yWrapper
                        field={card.fields?.Text}
                        className="paragraph-2-regular mt-6 rtaw"
                      />

                      {card.fields?.LearnMoreLink?.value?.href && (
                        <LinkWrapper
                          field={card.fields?.LearnMoreLink}
                          className="my-2 text-strokes-action font-medium"
                        ></LinkWrapper>
                      )}
                      {card.fields?.CallToAction?.value?.href && (
                        <div className="mt-6">
                          <Button field={card.fields?.CallToAction} type="auxiliary" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="info-cards-controls">
            <div className="carousel-marquee-dots">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={'carousel-marquee-dot'.concat(
                    index === selectedIndex ? ' carousel-marquee-dot--selected' : ''
                  )}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default InfoCards;
