import React from 'react';
import { Marketing } from 'lib/templates/Feature.BOKF.model';
import { Bok } from 'lib/templates/Foundation.BOKF.model';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';

type LayoutProps = Marketing.DataTemplates.JumpTo;

export type JumpToProps = {
  params: { Spacing: { Value?: { value?: string } } };
  fields: {
    children: Bok.BaseTypes.SingleLink[];
  };
} & LayoutProps;

const JumpTo = (props: JumpToProps): JSX.Element => {
  const items = props?.fields.children;
  return (
    <section
      className={`component container flex flex-col gap-y-8 ${
        props?.params?.Spacing?.Value?.value || 'spacing-md'
      }`}
    >
      <RichTextA11yWrapper field={props?.fields?.Title} className="paragraph-2-regular" />
      {items && items.length > 0 && (
        <ul className="flex md:grid lg:flex flex-wrap md:flex-nowrap lg:flex-wrap md:grid-cols-3 gap-4 lg:gap-6 md:grow-last-row items-stretch">
          {items.map((item: Bok.BaseTypes.SingleLink, key) => (
            <li key={key} className="min-w-[100px] grow shrink basis-auto">
              <LinkWrapper
                field={item.fields?.Link as LinkField}
                className="btn btn-auxiliary paragraph-2-medium w-full h-full focus:no-underline text-center"
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default JumpTo;
