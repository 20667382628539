import { format } from 'date-fns';
import { RatesRequest } from 'types/MarketingServices/Rates.Models';
import { Rates } from 'lib/templates/Feature.BOKF.model';

export const RATES_LOADING = 'Loading Rates...';
export const RATES_UNAVAILABLE = 'Rates are unavailable at this time. Please try again later.';
export const NEXT_RATES_API = '/api/rates';

export const BuildRatesRequest = (props: Rates.Filter): RatesRequest => {
  // Rates Configuration Parsing
  const businessLineId =
    Number((props?.fields?.BusinessLine as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  const regionId =
    Number((props?.fields?.Region as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  const marketId =
    Number((props?.fields?.Market as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 1;

  const channelId =
    Number((props?.fields?.Channel as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 1;

  const groupId =
    Number((props?.fields?.Group as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  const productId =
    Number((props?.fields?.Product as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  const termId =
    Number((props?.fields?.Term as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  const tierId =
    Number((props?.fields?.Tier as Rates.RatesConfigurationLookUp)?.fields?.Id?.value) || 0;

  // Format the date and time as YYYY-MM-DDTHH:mm:ss
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ss");

  const request: RatesRequest = {
    BusinessLineId: businessLineId,
    RegionId: regionId,
    MarketId: marketId,
    Channel: channelId,
    GroupId: groupId,
    ProductId: productId,
    TermId: termId,
    TierId: tierId,
    StartDate: formattedDate,
  };

  return request;
};

export const CurrencyConverter = (value: number): string => {
  const convertedValue = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  return convertedValue;
};
