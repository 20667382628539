//Global
import { useSitecoreContext, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

//helpers
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

export type LongArticleHeroProps = {
  Hero: ImageField;
  HeroTablet: ImageField;
};

const LongArticleHero = () => {
  const { sitecoreContext } = useSitecoreContext();

  const { Hero, HeroTablet } = sitecoreContext?.route?.fields as LongArticleHeroProps;

  return (
    <section className="container">
      <ImageWrapper
        className="hidden lg:block w-full aspect-[3/1] object-cover overflow-hidden"
        field={Hero}
      />
      {HeroTablet?.value?.src ? (
        <ImageWrapper
          className="block lg:hidden w-full aspect-[3/1] object-cover"
          field={HeroTablet}
        />
      ) : (
        <ImageWrapper className="block lg:hidden w-full aspect-[3/1] object-cover" field={Hero} />
      )}
    </section>
  );
};

export default LongArticleHero;
