/**
 * @param {import('next').NextConfig} nextConfig
 */
const locationPlugin = (nextConfig = {}) => {
    return Object.assign({}, nextConfig, {
      async rewrites() {
        return [
          ...await nextConfig.rewrites(),
          // location route
          {
            source: '/find',
            destination: '/api/location'
          },
        ];
      },
    });
  };
  
  module.exports = locationPlugin;
  