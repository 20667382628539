// Global
import React, { useState, useEffect, useRef } from 'react';
import {
  Field,
  GetStaticComponentProps,
  ImageField,
  Item,
  LinkField,
  SitecoreContextValue,
} from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
// Lib
import clientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';
import { BoKNavigation } from 'lib/templates/Feature.BOKF.model';
import { useRealPathName } from 'lib/utils/useRealPathname';
import { SitecoreIds } from 'lib/constants/sitecore-ids';
import { useCookieRedirection } from 'lib/hooks/use-cookie-redirection';

// Local
import SVG from 'helpers/SVG/SVG';
import Button from 'helpers/Button/Button';
import HeaderQuery from './Header.graphql';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { useSearchOverlayContext } from 'helpers/Providers/SearchOverlayContextProvider';
import { usePathname } from 'next/navigation';

type SubItem = {
  Value: { value: string };
};

type ExtendedSitecoreContextProps = SitecoreContextValue & {
  FDICText?: Field<string>;
  FDICLogo?: { url: string; altText: string };
  IncludeFDICLogo?: Field<boolean>;
  DiscludeFDIC?: Field<boolean>;
};

export type ItemType = {
  name?: string;
  displayName?: string;
  template?: { name?: string };
  labelText?: { jsonValue: Field<string> | undefined };
  hideLabelSmallDesktop?: { jsonValue: Field<boolean> | undefined };
  icon?: { jsonValue: Item | undefined | null };
  link?: { jsonValue: LinkField | undefined };
  text?: { jsonValue: Field<string> | undefined };

  children?: {
    results: Array<ItemType>;
  };
};

export type LeprechaunHeaderProps = {
  disableChannelRedirect?: Field<boolean> | undefined;
  logo?: { jsonValue: ImageField | undefined };
  logoLink?: { jsonValue: LinkField | undefined };
  link?: { jsonValue: LinkField | undefined };
  rightAlignIcons?: Field<boolean> | undefined;
  children?: {
    results: ItemType[] &
      {
        displayName?: string;
        hideLabelSmallDesktop?: { jsonValue: Field<boolean> };
        icon?: { jsonValue: Item | undefined | null };
        name: string;
        template: { name: string };
        link?: { jsonValue: LinkField | undefined };
        text?: { jsonValue: Field<string> | undefined };
        children: { results: ItemType[] };
        fields?: ItemType | undefined;
      }[];
  };
};

export type SubItemType =
  | {
      id?: string;
      displayName?: string;
      name?: string;
      templateId?: string;
      templateName?: string;
      url?: string;

      fields?: {
        HideLabelSmallDesktop?: { value: boolean };
        icon?: {
          id?: string;
          name?: string;
          displayName?: string;
          url?: string;
          fields: { Value: { value: string } } | null;
        } | null;
        Link?: LinkField | null;
        Text?: { value: string };
        children: {
          id?: string;
          displayName?: string;
          name?: string;
          templateId?: string;
          templateName?: string;
          url?: string;

          fields: {
            HideLabelSmallDesktop?: { value: boolean };
            Icon?: {
              id?: string;
              name?: string;
              displayName?: string;
              url?: string;
              fields: { Value: { value: string } } | null;
            } | null;
            Link?: LinkField | null;
            Text?: { value: string };
            children: {
              name?: string;
              templateId?: string;
              templateName?: string;
              id?: string;
              url?: string;
              HideLabelSmallDesktop?: { value: boolean };
              Icon?: {
                id?: string;
                name?: string;
                displayName?: string;
                url?: string;
                fields: { Value: { value: string } } | null;
              } | null;
              Link?: LinkField | null;
              Text?: { value: string };
              fields: {
                HideLabelSmallDesktop?: { value: boolean };
                Icon?: {
                  id?: string;
                  name?: string;
                  displayName?: string;
                  url?: string;
                  fields: { Value: { value: string } } | null;
                } | null;
                Link?: LinkField | null;
                Text?: { value: string };
                name?: string;
                templateId?: string;
                templateName?: string;
                id?: string;
                url?: string;
              };
            }[];
          };
        }[];
      };
    }
  | undefined;

export type HeaderProps = ComponentProps &
  BoKNavigation.NavigationConfiguration & {
    staticProps?: {
      header?: LeprechaunHeaderProps;
    };
  };

export interface SubMenuItem {
  open: boolean;
  subItem: ItemType;
}

export type ColorItemProps = {
  fields: {
    Value: {
      value?: string;
    };
  };
};

export type CategoryItemProps = {
  fields: {
    Color: ColorItemProps;
  };
};

export interface SitecoreContextType {
  sitecoreContext?: {
    route?: {
      templateId?: string;
      fields?: {
        Category?: CategoryItemProps[];
      };
    };
  };
}

const Header = (serverProps: HeaderProps): JSX.Element => {
  const props = { fields: serverProps?.staticProps?.header };

  const { overlayOpen, setOverlayOpen } = useSearchOverlayContext();

  // check if the current page temmplate is Article or VideoResource and apply category specific classes
  const sitecoreContext = useSitecoreContext();

  const routeData = (sitecoreContext as SitecoreContextType)?.sitecoreContext?.route;
  const isPageTypeArticleOrVideoResource = (() => {
    for (const [, { templateId, templateName }] of Object.entries(SitecoreIds.Route)) {
      if (templateId === routeData?.templateId) {
        return templateName;
      }
    }
    return undefined;
  })();
  let activeCategoryofPage: string | undefined = undefined;
  if (isPageTypeArticleOrVideoResource && routeData?.fields?.Category?.[0]) {
    activeCategoryofPage =
      routeData?.fields?.Category?.[0]?.fields?.Color?.fields?.Value?.value || '';
  }

  const id = serverProps.params?.RenderingIdentifier || '';
  const headerUtilityMobile = serverProps?.staticProps?.header?.children?.results.find(
    (elem) => elem.template?.name === 'HeaderUtilityFolderMobile'
  );

  const utilityNavMobile = serverProps?.staticProps?.header?.children?.results.find(
    (elem) => elem.template?.name === 'UtilityNavigationFolderMobile'
  );

  const extendedSitecoreContext: ExtendedSitecoreContextProps =
    useSitecoreContext().sitecoreContext;

  const FDICImage = extendedSitecoreContext.FDICLogo?.url;
  const FDICImageAltText = extendedSitecoreContext.FDICLogo?.altText;
  const FDICText = extendedSitecoreContext.FDICText?.value;
  const includeFDICLogo = extendedSitecoreContext.IncludeFDICLogo?.value;
  const discludeFDIC = extendedSitecoreContext.route?.fields?.['DiscludeFDIC'] as Field<boolean>;
  const isFDICExists = includeFDICLogo && !discludeFDIC?.value;

  function filterFunc(value: ItemType) {
    return value?.template?.name === 'NavigationLink';
  }

  function filterFunc2(value: ItemType) {
    return value?.template?.name === 'Promo Button';
  }

  function filterFunc3(value: ItemType) {
    return value?.template?.name === 'HeaderUtilityFolderDesktop';
  }

  function filterFunc4(value: ItemType) {
    return value?.template?.name === 'UtilityNavigationFolderDesktop';
  }

  const primaryNavData = serverProps?.staticProps?.header?.children?.results.filter(filterFunc);

  const headerUtilityData = serverProps?.staticProps?.header?.children?.results.filter(filterFunc3);

  const utilityNavData = serverProps?.staticProps?.header?.children?.results.filter(filterFunc4);

  const subscribeButton = serverProps?.staticProps?.header?.children?.results.filter(filterFunc2);

  //Get all lob channel from header props

  useCookieRedirection(serverProps?.fields?.DisableChannelRedirect?.value, primaryNavData);

  const [isOpen, setOpen] = useState(false);

  const [isSubMenuOpen, setSubMenuOpen] = useState<SubMenuItem>({
    open: false,
    subItem: {},
  });

  const pathname = useRealPathName();
  let strippedPath = pathname?.split('/', 2); // get the first item in path

  // just use personal data for storybook
  if (process.env.IS_STORYBOOK) {
    strippedPath = ['', 'Personal'];
  }

  const navRef = useRef<HTMLDivElement>(null);

  const updateHeight = () => {
    if (navRef.current) {
      const height = navRef.current.offsetHeight;
      document.documentElement.style.setProperty('--nav-height', `${height}px`);
    }
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  function getLocation(href: string) {
    const match = href.match(
      /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
    );
    return (
      match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    );
  }

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : '';

    isOpen &&
      primaryNavData?.length === 1 &&
      setSubMenuOpen({
        open: true,
        subItem: primaryNavData[0],
      });
  }, [isOpen]);

  const handleHamburgerMenuClick = () => {
    if (window.innerWidth > 767) {
      setOpen(false);
      setSubMenuOpen({ open: false, subItem: {} });
      document.documentElement.style.overflow = '';
    } else {
      setOpen((prev) => !prev);
      setSubMenuOpen({ open: false, subItem: {} });
      document.documentElement.style.overflow = isOpen ? '' : 'hidden';
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setOpen(false);
        setSubMenuOpen({ open: false, subItem: {} });
        document.documentElement.style.overflow = '';
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const pathName = usePathname();
  useEffect(() => {
    setOpen(false);
    setSubMenuOpen({ open: false, subItem: {} });
  }, [pathName]);

  const toggleSearchOverlay = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setOverlayOpen(!overlayOpen);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen || isSubMenuOpen.open) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab' && containerRef.current) {
          const focusableElements = containerRef.current.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
          ) as NodeListOf<HTMLElement>;
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey) {
            // Shift + Tab
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            // Tab
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      const container = containerRef.current;
      if (container) {
        container.addEventListener('keydown', handleKeyDown);
      }

      return () => {
        if (container) {
          container.removeEventListener('keydown', handleKeyDown);
        }
      };
    }
    // Ensure that the useEffect hook always returns undefined
    return undefined;
  }, [isOpen, isSubMenuOpen.open]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setIsScrollingUp(currentPosition < prevScrollPosition);
    setPrevScrollPosition(currentPosition);
    setScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPosition]);

  return (
    <header
      ref={containerRef}
      id={`${id ? id : ''}`}
      className={`${isOpen ? 'fixed w-full' : 'sticky'} md:!sticky top-0 z-20 ${
        isScrollingUp ? 'header-show' : ''
      } ${scrollPosition > 100 && !isScrollingUp ? 'header-hidden' : ''}`}
    >
      {/* Main Nav */}
      <div
        ref={navRef}
        className={`relative w-full flex z-50 px-6 mobile:gap-x-2 gap-x-6 md:gap-x-0 bg-background-default-2 h-12 sm:h-14 before:content-[''] before:absolute before:top-12 sm:before:top-14 before:border-l-[45px] before:border-solid before:border-l-transparent before:border-t-[45px] before:border-t-strokes-inverse before:h-0 before:w-0 before:right-0 before:z-10 ${
          isOpen || isSubMenuOpen.open ? 'absolute' : ''
        } ${isFDICExists ? '' : 'shadow-small'}`}
      >
        <button
          type="button"
          onClick={handleHamburgerMenuClick}
          className="group flex md:tw-hidden items-center my-2 p-2 rounded group-hover:text-color-active group-hover:cursor-pointer hover:bg-background-dark-3 focus:bg-background-dark-3 focus:text-color-active focus:outline-none"
        >
          <SVG svg="icon-menu"></SVG>
        </button>
        <LinkWrapper
          field={props?.fields?.logoLink?.jsonValue?.value || {}}
          className="max-w-[18rem] w-full my-1 md:my-2 md:mr-2 px-2 md:px-4"
        >
          {/* BOKF Logo */}
          <ImageWrapper
            className="w-full h-full"
            field={props?.fields?.logo?.jsonValue}
            height="56"
            width="256"
          />
        </LinkWrapper>
        {primaryNavData && primaryNavData.length > 0 && (
          <ul className="tw-hidden md:flex gap-x-1 lg:gap-x-3 xl:gap-x-8">
            {primaryNavData.length > 1 &&
              primaryNavData?.map((item, key) => {
                const itemHref = item?.link?.jsonValue?.value?.href;
                const location = getLocation(item?.link?.jsonValue?.value?.href || '')
                  ?.pathname.replace('/', '')
                  .toLowerCase();
                const pathComparison = strippedPath[1].toLowerCase();
                const categoryClass = item?.link?.jsonValue?.value?.class || '';
                let activeClass =
                  'font-medium text-color-default-1 border-strokes-brand bg-background-dark-3 hover:text-color-default-1 focus:border-strokes-action focus:text-color-default-1';
                let otherClass =
                  'text-color-default-2 border-transparent hover:border-strokes-brand hover:text-color-default-1 dark:hover:border-strokes-brand focus:border-strokes-action focus:text-color-default-1';

                type CategoryKey = 'category-1' | 'category-2' | 'category-3' | 'category-4';

                const categoryClasses: Record<
                  CategoryKey,
                  { activeClass: string; otherClass: string }
                > = {
                  'category-1': {
                    activeClass:
                      'font-medium text-color-default-1 border-background-category-1 bg-background-dark-3 hover:text-color-default-1 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                    otherClass:
                      'text-color-default-2 border-transparent hover:border-background-category-1 hover:text-color-default-1 dark:hover:border-background-category-1 focus:border-background-category-1 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                  },
                  'category-2': {
                    activeClass:
                      'font-medium text-color-default-1 border-background-category-2 bg-background-dark-3 hover:text-color-default-1 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                    otherClass:
                      'text-color-default-2 border-transparent hover:border-background-category-2 hover:text-color-default-1 dark:hover:border-background-category-2 focus:border-background-category-2 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                  },
                  'category-3': {
                    activeClass:
                      'font-medium text-color-default-1 border-background-category-3 bg-background-dark-3 hover:text-color-default-1 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                    otherClass:
                      'text-color-default-2 border-transparent hover:border-background-category-3 hover:text-color-default-1 dark:hover:border-background-category-3 focus:border-background-category-3 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                  },
                  'category-4': {
                    activeClass:
                      'font-medium text-color-default-1 border-background-category-4 bg-background-dark-3 hover:text-color-default-1 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                    otherClass:
                      'text-color-default-2 border-transparent hover:border-background-category-4 hover:text-color-default-1 dark:hover:border-background-category-4 focus:border-background-category-4 focus:outline focus:outline-strokes-action focus:outline-2 focus:rounded focus:outline-offset-[-2px] focus:text-color-default-1',
                  },
                };

                if (categoryClasses[categoryClass as CategoryKey]) {
                  activeClass = categoryClasses[categoryClass as CategoryKey].activeClass;
                  otherClass = categoryClasses[categoryClass as CategoryKey].otherClass;
                }
                const linkClassNames = classNames(
                  'paragraph-1-regular h-full content-center pt-1 px-3 lg:px-6 whitespace-nowrap inline-block text-center',
                  'before:block before:content-[attr(title)] before:font-medium before:h-0 before:overflow-hidden before:invisible',
                  'focus:bg-background-dark-3 focus:font-medium focus:outline-none focus:no-underline',
                  'hover:bg-background-dark-3 hover:font-medium border-b-4 hover:no-underline',
                  {
                    // Active class when the path matches, show category-specific border
                    [activeClass]:
                      (pathComparison.length && pathComparison === location) ||
                      pathComparison === itemHref?.replace('/', '').toLowerCase() ||
                      activeCategoryofPage === categoryClass,
                    // Inactive state with transparent border, show category-specific border on hover
                    [otherClass]:
                      !(pathComparison.length && pathComparison === location) &&
                      pathComparison !== itemHref?.replace('/', '').toLowerCase() &&
                      activeCategoryofPage !== categoryClass,
                  }
                );

                return (
                  <li key={key}>
                    <LinkWrapper
                      field={item?.link?.jsonValue?.value || {}}
                      title={item?.text?.jsonValue?.value}
                      className={linkClassNames}
                    >
                      {item?.text?.jsonValue?.value}
                    </LinkWrapper>
                  </li>
                );
              })}
          </ul>
        )}
        {/* Location/Languages */}
        {headerUtilityData?.[0]?.children?.results &&
        headerUtilityData?.[0]?.children?.results?.length > 0 ? (
          <ul className="tw-hidden md:flex flex-row ml-auto items-center divide-x divide-strokes-default-2 font-medium xl:flex">
            {headerUtilityData?.[0]?.children?.results.map((item, key) => {
              return (
                <li key={key} className="md:mr-1 lg:mr-4 last:mr-0 md:pl-1 lg:pl-4 first:pl-0">
                  <LinkWrapper
                    field={item.link?.jsonValue?.value || {}}
                    className="paragraph-3-medium flex items-center p-2 text-color-default-2 hover:text-color-active hover:no-underline hover:bg-background-dark-3 focus:outline-color-active focus-visible:outline-color-active rounded"
                  >
                    <SVG
                      className="pr-0 xl:pr-2"
                      svg={(item.icon?.jsonValue?.fields as SubItem)?.Value?.value}
                      hidden={false}
                    ></SVG>
                    <span className="tw-hidden xl:block">{item.text?.jsonValue?.value}</span>
                  </LinkWrapper>
                </li>
              );
            })}
          </ul>
        ) : null}
        {/* END Location/Languages */}
        {/* Mobile Login */}

        <LinkWrapper
          field={{
            value: {
              href: headerUtilityMobile?.children?.results[0]?.link?.jsonValue?.value?.href || '/',
            },
          }}
          className={`group flex gap-x-2 md:tw-hidden ml-auto my-auto mobile:pr-0 pr-2 paragraph-2-regular text-color-default-2 hover:text-color-default-2 group-hover:underline items-center ${headerUtilityMobile?.children?.results[0]?.link?.jsonValue?.value?.class}`}
        >
          <SVG
            className="p-1 border-transparent rounded group-hover:bg-background-dark-3 group-hover:text-color-active"
            svg={
              (headerUtilityMobile?.children?.results[0]?.icon?.jsonValue?.fields as SubItem)?.Value
                ?.value
            }
          ></SVG>
          <span className="mobile:tw-hidden block">
            {headerUtilityMobile?.children?.results[0]?.text?.jsonValue?.value}
          </span>
        </LinkWrapper>
        {/* END Mobile Login */}
      </div>
      {/* Utility Nav */}
      <div>
        <nav className="tw-hidden md:flex absolute top-14 right-0 h-auto ml-auto z-10">
          <SVG className="" svg="utility-nav-red-triangle" hidden={false}></SVG>
          <ul className="flex h-11 pr-11 items-center bg-background-brand text-color-inverse divide-x divide-strokes-default-3">
            {utilityNavData?.[0]?.children?.results.map((item, key) => {
              const isSearchLink = item?.link?.jsonValue?.value?.class === 'jsMenuSearch';
              return (
                <li key={key} className="px-2">
                  <LinkWrapper
                    field={{
                      value: item?.link?.jsonValue?.value?.href
                        ? { href: item?.link?.jsonValue?.value?.href }
                        : {},
                    }}
                    className={`flex gap-2 px-2 py-1 items-center font-normal text-color-inverse hover:text-color-inverse hover:bg-icon-background-hover-2 hover:no-underline border-transparent rounded focus:outline-strokes-inverse focus:bg-icon-background-hover-2 focus:outline focus:outline-2 focus:no-underline ${item?.link?.jsonValue?.value.class}`}
                    onClick={isSearchLink ? (e) => toggleSearchOverlay(e) : undefined}
                  >
                    {(item?.icon?.jsonValue?.fields as SubItem)?.Value?.value && (
                      <SVG
                        svg={(item?.icon?.jsonValue?.fields as SubItem)?.Value?.value}
                        hidden={false}
                      ></SVG>
                    )}
                    {item?.text?.jsonValue?.value}
                  </LinkWrapper>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      {/* END Utility Nav */}
      {/* FDIC */}
      {isFDICExists && (
        <div
          className={`absolute z-[42] md:z-0 top-12 sm:top-14 left-0 w-full h-[45px] md:h-11 bg-background-dark-1 pr-9 md:pr-[445px] mobile:pl-2 pl-8 lg:pl-10 py-3 text-[13px] flex items-center gap-x-2 ${
            isFDICExists ? 'shadow-small' : ''
          }`}
        >
          <ImageWrapper
            className="w-full h-full max-w-fit"
            field={{ value: { src: FDICImage, alt: FDICImageAltText } }}
            height="17"
            width="41"
          />
          <p className="italic">{FDICText}</p>
        </div>
      )}
      {/* END FDIC */}
      {/* Mobile Nav */}
      {isOpen && (
        <nav
          className={`flex flex-col absolute w-full max-w-[20rem] z-40 border-white bg-color-inverse overflow-auto bok-scrollbar bok-scrollbar--no-border ${
            isFDICExists
              ? 'h-[calc(100dvh-var(--nav-height)-44px)] top-[calc(var(--nav-height)+44px)]'
              : 'h-[calc(100dvh-var(--nav-height))] top-[calc(var(--nav-height))]'
          } ${isOpen && !isSubMenuOpen.open ? 'px-8 py-6 justify-between' : 'justify-normal'}`}
        >
          {primaryNavData && primaryNavData.length > 1 && isSubMenuOpen.open && (
            <div className="flex p-6 justify-between border-b-2 text-paragraph-3-medium-md border-b-strokes-brand bg-background-default-1 items-center ">
              <button
                tabIndex={0}
                className="flex items-center gap-x-2 paragraph-3-medium text-color-default-1 hover:text-color-default-1 hover:no-underline py-1 pl-1 pr-1.5 focus:outline-color-active focus:bg-background-dark-1 hover:bg-background-dark-1 hover:outline-color-active rounded"
                onClick={() => setSubMenuOpen({ open: false, subItem: {} })}
                aria-label="Mobile Navigation Menu Button"
              >
                <SVG className="w-5 h-5" svg="icon-chevron-left"></SVG>
                All categories
              </button>
            </div>
          )}
          {/* Mobile Primary Nav */}
          {isOpen && !isSubMenuOpen.open && primaryNavData && primaryNavData.length > 0 && (
            <ul className="flex flex-col gap-y-2">
              {primaryNavData?.map((item, key) => {
                return (
                  <li key={key}>
                    <button
                      tabIndex={0}
                      className="flex items-center w-full justify-between px-2 py-2.5 paragraph-1-medium text-color-default-1 hover:text-color-default-1 hover:no-underline word-break-break-word focus:outline-color-active"
                      onClick={() => setSubMenuOpen({ open: true, subItem: item })}
                      aria-label="Mobile Navigation Menu Button"
                    >
                      {item?.text?.jsonValue?.value}
                      <SVG className="p-1 h-8 w-8" svg="icon-chevron-right"></SVG>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
          {/* END Mobile Primary Nav */}
          <div
            className={`h-full flex flex-col ${
              isOpen && !isSubMenuOpen.open ? 'justify-end' : 'justify-between'
            }`}
          >
            {isSubMenuOpen.open && (
              <>
                <ul className={`${isOpen && !isSubMenuOpen.open ? '' : 'mx-8 mt-8 sm:mt-6'}`}>
                  <li className="mb-4 sm:mb-6">
                    <LinkWrapper
                      field={{
                        value: { href: isSubMenuOpen.subItem.link?.jsonValue?.value.href || '/' },
                      }}
                      onClick={() => {
                        setSubMenuOpen({ open: false, subItem: {} });
                        setOpen(false);
                      }}
                      className="flex items-center p-2 gap-x-2 paragraph-1-medium text-color-default-1 focus:outline-strokes-action focus:no-underline hover:no-underline hover:text-color-default-1"
                    >
                      {isSubMenuOpen?.subItem?.text?.jsonValue?.value}
                      <SVG className="w-5 h-5" svg="icon-arrow-right"></SVG>
                    </LinkWrapper>
                  </li>
                  {isSubMenuOpen.subItem?.children?.results.map((item: ItemType, key: number) => {
                    // if (Object.hasOwn(item, 'icon')) {
                    return (
                      <React.Fragment key={key}>
                        {/* Static Label */}
                        {item.labelText?.jsonValue?.value && (
                          <li
                            key={key}
                            className={`caption text-color-default-2 pl-2 ${
                              key > 0
                                ? 'mt-4 sm:mt-6 pt-4 sm:pt-6 border-t stroke-strokes-default-3'
                                : ''
                            }`}
                          >
                            {item.labelText?.jsonValue?.value}
                          </li>
                        )}
                        {/* END Static Label */}
                        {/* Secondary Links */}
                        {item.link?.jsonValue?.value && (
                          <li>
                            <LinkWrapper
                              field={item.link?.jsonValue || {}}
                              className="flex p-2 paragraph-2-medium rounded text-color-default-1 hover:text-color-default-1 hover:no-underline hover:bg-background-dark-1 active:bg-background-dark-1 active:border-color-active focus:outline-strokes-action  focus:no-underline focus:bg-background-dark-1"
                            >
                              {item.text?.jsonValue?.value}
                            </LinkWrapper>
                            {/* Tertiary Links */}
                            {item?.children?.results && item?.children?.results.length > 0 && (
                              <ul>
                                {item?.children?.results?.map((item: ItemType, key: number) => {
                                  const textValue = item.text?.jsonValue?.value;
                                  const hrefValue = item.link?.jsonValue?.value?.href;

                                  if (!textValue || !hrefValue) {
                                    return null; // Skip rendering if textValue is empty
                                  }
                                  return (
                                    <li className="flex" key={key}>
                                      <LinkWrapper
                                        field={item.link?.jsonValue || {}}
                                        className="w-full py-2 pr-2 pl-4 paragraph-2-regular text-color-default-1 rounded hover:bg-background-dark-1 hover:text-color-default-1 hover:no-underline focus:no-underline focus:bg-background-dark-1 focus:outline-strokes-action active:bg-background-dark-1 active:border-color-active"
                                      >
                                        {textValue}
                                      </LinkWrapper>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                            {/* END Tertiary Links */}
                          </li>
                        )}
                        {/* END Secondary Links */}
                      </React.Fragment>
                    );
                  })}
                </ul>
              </>
            )}
            {/* Mobile Utility */}
            <ul
              className={`flex flex-col mt-4 sm:mt-6 pt-4 sm:pt-6 border-t stroke-strokes-default-3 ${
                isOpen && !isSubMenuOpen.open ? '' : 'mx-8 pb-8 sm:pb-6'
              }`}
            >
              {utilityNavMobile?.children?.results?.map((item, key) => {
                const isSearchLink = item?.link?.jsonValue?.value?.class === 'jsMenuSearch';
                return (
                  <li className="flex" key={key}>
                    <LinkWrapper
                      field={item?.link?.jsonValue?.value || {}}
                      className="flex items-center gap-x-2 p-2 rounded group paragraph-3-regular text-color-default-2 hover:text-color-default-2 hover:bg-background-dark-1 focus:outline-strokes-action focus:no-underline focus:bg-background-dark-1 active:underline"
                      onClick={isSearchLink ? (e) => toggleSearchOverlay(e) : undefined}
                    >
                      {(item?.icon?.jsonValue?.fields as SubItem)?.Value?.value && (
                        <SVG
                          className="text-icon-default-2"
                          svg={(item?.icon?.jsonValue?.fields as SubItem)?.Value?.value}
                          hidden={false}
                        ></SVG>
                      )}
                      {item?.text?.jsonValue?.value}
                    </LinkWrapper>
                  </li>
                );
              })}
              {/* Subscribe Buttons */}
              {subscribeButton?.[0]?.children?.results?.map((item, key) => {
                return (
                  <li className="mt-4 sm:mt-6" key={key}>
                    <Button
                      tag="a"
                      type="auxiliary"
                      size="full"
                      href={item?.link?.jsonValue?.value.href}
                      label={item?.text?.jsonValue?.value}
                      title={item?.text?.jsonValue?.value}
                      fieldClass={item?.link?.jsonValue?.value?.class}
                    />
                  </li>
                );
              })}
              {/* END Subscribe Buttons */}
            </ul>
            {/* END Mobile Utility */}
          </div>
        </nav>
      )}
      {/* END Mobile Nav */}

      {isOpen && (
        <>
          <div
            onClick={handleHamburgerMenuClick}
            className="before:content-[''] before:block before:h-screen before:left-0 before:opacity-95 before:overflow-y-auto before:fixed before:top-0 before:w-screen before:z-10 before:bg-[#352b2b]"
          ></div>
        </>
      )}
      {/* END Main Nav */}
    </header>
  );
};

export default Header;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = clientFactory({});
  const result = await graphQLClient.request<unknown>(HeaderQuery, {
    datasource: rendering.dataSource,
    language: layoutData?.sitecore?.context?.language,
  });
  return {
    staticProps: result,
  };
};
