import React, { useEffect, useRef, useState } from 'react';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { BoK } from 'lib/templates/Feature.BOKF.model';
import SVG from 'helpers/SVG/SVG';

type LayoutProps = BoK.Content.Faq;

export type FaqProps = {
  params: { AnchorId?: string };
  fields: {
    children: BoK.Content.Faqitem[];
  };
} & LayoutProps;

const FaqItem = (item: BoK.Content.Faqitem): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const faqRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (faqRef.current) {
      const links = faqRef.current.querySelectorAll('a');
      links.forEach((link) => {
        link.tabIndex = drawerOpen ? 0 : -1;
      });

      if (drawerOpen) {
        faqRef.current.style.height = `${faqRef.current.scrollHeight}px`;
      } else {
        faqRef.current.style.height = '0px';
      }
    }
  }, [drawerOpen]);

  return (
    <>
      <dt>
        <button
          type="button"
          onClick={() => {
            setDrawerOpen(!drawerOpen);
          }}
          className="flex w-full justify-between px-2 py-4 lg:py-5 paragraph-2-regular items-center stroke-strokes-default-3 border-t hover:cursor-pointer hover:ease-in hover:font-medium focus:bg-background-dark-1 focus:outline-none"
        >
          <RichTextA11yWrapper field={item.fields?.FAQQuestion}></RichTextA11yWrapper>
          <SVG
            svg="icon-chevron-down"
            className={`transition-all ease-in-out ${drawerOpen ? 'rotate-180' : ''}`}
            title={drawerOpen ? 'Collapse' : 'Expand'}
          ></SVG>
        </button>
      </dt>
      <dd
        className={`overflow-hidden transition-height duration-500 ease-in-out stroke-strokes-default-3`}
        ref={faqRef}
      >
        <RichTextA11yWrapper
          field={item.fields?.FAQAnswer}
          tag="div"
          className="my-4 md:my-[26px] lg:my-5 mx-5 md:mx-6 lg:mx-8 paragraph-2-regular text-color-default-2 rtaw"
        ></RichTextA11yWrapper>
      </dd>
    </>
  );
};

const Faq = (props: FaqProps): JSX.Element => {
  const items =
    props?.fields?.FAQItems?.length === 0 ? props.fields?.children : props.fields?.FAQItems;

  return (
    <section className={`component container spacing-md`} id={props.params?.AnchorId || ''}>
      <RichTextA11yWrapper field={props?.fields?.FAQTitle} tag="h3"></RichTextA11yWrapper>
      <RichTextA11yWrapper
        field={props?.fields?.FAQDescription}
        tag="div"
        className="mt-4 lg:mt-6 paragraph-2-regular text-color-default-2 rtaw"
      ></RichTextA11yWrapper>
      <dl className="mt-6 last-of-type:border-b">
        {items &&
          items?.length > 0 &&
          items?.map((item: BoK.Content.Faqitem, key) => {
            return (
              <div key={key}>
                <FaqItem {...item} />{' '}
              </div>
            );
          })}
      </dl>
    </section>
  );
};

export default Faq;
