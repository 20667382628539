//Global
import React, { useState, useEffect } from 'react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface SharebarProps {
  params: Params;
  fields: SharebarFieldsProps;
}
interface SharebarFieldsProps {
  children: SharebarItemProps[];
}
interface SharebarItemProps {
  id: string;
  displayName: string;
  name: string;
  templateId: string;
  templateName: string;
  url: string;
  fields: Fields;
}
interface Fields {
  ShareLinkMarkup: Field<string>;
}

interface Params {
  SharebarNetworks: string;
  ShowSubscribeButton: string;
}

export type PageTitleProps = {
  MetaOGDescription: Field<string>;
  Headline: Field<string>;
  PageTitle: Field<string>;
  Teaser: Field<string>;
};

const HorizontalSharebar = (props: SharebarProps): JSX.Element => {
  const [svgs, setSvgs] = useState<string[]>([]);
  const { sitecoreContext } = useSitecoreContext();

  const children = props?.fields?.children;

  const { PageTitle, Teaser } = sitecoreContext?.route?.fields as PageTitleProps;

  const removeHTMLTags = (html: string) => {
    const refineHtmlString = html && html.replace(/<[^>]*>/g, '');
    return refineHtmlString;
  };

  useEffect(() => {
    const arr: string[] = [];
    children &&
      children.map(async (fields) => {
        let actualString = fields?.fields?.ShareLinkMarkup?.value;
        const pageTitleWithoutHtml = removeHTMLTags(PageTitle?.value);
        const TeaserWithoutHtml = removeHTMLTags(Teaser?.value);

        actualString = actualString.replace('{Title}', pageTitleWithoutHtml);
        actualString = actualString.replace('{BodyTeaser}', TeaserWithoutHtml);
        actualString = actualString.replace('{ShareUrl}', decodeURI(window?.location?.href));
        arr.push(actualString);
      });
    setSvgs(arr);
  }, [children]);

  if (!props?.fields) return <></>;

  return (
    <div className="flex items-center gap-x-8">
      {svgs &&
        svgs.map((item, index) => (
          <div
            key={index}
            className="flex [&_a]:h-7 [&_a]:w-7 [&_svg]:h-5 [&_svg]:w-5 [&_a]:p-2 [&_a]:items-center hover:[&_a]:bg-background-dark-2 hover:[&_a]:rounded focus:[&_a]:outline-strokes-action focus:[&_a]:bg-background-dark-2 focus:[&_a]:rounded"
            dangerouslySetInnerHTML={{ __html: item }}
          ></div>
        ))}
    </div>
  );
};

export default HorizontalSharebar;
